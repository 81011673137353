import React from 'react';
import {
    Modal,
    ModalBody,
    Button,
    Form,
    FormGroup,
    Label,
    ModalHeader,
    ModalFooter,
} from 'reactstrap';
import { IValidator } from './../../../interfaces';
import { reeValidators, staticConstants, MAGIC_NUMBER } from '../../../utils';
import ReeValidate from 'ree-validate';
import StarRatings from 'react-star-ratings';

interface IProps {
    isOpenModal: boolean;
    modalPopupObj: {};
    handleSubmit: any;
    hideModal(): void;
}

interface IState {
    modal: boolean;
    errors?: any;
    formData: {
        comment: string;
    };
    overAllQuality: number;
    participantsEngagement: number;
}

class ExpertRatingPopUp extends React.Component<IProps, IState> {
    public validator: IValidator;
    constructor(props: IProps) {
        super(props);
        this.validator = new ReeValidate({
            comment: (this.props.modalPopupObj['type'] !== 'participantsEngagement') 
            && reeValidators.required_min_3_max_500,
        });

        this.state = {
            modal: this.props.isOpenModal,
            formData: { comment: this.props.modalPopupObj['comment'] },
            errors: this.validator.errors,
            overAllQuality: this.props.modalPopupObj['overAllQuality'],
            participantsEngagement: this.props.modalPopupObj['participantsEngagement'],
        };
    }

    public changeRating = (newRating, name) => {
        this.setState<never>({
            [name]: newRating,
        });
    }
    public handleChange = (event: any) => {
        const { name, value } = event.target;
        const { errors } = this.validator;
        this.setState({ formData: { ...this.state.formData, [name]: value } });
        errors.remove(name);
        this.validator.validate(name, value)
            .then(() => {
                this.setState({ errors });
            });
    }

    public validateAndSubmit = (e) => {
        e.preventDefault();
        const modalData = this.props.modalPopupObj;
        const { comment } = (modalData['type'] !== 'participantsEngagement') && this.state.formData;
        const { errors } = this.validator;
        this.validator.validateAll({ comment })
            .then((success: boolean) => {
                if (success) {
                    this.props.handleSubmit(this.state);
                } else {
                    this.setState({ errors });
                }
            });
    }

    public render() {
        const { modal, formData, errors, overAllQuality, participantsEngagement } = this.state;
        const modalData = this.props.modalPopupObj;
        return (
            <Modal isOpen={modal} className="organization-code-modal">
                <ModalHeader toggle={this.props.hideModal}>{modalData['type'] === 'overAllQuality' ?
                 'Session Rating & Review' : 'Participant Rating'}</ModalHeader>
                <ModalBody>
                    <div className="rating-view-only d-flex flex-wrap">
                        {modalData && (modalData['type'] === 'overAllQuality' || modalData['type'] === 'both') &&
                            <div className="modal-rating-wrapper">
                                {
                                    modalData['overAllQuality'] > MAGIC_NUMBER.ZERO
                                        ? <h3>Overall quality of the session.</h3>
                                        : <h3>Rate the overall quality of the session.</h3>
                                }

                                <StarRatings
                                    rating={overAllQuality}
                                    changeRating={modalData['overAllQuality'] < 1 ? this.changeRating : null}
                                    numberOfStars={MAGIC_NUMBER.FIVE}
                                    starHoverColor="#4be59e"
                                    starEmptyColor="#eaeaea"
                                    starRatedColor="#4be59e"
                                    starDimension="20px"
                                    starSpacing="5px"
                                    name="overAllQuality"
                                />
                                <p>{staticConstants.RATE_OVERALL_SESSION_QUALITY[this.state.overAllQuality]}</p>
                            </div>
                        }
                        {modalData && (modalData['type'] === 'participantsEngagement' || modalData['type'] === 'both') &&
                            <div className={`modal-rating-wrapper ${modalData['type'] === 'participantsEngagement'
                             && modalData['participantsEngagement'] > MAGIC_NUMBER.ZERO ? ' mb-2' : ''
                                }`}
                            >
                                {
                                    modalData['participantsEngagement'] > MAGIC_NUMBER.ZERO
                                        ? <h3>Participant's engagement level during the session</h3>
                                        : <h3>Rate the participant's engagement level during the session</h3>

                                }
                                <StarRatings
                                    rating={participantsEngagement}
                                    changeRating={modalData['participantsEngagement'] < 1 ? this.changeRating : null}
                                    numberOfStars={MAGIC_NUMBER.FIVE}
                                    starHoverColor="#4be59e"
                                    starEmptyColor="#eaeaea"
                                    starRatedColor="#4be59e"
                                    starDimension="20px"
                                    starSpacing="5px"
                                    name="participantsEngagement"
                                />
                                <p>{staticConstants.RATE_PARTICIPANTS_ENGAGEMENT[this.state.participantsEngagement]}</p>
                            </div>
                        }
                    </div>
                    {modalData && (modalData['type'] === 'overAllQuality' || modalData['type'] === 'both') &&
                        <Form onSubmit={this.validateAndSubmit}>
                            <FormGroup
                                className={`floating-label disabled-input textarea-label ${
                                    errors.has('comment') ? ' has-error' : ''
                                    } ${
                                    modalData['overAllQuality'] > MAGIC_NUMBER.ZERO ? ' mb-0' : ''
                                    }`}>

                                {modalData['overAllQuality'] > MAGIC_NUMBER.ZERO ?
                                    <span className="review-readonly-text">{formData.comment}</span>
                                    :
                                    <textarea
                                        className={`form-control textarea-md ${
                                            modalData['overAllQuality'] > MAGIC_NUMBER.ZERO ? ' border-0' : ''
                                            }`}
                                        name="comment"
                                        id="comment"
                                        value={formData.comment}
                                        onChange={this.handleChange}
                                        maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                                        placeholder="Write a review" disabled={modalData['overAllQuality'] > MAGIC_NUMBER.ZERO}
                                    />
                                }
                                {modalData['overAllQuality'] > MAGIC_NUMBER.ZERO
                                    ? ''
                                    : <span className="text-count">{MAGIC_NUMBER.FIVE_HUNDRED - formData.comment.length}/500</span>
                                }
                                <Label for="summary" className={formData.comment ? 'selected' : ''}>
                                    {modalData['overAllQuality'] > MAGIC_NUMBER.ZERO ? 'Review' : 'Write a review'}
                                </Label>
                                {errors.has('comment') &&
                                    <div className="error-text">
                                        {errors.first('comment').replace('comment', '')}
                                    </div>
                                }
                            </FormGroup>
                        </Form>
                    }
                </ModalBody>

                {
                    modalData && modalData['type'] === 'overAllQuality' && modalData['overAllQuality'] < 1 &&
                    <ModalFooter>
                        <Button className="btn btn-regular mr-2" onClick={this.props.hideModal}>Cancel</Button>
                        <Button color="primary" disabled={this.state.overAllQuality < 1} onClick={this.validateAndSubmit}>Submit</Button>
                    </ModalFooter>
                }
                {
                    modalData && modalData['type'] === 'participantsEngagement' && modalData['participantsEngagement'] < 1 &&
                    <ModalFooter>
                        <Button className="btn btn-regular mr-2" onClick={this.props.hideModal}>Cancel</Button>
                        <Button color="primary" disabled={this.state.participantsEngagement < 1} onClick={this.validateAndSubmit}>Submit</Button>
                    </ModalFooter>
                }
                {
                    modalData && modalData['type'] === 'both' &&
                    <ModalFooter>
                        <Button className="btn btn-regular mr-2" onClick={this.props.hideModal}>Cancel</Button>
                        <Button color="primary" onClick={this.validateAndSubmit}>Submit</Button>
                    </ModalFooter>
                }
            </Modal>
        );
    }
}

export { ExpertRatingPopUp };
