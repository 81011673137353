import { bidStatusText, CSS_ClASSES, CONSTANTS } from './staticConstants';

export const messages = {
  profileImageInvalidExtension: 'Only .png, .jpg are allowed',
  profileImageMaxSize: 'Max upload size is 5 mb.',
  confirmPasswordNotMatch: 'Confirm password does not match',
  profileUpdated: 'Your profile has been updated.',
  stripeConnectSuccess: 'You have successfully created your Stripe account.',
  invalidPhoneNumber: 'The phone number field can have maximum 15 and minimum 5 characters',
  flaggedUser: 'User flagged successfuly.',
  goalRated: 'Goal rated successfully.',
  validateAttendance: 'Attendance marked successfully.',
  commissionLimit: 'Commission field can have maximum 100%',
  alreadyExistingEmail: 'This email already exists',
};

export const deleteGoalMsg = {
  title: 'Delete Request',
  body: 'This will delete your request from the list.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Delete',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const deleteFaq = {
  title: 'Delete FAQs',
  body: 'This will delete FAQ from the list.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Delete',
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const updateActiveUserStatusMsg = {
  title: 'Activate User',
  body: 'You want to activate this user.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Active',
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const updateInActiveUserStatusMsg = {
  title: 'Deactivate User',
  body: 'You are going to deactivate this user.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Deactivate',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const rejectUserRequestMsg = {
  title: 'Reject Request',
  body: 'This request will reject from the list.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Reject',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const rejectLicenceMovement = {
  title: 'Deny Licence Movement Request',
  body: 'This will deny the request to exceed the monthly limit of licence movements for this user.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Deny',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const acceptLicenceMovementMsg = {
  title: 'Accept Licence Movement Request',
  body: 'This will accept the request to exceed the monthly limit of licence movements for this user.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Accept',
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const acceptRateMsg = {
  title: 'Accept Rate',
  body: 'This rate change request will accept from the list.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Accept',
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const rejectRateMsg = {
  title: 'Reject Rate',
  body: 'This rate change request will reject from the list.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Reject',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const deleteExpertMsg = {
  title: 'Delete Expert',
  body: 'This expert will be deleted from the list.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Delete',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const registerFbAccountWithEmail = {
  title: 'Message',
  body: `We couldn't find an email ID associated with this account. Please add your email to your Facebook account & try again.`,
  areYouSure: '',
  yesBtnText: 'OK',
  noBtnText: '',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const sendRFPModalContent = {
  title: bidStatusText.sendRequest,
  body: 'This will send your request to experts.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: bidStatusText.sendRequest,
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const sendForApprovalModalContent = {
  title: bidStatusText.sendRequest,
  body: 'This will send your request to Team Admin for approval.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: bidStatusText.sendRequest,
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const cancelRequest = {
  title: 'Cancel Request',
  body: 'Are you sure? <br/> This will cancel selected request from the list.',
  areYouSure: '',
  yesBtnText: 'Yes',
  noBtnText: 'No',
  yesBtnClass: '',
  noBtnClass: '',
};

export const cancelEvent = {
  title: 'Cancel Event',
  body: `Cancellation Policy: <br/> Cancellation with more than 48hrs from scheduled event: 30% charged and the balance will be returned.
  <br/> Cancellation within 48 hours of scheduled event: No refund. <br/>
  <br/> Are you sure you want to cancel event?`,
  areYouSure: '',
  yesBtnText: 'Yes',
  noBtnText: 'No',
  yesBtnClass: '',
  noBtnClass: '',
};

export const rejectBidModalContent = {
  title: 'Reject Bid',
  body: 'Are you sure? <br/> This will reject bid from the list.',
  areYouSure: '',
  yesBtnText: 'Reject',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const cancelBid = {
  title: 'Cancel Bid',
  body: 'Are you sure you want to cancel this bid?',
  areYouSure: '',
  yesBtnText: 'Yes',
  noBtnText: 'No',
  yesBtnClass: '',
  noBtnClass: '',
};

export const unsaveRequest = {
  title: 'Unsave Request',
  body: 'Are you sure you want to unsave this bid?',
  areYouSure: '',
  yesBtnText: 'Yes',
  noBtnText: 'No',
  yesBtnClass: '',
  noBtnClass: '',
};

export const extendEventMsg = {
  title: 'Event Extend',
  body: 'Do you want to continue?',
  areYouSure: '',
  yesBtnText: 'Yes',
  noBtnText: 'No',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const cancelSubscriptionModalContent = {
  title: 'Cancel Plan',
  areYouSure: '',
  body: `Your subscription will be cancelled immediately and you will not be able to access the platform without buying subscription again.
  </br> Are you sure you want to cancel the plan?`,
  yesBtnText: 'Yes',
  noBtnText: 'No',
  yesBtnClass: 'btn-delete ',
  noBtnClass: '',
};

export const expertAddOnMsg = {
  title: 'Expert Profile',
  areYouSure: '',
  body: 'Expert\'s profile is locked. <br/> To view an expert\'s full profile details you need to purchase an add-on.',
  btnText: CONSTANTS.PURCHASE_ADD_ON,
  btnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const orgAdminAddOnMsg = {
  title: 'Team Admin',
  areYouSure: '',
  body: 'Team\'s admin profile is locked. <br/> To view an Team Admin\'s full profile you need to purchase <br/> an add-on.',
  btnText: CONSTANTS.PURCHASE_ADD_ON,
  btnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const learnerAddOnMsg = {
  title: 'Locked Profile',
  areYouSure: '',
  body: 'To view a user profile you need to purchase an add-on.',
  btnText: CONSTANTS.PURCHASE_ADD_ON,
  btnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const professionalAddOnMsg = {
  title: 'Individual Profile',
  areYouSure: '',
  body: 'Individual\'s profile is locked. <br/> To view an Individual\'s full profile you need to purchase <br/> an add-on.',
  btnText: CONSTANTS.PURCHASE_ADD_ON,
  btnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const assignLicence = {
  title: 'Assign Licence',
  areYouSure: '',
  body: 'This will assign a licence to learner. <br/> Are you sure?.',
  yesBtnText: 'Assign',
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const unAssignLicence = {
  title: 'Unassign Licence',
  areYouSure: '',
  body: 'This will unassign a licence from learner. <br/> Are you sure?.',
  yesBtnText: 'Unassign',
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnDel,
  noBtnClass: '',
};

export const raiseLicenceRequestToAdmin = {
  title: 'Licence Movement Exceeded',
  areYouSure: '',
  body: `A user can only move a licence between users up to 3
  <br/> times per month. To exceed this movement, approval needs to be <br/> gained from system admin by raising a request.
  <br/> Are you sure you would like to raise <br/> the request to move this licence?`,
  yesBtnText: 'Raise',
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const subscribeMoreLicence = {
  title: 'Subscribe More Licence',
  areYouSure: '',
  body: 'You have exhausted the number of licences. <br/> Please by more licence to allocate to more users.',
  yesBtnText: 'Subscribe',
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const deleteLearner = {
  title: 'Delete Team Member',
  areYouSure: '',
  body: 'This will delete Team Member from account. <br/> Are you sure?',
  yesBtnText: 'Delete',
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnDel,
  noBtnClass: '',
};

export const deleteSavedCard = {
  title: 'Delete Card',
  areYouSure: '',
  body: 'Are you sure you want to delete this card?',
  yesBtnText: 'Yes',
  noBtnText: 'No',
  yesBtnClass: CSS_ClASSES.btnDel,
  noBtnClass: '',
};

export const deleteFeedback = {
  title: 'Delete Feedback',
  areYouSure: '',
  body: 'This will delete feedback from the list. <br/> Are you sure?.',
  yesBtnText: 'Delete',
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const deletePlan = {
  title: 'Delete Plan',
  areYouSure: '',
  body: 'This will delete plan from the list. <br/> Are you sure?.',
  yesBtnText: 'Delete',
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnDel,
  noBtnClass: '',
};

export const deleteChatGroup = {
  title: 'Delete Group',
  body: 'This group will deleted.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Delete',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const sessionFeePaymenTSuccess = {
  title: 'Success',
  body: 'Your payment was successful.',
  okBtnText: 'Ok',
  okBtnClass: '',
};

export const extendEventPaymentModal = {
  title: 'Payment',
  areYouSure: '',
  body: 'Your request to extend session has been accepted. Please initiate payment now.',
  yesBtnText: 'Pay',
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const learnerAlreadyAssignToGoalError = {
  title: 'Error',
  areYouSure: '',
  body: `A licence cannot be unassigned from a user that is <br/>  currently associated with one or more Goals or Scheduled Events. <br/> 
  Remove the user from the Goal or Scheduled Event and try again.`,
  yesBtnText: 'Ok',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
  closePopup: 'yes',
};

export const deleteDomainMsg = {
  title: 'Delete Domain',
  body: 'This will delete domain from the list.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Delete',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const deleteSubDomainMsg = {
  title: 'Delete Sub-Domain',
  body: 'This will delete sub-domain from the list.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Delete',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const deleteCategoryMsg = {
  title: 'Delete Category',
  body: 'This will delete category from the list.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Delete',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const deleteSubCategoryMsg = {
  title: 'Delete Sub-Category',
  body: 'This will delete sub-category from the list.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Delete',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const deleteTopicMsg = {
  title: 'Delete Topic',
  body: 'This will delete topic from the list.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Delete',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const deleteActivityCodeMsg = {
  title: 'Delete Activity Code',
  body: 'This will delete activity code from the list.',
  areYouSure: bidStatusText.areYouSure,
  yesBtnText: 'Delete',
  noBtnText: 'Cancel',
  yesBtnClass: '',
  noBtnClass: '',
};

export const publishTopicMsg = {
  title: 'Publish Topic',
  areYouSure: '',
  body: 'Are you sure you want to publish this topic?',
  yesBtnText: 'Publish',
  noBtnText: 'Cancel',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const zoomEventStartModalContent = (date: string, time: string) => {
  return { title: 'Zoom Event',
    areYouSure: '',
    body: `This event is scheduled to start on <b>${date}</b> at <b>${time}</b>.`,
    okBtnText: 'Ok'};
};

export const completeRequest = {
  title: 'Close Request',
  body: 'This will stop this request from receiving any new bids. You can save this request as a goal or click delete to close this request.',
  areYouSure: '',
  yesBtnText: 'Delete',
  noBtnText: 'Save as a goal',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};

export const rescheduleRequest = {
  title: 'Reschedule Request',
  body: 'Select a new time and date availability below:',
  areYouSure: '',
  toDateRequested: null,
  toTimeRequested: null,
  fromTime: null,
  yesBtnText: 'Accept',
  noBtnText: 'Reject',
  yesBtnClass: CSS_ClASSES.btnPrimary,
  noBtnClass: '',
};
