import { GET_MESSAGE_BOARD_CONNECTIONS, GET_GROUPS } from '../graphql-client/queries/messageBoardQuery';
import { CREATE_GROUP, REMOVE_GROUP } from '../graphql-client/mutations/messageBoardMutation';
import { client } from '../index';
import { handleResponse } from '../utils/common';

export const messageBoardServices = {
  getMessageBoardConnections,
  getGroups,
  createGroup,
  removeGroup,
};

async function getMessageBoardConnections() {
  const response = await client.query({
    query: GET_MESSAGE_BOARD_CONNECTIONS,
  });
  const resp = await handleResponse(response);
  return resp.messageBoard;
}

async function getGroups() {
  const response = await client.query({
    query: GET_GROUPS,
  });
  const resp = await handleResponse(response);
  return resp.getGroup;
}

async function createGroup(groupName: string, users: Array<[string]>) {
  const response = await client.mutate({
    mutation: CREATE_GROUP,
    context: { useApolloNetworkStatus: true },
    variables: {
      groupName,
      users,
    },
  });
  const resp = await handleResponse(response);
  return resp.createGroup;
}

async function removeGroup(groupId: string) {
  const response = await client.mutate({
    mutation: REMOVE_GROUP,
    context: { useApolloNetworkStatus: true },
    variables: { groupId },
  });
  const resp = await handleResponse(response);
  return resp.removeGroup;
}
