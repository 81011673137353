import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../../shared';
import { history } from '../../../helpers';
import { Back } from '../../shared/back/back';
import paymentIcon from './../../../assets/images/icons/payment.svg';
import { expertPaymentAction } from './redux/actions';
import { ErrorPopup } from '../../shared/modals/errorPopup';
import { ILocalUser } from './../../../interfaces';
import { formatCurrency } from '../../../utils/common';
import { MAGIC_NUMBER } from '../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  expertIncome: { totalReceieved: number, totalPending: number };
  user: ILocalUser;
}

interface IState {
  displayErrorPopup: boolean;
}

class WithdrawAmount extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = { displayErrorPopup: false };
  }
  public componentDidMount() {
    this.props.dispatch(expertPaymentAction.getExpertIncome());
  }

  public withdrawAmount = () => {
    const { dispatch, user } = this.props;
    if (!Boolean(user.isStripeAccount)) {
      this.setState({ displayErrorPopup: true });
    } else {
      dispatch(expertPaymentAction.withdrawAmount());
    }
  }

  public render() {
    const { expertIncome: { totalReceieved, totalPending }, user } = this.props;
    return (
      <>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <Back {...this.props} />
          <div className="dashboard-content shadow">
            <div className="withdraw-content withdraw-content-col-2">
              <div  className="withdraw-box">
                <div className="view-profile-box flex-wrap p-0">
                  <div className="user-profile-details">
                    <h4>Total Balance</h4>
                    <span className="profile-picture-view-only payment-card-icon">
                      <img src={paymentIcon}
                        alt="payment icon" />
                    </span>
                    <div className="profile-email-view-only text-truncate">
                      <h3 className="text-truncate text-lg">
                        <em>$</em>
                        {formatCurrency(+totalPending.toFixed(MAGIC_NUMBER.TWO))}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div  className="withdraw-box">
                <div className="view-profile-box flex-wrap p-0">
                  <div className="user-profile-details">
                    <h4>Available today</h4>
                    <span className="profile-picture-view-only payment-card-icon">
                      <img src={paymentIcon}
                        alt="payment icon" />
                    </span>
                    <div className="profile-email-view-only text-truncate">
                      <h3 className="text-truncate text-lg">
                        <em>$</em>
                        {formatCurrency(+totalReceieved.toFixed(MAGIC_NUMBER.TWO))}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <p className="withdraw-text">
              Funds become available at the completion of an event. You’ll be
              able to withdraw as soon as you reach a minimum threshold of $100. Contact
              <a href="mailto:systemadmin@ovlp.app"> systemadmin@ovlp.app</a> if you have any questions.
              </p>
            </div>
            <div className="card-footer d-flex mt-0">
              <Button color="icon" className="icon danger ml-2" onClick={() => history.goBack()}>
                <em className="icon icon-cross danger" />
                Cancel
            </Button>
              <Button color="primary" className="btn ml-auto" disabled={!totalReceieved} onClick={this.withdrawAmount}>
                Withdraw
            </Button>
            </div>
          </div>
        </div>

        { this.state.displayErrorPopup &&
              <ErrorPopup
                isOpenModal={true}
                hideModal={() => this.setState({ displayErrorPopup: false })}
                connectStripe={() => {
                  this.setState({ displayErrorPopup: false });
                  history.push(`/${user.role.toLowerCase()}/viewProfile/#3`);
                }}
              />
            }
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { expertPayments: { expertIncome }, authentication: { user } } = state;
  return { expertIncome, user };
}

const connectedWithdrawAmount = connect(mapStateToProps)(WithdrawAmount);

export default connectedWithdrawAmount;
