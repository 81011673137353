import { notificationServices } from '../../../../services';
import { alertActions } from '../../../alert/redux/actions';
import { endLoading, startLoading } from '../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { handleError } from '../../../../utils';

export const notificationAction = {
  getNotifications,
  markAsRead,
};

function getNotifications(notification: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
      dispatch(request());
      dispatch(startLoading('MAIN_LOADER', 'Loading'));
      const isTopListing = notification.isTopListing;
      delete notification.isTopListing;
      notificationServices.getNotification(notification)
            .then(
                (notifications) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  if (isTopListing) {
                      dispatch(successForTopListing(notifications));
                    } else {
                      dispatch(success(notifications));
                    }
                },
                (error) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
    };

  function request() { return { type: actionTypes.NOTIFICATION_LIST_REQUEST }; }
  function success(notifications: string) { return { type: actionTypes.NOTIFICATION_LIST_SUCCESS, notifications }; }
  function successForTopListing(notifications: string) { return { type: actionTypes.TOP_NOTIFICATION_LIST_SUCCESS, notifications }; }
  function failure(error: string) { return { type: actionTypes.NOTIFICATION_LIST_FAILURE, error }; }
}

function markAsRead(notification: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
      dispatch(startLoading('MAIN_LOADER', 'Loading'));
      notificationServices.markAsRead(notification)
            .then(
                (data) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(data));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
    };
  function success(data: string) { return { type: actionTypes.UPDATE_NOTIFICATION_STATUS_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_NOTIFICATION_STATUS_FAILURE, error }; }
}
