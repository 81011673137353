import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  FormGroup,
  Input,
  NavLink
} from 'reactstrap';
import { history } from '../../helpers/history';
import logoImage from './../../assets/images/logo.svg';
import univercityImage from './../../assets/images/university.svg';
import univercityHover from './../../assets/images/universityhover.svg';
import businessImage from './../../assets/images/businessman.svg';
import businessHover from './../../assets/images/businessmanhover.svg';
import { pageRoutes } from './../../utils/routeConstants';

interface IState {
  selectUserType?: string;
  checked?: boolean;
  userTypeChecked?: boolean;
}

class UserTypeContainer extends React.Component<{}, IState> {

  constructor(props: any) {
    super(props);
    this.state = {
      selectUserType: '',
      checked: false,
      userTypeChecked: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.moveToRegisterPage = this.moveToRegisterPage.bind(this);
  }

  public handleChange(value: string) {
    if (value === 'checked') {
      this.setState({ checked: !this.state.checked });
    } else {
      this.setState({
        selectUserType: value,
        userTypeChecked: true,
      });
    }
  }

  public moveToRegisterPage() {
    const path = `${pageRoutes.REGISTER.PATH}/${this.state.selectUserType}`;
    history.push(path);
  }

  public render() {
    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-12 col-12 pt-5 pb-5">
            <div className="row">
              <div className="col-12 text-center logo_2">
                <NavLink href="/">
                  <img src={logoImage} alt="Logo" className="img-fluid" />{' '}
                </NavLink>
              </div>
              <div className="col-12 mt-4 mb-3">
                <h2 className="common_heading mt-2 mb-3">Select User Type</h2>
                <p className="common_p">You can register either as a Team or Individual by clicking on the relevant icon.</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-md-0 mb-3">
                <FormGroup check>
                  <Input type="radio" name="selectUserType" value="orgadmin" onClick={() => this.handleChange('orgadmin')} />
                  {/* <a href="team.html" className="user_select_link"> */}
                  <div className="user_box"  onClick={() => this.handleChange('orgadmin')}>
                    <img src={univercityImage} alt="profile" />
                    <img src={univercityHover} alt="profile" />
                    <h5>Team</h5>
                  </div>
                  {/* </a> */}
                </FormGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-md-0 mb-3">
                <FormGroup check className="user_select_link">
                  <Input type="radio" name="selectUserType" value="professional" onClick={() => this.handleChange('professional')} />
                  {/* <a href="individual.html" className="user_select_link"> */}
                  <div className="user_box" onClick={() => this.handleChange('professional')} >
                    <img src={businessImage} alt="profile" />
                    <img src={businessHover} alt="profile" />
                    <h5>Individual</h5>
                  </div>
                  {/* </a> */}
                </FormGroup>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12  mt-lg-4 mt-2 agree_checkbox">
              <label className="control control--checkbox m-0 pl-4">
                <Input
                  type="checkbox"
                  name="isAddOn"
                  onChange={() => this.handleChange('checked')}
                />I agree to <Link to="/termsConditions" target="_blank" className="terms-link">Terms &amp; Conditions</Link>
                <div className="control__indicator" />
              </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12  mt-lg-4 mt-3">
                {/* <a className="get_start_btn cta_btn d-block text-center big_btn" href="#">NEXT</a> */}
                <Button onClick={this.moveToRegisterPage}
                 disabled={!this.state.checked || !this.state.userTypeChecked} 
                 color="cta_btn"
                 className="get_start_btn cta_btn d-block text-center big_btn">
                  Next </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { UserTypeContainer };
