import React, { Fragment, Dispatch } from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { DashBoardPage } from './DashBoardPage';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

class DashBoardContainer extends React.Component<IProps> {

  public render() {
    const props = this.props;
    return (
          <div className="form-container d-flex">
          <Fragment>
             <DashBoardPage {...props}/>
          </Fragment>
      </div>
    );
  }
}

export { DashBoardContainer };
