import React from 'react';
import { staticConstants, EVENT_PAYMENT_TYPES, MAGIC_NUMBER } from '../../../utils';
import CheckOut from './CheckOut';

interface IProps {
  role: string;
  isExpertPurchaseAddon: boolean;
  isAddOn: boolean;
  action: string;
  licence: number;
  amount: number;
  taxRate: number;
  canAvailTrialPeriod: boolean;
}

function PurchaseDetail(props: IProps) {
  const { role, isExpertPurchaseAddon, action, licence, isAddOn, amount, taxRate, canAvailTrialPeriod } = props;
  return (
      <>
      <div className="addon-wrapper">
        {role !== staticConstants.ROLE.PROFESSIONAL &&
            [EVENT_PAYMENT_TYPES.SESSION_FEE, EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE].indexOf(action) === MAGIC_NUMBER.FOUND_INDEX &&
                <div className="addon-details">
                    {!isExpertPurchaseAddon ?
                        <>
                            <h3>Total Licences (incl. Team Admin)</h3>
                            <h1>{Number(licence)}</h1>
                        </>
                        : <h1>1</h1>}
                    {isExpertPurchaseAddon && isAddOn &&
                        <h2> Add On</h2>}
                    {!isExpertPurchaseAddon && isAddOn && <h2>+ Add On</h2>}
                </div>
        }
        {[EVENT_PAYMENT_TYPES.SESSION_FEE, EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE].indexOf(action) > MAGIC_NUMBER.FOUND_INDEX &&
            <div className="w-100">
                <div className="addon-details">
                    <h3>Session Fee</h3>
                    <h1>${amount}</h1>
                    {isExpertPurchaseAddon && isAddOn &&
                        <h2> Add On</h2>}
                    {!isExpertPurchaseAddon && isAddOn && <h2>+ Add On</h2>}
                </div>
            </div>
        }
            <CheckOut
            amount={canAvailTrialPeriod ? 0 : amount}
            licences={licence}
            taxRate={canAvailTrialPeriod ? 0 : taxRate}
            />
        </div>
    </>
  );
}

export default PurchaseDetail;
