import React, { Dispatch } from 'react';
import {
    Button,
    Form,
    FormGroup,
    Input, Label,
} from 'reactstrap';
import { IChangePasswordReq, IValidator } from './../../../interfaces/';
import { profileAction } from './redux/actions';

import ReeValidate from 'ree-validate';
import { validator } from './../../../helpers';
import { messages } from './../../../utils/messages';
import OpenFieldTypes from 'OpenFieldTypes';
import { connect } from 'react-redux';
import { MAGIC_NUMBER, CONSTANTS } from './../../../utils';

validator(ReeValidate);

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  toggle: any;
  changePasswordSuccess: boolean;
  clearData: boolean;
}

interface IState {
  passwordFormData: {
      oldPassword: string;
      newPassword: string;
      confirmPassword: string;
    };
  errors: any;
  createPassword?: any;
  confirmPassErr: string;
}

class ChangePasswordForm extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: any) {
      super(props);
      this.validator = new ReeValidate({
          oldPassword: 'required',
          newPassword: 'required|verify_password',
          confirmPassword: 'required',
        });
      this.state = {
          passwordFormData: {
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            },
          errors: this.validator.errors,
          confirmPassErr: '',
        };
    }

  public componentWillReceiveProps(props: any, nextProps: any) {
      const { errors } = this.validator;
      if (props.changePasswordSuccess || props.clearData) {
          errors.remove('newPassword');
          errors.remove('oldPassword');
          errors.remove('confirmPassword');
          this.setState({
              passwordFormData: {
                  oldPassword: '',
                  newPassword: '',
                  confirmPassword: '',
                },
              confirmPassErr: '',
            });
        }
    }

  public validateConfirmPassword() {
      const passwordFormData = this.state.passwordFormData;
      let confirmPassErr = this.state.confirmPassErr;
      if (passwordFormData.newPassword && passwordFormData.confirmPassword) {
          if (passwordFormData.confirmPassword !== passwordFormData.newPassword) {
              confirmPassErr = messages.confirmPasswordNotMatch;
            } else {
              confirmPassErr = '';
            }
        }
      this.setState({ confirmPassErr });
    }
  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      const { errors } = this.validator;

      this.setState({ passwordFormData: { ...this.state.passwordFormData, [name]: value } }, () => {
          this.validateConfirmPassword();
        });
      errors.remove(name);

      this.validator.validate(name, value)
            .then(() => {
              this.setState({ errors });
            });

    }
  public validateAndSubmit = (e: { preventDefault: () => void }) => {
      e.preventDefault();
      const { oldPassword, newPassword, confirmPassword } = this.state.passwordFormData;
      const { errors } = this.validator;
      this.validateConfirmPassword();
      if (!this.state.confirmPassErr) {
          this.validator.validateAll({ oldPassword, newPassword, confirmPassword })
                .then((success: boolean) => {
                  if (success) {
                      this.handleSubmit();
                    } else {
                      this.setState({ errors });
                    }
                });
        }

    }

  public handleSubmit = () => {
      const { dispatch } = this.props;
      const { oldPassword, confirmPassword } = this.state.passwordFormData;
      const obj: IChangePasswordReq = {
          oldPassword,
          confirmPassword,
        };
      dispatch(profileAction.changePassword(obj));
    }

  public render() {
      const { passwordFormData, errors, confirmPassErr } = this.state;
      return (<div className="form-section" >
            <div className="form-wrapper d-flex align-items-center flex-wrap">
                <div className="form-group-wrapper create-password-wrapper">
                    <Form noValidate>
                        <FormGroup
                            className={`floating-label ${
                                passwordFormData.oldPassword.length > MAGIC_NUMBER.ZERO && !errors.has('oldPassword') ? CONSTANTS.SET_PASSWORD : ''
                                }`}>

                            <Input
                                type="password"
                                className="form-control"
                                name="oldPassword"
                                id="oldPassword"
                                value={passwordFormData.oldPassword}
                                onChange={this.handleChange}
                                placeholder="Old Password"
                                ref="oldPassword"
                            />
                            <Label for="firstName">Old Password</Label>
                            {errors.has('oldPassword') &&
                                <div className="error-text">{errors.first('oldPassword').replace('oldPassword', 'old password')}</div>
                            }
                        </FormGroup>
                        <FormGroup
                            className={`floating-label ${
                                passwordFormData.newPassword.length > MAGIC_NUMBER.ZERO && !errors.has('newPassword') ? CONSTANTS.SET_PASSWORD : ''
                                }`}>

                            <Input
                                type="password"
                                className="form-control"
                                name="newPassword"
                                id="newPassword"
                                value={passwordFormData.newPassword}
                                onChange={this.handleChange}
                                placeholder="New Password"
                                ref="newPassword"
                            />
                            <Label for="firstName">New Password</Label>
                            {errors.has('newPassword') &&
                                <div className="error-text">{errors.first('newPassword').replace('newPassword', ' new password')}</div>
                            }
                        </FormGroup>
                        <FormGroup
                            className={`floating-label ${
                                passwordFormData.confirmPassword.length > MAGIC_NUMBER.ZERO && (!errors.has('confirmPassword') && !confirmPassErr) ? CONSTANTS.SET_PASSWORD : ''
                                }`}>
                            <Input
                                type="password"
                                className="form-control"
                                name="confirmPassword"
                                id="confirmPassword"
                                value={passwordFormData.confirmPassword}
                                onChange={this.handleChange}
                                placeholder="Confirm New Password"
                            />
                            <Label for="confirmPassword">Confirm New Password</Label>
                            {errors.has('confirmPassword') || confirmPassErr ?
                                <div className="error-text">
                                    {(errors.has('confirmPassword') && errors.first('confirmPassword').replace('confirmPassword', 'confirm new password')) || confirmPassErr}</div>
                                : ''
                            }
                        </FormGroup>

                        <FormGroup className="login-button-group mb-0 d-flex justify-content-end">
                            <Button onClick={() => { this.props.toggle('1'); }} className="btn btn-regular mr-3">
                                Cancel
                            </Button>
                            <Button color="primary" className="" onClick={this.validateAndSubmit}>
                                Update
                          </Button>
                        </FormGroup>
                    </Form>
                </div>
            </div>
        </div>);
    }
}
function mapStateToProps(state: any) {
  const { changePasswordSuccess } = state.updateProfile;
  return {
      changePasswordSuccess,
    };

}
const connectedChangePasswordForm = connect(mapStateToProps)(ChangePasswordForm);
export { connectedChangePasswordForm as ChangePasswordForm };
