import gql from 'graphql-tag';

export const CREATE_GROUP = gql`
  mutation createGroup(
    $groupName: String!,
    $users: [String]!,
  ) {
      createGroup(
        groupName: $groupName,
        users: $users
      ) {
        message
      }
    }
`;

export const REMOVE_GROUP = gql`
  mutation removeGroup(
    $groupId: String!,
  ) {
    removeGroup(
        groupId: $groupId,
      ) {
        message
      }
    }
`;
