import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Element, Events, scrollSpy, scroller } from 'react-scroll';
import { Button, Modal, ModalBody, ModalFooter, } from 'reactstrap';
import { registerAction } from './redux/actions';
import Stepper from 'react-stepper-horizontal';
import preImage from './../../../assets/images/back_step_arrow.png';
import nextImage from './../../../assets/images/next_arrow.png';
import OpenFieldTypes from 'OpenFieldTypes';
import { PersonalInfoForm } from './PersonalInfoForm';
import { EducationForm } from './EducationForm';
import { ExperienceForm } from './ExperienceForm';
import { ReferenceForm } from './ReferenceForm';
import { ReferredForm } from './ReferredForm';
import { staticConstants, MAGIC_NUMBER } from '../../../utils';
import { mapTagstoAPIRequest } from '../../../utils/common';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

interface IState {
  errors?: any;
  activeStep: number;
  sharingExpertiseModal: any;
}

class RegisterForm extends React.Component<IProps, IState> {

  public referredFormRef: any;
  public personalInfoFormRef: any;
  public educationFormRef: any;
  public experienceFormRef: any;
  public referenceFormRef: any;

  /**
   * @description
   * constructor is used to define the initial state and property
   * @param fields {Object} props
   */
  constructor(props: IProps) {
    super(props);
    this.state = {
      sharingExpertiseModal: false,
      activeStep: staticConstants.EXPERT_REGISTER_FORM_STEP.REFERRED_INFO,
    };
    this.referredFormRef = React.createRef();
    this.personalInfoFormRef = React.createRef();
    this.educationFormRef = React.createRef();
    this.experienceFormRef = React.createRef();
    this.referenceFormRef = React.createRef();
  }

  public componentDidMount() {
    Events.scrollEvent.register('begin', function (to, element) { });
    Events.scrollEvent.register('end', function (to, element) { });
    scrollSpy.update();
  }

  public componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  public openExpertiseModal = () => {
    this.setState({
      sharingExpertiseModal: true,
    });
  }
  public hideExpertiseModal = () => {
    this.setState({
      sharingExpertiseModal: false,
    });
  }

  /**
   * @description
   * moveBack is called when someone click on the back button
   */
  public moveBack = () => {
    const { activeStep } = this.state;
    if (activeStep > staticConstants.EXPERT_REGISTER_FORM_STEP.REFERRED_INFO) {
      this.setState({ activeStep: activeStep - 1 });
    }
  }

  /**
   * @description
   * moveNext is called when someone click on the next button
   * @param fields {Object} e
   */
  public moveNext = (e: { preventDefault: () => void }) => {
    const { activeStep } = this.state;

    if (activeStep === staticConstants.EXPERT_REGISTER_FORM_STEP.REFERRED_INFO) {
      this.referredFormRef.current.validateAndSubmit();
    }

    if (activeStep === staticConstants.EXPERT_REGISTER_FORM_STEP.PERSONAL_INFO) {
      this.personalInfoFormRef.current.validateAndSubmit();
    }

    if (activeStep === staticConstants.EXPERT_REGISTER_FORM_STEP.EDUCATION) {
      this.educationFormRef.current.validateAndSubmit();
    }
    if (activeStep === staticConstants.EXPERT_REGISTER_FORM_STEP.EXPERIENCE) {
      this.experienceFormRef.current.validateAndSubmit();
    }
    if (activeStep === staticConstants.EXPERT_REGISTER_FORM_STEP.REFERENCE) {
      this.referenceFormRef.current.validateAndSubmit();
    }
    scroller.scrollTo('myScrollToElement', {
      duration: MAGIC_NUMBER.HUNDRED,
      delay: MAGIC_NUMBER.HUNDRED,
      smooth: true,
      offset: MAGIC_NUMBER.MINUS_HUNDRED,
    });
  }

  public removeIndexFromArr(reqArr: any) {
    const length = reqArr.length;
    const upatedArr = [];
    for (let i = 0; i < length; i++) {
      const obj = reqArr[i];
      const updatedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const updateKey = key.replace(`-${i}`, '');
          updatedObj[updateKey] = obj[key];
        }
      }
      upatedArr.push(updatedObj);
    }
    return upatedArr;
  }

  /**
   * @description
   * successValidateSubmit is used to move the stepper
   */
  public successValidateSubmit = () => {
    const { activeStep } = this.state;
    if (activeStep < staticConstants.EXPERT_REGISTER_FORM_STEP.REFERENCE) {
      this.setState({ activeStep: (Number(activeStep) + 1) });
    } else {
      const { referred } = this.referredFormRef.current.state;
      const { personalInfo, bio } = this.personalInfoFormRef.current.state;
      const { education, selectedEducation } = this.educationFormRef.current.state;
      const { experiences } = this.experienceFormRef.current.state;
      const { references } = this.referenceFormRef.current.state;
      education.availableDays = this.educationFormRef.current.selectedAvailabilities;
      education.tags = mapTagstoAPIRequest(education.tags);
      education.education = selectedEducation ? selectedEducation.value : '';
      const updatedExperiences = this.removeIndexFromArr(experiences);
      const updatedReferences = this.removeIndexFromArr(references);
      const updatedObj = {};
      for (const key in personalInfo) {
        if (personalInfo.hasOwnProperty(key)) {
          updatedObj[key] = personalInfo[key];
        }
      }
      updatedObj['title'] = updatedObj['title'].value;
      const referredVal = { isReferred: !!referred.isReferred, referredBy: referred.referredBy };
      const obj = {
        ...updatedObj,
        expertEducation: education,
        experiences: updatedExperiences,
        references: updatedReferences,
        bio,
        referred: referredVal
      };
      const { dispatch } = this.props;
      dispatch(registerAction.register(obj));
    }
  }

  /**
   * @description
   * render method is used to render the form
   */
  public render() {

    const { activeStep, sharingExpertiseModal } = this.state;

    return (
      <>
      <Modal isOpen={sharingExpertiseModal} className="feedback-modal">
        <ModalBody>
          <p>Experts earn an hourly rate for providing knowledge services to Overlap users.
             Rates are determined by several factors, including area of expertise, experience and profile.
              Comparing these factors to other experts, and accounting for user demand will determine starting rates.
               Activity on the platform will determine any changes made to the rate.
                Rate changes can be initiated by the expert themselves or after an annual audit of overall activity on
                 the platform.</p>
          <h4 className="heading heading-sm roboto-bold">Expert hourly rates are as follows:</h4>
          <p className="mb-2">Level One: less than 5 years’ experience ($100-$250).</p>
          <p className="mb-2">Level Two: more than 5 but less than 10 years’ experience ($255-$385).</p>
          <p className="mb-0">Level Three: more than 10 years’ experience ($390-$500).</p>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular ml-0" onClick={this.hideExpertiseModal}>Close</Button>{' '}
        </ModalFooter>
      </Modal>
      <div className="common_padding">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="common_heading mb-3">Join us. It’s free.</h2>
              <p className="common_p">Membership gives you access to our matchmaking technology and our network of organizations and professionals from around the world.
                All your requests, events and income, in one place.</p>
              </div>
            </div>
          </div>
          <div className="container" id="joinUs" >
            <div className="multisteps-form">
              <Stepper
                steps={[
                  { title: '' },
                  { title: '' },
                  { title: '' },
                  { title: '' },
                  { title: '' },
                ]}
                activeStep={activeStep}
                activeColor={'#36f'}
                completeColor={'#36f'}
                defaultColor={'#f2f2f2'}
                size={MAGIC_NUMBER.TWENTY_FOUR}
                circleFontSize={MAGIC_NUMBER.ZERO}
                titleFontSize={MAGIC_NUMBER.ELEVEN}
                circleTop={MAGIC_NUMBER.ZERO}
                lineMarginOffset={MAGIC_NUMBER.ZERO}
                completeBarColor={'#36f'}
                defaultBarColor={'#cad6e4'}
                defaultBorderHeight={MAGIC_NUMBER.EIGHT}
                defaultBorderColor={'#060060'}
                class={activeStep !== staticConstants.EXPERT_REGISTER_FORM_STEP.REFERRED_INFO}

              />
            </div>
            <div className="row">
              <div className="col-12 col-lg-12 m-auto step_form">
                <Element name="myScrollToElement" className="multisteps-form__form multisteps-form__panel shadow rounded bg-white js-active">
                  <div className={activeStep !== staticConstants.EXPERT_REGISTER_FORM_STEP.REFERRED_INFO ? `d-none` : ''}>
                    <ReferredForm ref={this.referredFormRef} successValidateSubmit={this.successValidateSubmit} />
                  </div>

                  <div className={activeStep !== staticConstants.EXPERT_REGISTER_FORM_STEP.PERSONAL_INFO ? `d-none` : ''}>
                    <PersonalInfoForm ref={this.personalInfoFormRef} successValidateSubmit={this.successValidateSubmit} />
                  </div>
                  <div className={activeStep !== staticConstants.EXPERT_REGISTER_FORM_STEP.EDUCATION ? `d-none` : ''}>
                    <EducationForm ref={this.educationFormRef} successValidateSubmit={this.successValidateSubmit} />
                  </div>
                  <div className={activeStep !== staticConstants.EXPERT_REGISTER_FORM_STEP.EXPERIENCE ? `d-none` : ''}>
                    <ExperienceForm ref={this.experienceFormRef} successValidateSubmit={this.successValidateSubmit} />
                  </div>
                  <div className={activeStep !== staticConstants.EXPERT_REGISTER_FORM_STEP.REFERENCE ? `d-none` : ''}>
                    <ReferenceForm ref={this.referenceFormRef} successValidateSubmit={this.successValidateSubmit} />
                  </div>
                  <div className="button-group step-form-btn-group">
                    {activeStep !== staticConstants.EXPERT_REGISTER_FORM_STEP.REFERRED_INFO &&
                      <Button color="back_stap" className="btn back_stap js-btn-prev" onClick={this.moveBack}>
                        <img src={preImage} alt="arrow" className="pr-2" />Back</Button>
                    }
                    {activeStep === staticConstants.EXPERT_REGISTER_FORM_STEP.REFERENCE &&
                      <Button color="back_stap" className="get_start_btn cta_btn text-center js-btn-next" onClick={this.moveNext}>Done</Button>
                    }
                    {activeStep !== staticConstants.EXPERT_REGISTER_FORM_STEP.REFERENCE &&
                      <Button color="back_stap" className="get_start_btn cta_btn text-center js-btn-next ml-auto" onClick={this.moveNext}>
                        <img src={nextImage} alt="arrow" className="pl-2" /> Next</Button>
                    }
                  </div>
                </Element>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { registering } = state.registeration;
  return {
    registering,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterForm);
export { connectedRegisterPage as RegisterForm };
