import React, { Fragment, Dispatch } from 'react';
import moment from 'moment';
import { IRFPDetail, IParams, IBidDetail, ILocalUser } from './../../../../interfaces';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import {
  pageRoutes, staticConstants, goalStatusOrgAdminTimeline,
  getGoalStatusText, goalStatus, bidStatus, cancelRequest, completeRequest,
  rejectBidModalContent, getLocalUserData, checkAddOn, goalStatusRequestLearner, EVENT_PAYMENT_TYPES,
  RESCHEDULE_STATUS, MAGIC_NUMBER, CSS_ClASSES
} from './../../../../utils';
import { Header } from '../../header';
import { Sidebar } from '../../sidebar';
import _ from 'lodash';
import { history } from '../../../../helpers';
import {
  Col,
  Row,
  Button,
  TabContent,
  TabPane,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardText,
  CardColumns,
} from 'reactstrap';
import { rfpAction } from '../redux/actions';
import { ConfirmatioPopup } from '../../modals/confirmationPopup';
import { CloseBidPopup } from '../../modals/closeBidPopup';
import { ErrorPopup } from '../../modals/errorPopup';
import { Back } from '../../back/back';
import StarRatings from 'react-star-ratings';
import { ReschedulePopup } from '../../modals/reschedulePopup';

interface IProps {
  rfpDetail: IRFPDetail;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  match: {
    params: {
      [key: string]: IParams;
    };
  };
  status: object;
  rfpRequest: { detail: IRFPDetail };
  user: ILocalUser;
  sessionFee: { amount: number; commission: number };
  sessionFeeBidId: string;
}

interface IState {
  rfpDetail: IRFPDetail;
  activeTab: string;
  displayCancelPopup: boolean;
  displayRejectBidPopup: boolean;
  displayReschedulePopup: boolean;
  displayCompleteRequestPopup: boolean;
  displayErrorPopup: boolean;
  modalPopupObj: {};
  modalCompleteObj: {};
  goalId: string;
  bidId: string;
}

enum tabs {
  SHORTLISTED = '2',
  BID = '1',
  ACCEPTED = '3',
}
class RequestDetailContainer extends React.Component<IProps, IState> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps) {
    if (props.rfpRequest && props.rfpRequest.detail) {
      if (props.rfpRequest.detail.status === goalStatus.RFP_REQUEST &&
        !props.status['bidAcceptRequest'] && props.status['bidAccepted'] !== false) {
        const successPayment = props.rfpRequest.detail.bid.find((bi: any) => bi.payment[0] &&
          bi.payment[0].type === EVENT_PAYMENT_TYPES.SESSION_FEE &&
          bi.payment[0].isPaymentSuccessful && bi.payment[0].bidId && bi.status === bidStatus.PAYMENT_SUCCESSFUL);
        if (successPayment && successPayment.payment[0].isPaymentSuccessful) {
          props.dispatch(rfpAction.acceptBid(successPayment.payment[0].bidId));
        }
      }
      return {
        rfpDetail: props.rfpRequest.detail,
      };
    }
    return null;
  }
  /**
   * @description
   * constructor is used to define the initial state and property
   * @param fields {Object} props
   */

  constructor(props: IProps) {
    super(props);
    this.state = {
      rfpDetail: {
        _id: null,
        bid: null,
        participants: [],
        createdAt: '',
        fromDate: '',
        toDate: '',
        fromTime: '',
        toTime: '',
        title: '',
        eventType: '',
        modeOfDevivery: '',
        description: '',
        isReviewTags: false,
        topic: {
          name: '',
          id: '',
        },
        domain: {
          name: '',
          id: '',
        },
        subDomain: {
          name: '',
          id: '',
        },
        category: {
          name: '',
          id: '',
        },
        subCategory: {
          name: '',
          id: '',
        },
        activity: {
          name: '',
          id: '',
        },
        duration: '',
        isMe: false,
        status: '',
        isAcceptBidClosed: false,
      },
      activeTab: tabs.BID,
      displayCancelPopup: false,
      displayRejectBidPopup: false,
      displayReschedulePopup: false,
      displayCompleteRequestPopup: false,
      displayErrorPopup: false,
      modalPopupObj: {},
      modalCompleteObj: {},
      goalId: '',
      bidId: '',
    };
  }

  /**
   * @description
   * showCancelPopup is used show confirmation popup.
   * @param fields {String} id
   */
  public showCancelPopup = (id: string) => {
    const modalPopupObj = cancelRequest;
    this.setState({
      displayCancelPopup: true,
      goalId: id,
      modalPopupObj,
    });
  }

  /**
   * @description
   * showCompleteRequest is used show confirmation popup
   */
  public showCompleteRequest = (id: string) => {
    const modalCompleteObj = completeRequest;
    this.setState({
      displayCompleteRequestPopup: true,
      goalId: id,
      modalCompleteObj,
    });
  }

  /**
   * @description
   * completeRequest is called when someone click on the ok button
   */
  public completeRequest = (status: boolean) => {
    const { goalId } = this.state;
    this.props.dispatch(rfpAction.completeRequest(goalId, status));
    this.setState({
      displayCompleteRequestPopup: false,
      modalCompleteObj: {},
      goalId: '',
    });
  }

  /**
   * @description
   * completeRequest is called when someone click on the ok button
   */
  public cancelPopupHide = () => {
    this.setState({
      displayCompleteRequestPopup: false,
      modalCompleteObj: {},
      goalId: '',
    });
  }

  /**
   * @description
   * cancelRequest is called when someone click on the ok button
   */
  public cancelRequest = () => {
    const { goalId } = this.state;
    this.props.dispatch(rfpAction.updateRequestStatus(
      { status: 'cancelRequest', goalId },
      null));
    this.setState({
      displayCancelPopup: false,
      modalPopupObj: {},
      goalId: '',
    });
  }

  /**
   * @description
   * showCancelPopup is used show reject bid popup.
   * @param fields {String} id
   */
  public showRejectBidPopup = (id: string) => {
    const modalPopupObj = rejectBidModalContent;
    this.setState({
      displayRejectBidPopup: true,
      bidId: id,
      modalPopupObj,
    });
  }

  /**
   * @description
   * open reschedule modal popup
   */
  public openRescheduleModal = (id: string) => {
    this.setState({
      ...this.state,
      displayReschedulePopup: true,
      bidId: id,
    });
  }

  /**
   * @description
   * cancelRequest is called when someone click on the ok button
   */
  public rejectBid = () => {
    const { bidId } = this.state;
    this.props.dispatch(rfpAction.rejectBid(bidId));
    this.setState({
      displayRejectBidPopup: false,
      modalPopupObj: {},
      bidId: '',
    });
  }

  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getrfpDetail to fetch goal detail
   */
  public componentDidMount() {
    const { match: { params } } = this.props;
    if (params.id) {
      this.props.dispatch(rfpAction.getRequestDetails({ id: String(params.id) }));
    }
  }

  public acceptBid = (bidId: string) => {
    this.props.dispatch(rfpAction.acceptBid(bidId));
  }

  public shortlistBid = (bidId: string, isShortlisted: boolean) => {
    this.props.dispatch(rfpAction.shortlistBid({ bidId, isShortlisted }));
  }

  /**
   * @description Function to show expert profile
   * @param {string} profileId conatin unique id
   * @param {string} bidId contain unique id
   * @param {number} currentIndex contain current index id
   */
  public viewExpertProfile(profileId: string, bidId: string, currentIndex: number) {
    const { user } = this.props;
    const profileViewUrl = checkAddOn()
      ? `${pageRoutes.VIEW_PROFILE_EXPERT.PATH}/${profileId}/${bidId}/complete`
      : `${pageRoutes.VIEW_PROFILE_EXPERT.PATH}/${profileId}/${bidId}`;
    history.push({
      pathname: user.role === staticConstants.ROLE.SYSTEM_ADMIN
        ? `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.VIEW_PROFILE.PATH}/${profileId}`
        : profileViewUrl,
      state: {
        breadcrumb: [
          { title: 'Request Management', redirectTo: `${pageRoutes.REQUESTMANAGEMENT.PATH}` },
          { title: this.state.rfpDetail.title, 
            redirectTo: `${pageRoutes.REQUESTMANAGEMENT.PATH}${pageRoutes.REQUESTMANAGEMENT.DETAIL}/${this.state.rfpDetail._id}` },
        ],
        currentIndex,
      },
    });
  }

  public toogle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  public proceedForPayment = (bidId: string) => {
    this.props.dispatch(rfpAction.getSessionFee(bidId));
  }

  public checkStripeData = (bidId: string) => {
    const localUser = getLocalUserData();
    if (!Boolean(localUser.isStripeAccount)) {
      this.setState({ displayErrorPopup: true });
    } else {
      this.proceedForPayment(bidId);
    }
  }

  /**
   * @description function to render tag list
   */
  public renderTagList = () => {
    const { rfpDetail } = this.state;
    const keys = ['domain', 'subDomain', 'category', 'subCategory', 'topic'];
    return keys.map(key => {
      return rfpDetail[key] && rfpDetail[key].id && <span 
      className="tags-view-only" key={rfpDetail[key].id}>{rfpDetail[key].name}</span>;
    });
  }

  public getRescheduleDateTime(item) {
    if (item['rescheduleRequestStatus'] === RESCHEDULE_STATUS.REQUESTED) {
      const rDate = moment(item.toDateRequested).format(staticConstants.DATE_FORMAT);
      return `${rDate}`;
    }
    const pDate = moment(item.proposedDate).format(staticConstants.DATE_FORMAT);
    if (item['rescheduleRequestStatus'] === RESCHEDULE_STATUS.APPROVED) {
      const pTime = moment(item.proposedTime).format(staticConstants.TIME_FORMAT);
      return <><span className="new-tag" >New </span><span>{pDate} {pTime}</span></>;
    }
    return `${pDate}`;
  }

  /**
   * @description function to render participants list for bid
   */

  public getCard(item: IBidDetail, index: number) {
    const { displayErrorPopup } = this.state;
    const { user } = this.props;
    const rescheduleBtn = item['rescheduleRequestStatus'] === RESCHEDULE_STATUS.REQUESTED ? 'Request Sent' : 'Reschedule';
    let averageProfileRating = 0;
    if (item.userId && item.userId.averageRating) {
      item.userId.averageRating = item.userId.averageRating || 0;
      averageProfileRating = Number.isInteger(item.userId.averageRating)
        ? item.userId.averageRating
        : Number(item.userId.averageRating.toFixed(MAGIC_NUMBER.ONE));
    }
    return (
      <Card key={item._id} className="flex-card">
        <CardHeader>
          <CardTitle>{this.getRescheduleDateTime(item)}</CardTitle>
          <CardSubtitle><small>$</small>
            {(item.userId && item.userId.hourlyRate) && item.userId.hourlyRate.current}
          </CardSubtitle>
        </CardHeader>
        <CardColumns className="rating-view-only">
          <h2>{checkAddOn() ? `${item.userId.firstName} ${item.userId.lastName}` : `Bidder ${index + 1}`}</h2>
          <h4>{`${item.userId['address']}${item.userId['location'] ? ', ' + item.userId['location'] : ''}`}</h4>
          <StarRatings
            rating={averageProfileRating}
            starHoverColor="#4be59e"
            starEmptyColor="#dcdcdc"
            starRatedColor="#4be59e"
            starDimension="20px"
            starSpacing="2px"
            numberOfStars={MAGIC_NUMBER.FIVE}
            name="averageRating"
          />
          <p className="star-rating-count">{averageProfileRating}/5</p>
        </CardColumns>
        <CardBody>
          <CardText>
            {item.bidDetails}
          </CardText>
          <div className="bid-card-action-button">
            <span
              className="body-link"
              onClick={() => this.viewExpertProfile(item.userId._id, item._id, index)}
            >
              View Details
          </span>
            {(item.status === bidStatus.PROPOSED || item.status === null) &&
              <span className="body-link reschedule-btn"
                onClick={item['rescheduleRequestStatus'] !== RESCHEDULE_STATUS.REQUESTED &&
                 (() => this.openRescheduleModal(item._id))}
              >{rescheduleBtn}
              </span>}
          </div>
        </CardBody>
        {user.role !== staticConstants.ROLE.SYSTEM_ADMIN &&
          <CardFooter>
            {(item.status === bidStatus.PROPOSED || item.status === null) &&
              <>{!item.isShortlisted ?
                <Button onClick={() => this.shortlistBid(item._id, true)}>Shortlist Bid</Button> :
                <Button onClick={() => this.shortlistBid(item._id, false)}>
                  <span className="icon icon-delete" /> Remove</Button>} </>}
            {(item.status === bidStatus.PROPOSED || item.status === null) &&
              <Button className="reject" onClick={() => this.showRejectBidPopup(item._id)}>
                <span className="icon icon-cross" /> Reject Bid</Button>}
            {(item.status === bidStatus.PROPOSED || item.status === null) &&
              <Button className="accept" onClick={item['rescheduleRequestStatus'] !== RESCHEDULE_STATUS.REQUESTED &&
               (() => this.checkStripeData(item._id))}>
                <span className="icon icon-accept" /> Accept Bid</Button>}
            {displayErrorPopup &&
              <ErrorPopup
                isOpenModal={true}
                connectStripe={() => {
                  this.setState({ displayErrorPopup: false });
                  history.push(`/${user.role.toLowerCase()}/viewProfile/#3`);
                }}
                hideModal={() => this.setState({ displayErrorPopup: false })}
              />
            }
          </CardFooter>
        }
      </Card>);
  }
  public renderBidCards = (activeTab: tabs) => {
    const { bid } = this.state.rfpDetail;
    const acceptedStatus = [bidStatus.ACCEPTED, bidStatus.SCHEDULE, bidStatus.EVENT_COMPLETED, bidStatus.EVENT_STARTED,
    bidStatus.SCHEDULE, bidStatus.PAYMENT_SUCCESSFUL, bidStatus.PAYMENT_RELEASED];
    let html = _.map(bid, (item: IBidDetail, index: number) => {
      if (activeTab === tabs.SHORTLISTED && item['isShortlisted'] && item['status'] !== bidStatus.ACCEPTED) {
        return this.getCard(item, index);
      }
      if (activeTab === tabs.ACCEPTED && acceptedStatus.indexOf(item['status']) !== MAGIC_NUMBER.FOUND_INDEX) {
        return this.getCard(item, index);
      }
      if (activeTab === tabs.BID && !item['isShortlisted'] && acceptedStatus.indexOf(item['status']) === MAGIC_NUMBER.FOUND_INDEX) {
        return this.getCard(item, index);
      }
    });

    html = _.without(html, undefined);
    if (html.length <= 0) {
      if (activeTab === tabs.SHORTLISTED) {
        html.push(<span className="empty-data" key="shortlistBid">You haven't shortlisted any bid yet.</span>);
      }
      if (activeTab === tabs.BID) {
        html.push(<span className="empty-data" key="noBid">No bids received</span>);
      }
      if (activeTab === tabs.ACCEPTED) {
        html.push(<span className="empty-data" key="noBid">You haven't accepted any bid yet</span>);
      }
    }
    return html;
  }

  public renderTimeLine = (rfpDetail: any) => {
    return <div className="timeline-view selected">
      <h2
        className={`heading roboto-medium ${rfpDetail.status === goalStatus.REJECT ? 'reject' : 'success'}
        ${rfpDetail.status === goalStatus.RFP_REQUEST ? 'selected' : ''}`}>
        {getGoalStatusText(rfpDetail.status)}
      </h2>
      <div className={`timeline-row created-row`}>
        <small>{getGoalStatusText(goalStatus.CREATED)}</small>
        <p>{this.getRequestDate(goalStatus.CREATED, rfpDetail)}</p>
        <span className="icon time-icon" />
      </div>
      {rfpDetail.orgId ? this.renderLearnerTimeLine(goalStatusRequestLearner, rfpDetail)
        : this.renderLearnerTimeLine(goalStatusOrgAdminTimeline, rfpDetail)}
    </div>;
  };

  public renderLearnerTimeLine = (arr: string[], rfpDetail) => {
    const html = [];
    arr.forEach((element, index) => {
      const currentStatus = _.find(rfpDetail.statusTimeline, (timeLine) => {
        return timeLine.status === element;
      });
      let selectedClass = null;
      if (currentStatus) {
        selectedClass = 'active';
      }

      let goalStatusText = getGoalStatusText(element);
      if (element === goalStatus.DELETED) {
        return;
      }
      if (currentStatus && (currentStatus['status'] === goalStatus.RFP_REQUEST
        || currentStatus['status'] === goalStatus.REQUEST_SEND_BY_LERNER)) {
        selectedClass = 'active selected';
      }
      if (currentStatus && currentStatus['status'] === goalStatus.ACCEPT) {
        goalStatusText = 'Request Accepted';
      }
      html.push(
        <div key={index} className={`timeline-row ${selectedClass}`}>
          <small>{goalStatusText}</small>
          <p>{this.getRequestDate(element, rfpDetail)}</p>
          <span className="icon check-icon" />
        </div>,
      );
    });
    return html;
  }

  public renderBidList = () => {
    return (
      <div className="w-100 mt-4">
        <div className="tab-btn-group">
          <Button type="button"
           className={` btn btn-rg h-50 tab-btn btn btn-secondary ${this.state.activeTab === '1' ? CSS_ClASSES.btnActive : ''}`} 
           onClick={() => { this.toogle('1'); }}>Bids</Button>
          <Button type="button"
           className={`btn btn-rg h-50 tab-btn btn btn-secondary ${this.state.activeTab === '2' ? CSS_ClASSES.btnActive : ''}`} 
           onClick={() => { this.toogle('2'); }}> Shortlisted Bids</Button>
          <Button type="button"
           className={`btn btn-rg h-50 tab-btn btn btn-secondary ${this.state.activeTab === '3' ? CSS_ClASSES.btnActive : ''}`}
            onClick={() => { this.toogle('3'); }}> Accepted</Button>
        </div>
        <div className="participant-wrapper box-shadow white-bg mb-0">
          <div className="participant-card-wrapper">
            <TabContent activeTab={this.state.activeTab} className="w-100">
              <TabPane tabId="1">
                <div className="w-100 d-flex flex-wrap">
                  {this.renderBidCards(tabs.BID)}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="w-100 d-flex">
                  {this.renderBidCards(tabs.SHORTLISTED)}
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="w-100 d-flex">
                  {this.renderBidCards(tabs.ACCEPTED)}
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>);
  };

  public getRequestDate = (status, rfpDetail) => {
    if (!rfpDetail.statusTimeline) { return null; }
    const timeLine = rfpDetail.statusTimeline.find(el => el.status === status);
    return timeLine ? moment(timeLine.createdAt).format(staticConstants.DATE_FORMAT) : null;
  };

  public render() {
    const { rfpDetail, bidId, displayCancelPopup, modalPopupObj, modalCompleteObj,
       displayRejectBidPopup, displayReschedulePopup, displayCompleteRequestPopup } = this.state;
    const { match: { params }, user } = this.props;
    const fromDate = moment(rfpDetail.fromDate).format(staticConstants.DATE_FORMAT);
    const toDate = moment(rfpDetail.toDate).format(staticConstants.DATE_FORMAT);
    const fromTime = moment(rfpDetail.fromTime).format(staticConstants.TIME_FORMAT);
    const toTime = moment(rfpDetail.toTime).format(staticConstants.TIME_FORMAT);
    
    return (
      rfpDetail && (
        <div className="form-container d-flex">
          <Fragment>
            <Header />
            <Sidebar {...this.props} />
            <div className="dashboard-wrapper">
              <Back {...this.props} />
              {rfpDetail.bid && rfpDetail.bid.length > MAGIC_NUMBER.ZERO && this.renderBidList()}
              <div className="dashboard-content shadow">
                <div className="learning-goal-wrapper">
                  <h2 className="heading heading-rg roboto-medium d-inline-block heading-border">
                    {rfpDetail.eventType}
                  </h2>
                  <div className="goal-view-wrapper">
                    <h2 className="heading heading-lg roboto-medium">
                      {rfpDetail.title} <span>{rfpDetail.modeOfDevivery}</span>
                    </h2>
                    {rfpDetail.topic && <em>({rfpDetail.topic.name})</em>}
                    <p>{rfpDetail.description}</p>
                    <div className="form-details">
                      <Row>
                        <Col md="12" lg="9">
                          <div className="form-data">
                            <Row>
                              <Col xs="12" md="4">
                                <span>Domain</span>
                                <p>{rfpDetail.domain.name}</p>
                              </Col>
                              <Col xs="12" md="4">
                                <span>Sub-Domain</span>
                                <p>{rfpDetail.subDomain.name}</p>
                              </Col>
                              {rfpDetail.category && <Col xs="12" md="4">
                                <span>Category</span>
                                <p>{rfpDetail.category.name}</p>
                              </Col>}
                              {rfpDetail.subCategory && <Col xs="12" md="4">
                                <span>Sub-Category</span>
                                <p>{rfpDetail.subCategory.name}</p>
                              </Col>}
                              {rfpDetail.activity && <Col xs="12" md="4">
                                <span>Activity Code</span>
                                <p>{rfpDetail.activity.name}</p>
                              </Col>}
                              {user.role === staticConstants.ROLE.SYSTEM_ADMIN ?
                                <Col xs="12" md="4">
                                  <span>Created By</span>
                                  <p>{rfpDetail.userId && `${rfpDetail.userId['firstName']} ${rfpDetail.userId['lastName']}`}</p>
                                </Col> : ''}
                              <Col xs="12" md="4">
                                <span>No. of Participants</span>
                                <p>{rfpDetail.participants.length}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" md="4">
                                <span>Duration</span>
                                <p>
                                  {rfpDetail.duration}{' '}
                                  {Number(rfpDetail.duration) > MAGIC_NUMBER.ONE
                                    ? `hrs`
                                    : `hr`}
                                </p>
                              </Col>
                              <Col xs="12" md="4">
                                <span>Date Availability</span>
                                <p>
                                  {fromDate} - {toDate}
                                </p>
                              </Col>
                              <Col xs="12" md="4">
                                <span>Time Availability</span>
                                <p>
                                  {fromTime} - {toTime}
                                </p>
                              </Col>
                              <Col xs="12">
                                <span>Tags</span>
                                <div className="tags-wrapper">
                                  {this.renderTagList()}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col md="12" lg="3">
                          <div className="timeline-view">
                            {this.renderTimeLine(rfpDetail)}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className={'card-footer d-flex mt-0 ' + (user.role === staticConstants.ROLE.SYSTEM_ADMIN ? 'd-none' : '')}>
                  {!rfpDetail.bid && user.role !== staticConstants.ROLE.SYSTEM_ADMIN && (
                    <Button
                      className="btn btn-icon danger mr-auto"
                      onClick={() => this.showCancelPopup(String(params.id))}
                    >
                      <span className="icon icon-cross" /> Cancel
                    </Button>
                  )}
                  {!rfpDetail.isAcceptBidClosed &&
                   [staticConstants.ROLE.ORGANIZATION_ADMIN, staticConstants.ROLE.PROFESSIONAL].indexOf(user.role) !== -1 &&
                      <Button className="btn btn-icon danger mr-auto"
                      onClick={() => this.showCompleteRequest(String(params.id))}
                    > <span className="icon icon-cross" /> Close Request </Button>
                  }
                </div>
              </div>
            </div>
            {displayCancelPopup ? (
              <ConfirmatioPopup
                isOpenModal={displayCancelPopup}
                modalPopupObj={modalPopupObj}
                hideModal={() => this.setState({ displayCancelPopup: false })}
                onClickYes={this.cancelRequest}
              />
            ) : (
                ''
              )}
            {displayRejectBidPopup && (
              <ConfirmatioPopup
                isOpenModal={displayRejectBidPopup}
                modalPopupObj={modalPopupObj}
                hideModal={() =>
                  this.setState({
                    displayCancelPopup: false,
                    displayRejectBidPopup: false,
                  })
                }
                onClickYes={this.rejectBid}
              />
            )}
            {displayReschedulePopup && (
              <ReschedulePopup goal={rfpDetail} bidId={bidId} isOpenModal={displayReschedulePopup} hideModal={() => {
                this.setState({
                  displayReschedulePopup: false,
                });
              }} />
            )}
            {displayCompleteRequestPopup && (
              <CloseBidPopup
                isOpenModal={displayCompleteRequestPopup}
                modalPopupObj={modalCompleteObj}
                cancelPopupHide={this.cancelPopupHide}
                hideModal={() => { this.completeRequest(true); }}
                onClickYes={() => { this.completeRequest(false); }}
              />
            )}
          </Fragment>
        </div>
      )
    );
  }
}
function mapStateToProps(state: any) {
  const { user } = state.authentication;
  return {
    sessionFeeBidId: state.rfpRequest.sessionFeeBidId,
    sessionFee: state.rfpRequest.sessionFee,
    rfpRequest: state.rfpRequest,
    user,
    status: state.rfpRequest.status,
  };
}

const mapRequestDetailContainer = connect(mapStateToProps)(RequestDetailContainer);
export { mapRequestDetailContainer as RequestDetailContainer };
