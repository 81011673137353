import React from 'react';
import {
  Card,
  CardText,
  CardTitle,
} from 'reactstrap';
import sliderImage from './../../../assets/images/login-banner.png';

class SliderContainer extends React.Component {
  public render() {
    return (
      <div className="slide-section">
        <img src={sliderImage} className="img-cover" alt="login banner" />
        <Card body className="login-quote">
          <CardTitle><span className="card-head-text">Everyone is going to need to understand
          [climate change] the same way you’d assume everyone in business needs to
                   have some fluency in social media today.</span>
          </CardTitle>
          <CardText>
            Andrew Winston
                    <span>Author</span>
          </CardText>
        </Card>
      </div>
    );
  }
}

export { SliderContainer };
