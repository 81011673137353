import React from 'react';
import { connect } from 'react-redux';
import AvatarEditor from 'react-avatar-editor';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { MAGIC_NUMBER } from '../../../utils';

interface IProps {
  isOpenModal: boolean;
  savedCroppedImage: any;
  image: any;
  hideModal(): void;
}

interface IState {
  modal: boolean;
  allowZoomOut: boolean;
  scale: any;
  image: any;
}

class ImageCropperPopup extends React.Component<IProps, IState> {
  public editor: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      modal: props.isOpenModal,
      image: null,
      allowZoomOut: false,
      scale: 1,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  public componentWillReceiveProps(props: any) {
    this.setState({
      modal: props.isOpenModal,
      image: props.image,
    });
  }

  public setEditorRef = (editor: any) => this.editor = editor;

  public hideModal() {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  }

  public savedCroppedImage = () => {
    const img = this.editor.getImageScaledToCanvas().toDataURL();
    this.props.savedCroppedImage(img);
  }

  public render() {
    const { modal, image, scale, allowZoomOut } = this.state;
    return (
      <Modal isOpen={modal} className="image-crop-modal">
                    <ModalHeader toggle={this.hideModal}>Crop Image</ModalHeader>
                    <ModalBody>
                        <AvatarEditor
                            ref={this.setEditorRef}
                            image={image}
                            scale={parseFloat(scale)}
                            borderRadius={MAGIC_NUMBER.ONESIXTWO}
                            width={MAGIC_NUMBER.ONESIXTWO}
                            height={MAGIC_NUMBER.ONESIXTWO}
                            border={MAGIC_NUMBER.FOURTY_SEVEN}
                            color={MAGIC_NUMBER.COLOR_CODE}
                        />
                        <div className="d-flex justify-content-between align-items-center mt-3">
                        <input
                            name="scale"
                            type="range"
                            onChange={this.handleScale}
                            min={allowZoomOut ? '0.1' : '1'}
                            max="2"
                            step="0.01"
                            defaultValue="1"
                            className="range-slider"
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-regular mr-2" onClick={this.hideModal}>Cancel</Button>{' '}
                        <Button color="primary" onClick={this.savedCroppedImage}>Crop</Button>
                    </ModalFooter>
                </Modal>
    );
  }
}

const connectedimageCropperPopupPage = connect(null)(ImageCropperPopup);
export { connectedimageCropperPopupPage as ImageCropperPopup };
