import _ from 'lodash';
import moment from 'moment';
import React, { Dispatch } from 'react';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';
import { goalAction } from '../redux/actions';
import {
  getSelectedValue, getUpdatedOptions, deleteGoalMsg, pageRoutes,
  sendRFPModalContent, tableItemsCount, staticConstants, goalStatus, sendForApprovalModalContent,
  MAGIC_NUMBER
} from './../../../../utils';
import { tagAction } from './../../tags/redux/actions';
import { GoalItem } from './goalItem';
import OpenFieldTypes from 'OpenFieldTypes';
import { ConfirmatioPopup } from '../../modals/confirmationPopup';
import { ReqSendErrorPopup } from '../../modals/reqSendErrorPopup';
import { RequestTypeTab } from './requestTypeTab';
import { FilterComponent } from '../../filter/filterComponent';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  domainOptions: any;
  user: any;
  role: any;
  goals: any;
  isGoalsCreatedMyLerner?: any;
}
class ListingContainer extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps) {
    if (props.goals && props.goals.list) {
      return {
        goals: props.goals,
      };
    }

    if (props.goals && props.goals.successForCreateGoal) {
      return {
        fetchGoal: true,
      };
    }
    return null;
  }

  public state = {
    displaySendErrorPopup: false,
    selectedOption: null,
    tooltipOpen: false,
    activePage: 1,
    items: tableItemsCount,
    title: '',
    eventType: null,
    category: null,
    domain: null,
    status: null,
    sort: '',
    sortBy: 0,
    goals: {
      count: 0,
      list: [],
      fetchedListing: false,
    },
    fetchGoal: false,
    displayDeletePopup: false,
    displayRFPPopup: false,
    displayApprovalPopup: false,
    goalId: '',
    reqObj: {},
    modalPopupObj: {},
    activeTab: null,
    openFilter: false,
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getGoals to fetch goal listings
   * call goalAction.getGoals to fetch goal categories
   */
  public componentDidMount() {
    const { items, activePage } = this.state;
    this.props.dispatch(tagAction.getDomains());
    const reqObj: any = {
      page: Number(activePage),
      limit: items,
    };
    if (this.props.role === staticConstants.ROLE.ORGANIZATION_LEARNER) {
      this.setState({ activeTab: goalStatus.NEW_REQUEST }, () => {
        const updateReqObj = this.updateReqObj(reqObj);
        this.props.dispatch(goalAction.getGoals(updateReqObj));
      });
    } else {
      const updateReqObj = this.updateReqObj(reqObj);
      this.props.dispatch(goalAction.getGoals(updateReqObj));
    }
  }

  /**
   * @description componentDidUpdate is called when we need to fetch listing again.
   * call goalAction.getGoals to fetch goal listings
   */
  public componentDidUpdate() {
    if (this.state.fetchGoal) {
      const reqObj: any = {
        page: Number(this.state.activePage),
        limit: this.state.items,
      };
      const updateReqObj = this.updateReqObj(reqObj);
      this.props.dispatch(goalAction.getGoals(updateReqObj));
      this.setState({ fetchGoal: false });
    }
  }

  /**
   * @description componentWillUnmount is used when component destroyed
   * call tagAction.resetAllTags() to reset the tags
   */
  public componentWillUnmount() {
    this.props.dispatch(tagAction.resetAllTags());
  }

  /**
   * @description updateReqObj to update object before sending to the server
   */
  public updateReqObj(reqObj: any) {
    if (this.props.role === staticConstants.ROLE.ORGANIZATION_LEARNER) {
      if (this.props.isGoalsCreatedMyLerner) {
        reqObj['goalStatus'] = this.state.activeTab;
      } else {
        reqObj['isFetchGoalByAdmin'] = true;
      }
    }
    return reqObj;
  }

  /**
   * @description fetchGoalsData is used on initial load
   */
  public fetchGoalsData(pageno: number) {
    const { sort, sortBy, activeTab } = this.state;
    let reqObj = {
      page: pageno,
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      domain: getSelectedValue(this.state.domain),
      status: getSelectedValue(this.state.status),
      title: this.state.title,
    };
    if (activeTab !== null) {
      reqObj['goalStatus'] = activeTab;
    }
    if (sort) {
      reqObj = Object.assign(reqObj, { sort, sortBy });
    }
    const updateReqObj = this.updateReqObj(reqObj);
    this.props.dispatch(goalAction.getGoals(updateReqObj));
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleChange = (value: any, key: string) => this.setState({ ...this.state, [key]: value }, () => { this.fetchGoalsData(1); });

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => this.setState({ activePage: pageno }, () => this.fetchGoalsData(pageno));

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData = (sort: any, sortBy: any) => {
    const { activePage } = this.state;
    this.setState({ ...this.state, sort, sortBy }, () => this.fetchGoalsData(activePage));
  }

  /**
   * @description
   * showDeletePopup is used show delete popup.
   * @param fields {String} id
   */
  public showDeletePopup = (id: string) => {
    const { sort, sortBy, activeTab } = this.state;
    const modalPopupObj = deleteGoalMsg;
    let reqObj = {
      page: Number(this.state.activePage),
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      category: getSelectedValue(this.state.category),
      status: getSelectedValue(this.state.status),
      title: this.state.title,
    };
    if (activeTab !== null) {
      reqObj['goalStatus'] = activeTab;
    }
    if (sort) {
      reqObj = Object.assign(reqObj, { sort, sortBy });
    }
    const updateReqObj = this.updateReqObj(reqObj);
    this.setState({
      displayDeletePopup: true,
      goalId: id,
      reqObj: updateReqObj,
      modalPopupObj,
    });
  }

  /**
   * @description
   * deleteGoal is called when someone click on the ok button
   */
  public deleteGoal = () => {
    const { goalId, reqObj } = this.state;
    this.props.dispatch(goalAction.deleteGoal({ goalId }, reqObj));
    this.setState({
      displayDeletePopup: false,
      modalPopupObj: {},
      goalId: '',
    });
  }

  /**
   * @description
   * showSendRFPPopup is used show send rfp popup.
   * @param fields {String} id
   */
  public showSendRFPPopup = (id: string) => {
    const { sort, sortBy, activeTab } = this.state;
    const goalCheck = this.state.goals.list.filter((item) => {
      return item._id === id;
    });
    if (goalCheck.length > MAGIC_NUMBER.ZERO && (moment(goalCheck[0].toDate).diff(moment(), 'days') >  MAGIC_NUMBER.NINETY)) {
      this.setState({
        displaySendErrorPopup: true
      });
    } else {
      const modalPopupObj = sendRFPModalContent;
      let reqObj = {
        page: Number(this.state.activePage),
        limit: this.state.items,
        eventType: getSelectedValue(this.state.eventType),
        category: getSelectedValue(this.state.category),
        status: getSelectedValue(this.state.status),
        title: this.state.title,
      };
      if (activeTab !== null) {
        reqObj['goalStatus'] = activeTab;
      }
      if (sort) {
        reqObj = Object.assign(reqObj, { sort, sortBy });
      }
      const updateReqObj = this.updateReqObj(reqObj);
      this.setState({
        displayRFPPopup: true,
        goalId: id,
        reqObj: updateReqObj,
        modalPopupObj,
      });
    }
  }

  /**
   * @description
   * sendRFP is called when someone click on the ok button
   */
  public sendRFP = () => {
    const { goalId } = this.state;
    this.props.dispatch(
      goalAction.updateGoalStatus(
        {
          goalId,
          isFromDetailPage: false,
          status: goalStatus.RFP_REQUEST,
        },
        () => this.fetchGoalsData(1)),
    );
    this.setState({
      displayRFPPopup: false,
      modalPopupObj: {},
      goalId: '',
    });
  }

  /**
   * @description
   * renderGoalListing is called to render the goal listings
   */
  public renderGoalListing() {
    const { list } = this.state.goals;
    return _.map(list, (item) => {
      return (
        <GoalItem key={v4()} goal={item} activeTab={this.state.activeTab} deleteGoal={this.showDeletePopup}
          isFromRequestListPage={false} sendRFP={this.showSendRFPPopup}
          isGoalsCreatedMyLerner={this.props.isGoalsCreatedMyLerner}
          sendForApproval={this.showSendApprovalPopup} />
      );
    });
  }

  /**
   * @description
   * changeRequestType is called when some click on the child tab.
   */
  public changeRequestType = (activeTab: any) => this.setState({ activeTab }, () => this.fetchGoalsData(1));

  /**
   * @description
   * showSendApprovalPopup is used show approval popup.
   * @param fields {String} id
   */
  public showSendApprovalPopup = (id: string) => {
    const modalPopupObj = sendForApprovalModalContent;
    this.setState({
      displayApprovalPopup: true,
      goalId: id,
      modalPopupObj,
    });
  }

  /**
   * @description
   * sendForApproval is called when someone click on the ok button
   */
  public sendForApproval = () => {
    const { goalId } = this.state;
    this.props.dispatch(goalAction.updateGoalStatus({
      goalId,
      isFromDetailPage: false,
      status: goalStatus.REQUEST_SEND_BY_LERNER,
    }));
    this.setState({
      displayApprovalPopup: false,
      modalPopupObj: {},
      goalId: '',
    });
  }

  /**
   * @description
   * render is used to render HTML
   */
  public render() {
    const { activePage, items, goals, sort, sortBy,
      displayDeletePopup, modalPopupObj, displayRFPPopup, activeTab,
      displayApprovalPopup, displaySendErrorPopup } = this.state;
    const role = this.props.role;
    let minDisplay;
    let maxDisplay;
    if (goals.count === MAGIC_NUMBER.ZERO) {
      maxDisplay = MAGIC_NUMBER.ZERO;
      minDisplay = MAGIC_NUMBER.ZERO;
    } else if (goals.count <= items) {
      maxDisplay = goals.count;
      minDisplay = MAGIC_NUMBER.ONE;
    } else {
      maxDisplay = (Number(activePage) * Number(items)) < goals.count
        ? (Number(activePage) * Number(items)) : goals.count;
      minDisplay = ((Number(activePage) - 1) * Number(items)) + 1;
    }
    const classVarArr = ['table-head'];
    if (activeTab === goalStatus.ACCEPT && role === staticConstants.ROLE.ORGANIZATION_LEARNER &&
      this.props.isGoalsCreatedMyLerner) {
      classVarArr.push('table-head-accept');
    }
    if (activeTab === goalStatus.REJECT && role === staticConstants.ROLE.ORGANIZATION_LEARNER &&
      this.props.isGoalsCreatedMyLerner) {
      classVarArr.push('table-head-reject');
    }
    if (role === staticConstants.ROLE.ORGANIZATION_LEARNER && !this.props.isGoalsCreatedMyLerner) {
      classVarArr.push('table-head-orgadmin');
    }
    if (role === staticConstants.ROLE.PROFESSIONAL) {
      classVarArr.push('Professional-head');
    }
    const classes = classVarArr.join(' ');
    let filterContent = [];
    switch (role) {
      case staticConstants.ROLE.PROFESSIONAL:
        filterContent = ['search'];
        break;
      case staticConstants.ROLE.ORGANIZATION_LEARNER:
      case staticConstants.ROLE.ORGANIZATION_ADMIN:
        filterContent = ['search', 'eventType', 'domain'];
        break;
    }

    return (
      <React.Fragment>
        <div className="filter-wrapper">
          <FilterComponent filterContent={filterContent} handleFilter={this.handleChange.bind(this)}
            activeTab={activeTab} />
          {role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
            <Link to={`${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.CREATE_GOAL}`}
              className="btn btn-primary round-circle add-filter-btn">
              Add Goal
              </Link>
          }
          {((role === staticConstants.ROLE.ORGANIZATION_LEARNER && this.props.isGoalsCreatedMyLerner)
            || role === staticConstants.ROLE.PROFESSIONAL) &&
            <Link to={`${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.CREATE_GOAL}`}
              className={`btn btn-primary round-circle add-filter-btn ${role === staticConstants.ROLE.PROFESSIONAL ? 'ml-auto' : ''}`}>
              Add Goal
            </Link>}
        </div>
        {role !== staticConstants.ROLE.PROFESSIONAL &&
          this.props.isGoalsCreatedMyLerner &&
          <RequestTypeTab requestType={activeTab} role={role} changeRequestType={this.changeRequestType} />
        }
        <div className="table-wrapper">
          <div className="flex-table">
            <div className={classes} >
              <div className="table-row">
                <div className="table-col table-col-17">
                  <span className="sorting-icon">Title
                      <strong>
                      <em className={(sort === 'title' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('title', MAGIC_NUMBER.ONE); }}/>
                      <em className={(sort === 'title' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('title', MAGIC_NUMBER.FOUND_INDEX); }}/>
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-10">
                  <span className="sorting-icon">Event Type
                      <strong>
                      <em className={(sort === 'eventType' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('eventType', MAGIC_NUMBER.ONE); }}/>
                      <em className={(sort === 'eventType' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('eventType', MAGIC_NUMBER.FOUND_INDEX); }}/>
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-15">
                  <span className="sorting-icon">Domain
                      <strong>
                      <em className={(sort === 'domain' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('domain', MAGIC_NUMBER.ONE); }}/>
                      <em className={(sort === 'domain' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('domain', MAGIC_NUMBER.FOUND_INDEX); }}/>
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-13">
                  <span className="sorting-icon">Sub-Domain
                      <strong>
                      <em className={(sort === 'subDomain' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('subDomain', MAGIC_NUMBER.ONE); }}/>
                      <em className={(sort === 'subDomain' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('subDomain', MAGIC_NUMBER.FOUND_INDEX); }}/>
                    </strong>
                  </span>
                </div>
                {role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                  <div className="table-col table-col-12">
                    <span className="sorting-icon">Goal Created
                            <strong>
                        <em className={(sort === 'createdAt' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('createdAt', MAGIC_NUMBER.ONE); }}/>
                        <em className={(sort === 'createdAt' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('createdAt', MAGIC_NUMBER.FOUND_INDEX); }}/>
                      </strong>
                    </span>
                  </div>
                }
                {((role === staticConstants.ROLE.ORGANIZATION_LEARNER &&
                  activeTab === goalStatus.NEW_REQUEST) || role === staticConstants.ROLE.PROFESSIONAL) &&
                  <div className="table-col table-col-12">
                    <span className="sorting-icon">Goal Created
                        <strong>
                        <em className={(sort === 'createdAt' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('createdAt', MAGIC_NUMBER.ONE); }}/>
                        <em className={(sort === 'createdAt' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('createdAt', MAGIC_NUMBER.FOUND_INDEX); }}/>
                      </strong>
                    </span>
                  </div>
                }
                {role === staticConstants.ROLE.ORGANIZATION_LEARNER &&
                  (activeTab === goalStatus.ACCEPT) &&
                  <div className="table-col table-col-12">
                    <span className="sorting-icon">Goal Accepted
                        <strong>
                        <em className={(sort === 'acceptedAt' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('acceptedAt', MAGIC_NUMBER.ONE); }}/>
                        <em className={(sort === 'acceptedAt' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('acceptedAt', MAGIC_NUMBER.FOUND_INDEX); }}/>
                      </strong>
                    </span>
                  </div>
                }
                {role === staticConstants.ROLE.ORGANIZATION_LEARNER &&
                  (activeTab === goalStatus.REJECT) &&
                  <div className="table-col table-col-12">
                    <span className="sorting-icon">Goal Rejected
                        <strong>
                        <em className={(sort === 'rejectedAt' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('rejectedAt', MAGIC_NUMBER.ONE); }}/>
                        <em className={(sort === 'rejectedAt' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('rejectedAt', MAGIC_NUMBER.FOUND_INDEX); }}/>
                      </strong>
                    </span>
                  </div>
                }
                {role !== staticConstants.ROLE.PROFESSIONAL &&
                  <div className="table-col table-col-13">
                    <span className="sorting-icon justify-content-center">Participants
                      <strong>
                        <em className={(sort === 'totalParticipants' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('totalParticipants', MAGIC_NUMBER.ONE); }}/>
                        <em className={(sort === 'totalParticipants' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('totalParticipants', MAGIC_NUMBER.FOUND_INDEX); }}/>
                      </strong>
                    </span>
                  </div>
                }
                {role === staticConstants.ROLE.ORGANIZATION_LEARNER &&
                  (activeTab === goalStatus.NEW_REQUEST || activeTab === goalStatus.ACCEPT) &&
                  <div className="table-col table-col-10">
                    <span className="sorting-icon">Status</span>
                  </div>
                }
                {(role === staticConstants.ROLE.ORGANIZATION_ADMIN
                  || role === staticConstants.ROLE.PROFESSIONAL) &&
                  <div className="table-col table-col-10">
                    <span className="sorting-icon">Status</span>
                  </div>
                }
                {role === staticConstants.ROLE.ORGANIZATION_LEARNER &&
                  <>
                    {this.props.isGoalsCreatedMyLerner &&
                      (activeTab === goalStatus.NEW_REQUEST || activeTab === goalStatus.REJECT) &&
                      <div className="table-col table-col-10 text-center">
                        <span>Action</span>
                      </div>
                    }
                  </>
                }

                {(role === staticConstants.ROLE.ORGANIZATION_ADMIN
                  || role === staticConstants.ROLE.PROFESSIONAL) &&
                  <div className="table-col table-col-10 text-center">
                    <span>Action</span>
                  </div>
                }
              </div>
            </div>
            <div className="table-body">
              {this.renderGoalListing()}
              {goals && goals.fetchedListing && goals.count === MAGIC_NUMBER.ZERO ?
                <div className="table-row table-no-data">
                  No Results Found.
                    </div>
                : ''}
            </div>
            {(goals && goals.count > MAGIC_NUMBER.TEN) &&
              <div className="table-footer">
                  <React.Fragment>
                    <p>Showing {minDisplay} - {maxDisplay} of {goals.count}</p>
                    <div className="pagination-wrapper">
                      <Pagination
                        hideFirstLastPages
                        activePage={activePage}
                        itemsCountPerPage={items}
                        totalItemsCount={goals.count}
                        pageRangeDisplayed={MAGIC_NUMBER.FIVE}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  </React.Fragment>
              </div>
            }
          </div>
        </div>
        {
          displayDeletePopup ?
            <ConfirmatioPopup isOpenModal={displayDeletePopup} modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displayDeletePopup: false })} onClickYes={this.deleteGoal} />
            : ''
        }
        {
          displayRFPPopup ?
            <ConfirmatioPopup isOpenModal={displayRFPPopup} modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displayRFPPopup: false })} onClickYes={this.sendRFP} />
            : ''
        }
        {
          displayApprovalPopup ?
            <ConfirmatioPopup isOpenModal={displayApprovalPopup} modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displayApprovalPopup: false })} onClickYes={this.sendForApproval} />
            : ''
        }
        {
         displaySendErrorPopup ?
          <ReqSendErrorPopup
            isOpenModal={true}
            hideModal={() => this.setState({ displaySendErrorPopup: false })}
          /> : ''
        }
      </React.Fragment >
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, tags: { domains } } = state;
  const { user } = authentication;
  return {
    user,
    role: user.role,
    goals: state.goals,
    domainOptions: getUpdatedOptions(domains, 'name'),
  };
}

const connectedGoalListingContainer = connect(mapStateToProps)(ListingContainer);
export { connectedGoalListingContainer as ListingContainer };
