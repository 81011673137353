import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import OpenFieldTypes from 'OpenFieldTypes';
import { UncontrolledTooltip } from 'reactstrap';
import { history } from '../../../../helpers';
import { pageRoutes, ACTION_CLASSES, MAGIC_NUMBER, staticConstants } from './../../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  request: any;
  onCancel(id: string): void;
}

class RequestItem extends React.Component<IProps> {

  public redirectEventDetail(event: any, request: any) {
    if (ACTION_CLASSES.indexOf(event.target.className) === MAGIC_NUMBER.FOUND_INDEX) {
      history.push({
        pathname: `${pageRoutes.REQUESTMANAGEMENT.PATH}${pageRoutes.REQUESTMANAGEMENT.DETAIL}/${request._id}`,
        state: {},
      });
    }
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { request } = this.props;
    let participants = 0, bidStatus = '', dateAvailability;
    if (request) {
      participants = request.participants.length;
      bidStatus =
        !request.bid || request.bid.length === MAGIC_NUMBER.ZERO
          ? 'Awaiting Bids'
          : request.bid.length  === MAGIC_NUMBER.ONE
            ? ' 1 Bid Received'
            : `${request.bid.length} Bids Received`;
      dateAvailability = `${moment(request['fromDate']).format(staticConstants.DATE_FORMAT)} - ${moment(request['toDate']).format(staticConstants.DATE_FORMAT)}`;
    }
    return (
      request && (
        <div className="table-row" onClick={(event) => this.redirectEventDetail(event, request)} >
          <div className="table-col table-col-15" data-label="Title">
            <span className="roboto-medium link-btn text-truncate">
              {request.title}
            </span>
          </div>
          <div className="table-col table-col-10" data-label="Event Type">
            <span>{request.eventType}</span>
          </div>
          <div className="table-col table-col-12" data-label="Domain">
            <span>{request.domain.name}</span>
          </div>
          <div className="table-col table-col-13" data-label="Sub-Domain">
            <span>{request.subDomain.name}</span>
          </div>
          <div
            className="table-col table-col-13 sm-50 text-center"
            data-label="Participants"
          >
            <span>{participants}</span>
          </div>
          <div
            className="table-col table-col-17 sm-50"
            data-label="Date Availability"
          >
            <span>{dateAvailability}</span>
          </div>
          <div className="table-col table-col-10 sm-50" data-label="Status">
            <span>{bidStatus}</span>
          </div>
          <div className="table-col table-col-10 text-center p-0 action-col">
              <em className="icon icon-cross danger"
                id={`tooltip_${request._id}` }
                onClick={() => this.props.onCancel(request._id)} />
              <UncontrolledTooltip placement="top" target={`tooltip_${request._id}` } >Close Request</UncontrolledTooltip>
          </div>
        </div>
      )
    );
  }
}

function mapStateToProps(state: { authentication: { user: any } }) {
  const { user } = state.authentication;
  return {
    user,
  };

}

const requestItemPage = connect(mapStateToProps)(RequestItem);
export { requestItemPage as RequestItem };
