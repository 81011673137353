import React, { useState } from 'react';
import {
    Button,
  } from 'reactstrap';
import { Link } from 'react-router-dom';

interface IProps {
  stripeAccountId: string;
  createPaymentGateWayDashBoardLink: any;
}

function StripeConnection({ stripeAccountId, createPaymentGateWayDashBoardLink }: IProps) {
  const[isStripeConnection, setIsStripeConnection] = useState(null);

  const redirectToPaymentGateWay = () => {
    const { role } = this.props;
    const redirectUrl = `${process.env.REACT_APP_WEB_URL}/${role.toLowerCase()}/viewProfile`;
    const clientId = process.env.REACT_APP_STRIPE_CLIENT_ID;
    const pathArr = [
      process.env.REACT_APP_STRIPE_AUTH_URL,
      `redirect_uri=${redirectUrl}`,
      `client_id=${clientId}`,
    ];
    window.location.href = pathArr.join('&');
  };

  return (
          <>
            <div className="collapse-body pt-0">
              <div className="small-text">
                <p>Overlap uses <span className="tooltip-text"
                  title={`Stripe has been audited by a PCI-certified auditor and is certified to PIC
             Service Provider Level 1. This is the most stringent level of certification available in the payment indistry. To accomplish this, they make use of best-in-class
              security tools and practices to maintian the highest level of
               security at Stripe.`}> Stripe</span>
            Connect to handle milestone payments between Team, Individual & Expert
             (see our <Link to="/faq" target="_blank" className="link-text">FAQ</Link>
             for details). In order to do this you will need to
            create a Stripe account by clicking on the Create Stripe Account button below.
             This will send you to the stripe site where you will create your account,
              complete your profile, and setup your payment preferences.

            </p>
                <p>
                  After you complete these actions you will be returned to the Overlap site.
                  Your Stripe account information will be securely linked with your Overlap
                  account and
            kept private (see our <Link target="_blank" to="/privacyPolicy" className="link-text">
                    Privacy Policy</Link> for details). If you have any additional questions
               about Overlap’s relationship with Stripe that our FAQ or Privacy Policy doesn’t
            answer please contact us the Overlap support team at
             <a href="mailto:finance@ovlp.app" className="link-text">finance@ovlp.app</a>.
            </p>
              </div>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-end">
              {!stripeAccountId &&
                <Button color="primary" className="btn btn-rg" onClick={redirectToPaymentGateWay}>
                  Connect With Stripe</Button>
              }
              {stripeAccountId &&
                <Button color="primary" className="btn btn-rg" onClick={createPaymentGateWayDashBoardLink}>
                  Stripe Dashboard</Button>
              }
            </div>
            </>
  )
}

export default StripeConnection;
