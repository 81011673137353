import { Subscription } from 'react-apollo';

export const extendEvent = 'Extend Event';

export const staticConstants = {
  TWO: 2,
  TWENTY: 20,
  HUNDRED: 100,
  DATE_FORMAT: 'MMM DD, YYYY',
  DASHBOARD_FORMAT: 'MM/DD/YYYY',
  EVENT_EXTEND_ENABLE_DURATION: 15, // minutes
  TIME_FORMAT: 'hh:mm A',
  LOG_TIME_FORMAT: 'YYYY-MM-DD hh:mm A',
  NOTIFICATION_TIME_FORMAT: 'YYYY-MM-dd hh:mm A',
  MESSAGE_DATE_FORMAT: 'DD, MMMM YYYY',
  MESSAGE_TIME_FORMAT: 'HH:mm',
  PASSWORD_PATTERN: '',
  PARENT_PAYMENT_TAB: 'payment',
  ROLE: {
    EXPERT: 'expert',
    PROFESSIONAL: 'professional',
    ORGANIZATION_ADMIN: 'orgAdmin',
    ORGANIZATION_LEARNER: 'learner',
    SYSTEM_ADMIN: 'systemAdmin',
  },
  DISPLAY_ROLE: {
    expert: 'Expert',
    professional: 'Individual',
    orgAdmin: 'Team Admin',
    learner: 'Team Member',
    systemAdmin: 'Syatem Admin',
  },
  MAX_PROFILE_IMAGE_SIZE: 5000000, // 5 mb
  PROFILE_IMAGE_MIME: ['image/png', 'image/jpeg', 'image/gif'],
  MESSAGES: {
    profileImageInvalidExtension: 'Only .png, .jpg are allowed with maximum size up to 1 mb.',
  },
  LANDING_PAGE_FOOTER_TITLE: {
    FAQ: 'Frequently Asked Questions',
    ABOUT_US: 'Sustainability is hard',
    PRIVACY_POLICY: 'Privacy Policy',
    TERMS_AND_CONDITIONS: 'Terms and Conditions',
    CONTACT_US: 'Contact Us',
    PRICING: 'Making green business a priority',
    HOW_IT_WORK: 'How It Works',
  },
  LANDING_PAGE_SUB_TITLE: {
    HOW_IT_WORK: 'You set your goal, send a request, select an expert, then connect directly on Zoom in 4-simple steps.',
    ABOUT_US: 'We are the people with the knowledge you need to move forward.',
  },
  EXPERT_REGISTER_FORM_STEP: {
    REFERRED_INFO: 0,
    PERSONAL_INFO: 1,
    EDUCATION: 2,
    EXPERIENCE: 3,
    REFERENCE: 4,
  },
  BID_MANAGEMENT_TAB: {
    NEW_REQUEST: 'newRequest',
    OPEN_REQUEST: 'openRequest',
    SAVED_REQUEST: 'savedRequest',
    EVENTS: 'events',
    SENT_BIDS: 'sentBids',
    REJECTED_BIDS: 'rejectedBids',
  },
  BID_MANAGEMENT_ACTIVE_TAB: {
    newRequest: 'NEW REQUESTS',
    openRequest: 'OPEN REQUESTS',
    savedRequest: 'SAVED REQUESTS',
    sentBids: 'SENT BIDS',
    rejectedBids: 'REJECTED BIDS',
  },
  GOAL_MANAGEMENT_TAB: {
    ADMIN_LEARNING_GOALS: 'adminLearningGoals',
    LERNER_GOAL_REQUESTS: 'learnerGoalRequests',
    MY_LERNERING_GOALS: 'myLearningGoals',
    NEW_REQUEST: 'newRequest',
    ACCEPT_REQUEST: 'acceptRequest',
    REJECT_REQUEST: 'rejectRequest',
  },
  USER_MANAGEMENT_TAB: {
    ORG_ADMIN: 'organizationAdmin',
    PROFESSIONAL: 'professional',
    EXPERTS: 'expert',
    FLAG_PROFILES: 'flaggedProfile',
  },
  USER_MANAGEMENT_REPORT: {
    ORG_ADMIN: 'teamAdmin',
    PROFESSIONAL: 'individuals',
  },
  USER_MANAGEMENT_FILTER_TAB: {
    ORG_ADMIN: 'organizationAdmin',
    LEARNER: 'organizationAdmin',  // Check in backend side
    PROFESSIONAL: 'professional',
    REQUEST: 'request',
    EVENTS: 'events',
  },
  MESSAGE_BOARD_TAB: {
    ORGANIZATIONS: 'organization',
    USERS: {
      EXPERT: 'expert',
      PROFESSIONAL: 'professional',
      ORGANIZATION_LEARNER: 'organizationLearner',
    },
  },
  CONTENT_MANAGEMENT_TAB: {
    ABOUT_US: 'aboutUs',
    FAQ: 'faq',
    TERMS_AND_CONDITIONS: 'termsCondition',
    PRIVACY_POLICY: 'privacyPolicy',
    CONTACT_US: 'contactUs',
  },
  USER_MANAGEMENT_EXPERT_FILTER_TAB: {
    ALL: 'all',
    REQUEST: 'request',
    HOURLY_CHANGE_REQUEST: 'hourlyChangeRequest',
  },
  EXPERT_PAYMENT_TAB: {
    ALL: 'all',
    RECEIVED: 'received',
    PENDING: 'pending',
    WITHDRAWAL_HISTORY: 'withdrawalHistory',
  },
  SYSTEM_ADMIN_PAYMENT_FILTER_TAB: {
    ALL: 'all',
    ORG_ADMIN: 'organizationAdmin',
    PROFESSIONAL: 'professional',
  },
  USER_MANAGEMENT_ORG_ADMIN_FILTER_TAB: {
    ALL: 'all',
    LICENCE_MOVEMENT_REQUESTS: 'licenceMovement',
  },
  SYSTEM_ADMIN_PAYMENT_TAB: {
    PENDING_PAYMENTS: 'pending',
    RECEIVED_PAYMENTS: 'paymentTransferred',
    RELEASED_REFUNDED_PAYMENTS: 'refundTransfer',
    SUBSCRIPTION_FEE: 'subscription',
    ADD_ON_FEE: 'addOn',
    DISPUTE_PAYMENTS: 'disputed',
  },
  SYSTEM_ADMIN_TAGS_TAB: {
    DOMAIN: 'domain',
    SUB_DOMAIN: 'subDomain',
    CATEGORY: 'category',
    SUB_CATEGORY: 'subCategory',
    TOPIC: 'topic',
    ACTIVITY_CODE: 'activityCode',
  },
  TAGS_TITLE: {
    domain: 'Add Domain',
    subDomain: 'Add Sub-Domain',
    category: 'Add Category',
    subCategory: 'Add Sub-Category',
    topic: 'Add Topic',
    activityCode: 'Add Activity Code',
  },
  EDIT_TAGS_TITLE: {
    domain: 'Edit Domain',
    subDomain: 'Edit Sub-Domain',
    category: 'Edit Category',
    subCategory: 'Edit Sub-Category',
    topic: 'Edit Topic',
    activityCode: 'Edit Activity Code',
  },
  PAYMENTS_TYPE: {
    RELEASED_PAYMENTS: 'expertTransferDispute',
    REFUNDED_PAYMENTS: 'orgAdminRefundDispute',
  },
  MAX_LENGTH: {
    EXPERIENCE: 3,
    LANGUAGE: 3,
    REFERENCE: 3,
  },
  PAYMENT_CARD_TAB: {
    NEW_CARD: 'newCard',
    SAVED_CARD: 'savedCard',
  },
  EVENT_MANAGEMENT_TAB: {
    UPCOMING_EVENTS: 'upcomingEvents',
    PAST_EVENTS: 'pastEvents',
  },
  EVENT_MANAGEMENT_ACTIVE_TAB: {
    upcomingEvents: 'UPCOMING EVENTS',
    pastEvents: 'PAST EVENTS',
    organizationAdminGoal: 'Team Admins',
    professionalGoal: 'Professionals',
  },
  PENDING_PAYMENT_STATUS_VALUE: {
    sessionFee: 'Upcoming Event',
    disputed: 'In Dispute',
  },
  RECEIVED_PAYMENT_STATUS_VALUE: {
    sessionFee: 'Completed',
    expertTransferDispute: 'Dispute Released',
    eventCancelAmountTransferExpert: 'Cancelled Event',
    orgAdminRefundDispute: 'Dispute Refunded',
  },
  RATE_EXPERT_KNOWLEDGE: {
    1: 'Poor',
    2: 'Unsatisfactory',
    3: 'Satisfactory',
    4: 'Very Satisfactory',
    5: 'Outstanding',
  },
  RATE_OVERALL_SESSION_QUALITY: {
    1: 'Very Poor Quality',
    2: 'Poor Quality',
    3: 'Satifactory',
    4: 'High Quality',
    5: 'Very High Quality',
  },
  RATE_PARTICIPANTS_ENGAGEMENT: {
    1: 'Not Engaged',
    2: 'Somewhat Engaged',
    3: 'Engaged',
    4: 'Very Engaged',
    5: 'Extremely Engaged',
  },
  ADMIN_GOAL_MANAGEMENT_TAB: {
    ADMIN_LEARNING_GOALS: 'organizationAdminGoal',
    LERNER_GOAL_REQUESTS: 'learnerGoal',
    PROFESSIONSAL_LEARNING_GOALS: 'professionalGoal',
    EXPERT_LEARNING_GOALS: 'expertGoal',
  },

  ADMIN_BID_MANAGEMENT_TAB: {
    ADMIN_LEARNING_GOALS: 'organizationAdminGoal',
    LERNER_GOAL_REQUESTS: 'learnerGoal',
    PROFESSIONSAL_LEARNING_GOALS: 'professionalGoal',
  },

  ADMIN_REQUEST_MANAGEMENT_TAB: {
    ADMIN_ORGANIZATION_ADMIN_REQUESTS: 'organizationAdminGoal',
    ADMIN_PROFESSIONAL_REQUESTS: 'professionalGoal',
    ADMIN_EXPERT_REQUESTS: 'expert',
  },

  ADMIN_REQUEST_MANAGEMENT_SECONDARY_TAB: {
    DELETED_REQUESTS: { TYPE: 'deleted', TITLE: 'Deleted Requests' },
    NEW_REQUESTS: { TYPE: 'requestSent', TITLE: 'New Requests' },
  },
  SUBSRIPTION_PRICING: {
    ORG_ADMIN: 70,
    PROFESSIONAL: 35,
    ADD_ON: 15,
  },
  PAYMENT_STATUS: {
    PENDING: 'pending',
    ON_HOLD: 'onHold',
    DISPUTED: 'disputed',
    RESOLVED: 'resolved',
    ABORTED: 'aborted',
    ADDON: 'addOn',
    REFUND_TRANSFER: 'refundTransfer',
    SUBSCRIPTION: 'subscription',
    PAYMENT_TRANSFERED: 'paymentTransferred',
    PAYMENT_RELEASED: 'paymentReleased',
    PAYMENT_FAILURE: 'paymentFailure',
    ORG_ADMIN_REFUND_DISPUTE: 'orgAdminRefundDispute',
    EXPERT_TRANSFER_DISPUTE: 'expertTransferDispute',
    EVENT_CANCEL_AMOUNT_REFUND: 'eventCancelAmountRefund',
    EVENT_CANCEL_AMOUNT_TRANSFER_EXPERT: 'eventCancelAmountTransferExpert',
    EVENT_CANCEL_AMOUNT_SYSTEM_ADMIN: 'eventCancelAmountTransferSysAdmin',
  },
  ORG_ADMIN_SUB_TEXT: `As a team leader, your profile will be the Team Admin.
  Team Admins have access to more features than everyone else and charged at $70 per month.
  Team members are charged at $35 per month. Either way, the first month is free. Select the number of team members and you’re done.
  You can adjust this number at any time.`,
  PROFESSIONAL_SUB_TEXT: `If you are an individual user, not looking to collaborate with your colleagues in a team,
   you pay $35 per month for access to Overlap. And, you get your first month free.`,
  LEARNER_ERROR_CODE: 426,
  LEARNER_REMOVE_CODE: 'Error: GraphQL error: 412',
  CHECK_MY_TAGS: `Unsure of your goal? Turn on to get help. We'll send suggestions to improve your request
   for expertise and ensure that you get what you need. Straight to your inbox.`,
};
export const GRAPHQL_ERRORS = {
  UNAUTHENTICATED: 'UNAUTHENTICATED',
};
export const eventTypes = [
  { value: 'Advice', label: 'Advice' },
  { value: 'Assessment', label: 'Assessment' },
  { value: 'Briefing', label: 'Briefing' },
  { value: 'Facilitation', label: 'Facilitation' },
  { value: 'Interview', label: 'Interview' },
  { value: 'Talk', label: 'Talk' },
  { value: 'Training', label: 'Training' },
  { value: 'Problem Solving', label: 'Problem Solving' },
];

export const userStatusTypes = [
  { value: 'active', label: 'Active' },
  { value: 'inActive', label: 'InActive' },
];

export const flagTypes = [
  { value: 'organizationAdmin', label: 'Team Admin' },
  { value: 'learner', label: 'Participant' },
  { value: 'expert', label: 'Expert' },
  { value: 'professional', label: 'Individual' },
];

export const notificationFilterOptions = {
  orgAdmin: [
    { value: 'all', label: 'All' },
    { value: 'learningGoals', label: 'Learning Goals' },
    { value: 'requests', label: 'Requests' },
    { value: 'scheduledEvents', label: 'Events' },
    { value: 'membership', label: 'Membership' },
    { value: 'eventExtended', label: extendEvent },
  ],
  professional: [
    { value: 'all', label: 'All' },
    { value: 'requests', label: 'Requests' },
    { value: 'scheduledEvents', label: 'Events' },
    { value: 'membership', label: 'Membership' },
    { value: 'eventExtended', label: extendEvent },
  ],
  expert: [
    { value: 'all', label: 'All' },
    { value: 'scheduledEvents', label: 'Scheduled Events' },
    { value: 'rejectBid', label: 'Bid Rejected' },
    { value: 'eventExtended', label: extendEvent },
  ],
  learner: [
    { value: 'all', label: 'All' },
    { value: 'learningGoals', label: 'Learning Goals' },
    { value: 'scheduledEvents', label: 'Events' },
  ],
  systemAdmin: [
    { value: 'all', label: 'All' },
    { value: 'hourlyChangeRequestSystemAdmin', label: 'Hourly Rate Change Request' },
    { value: 'paymentHeldSystemAdmin', label: 'Payment Held' },
    { value: 'flagUserSystemAdmin', label: 'Flagged profile' },
  ],
};

export const notificationType = {
  BID_REQUEST: 'bidRequest',
  ACCEPT_BID: 'acceptBid',
  REJECT_BID: 'rejectBid',
  DELETE_BID: 'deleteBid',
  EDIT_GOAL: 'editGoal',
  ACCEPT_GOAL: 'acceptGoal',
  REJECT_GOAL: 'rejectGoal',
  DELETE_GOAL: 'deleteGoal',
  SHORTLIST_BID: 'shortlistBid',
  REQUEST_ADMIN: 'learnerRequest',
  MEMBERSHIP: 'subscriptionPayment',
  SENT_BID: 'sentBid',
  WELCOME: 'welcomeMessage',
  RFP_REQUEST_LEARNER: 'rfpRequestLearner',
  RFP_REQUEST_EXPERT: 'rfpRequestExpert',
  HOURLY_CHANGE_REQUEST: 'hourlyChangeRequest',
  FLAG_PROFILE: 'flagProfile',
  CANCEL_BID_EXPERT: 'cancelBidExpert',
  EXPERT_PAYMENT_DUE: 'expertPaymentDue',
  PAYMENT_ADD_ON: 'paymentAddOn',
  SEND_RFP: 'sendRFP',
  SCHEDULE_EVENT: 'scheduleEvent',
  FLAG_USER_SYSTEM_ADMIN: 'flagUserSystemAdmin',
  HOURLY_CHANGE_REQUEST_SYSTEM_ADMIN: 'hourlyChangeRequestSystemAdmin',
  PAYMENT_HELD_SYSTEM_ADMIN: 'paymentHeldSystemAdmin',
  EXTEND_EVENT: 'eventExtended',
  CANCEL_EVENT: 'cancelEvent',
  EXPERT_REGISTER: 'expertRegister',
  LICENCE_MOVEMENT_REQUEST: 'LicenceMovementRequest',
  LICENCE_MOVEMENT_APPROVE: 'LicenceMovementApprove',
  LICENCE_MOVEMENT_REJECT: 'LicenceMovementReject',
  PAYMENT_RECEIVE: 'paymentReceive',
  REVIEW_REQUEST: 'reviewRequest',
  REHIRE_EXPERT: 'rehireExpert',
  RESCHEDULE_REQUESTED: 'rescheduleRequested',
  RESCHEDULE_RESPONSE_RECEIVED: 'rescheduleResponseReceived',
  RESCHEDULEREQUESTEXPIRED: 'rescheduleRequestExpired',
  LEARNER_GOAL_ACCECPTED: 'learnerGoalAccepted',
  LEARNER_GOAL_REJECTED: 'learnerGoalRejected',
  LEARNER_EVENT_ACCEPTED: 'learnerEventAccepted',
  GOAL_CREATED_FOR_LEARNERS: 'goalCreatedForLearners',
  BID_CANCELED_BY_EXPERT: 'bidCanceledByExpert',
};

export const languages = [
  {
    label: 'Afar',
    value: 'Afar',
  }, {
    label: 'Afrikaans',
    value: 'Afrikaans',
  }, {
    label: 'Albanian (official)',
    value: 'Albanian (official)',
  }, {
    label: 'Amharic',
    value: 'Amharic',
  }, {
    label: 'Angaur',
    value: 'Angaur',
  }, {
    label: 'Arabic (official)',
    value: 'Arabic (official)',
  }, {
    label: 'Aramaic',
    value: 'Aramaic',
  }, {
    label: 'Armenian (official)',
    value: 'Armenian (official)',
  }, {
    label: 'Assamese',
    value: 'Assamese',
  }, {
    label: 'Assyrian',
    value: 'Assyrian',
  }, {
    label: 'Aymara',
    value: 'Aymara',
  }, {
    label: 'Aymara (Bolivia)',
    value: 'Aymara (Bolivia)',
  }, {
    label: 'Azerbaijani (Georgia)',
    value: 'Azerbaijani (Georgia)',
  }, {
    label: 'Azerbaijani Turkic',
    value: 'Azerbaijani Turkic',
  }, {
    label: 'Azeri',
    value: 'Azeri',
  }, {
    label: 'Bahasa Indonesia',
    value: 'Bahasa Indonesia',
  }, {
    label: 'Bahasa Melayu (Malay)',
    value: 'Bahasa Melayu (Malay)',
  }, {
    label: 'Balochi',
    value: 'Balochi',
  }, {
    label: 'Bambara',
    value: 'Bambara',
  }, {
    label: 'Bandjabi',
    value: 'Bandjabi',
  }, {
    label: 'Bangla',
    value: 'Bangla',
  }, {
    label: 'Bantu',
    value: 'Bantu',
  }, {
    label: 'Bapounou/Eschira',
    value: 'Bapounou/Eschira',
  }, {
    label: 'Bari',
    value: 'Bari',
  }, {
    label: 'Basque',
    value: 'Basque',
  }, {
    label: 'Belorussian',
    value: 'Belorussian',
  }, {
    label: 'Bemba',
    value: 'Bemba',
  }, {
    label: 'Bengali',
    value: 'Bengali',
  }, {
    label: 'Bhojpuri',
    value: 'Bhojpuri',
  }, {
    label: 'Bicol',
    value: 'Bicol',
  }, {
    label: 'Bislama (Vanuatu)',
    value: 'Bislama (Vanuatu)',
  }, {
    label: 'Bojpoori',
    value: 'Bojpoori',
  }, {
    label: 'Bokmål Norwegian',
    value: 'Bokmål Norwegian',
  }, {
    label: 'Bosnian (official)',
    value: 'Bosnian (official)',
  }, {
    label: 'Brahui',
    value: 'Brahui',
  }, {
    label: 'Brazilero (Uruguay)',
    value: 'Brazilero (Uruguay)',
  }, {
    label: 'Bubi',
    value: 'Bubi',
  }, {
    label: 'Bulgarian',
    value: 'Bulgarian',
  }, {
    label: 'Burmese',
    value: 'Burmese',
  }, {
    label: 'Burushaski',
    value: 'Burushaski',
  }, {
    label: 'Cantonese',
    value: 'Cantonese',
  }, {
    label: 'Carolinian',
    value: 'Carolinian',
  }, {
    label: 'Castilian',
    value: 'Castilian',
  }, {
    label: 'Catalan',
    value: 'Catalan',
  }, {
    label: 'Catalán (official)',
    value: 'Catalán (official)',
  }, {
    label: 'Cebuano',
    value: 'Cebuano',
  }, {
    label: 'Chichewa',
    value: 'Chichewa',
  }, {
    label: 'Chilomwe',
    value: 'Chilomwe',
  }, {
    label: 'Chinese (official)',
    value: 'Chinese (official)',
  }, {
    label: 'Chinyanja',
    value: 'Chinyanja',
  }, {
    label: 'Chisena',
    value: 'Chisena',
  }, {
    label: 'Chitonga',
    value: 'Chitonga',
  }, {
    label: 'Chitumbuka',
    value: 'Chitumbuka',
  }, {
    label: 'Chiyao',
    value: 'Chiyao',
  }, {
    label: 'Chukese',
    value: 'Chukese',
  }, {
    label: 'Circassian',
    value: 'Circassian',
  }, {
    label: 'Cisena',
    value: 'Cisena',
  }, {
    label: 'Creole',
    value: 'Creole',
  }, {
    label: 'Criolo',
    value: 'Criolo',
  }, {
    label: 'Criuolo (Cape Verde)',
    value: 'Criuolo (Cape Verde)',
  }, {
    label: 'Croatian',
    value: 'Croatian',
  }, {
    label: 'Czech',
    value: 'Czech',
  }, {
    label: 'Dagomba',
    value: 'Dagomba',
  }, {
    label: 'Danish',
    value: 'Danish',
  }, {
    label: 'Dari Persian',
    value: 'Dari Persian',
  }, {
    label: 'Dimli',
    value: 'Dimli',
  }, {
    label: 'Dinka',
    value: 'Dinka',
  }, {
    label: 'Djerma',
    value: 'Djerma',
  }, {
    label: 'Dutch (official)',
    value: 'Dutch (official)',
  }, {
    label: 'Dzongkha (Bhutan)',
    value: 'Dzongkha (Bhutan)',
  }, {
    label: 'Echuwabo',
    value: 'Echuwabo',
  }, {
    label: 'Elomwe',
    value: 'Elomwe',
  }, {
    label: 'Emakhuwa',
    value: 'Emakhuwa',
  }, {
    label: 'English (official)',
    value: 'English (official)',
  }, {
    label: 'English (United Kingdom)',
    value: 'English (United Kingdom)',
  }, {
    label: 'English (United States)',
    value: 'English (United States)',
  }, {
    label: 'Estonian (Estonia)',
    value: 'Estonian (Estonia)',
  }, {
    label: 'Ewé',
    value: 'Ewé',
  }, {
    label: 'Fang',
    value: 'Fang',
  }, {
    label: 'Faroese',
    value: 'Faroese',
  }, {
    label: 'Farsi',
    value: 'Farsi',
  }, {
    label: 'Fijian',
    value: 'Fijian',
  }, {
    label: 'Filipino',
    value: 'Filipino',
  }, {
    label: 'Finnish (official)',
    value: 'Finnish (official)',
  }, {
    label: 'Fon (Benin)',
    value: 'Fon (Benin)',
  }, {
    label: 'French (France)',
    value: 'French (France)',
  }, {
    label: 'French (Francophone)',
    value: 'French (Francophone)',
  }, {
    label: 'French (Quebec)',
    value: 'French (Quebec)',
  }, {
    label: 'French patois',
    value: 'French patois',
  }, {
    label: 'Frisian',
    value: 'Frisian',
  }, {
    label: 'Fula',
    value: 'Fula',
  }, {
    label: 'Fulani',
    value: 'Fulani',
  }, {
    label: 'Gaelic',
    value: 'Gaelic',
  }, {
    label: 'Gagauz',
    value: 'Gagauz',
  }, {
    label: 'Galician',
    value: 'Galician',
  }, {
    label: 'Gan',
    value: 'Gan',
  }, {
    label: 'Garifuna (Belize)',
    value: 'Garifuna (Belize)',
  }, {
    label: 'Georgian',
    value: 'Georgian',
  }, {
    label: 'German (official)',
    value: 'German (official)',
  }, {
    label: 'Greek',
    value: 'Greek',
  }, {
    label: 'Greenlandic (Inuit dialect)',
    value: 'Greenlandic (Inuit dialect)',
  }, {
    label: 'Guaragigna',
    value: 'Guaragigna',
  }, {
    label: 'Guaraní',
    value: 'Guaraní',
  }, {
    label: 'Gujarati',
    value: 'Gujarati',
  }, {
    label: 'Hassaniya Arabic',
    value: 'Hassaniya Arabic',
  }, {
    label: 'Hassaniya Arabic (official)',
    value: 'Hassaniya Arabic (official)',
  }, {
    label: 'Hausa',
    value: 'Hausa',
  }, {
    label: 'Hebrew',
    value: 'Hebrew',
  }, {
    label: 'Herero',
    value: 'Herero',
  }, {
    label: 'Hiligaynon or Ilonggo',
    value: 'Hiligaynon or Ilonggo',
  }, {
    label: 'Hindi',
    value: 'Hindi',
  }, {
    label: 'Hindko',
    value: 'Hindko',
  }, {
    label: 'Hiri Motu',
    value: 'Hiri Motu',
  }, {
    label: 'Hokkien',
    value: 'Hokkien',
  }, {
    label: 'Hungarian',
    value: 'Hungarian',
  }, {
    label: 'I-Kiribati (Gilbertese)',
    value: 'I-Kiribati (Gilbertese)',
  }, {
    label: 'Ibo',
    value: 'Ibo',
  }, {
    label: 'Icelandic',
    value: 'Icelandic',
  }, {
    label: 'Ilocano',
    value: 'Ilocano',
  }, {
    label: 'Irish (Gaelic)',
    value: 'Irish (Gaelic)',
  }, {
    label: 'IsiXhosa',
    value: 'IsiXhosa',
  }, {
    label: 'IsiZulu',
    value: 'IsiZulu',
  }, {
    label: 'Italian (official)',
    value: 'Italian (official)',
  }, {
    label: 'Jamaican Creole',
    value: 'Jamaican Creole',
  }, {
    label: 'Japanese',
    value: 'Japanese',
  }, {
    label: 'Javanese',
    value: 'Javanese',
  }, {
    label: 'Jola',
    value: 'Jola',
  }, {
    label: 'Kabardian',
    value: 'Kabardian',
  }, {
    label: 'Kabyé',
    value: 'Kabyé',
  }, {
    label: 'Kalanga (Botswana)',
    value: 'Kalanga (Botswana)',
  }, {
    label: 'Kannada',
    value: 'Kannada',
  }, {
    label: 'Kaonda',
    value: 'Kaonda',
  }, {
    label: 'Kapingamarangi',
    value: 'Kapingamarangi',
  }, {
    label: 'Kashmiri',
    value: 'Kashmiri',
  }, {
    label: 'Kazak',
    value: 'Kazak',
  }, {
    label: 'Khmer',
    value: 'Khmer',
  }, {
    label: 'Kikongo',
    value: 'Kikongo',
  }, {
    label: 'Kingwana',
    value: 'Kingwana',
  }, {
    label: 'Kinyarwanda',
    value: 'Kinyarwanda',
  }, {
    label: 'Kiribati',
    value: 'Kiribati',
  }, {
    label: 'Kirundi (Burundi)',
    value: 'Kirundi (Burundi)',
  }, {
    label: 'Korean',
    value: 'Korean',
  }, {
    label: 'Kosrean',
    value: 'Kosrean',
  }, {
    label: 'Krio',
    value: 'Krio',
  }, {
    label: 'Kurdish',
    value: 'Kurdish',
  }, {
    label: 'Kyrgyz',
    value: 'Kyrgyz',
  }, {
    label: 'Lao (official)',
    value: 'Lao (official)',
  }, {
    label: 'Latin (Vatican City)',
    value: 'Latin (Vatican City)',
  }, {
    label: 'Latvian',
    value: 'Latvian',
  }, {
    label: 'Lingala',
    value: 'Lingala',
  }, {
    label: 'Lithuanian',
    value: 'Lithuanian',
  }, {
    label: 'Lozi',
    value: 'Lozi',
  }, {
    label: 'Luganda',
    value: 'Luganda',
  }, {
    label: 'Lunda',
    value: 'Lunda',
  }, {
    label: 'Luri',
    value: 'Luri',
  }, {
    label: 'Luvale',
    value: 'Luvale',
  }, {
    label: 'Luxermbourgish',
    value: 'Luxermbourgish',
  }, {
    label: 'Macedonian',
    value: 'Macedonian',
  }, {
    label: 'Magyar (Hungary)',
    value: 'Magyar (Hungary)',
  }, {
    label: 'Maithali',
    value: 'Maithali',
  }, {
    label: 'Malagasy',
    value: 'Malagasy',
  }, {
    label: 'Malay',
    value: 'Malay',
  }, {
    label: 'Malayalam',
    value: 'Malayalam',
  }, {
    label: 'Maldivian Dhivehi (official)',
    value: 'Maldivian Dhivehi (official)',
  }, {
    label: 'Maltese',
    value: 'Maltese',
  }, {
    label: 'Mandarin (Singapore)',
    value: 'Mandarin (Singapore)',
  }, {
    label: 'Mandinka',
    value: 'Mandinka',
  }, {
    label: 'Maori',
    value: 'Maori',
  }, {
    label: 'Marathi',
    value: 'Marathi',
  }, {
    label: 'Marshallese',
    value: 'Marshallese',
  }, {
    label: 'Mayan',
    value: 'Mayan',
  }, {
    label: 'Melanesian pidgin',
    value: 'Melanesian pidgin',
  }, {
    label: 'Mende',
    value: 'Mende',
  }, {
    label: 'Mina',
    value: 'Mina',
  }, {
    label: 'Minbei (Fuzhou)',
    value: 'Minbei (Fuzhou)',
  }, {
    label: 'Minnan (Hokkien-Taiwanese)',
    value: 'Minnan (Hokkien-Taiwanese)',
  }, {
    label: 'Mirandese',
    value: 'Mirandese',
  }, {
    label: 'Moldovan',
    value: 'Moldovan',
  }, {
    label: 'Monégasque',
    value: 'Monégasque',
  }, {
    label: 'Mongolian',
    value: 'Mongolian',
  }, {
    label: 'Monokutuba',
    value: 'Monokutuba',
  }, {
    label: 'Montenegrin',
    value: 'Montenegrin',
  }, {
    label: 'Moroccan Arabic',
    value: 'Moroccan Arabic',
  }, {
    label: 'Myene',
    value: 'Myene',
  }, {
    label: 'Nahua',
    value: 'Nahua',
  }, {
    label: 'Nahuatl',
    value: 'Nahuatl',
  }, {
    label: 'Nama',
    value: 'Nama',
  }, {
    label: 'Nauruan',
    value: 'Nauruan',
  }, {
    label: 'Ndebele (Sindebele)',
    value: 'Ndebele (Sindebele)',
  }, {
    label: 'Nepali',
    value: 'Nepali',
  }, {
    label: 'Nilo-Hamitic',
    value: 'Nilo-Hamitic',
  }, {
    label: 'Nubian',
    value: 'Nubian',
  }, {
    label: 'Nuer',
    value: 'Nuer',
  }, {
    label: 'Nukuoro',
    value: 'Nukuoro',
  }, {
    label: 'Nyanja',
    value: 'Nyanja',
  }, {
    label: 'Nynorsk Norwegian',
    value: 'Nynorsk Norwegian',
  }, {
    label: 'Nzebi',
    value: 'Nzebi',
  }, {
    label: 'Oriya',
    value: 'Oriya',
  }, {
    label: 'Orominga',
    value: 'Orominga',
  }, {
    label: 'Oshivambo',
    value: 'Oshivambo',
  }, {
    label: 'Palauan',
    value: 'Palauan',
  }, {
    label: 'Pampango',
    value: 'Pampango',
  }, {
    label: 'Pangasinense',
    value: 'Pangasinense',
  }, {
    label: 'Panjabi',
    value: 'Panjabi',
  }, {
    label: 'Pashtu',
    value: 'Pashtu',
  }, {
    label: 'Persian',
    value: 'Persian',
  }, {
    label: 'Pohnpeian',
    value: 'Pohnpeian',
  }, {
    label: 'Polish',
    value: 'Polish',
  }, {
    label: 'Portuguese',
    value: 'Portuguese',
  }, {
    label: 'Portuguese (official)',
    value: 'Portuguese (official)',
  }, {
    label: 'Portunol (Uruguay)',
    value: 'Portunol (Uruguay)',
  }, {
    label: 'Pulaar',
    value: 'Pulaar',
  }, {
    label: 'Punjabi',
    value: 'Punjabi',
  }, {
    label: 'Quéchua',
    value: 'Quéchua',
  }, {
    label: 'Roma (official)',
    value: 'Roma (official)',
  }, {
    label: 'Romanian',
    value: 'Romanian',
  }, {
    label: 'Romansch',
    value: 'Romansch',
  }, {
    label: 'Russian (official)',
    value: 'Russian (official)',
  }, {
    label: 'Sami',
    value: 'Sami',
  }, {
    label: 'Samoan',
    value: 'Samoan',
  }, {
    label: 'Sangho',
    value: 'Sangho',
  }, {
    label: 'Sanskrit',
    value: 'Sanskrit',
  }, {
    label: 'Sara',
    value: 'Sara',
  }, {
    label: 'Scots',
    value: 'Scots',
  }, {
    label: 'Sekgalagadi (Botswana)',
    value: 'Sekgalagadi (Botswana)',
  }, {
    label: 'Sepedi',
    value: 'Sepedi',
  }, {
    label: 'Serbian (official)',
    value: 'Serbian (official)',
  }, {
    label: 'Serbo-Croatian',
    value: 'Serbo-Croatian',
  }, {
    label: 'Seselwa Creole',
    value: 'Seselwa Creole',
  }, {
    label: 'Sesotho',
    value: 'Sesotho',
  }, {
    label: 'Setswana',
    value: 'Setswana',
  }, {
    label: 'Shikomoro (Comoros)',
    value: 'Shikomoro (Comoros)',
  }, {
    label: 'Shilluk',
    value: 'Shilluk',
  }, {
    label: 'Shona',
    value: 'Shona',
  }, {
    label: 'Sindhi',
    value: 'Sindhi',
  }, {
    label: 'Sinhala',
    value: 'Sinhala',
  }, {
    label: 'Siraiki',
    value: 'Siraiki',
  }, {
    label: 'siSwati',
    value: 'siSwati',
  }, {
    label: 'Slovak',
    value: 'Slovak',
  }, {
    label: 'Slovene (Austria)',
    value: 'Slovene (Austria)',
  }, {
    label: 'Slovenian',
    value: 'Slovenian',
  }, {
    label: 'Somali',
    value: 'Somali',
  }, {
    label: 'Soninke',
    value: 'Soninke',
  }, {
    label: 'Sonsoralese',
    value: 'Sonsoralese',
  }, {
    label: 'Spanish (official)',
    value: 'Spanish (official)',
  }, {
    label: 'Surinamese (lingua franca)',
    value: 'Surinamese (lingua franca)',
  }, {
    label: 'Swahili',
    value: 'Swahili',
  }, {
    label: 'Swedish',
    value: 'Swedish',
  }, {
    label: 'Ta Bedawie',
    value: 'Ta Bedawie',
  }, {
    label: 'Tagalog',
    value: 'Tagalog',
  }, {
    label: 'Taiwanese',
    value: 'Taiwanese',
  }, {
    label: 'Tajik (official)',
    value: 'Tajik (official)',
  }, {
    label: 'Tamang',
    value: 'Tamang',
  }, {
    label: 'Tamil',
    value: 'Tamil',
  }, {
    label: 'Telugu',
    value: 'Telugu',
  }, {
    label: 'Temne',
    value: 'Temne',
  }, {
    label: 'Teochew',
    value: 'Teochew',
  }, {
    label: 'Tetum',
    value: 'Tetum',
  }, {
    label: 'Thai',
    value: 'Thai',
  }, {
    label: 'Tharu',
    value: 'Tharu',
  }, {
    label: 'Tigre and Kunama',
    value: 'Tigre and Kunama',
  }, {
    label: 'Tigrigna',
    value: 'Tigrigna',
  }, {
    label: 'Tobi',
    value: 'Tobi',
  }, {
    label: 'Tok Pisin',
    value: 'Tok Pisin',
  }, {
    label: 'Tonga',
    value: 'Tonga',
  }, {
    label: 'Tongan',
    value: 'Tongan',
  }, {
    label: 'Tshiluba',
    value: 'Tshiluba',
  }, {
    label: 'Turkish (official)',
    value: 'Turkish (official)',
  }, {
    label: 'Turkmen',
    value: 'Turkmen',
  }, {
    label: 'Tuvaluan',
    value: 'Tuvaluan',
  }, {
    label: 'Ukrainian',
    value: 'Ukrainian',
  }, {
    label: 'Ulithian',
    value: 'Ulithian',
  }, {
    label: 'Urdu',
    value: 'Urdu',
  }, {
    label: 'Uzbek',
    value: 'Uzbek',
  }, {
    label: 'Vietnamese (official)',
    value: 'Vietnamese (official)',
  }, {
    label: 'Waray',
    value: 'Waray',
  }, {
    label: 'Welsh',
    value: 'Welsh',
  }, {
    label: 'Woleaian',
    value: 'Woleaian',
  }, {
    label: 'Wolof',
    value: 'Wolof',
  }, {
    label: 'Wu (Shanghaiese)',
    value: 'Wu (Shanghaiese)',
  }, {
    label: 'Xhosa',
    value: 'Xhosa',
  }, {
    label: 'Xiang',
    value: 'Xiang',
  }, {
    label: 'Xichangana',
    value: 'Xichangana',
  }, {
    label: 'Xitsonga',
    value: 'Xitsonga',
  }, {
    label: 'Yapase',
    value: 'Yapase',
  }, {
    label: 'Yezidi',
    value: 'Yezidi',
  }, {
    label: 'Yoruba',
    value: 'Yoruba',
  }, {
    label: 'Yue (Cantonese)',
    value: 'Yue (Cantonese)',
  }, {
    label: 'Zande',
    value: 'Zande',
  }, {
    label: 'Zulu',
    value: 'Zulu',
  }];

export const languageProficiencies = [
  { value: 'read', label: 'Read' },
  { value: 'write', label: 'Write' },
  { value: 'speak', label: 'Speak' },
];

export const alllanguageProficiencies = [
  { value: 'langulage', label: 'Language' },
  { value: 'all', label: 'All' },
];

export const allAvailableDays = [
  { value: 'allDays', label: 'All Days' },
];

export const availableDays = [
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tuesday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thursday', label: 'Thursday' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
  { value: 'sunday', label: 'Sunday' },
];

export const goalStatusTypes = [
  { value: 'requestSent', label: 'Request Sent' },
  { value: 'created', label: 'Created' },
  { value: 'deleted', label: 'Deleted' },
];

export const learnerStatus = [
  { value: 'assign', label: 'Assign' },
  { value: 'unassign', label: 'Unassign' },
  { value: 'requestSent', label: 'Request Sent' },
];

export const rfpStatusTypes = [
  { value: 'awaiting', label: 'Awaiting Bids' },
  { value: 'received', label: 'Received Bids' },
];

export const tableItemsCount = 10;

export const reeValidators = {
  required_min_3_max_50: 'required|min:3|max:50',
  required_min_3_max_100: 'required|min:3|max:100',
  required_min_3_max_500: 'required|min:3|max:500',
  required_min_1_max_100: 'required|min:1|max:100',
  min_3_max_100: 'min:3|max:100',
  email: 'required|email',
  pass: 'required|verify_password',
};

export const locations = [
  { value: 'Afghanistan', label: 'Afghanistan' },
  { value: 'Albania', label: 'Albania' },
  { value: 'Algeria', label: 'Algeria' },
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Angola', label: 'Angola' },
  { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
  { value: 'Argentina', label: 'Argentina' },
  { value: 'Armenia', label: 'Armenia' },
  { value: 'Australia', label: 'Australia' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Azerbaijan', label: 'Azerbaijan' },
  { value: 'Bahamas', label: 'Bahamas' },
  { value: 'Bahrain', label: 'Bahrain' },
  { value: 'Bangladesh', label: 'Bangladesh' },
  { value: 'Barbados', label: 'Barbados' },
  { value: 'Belarus', label: 'Belarus' },
  { value: 'Belgium', label: 'Belgium' },
  { value: 'Belize', label: 'Belize' },
  { value: 'Benin', label: 'Benin' },
  { value: 'Bhutan', label: 'Bhutan' },
  { value: 'Bolivia', label: 'Bolivia' },
  { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { value: 'Botswana', label: 'Botswana' },
  { value: 'Brazil', label: 'Brazil' },
  { value: 'Brunei', label: 'Brunei' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Burkina Faso', label: 'Burkina Faso' },
  { value: 'Burundi', label: 'Burundi' },
  { value: 'Cambodia', label: 'Cambodia' },
  { value: 'Cameroon', label: 'Cameroon' },
  { value: 'Canada', label: 'Canada' },
  { value: 'Cape Verde', label: 'Cape Verde' },
  { value: ' Central African Republic', label: ' Central African Republic' },
  { value: 'Chad', label: 'Chad' },
  { value: 'Chile', label: 'Chile' },
  { value: 'China', label: 'China' },
  { value: 'Colombia', label: 'Colombia' },
  { value: 'Comoros', label: 'Comoros' },
  { value: 'Congo, Democratic Republic of the', label: 'Congo, Democratic Republic of the' },
  { value: 'Congo, Republic of', label: 'Congo, Republic of' },
  { value: 'Costa Rica', label: 'Costa Rica' },
  { value: 'Côte d \'Ivoire, label: Côte d \'Ivoire' },
  { value: 'Croatia', label: 'Croatia' },
  { value: 'Cuba', label: 'Cuba' },
  { value: 'Cyprus', label: 'Cyprus' },
  { value: 'Czech Republic', label: 'Czech Republic' },
  { value: 'Denmark', label: 'Denmark' },
  { value: 'Djibouti', label: 'Djibouti' },
  { value: 'Dominica', label: 'Dominica' },
  { value: 'Dominican Republic', label: 'Dominican Republic' },
  { value: 'East Timor', label: 'East Timor' },
  { value: 'Ecuador', label: 'Ecuador' },
  { value: 'Egypt', label: 'Egypt' },
  { value: 'El Salvador', label: 'El Salvador' },
  { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
  { value: 'Eritrea', label: 'Eritrea' },
  { value: 'Estonia', label: 'Estonia' },
  { value: 'Ethiopia', label: 'Ethiopia' },
  { value: 'Fiji', label: 'Fiji' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { value: 'Gabon', label: 'Gabon' },
  { value: 'Gambia', label: 'Gambia' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Germany', label: 'Germany' },
  { value: 'Ghana', label: 'Ghana' },
  { value: 'Greece', label: 'Greece' },
  { value: 'Grenada', label: 'Grenada' },
  { value: 'Guatemala', label: 'Guatemala' },
  { value: 'Guinea', label: 'Guinea' },
  { value: ' Guinea-Bissau', label: ' Guinea-Bissau' },
  { value: 'Guyana', label: 'Guyana' },
  { value: 'Haiti', label: 'Haiti' },
  { value: 'Honduras', label: 'Honduras' },
  { value: 'Hungary', label: 'Hungary' },
  { value: 'Iceland', label: 'Iceland' },
  { value: 'India', label: 'India' },
  { value: 'Indonesia', label: 'Indonesia' },
  { value: 'Iran', label: 'Iran' },
  { value: 'Iraq', label: 'Iraq' },
  { value: 'Ireland', label: 'Ireland' },
  { value: 'Israel', label: 'Israel' },
  { value: 'Italy', label: 'Italy' },
  { value: 'Jamaica', label: 'Jamaica' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Jordan', label: 'Jordan' },
  { value: 'Kazakhstan', label: 'Kazakhstan' },
  { value: 'Kenya', label: 'Kenya' },
  { value: 'Kiribati', label: 'Kiribati' },
  { value: 'Korea, North', label: 'Korea, North' },
  { value: 'Korea, South', label: 'Korea, South' },
  { value: 'Kosovo', label: 'Kosovo' },
  { value: 'Kuwait', label: 'Kuwait' },
  { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  { value: 'Laos', label: 'Laos' },
  { value: 'Latvia', label: 'Latvia' },
  { value: 'Lebanon', label: 'Lebanon' },
  { value: 'Lesotho', label: 'Lesotho' },
  { value: 'Liberia', label: 'Liberia' },
  { value: 'Libya', label: 'Libya' },
  { value: 'Liechtenstein', label: 'Liechtenstein' },
  { value: 'Lithuania', label: 'Lithuania' },
  { value: 'Luxembourg', label: 'Luxembourg' },
  { value: 'Macedonia', label: 'Macedonia' },
  { value: 'Madagascar', label: 'Madagascar' },
  { value: 'Malawi', label: 'Malawi' },
  { value: 'Malaysia', label: 'Malaysia' },
  { value: 'Maldives', label: 'Maldives' },
  { value: 'Mali', label: 'Mali' },
  { value: 'Malta', label: 'Malta' },
  { value: 'Marshall Islands', label: 'Marshall Islands' },
  { value: 'Mauritania', label: 'Mauritania' },
  { value: 'Mauritius', label: 'Mauritius' },
  { value: 'Mexico', label: 'Mexico' },
  { value: 'Micronesia', label: 'Micronesia' },
  { value: 'Moldova', label: 'Moldova' },
  { value: 'Monaco', label: 'Monaco' },
  { value: 'Mongolia', label: 'Mongolia' },
  { value: 'Montenegro', label: 'Montenegro' },
  { value: 'Morocco', label: 'Morocco' },
  { value: 'Mozambique', label: 'Mozambique' },
  { value: 'Myanmar', label: 'Myanmar' },
  { value: 'Namibia', label: 'Namibia' },
  { value: 'Nauru', label: 'Nauru' },
  { value: 'Nepal', label: 'Nepal' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'New Zealand', label: 'New Zealand' },
  { value: 'Nicaragua', label: 'Nicaragua' },
  { value: 'Niger', label: 'Niger' },
  { value: 'Nigeria', label: 'Nigeria' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  { value: 'Oman', label: 'Oman' },
  { value: 'Pakistan', label: 'Pakistan' },
  { value: 'Palau', label: 'Palau' },
  { value: 'Palestinian State', label: 'Palestinian State' },
  { value: 'Panama', label: 'Panama' },
  { value: 'Papua New Guinea', label: 'Papua New Guinea' },
  { value: 'Paraguay', label: 'Paraguay' },
  { value: 'Peru', label: 'Peru' },
  { value: 'Philippines', label: 'Philippines' },
  { value: 'Poland', label: 'Poland' },
  { value: 'Portugal', label: 'Portugal' },
  { value: 'Qatar', label: 'Qatar' },
  { value: 'Romania', label: 'Romania' },
  { value: 'Russia', label: 'Russia' },
  { value: 'Rwanda', label: 'Rwanda' },
  { value: 'Niger', label: 'Niger' },
  { value: 'Samoa', label: 'Samoa' },
  { value: 'San Marino', label: 'San Marino' },
  { value: 'São Tomé and Príncipe', label: 'São Tomé and Príncipe' },
  { value: 'Saudi Arabia', label: 'Saudi Arabia' },
  { value: 'Senegal', label: 'Senegal' },
  { value: 'Serbia', label: 'Serbia' },
  { value: 'Seychelles', label: 'Seychelles' },
  { value: 'Sierra Leone', label: 'Sierra Leone' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'Slovakia', label: 'Slovakia' },
  { value: 'Slovenia', label: 'Slovenia' },
  { value: 'Solomon Islands', label: 'Solomon Islands' },
  { value: 'Somalia', label: 'Somalia' },
  { value: 'South Africa', label: 'South Africa' },
  { value: 'South Sudan', label: 'South Sudan' },
  { value: 'Spain', label: 'Spain' },
  { value: 'Sri Lanka', label: 'Sri Lanka' },
  { value: 'St. Kitts and Nevis', label: 'St. Kitts and Nevis' },
  { value: 'St. Lucia', label: 'St. Lucia' },
  { value: 'St. Vincent and the Grenadines', label: 'St. Vincent and the Grenadines' },
  { value: 'Sudan', label: 'Sudan' },
  { value: 'Suriname', label: 'Suriname' },
  { value: 'Swaziland', label: 'Swaziland' },
  { value: 'Sweden', label: 'Sweden' },
  { value: 'Switzerland', label: 'Switzerland' },
  { value: 'Syria', label: 'Syria' },
  { value: 'Taiwan', label: 'Taiwan' },
  { value: 'Tajikistan', label: 'Tajikistan' },
  { value: 'Tanzania', label: 'Tanzania' },
  { value: 'Thailand', label: 'Thailand' },
  { value: 'Togo', label: 'Togo' },
  { value: 'Tonga', label: 'Tonga' },
  { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
  { value: 'Tunisia', label: 'Tunisia' },
  { value: 'Turkey', label: 'Turkey' },
  { value: 'Turkmenistan', label: 'Turkmenistan' },
  { value: 'Tuvalu', label: 'Tuvalu' },
  { value: 'Ukraine', label: 'Ukraine' },
  { value: 'United Arab Emirates', label: ' United Arab Emirates' },
  { value: 'United Kingdom', label: 'United Kingdom' },
  { value: 'United States', label: 'United States' },
  { value: 'Uruguay', label: 'Uruguay' },
  { value: 'Uzbekistan', label: 'Uzbekistan' },
  { value: 'Vanuatu', label: 'Vanuatu' },
  { value: 'Vatican City (Holy See)', label: 'Vatican City (Holy See)' },
  { value: 'Venezuela', label: 'Venezuela' },
  { value: 'Vietnam', label: 'Vietnam' },
  { value: 'Yemen', label: 'Yemen' },
  { value: 'Zambia', label: 'Zambia' },
  { value: 'Hong Kong', label: 'Hong Kong' },
  { value: 'Zimbabwe', label: 'Zimbabwe' }];

export const faqTypes = [
  { value: 'INDIVIDUAL', label: 'Individual' },
  { value: 'TEAM', label: 'Team' },
  { value: 'EXPERT', label: 'Expert' }];

export const faqTypesConst = {
  INDIVIDUAL: 'INDIVIDUAL',
  TEAM: 'TEAM',
  EXPERT: 'EXPERT',
};

export const educations = [
  { value: 'High School', label: 'High School' },
  { value: 'Professional Certificate', label: 'Professional Certificate' },
  { value: 'Associates\'s Degree', label: 'Associates Degree' },
  { value: 'Bachelor\'s Degree', label: 'Bachelor\'s Degree' },
  { value: 'Master\'s Degree', label: 'Master\'s Degree' },
  { value: 'Doctoral Degree', label: 'Doctoral Degree' },
  { value: 'Professional Degree', label: 'Professional Degree' }];

export const goalStatus = {
  CREATED: 'created', // new gole created
  NEW_REQUEST: 'newRequest',
  ACCEPT: 'accepted', // orgadmin accepted learning goal
  REJECT: 'rejected', // orgadmin rejected
  RFP_REQUEST: 'rfpRequest', // RFP sent
  RFP_CANCELLED: 'rfpCancelled', // RFP sent
  APPROVE_REQUEST: 'approveRequest',
  REQUEST_SEND_BY_LERNER: 'request', // Request send by learner
  SCHEDULED: 'schedule',
  RESCHEDULED: 'reschedule',
  PROPOSED: 'proposed',
  EXPERT_ACCEPT: 'accepted',
  BID_ACCEPT: 'bidAccepted',
  DELETED: 'deleted',
  EVENT_COMPLETED: 'eventCompleted',
  EVENT_CANCELLED: 'canceledEvent',
  PAYMENT_RELEASED: 'paymentReleased',
};

export const extendEventStatus = {
  ACCEPT: 'accepted',
  REQUEST: 'requested',
  CANCEL: 'canceled',
  PAID: 'paid',
  EXTENDED: 'extended',
};

export const goalStatusTimeLine = {
  CREATED: 'Created', // new gole created
  ACCEPTANCE_PENDING: 'Acceptance Pending',
  ACCEPTED: 'Accepted', // orgadmin accepted learning goal
  REJECTED: 'Rejected', // orgadmin rejected
  RFP_REQUEST: 'Request Sent', // RFP sent
  DELETED: 'Deleted', // RFP sent
  SCHEDULED: 'Scheduled', // Request send by learner
  EVENT_COMPLETED: 'Event Completed', // Request send by learner
};

export const goalStatusLearnerTimeline = [
  goalStatus.DELETED,
  goalStatus.REQUEST_SEND_BY_LERNER,
  goalStatus.ACCEPT,
  goalStatus.RFP_REQUEST,
  goalStatus.SCHEDULED,
];

export const goalStatusRequestLearner = [
  goalStatus.DELETED,
  goalStatus.REQUEST_SEND_BY_LERNER,
  goalStatus.ACCEPT,
  goalStatus.RFP_REQUEST,
  goalStatus.BID_ACCEPT,
  goalStatus.SCHEDULED,
];

export const goalStatusOrgAdminTimeline = [goalStatus.DELETED, goalStatus.RFP_REQUEST, goalStatus.ACCEPT, goalStatus.SCHEDULED];

export const goalStatusExpertTimeLine = [goalStatus.PROPOSED, goalStatus.EXPERT_ACCEPT, goalStatus.SCHEDULED];

export const goalStatusExpertCancelTimeLine = [goalStatus.PROPOSED, goalStatus.EXPERT_ACCEPT, goalStatus.EVENT_CANCELLED];

export const goalStatusEventCancelTimeline = [goalStatus.DELETED, goalStatus.RFP_REQUEST, goalStatus.ACCEPT, goalStatus.EVENT_CANCELLED];

export const status = {
  REQUESTED: 'requested',
  COMPLETED: 'completed',
};
export const bidStatus = {
  RFPREQUEST: 'rfpRequest',
  PROPOSED: 'proposed',
  ACCEPTED: 'accepted',
  SCHEDULED: 'scheduled',
  EVENT_COMPLETED: 'eventCompleted',
  EVENT_STARTED: 'eventStarted',
  SCHEDULE: 'schedule',
  PAYMENT_SUCCESSFUL: 'paymentSuccesfull',
  PAYMENT_RELEASED: 'paymentReleased',
  EVENT_CANCELLED: 'canceledEvent',
};

export const bidStatusText = {
  proposed: 'Proposed',
  accepted: 'Proposal Accepted',
  scheduled: 'Scheduled',
  eventCompleted: 'Event Completed',
  eventTime: 'Event Time',
  expertName: 'Expert Name',
  sessionFee: 'Session Fee',
  participantName: 'Participant Name',
  goalCreated: 'Goal Created',
  acceptancePending: 'Acceptance Pending',
  areYouSure: 'Are you sure?',
  sendRequest: 'Send Request',
};

export const bidStatusTimeline = [
  bidStatus.PROPOSED,
  bidStatus.ACCEPTED,
  bidStatus.SCHEDULED,
];

export const nameTitle = [
  { value: 'mr', label: 'Mr.' },
  { value: 'ms', label: 'Ms.' },
  { value: 'mrs', label: 'Mrs.' },
  { value: 'dr', label: 'Dr.' },
  { value: 'prof', label: 'Prof.' },
];

export const chatFilterOption = [
  { value: 'organization', label: 'Team' },
  { value: 'users', label: 'Groups' },
];

export const ALERT_MESSAGES = {
  MESSAGE_BOARD: {
    SELECT_USER: 'Please select users from the list',
    SELECT_GROUP: 'Please select a group',
    SELECT_ORGANIZATION: 'Please select an team',
    BROADCAST_SUCCESS: 'Message has been sent to ',
    SEND_MESSAGE_FAILED: 'Unable to send message',
  },
};

export const EVENT_PAYMENT_TYPES = {
  SESSION_FEE: 'sessionFee',
  EXTEND_EVENT_FEE: 'extendEventFee',
  UPDATE_PLAN: 'update-plan',
};

export const RESCHEDULE_STATUS = {
  REQUESTED: 'requested',
  REJECTED: 'rejected',
  APPROVED: 'approved',
};

export const GOAL_BID_COUNT_STATUS = {
  GOALS: 'GOALS',
  BIDS: 'BIDS',
};

export const FEEDBACK_TRIGGER = {
  TRIGGER_COUNT: 11,
};

export const TABLE_SORTING = {
  BID: {
    REJECTEDAT: 'bid.rejectedAt',
    REQUESTEDBYROLE: 'requestedBy.role',
  },
};

export const BID_MANAGEMENT_CONST = {
  ERROR_CLASS: ' has-error',
};

export const MAGIC_NUMBER = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  THIRTEEN: 13,
  FIFTEEN: 15,
  TWENTY_FOUR: 24,
  CHAR_LENGTH: 500,
  SIXTY: 60,
  FOUND_INDEX: -1,
  HUNDRED: 100,
  FIFTY: 50,
  TWENTY_THREE: 23,
  THIRTY: 30,
  THIRTY_SIX: 36,
  FOURTY_SEVEN: 47,
  EIGHTY: 80,
  NINETY: 90,
  MINUS_HUNDRED: -100,
  FIVE_HUNDRED: 500,
  THREE_THOUSANT: 3000,
  THOUSANT: 1000,
  THIRTY_ONE: 31,
  THIRTY_TWO: 32,
  FOURZEROFOUR: 404,
  FOURZEROTWO: 402,
  TREE_HUNDRED: 300,
  ONESIXTWO: 162,
  COLOR_CODE: [255, 255, 255, 0.5],
};

export const ACTION_CLASSES = [
  'icon icon-send',
  'icon icon-edit',
  'icon icon-delete',
  'icon icon-accept success',
  'icon icon-cross danger',
  'control__indicator',
  'form-check-input',
  'action-button',
  'icon icon-resend',
  'icon icon-baseline-group-fwd unassigned',
  'icon icon-comment',
  'icon icon-baseline-group-fwd',
];

export const CSS_ClASSES = {
  btnActive: 'btn-active',
  BORDER_COLOR: 'rgba(0,0,0,0)',
  UPPERCASE: 'btn w-33 h-50 tab-btn text-uppercase ',
  btnTab: 'btn tab-btn btn-rg h-50 ',
  btnPrimary: 'btn-primary',
  btnDel: 'btn-delete',
};

export const CONSTANTS = {
  PF_CREDIT_CARD: 'pf-credit-card',
  EXPERT_PURCHASE_ADDON: 'expert-purchase-addon',
  PURCHASE_ADDON: 'purchase-addon',
  PURCHASE_ADD_ON: 'Purchase Add-On',
  SET_PASSWORD: ' set-password',
  TABLE_COL_10: 'table-col-10',
  TABLE_COL_12: 'table-col-12',
  TABLE_COL_13: 'table-col-13',
  TABLE_COL_15: 'table-col-15',
  TABLE_COL_17: 'table-col-17',
  TABLE_COL_20: 'table-col-20',
  TABLE_COL_25: 'table-col-25',
  TABLE_COL_30: 'table-col-30',
  TABLE_COL_13_TEXT: 'table-col-13 text-center',
  TABLE_COL_15_TEXT: 'table-col-15 text-center',
  CREATED_BY: 'Created By',
  EVENT_TYPE: 'Event Type',
  SCHECULE_FOR: 'Schedule For',
  SCHECULE_TIME: 'Schedule Time',
  TRANSACTION_ID: 'Transaction ID',
  SUBSCRIPTION_ID: 'Subscription ID',
  SUBSCRIPTION_FEE: 'Subscription Fee',
  DATE_OF_TRANSACTION: 'Date of Transaction',
  DOMAIN_NAME: 'Domain Name',
  SUB_DOMAIN: 'Sub-Domain',
  SUB_DOMAIN_NAME: 'Sub-Domain Name',
  CATEGORY_NAME: 'Category Name',
  SUB_CATEGORY_NAME: 'Sub-Category Name',
  CUSTOM_PLAN: 'Custom Plan',
};

export const stripeCountries = ['Australia', 'Austria', 'Belgium', 'Brazil', 'Bulgaria', 'Canada', 'Cyprus', 'Czech Republic', 'Denmark' , 'Estonia' , 'Finland'
, 'France', 'Germany', 'Greece', 'Hong Kong', 'Hungary', 'India', 'Ireland', 'Italy', 'Japan', 'Latvia', 'Lithuania', 'Luxembourg', 'Malaysia', 'Malta',
  'Mexico', 'Netherlands', 'New Zealand', 'Norway',
  'Poland', 'Portugal', 'Romania', 'Singapore', 'Slovakia', 'Slovenia', 'Spain',
  'Sweden', 'Switzerland', 'United Kingdom', 'United States'];
