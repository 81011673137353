import { tagServices } from './../../../../services';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { handleError } from './../../../../utils';

export const tagAction = {
  getCategories,
  getSubCategories,
  getDomains,
  getSubDomains,
  getTopics,
  getActivities,
  resetAllTags,
  getExpert,
  createTag,
  removeTag,
  setTagStatus,
  resetTagStatus,
  publishTag,
  editTag,
};

function getCategories(subDomain?: string, selectedTags?: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    tagServices.getCategories(selectedTags)
      .then(
        (category) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(category));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request() { return { type: actionTypes.CATEGORY_LIST_REQUEST }; }
  function success(category: string) { return { type: actionTypes.CATEGORY_LIST_SUCCESS, category }; }
  function failure(error: string) { return { type: actionTypes.CATEGORY_LIST_FAILURE, error }; }
}

function getSubCategories(category?: string, selectedTags?: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    tagServices.getSubCategories(selectedTags)
      .then(
        (subCategory) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(subCategory));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request() { return { type: actionTypes.SUB_CATEGORY_LIST_REQUEST }; }
  function success(subCategory: any) { return { type: actionTypes.SUB_CATEGORY_LIST_SUCCESS, subCategory }; }
  function failure(error: string) { return { type: actionTypes.SUB_CATEGORY_LIST_FAILURE, error }; }
}

function getTopics(subCategory?: string, selectedTags?: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    tagServices.getTopics(selectedTags)
      .then(
        (topic) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(topic));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request() { return { type: actionTypes.TOPIC_LIST_REQUEST }; }
  function success(topic: string) { return { type: actionTypes.TOPIC_LIST_SUCCESS, topic }; }
  function failure(error: string) { return { type: actionTypes.TOPIC_LIST_FAILURE, error }; }
}

function getDomains(requestObj?: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    tagServices.getDomains(requestObj)
      .then(
        (domain) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(domain));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request() { return { type: actionTypes.DOMAIN_LIST_REQUEST }; }
  function success(domain: any) { return { type: actionTypes.DOMAIN_LIST_SUCCESS, domain }; }
  function failure(error: string) { return { type: actionTypes.DOMAIN_LIST_FAILURE, error }; }
}

function getSubDomains(domain?: any, selectedTags?: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    tagServices.getSubDomains(selectedTags)
      .then(
        (subDomain) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(subDomain));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request() { return { type: actionTypes.SUB_DOMAIN_LIST_REQUEST }; }
  function success(subDomain: string) { return { type: actionTypes.SUB_DOMAIN_LIST_SUCCESS, subDomain }; }
  function failure(error: string) { return { type: actionTypes.SUB_DOMAIN_LIST_FAILURE, error }; }
}

function getActivities(topic?: string, selectedTags?: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    tagServices.getActivities(selectedTags)
      .then(
        (activities) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(activities));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request() { return { type: actionTypes.ACTIVITY_LIST_REQUEST }; }
  function success(activities: string) { return { type: actionTypes.ACTIVITY_LIST_SUCCESS, activities }; }
  function failure(error: string) { return { type: actionTypes.ACTIVITY_LIST_FAILURE, error }; }
}

function resetAllTags() {
  return (dispatch: any) => {
    dispatch({ type: actionTypes.RESET_ALL_TAGS });
  };
}

function getExpert(selectedTags: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    tagServices.getExpert(selectedTags)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request() { return { type: actionTypes.EXPERT_COUNT_REQUEST }; }
  function success(data: string) { return { type: actionTypes.EXPERT_COUNT_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.EXPERT_COUNT_FAILURE, error }; }
}

function createTag(tag: object, cb?) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    tagServices.createTag(tag)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
          dispatch(alertActions.successAlert(data.message));
          cb && cb();
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function success(data: object) { return { type: actionTypes.CREATE_TAG_SUCCESS, data }; }
  function failure(error: object) { return { type: actionTypes.CREATE_TAG_FAILURE, error }; }
}

function removeTag(tagObject: object, cb?) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    tagServices.removeTag(tagObject)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.successAlert(data.message));
          dispatch(success(data));
          cb && cb();
          
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };
  function success(data: object) { return { type: actionTypes.REMOVE_TAG_SUCCESS, data }; }
  function failure(error: object) { return { type: actionTypes.REMOVE_TAG_FAILURE, error }; }
}

function setTagStatus() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.TAG_SET_STATUS });
  };
}

function resetTagStatus() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.TAG_RESET_STATUS });
  };
}
function publishTag(tagId: object, cb?) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    tagServices.publishTag(tagId)
      .then(
        (data) => {
          dispatch(alertActions.successAlert(data.message));
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
          cb && cb();
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };
  function success(data: object) { return { type: actionTypes.PUBLISH_TAG_SUCCESS, data }; }
  function failure(error: object) { return { type: actionTypes.PUBLISH_TAG_FAILURE, error }; }
}

function editTag(tag: object, cb?) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    tagServices.editTag(tag)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
          cb && cb();
          dispatch(alertActions.successAlert(data.message));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function success(data: object) { return { type: actionTypes.EDIT_TAG_SUCCESS, data }; }
  function failure(error: object) { return { type: actionTypes.EDIT_TAG_FAILURE, error }; }
}
