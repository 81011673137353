import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    Button,
    FormGroup,
    Label,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from './../../../interfaces';
import ReeValidate from 'ree-validate';
import { reeValidators, MAGIC_NUMBER } from '../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  userDetail: object;
  hideModal(): void;
  onClickDelete(id: string): void;
  onClickSend(data: object): void;
}

interface IState {
  modal: boolean;
  errors?: any;
  formData: {
      feedbackReply: string;
    };
}

class ApplicationFeedbackModalPopUp extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
      super(props);
      this.validator = new ReeValidate({
          feedbackReply: reeValidators.required_min_3_max_500,
        });

      this.state = {
          modal: props.isOpenModal,
          formData: {
              feedbackReply: '',
            },
          errors: this.validator.errors,
        };
    }

  public hideModal = () => {
      this.setState({
          modal: false,
        });
      this.props.hideModal();
    }

  public handleChange = (event: any) => {
      const { name, value } = event.target;
      const { errors } = this.validator;
      this.setState({ formData: { ...this.state.formData, [name]: value } });
      errors.remove(name);
      this.validator.validate(name, value)
            .then(() => {
              this.setState({ errors });
            });
    }

  public validateAndSubmit = (e) => {
      e.preventDefault();
      const { feedbackReply } = this.state.formData;
      const { errors } = this.validator;
      this.validator.validateAll({ feedbackReply })
            .then((success: boolean) => {
              if (success) {
                  this.handleSubmit();
                } else {
                  this.setState({ errors });
                }
            });
    }

  public handleSubmit() {
      const { userDetail } = this.props;
      const obj = {};
      obj['userId'] = userDetail['_id'];
      obj['message'] = this.state.formData.feedbackReply;
      this.props.onClickSend(obj);
    }

  public render() {
      const { modal, formData, errors } = this.state;
      const { userDetail } = this.props;
      return (
            <Modal isOpen={modal} className="feedback-modal">
                <span className="close-btn icon icon-cross" onClick={this.hideModal}/>
                <ModalBody>
                    <h2 className="heading heading-lg roboto-medium mb-0">{`${userDetail['firstName']} ${userDetail['lastName']}`}</h2>
                    <em>{userDetail['email']}</em>
                    {userDetail['phoneNumber'] &&
                        <div className="feedback-data">
                            <span>Phone Number</span>
                            <p>{userDetail['phoneNumber']}</p>
                        </div>
                    }
                    <div className="feedback-data">
                        <span>Message</span>
                        <p>{userDetail['feedback']['message']}</p>
                    </div>
                    {
                        !userDetail['feedback']['isReply'] &&
                        <FormGroup
                            className={`floating-label disabled-input textarea-label answer-textarea mb-4 mt-5 ${
                                errors.has('feedbackReply') ? ' has-error' : ''
                                }`}>
                            <textarea
                                className="form-control textarea-md"
                                name="feedbackReply"
                                id="feedbackReply"
                                value={formData.feedbackReply}
                                onChange={this.handleChange}
                                maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                                placeholder="Write text..."
                            />
                            <span className="text-count">{MAGIC_NUMBER.FIVE_HUNDRED - formData.feedbackReply.length}/500</span>
                            <Label for="reply" className={formData.feedbackReply ? 'selected' : ''}>Feedback Reply</Label>
                            {errors.has('feedbackReply') &&
                                <div className="error-text">{errors.first('feedbackReply').replace('feedbackReply', 'feedback reply')}</div>
                            }
                        </FormGroup>
                    }

                </ModalBody>
                <div className="card-footer d-flex justify-content-end">
                    <Button type="button" className="btn btn-icon delete"
                    onClick={() => this.props.onClickDelete(userDetail['_id'])}>
                        <span className="icon icon-delete"/> Delete
                    </Button>
                    {
                        !userDetail['feedback']['isReply'] &&
                        <Button type="button" className="btn btn-primary ml-auto" onClick={this.validateAndSubmit}>Send</Button>
                    }
                </div>
            </Modal>
        );
    }
}

const connectedApplicationFeedbackModalPopUp = connect()(ApplicationFeedbackModalPopUp);
export { connectedApplicationFeedbackModalPopUp as ApplicationFeedbackModalPopUp };
