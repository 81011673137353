export const actionTypes = {
  SET_SELECTED_USER: 'SET_SELECTED_USER',
  ALL_CONNECTIONS_REQUEST: 'ALL_CONNECTIONS_REQUEST',
  ALL_CONNECTIONS_SUCCESS: 'ALL_CONNECTIONS_SUCCESS',
  ALL_CONNECTIONS_FAILURE: 'ALL_CONNECTIONS_FAILURE',
  ALL_INBOX_USERS_REQUEST: 'ALL_INBOX_USERS_REQUEST',
  ALL_INBOX_USERS_SUCCESS: 'ALL_INBOX_USERS_SUCCESS',
  ALL_INBOX_USERS_FAILURE: 'ALL_INBOX_USERS_FAILURE',
  ALL_MESSAGES_REQUEST: 'ALL_MESSAGES_REQUEST',
  ALL_MESSAGES_SUCCESS: 'ALL_MESSAGES_SUCCESS',
  ALL_MESSAGES_FAILURE: 'ALL_MESSAGES_FAILURE',
  NEW_UNREAD_MESSAGE: 'NEW_UNREAD_MESSAGE',
  CLEAR_CHAT_REDUCERS: 'CLEAR_CHAT_REDUCERS',
  GET_GROUPS_REQUEST: 'GET_GROUPS_REQUEST',
  GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
  GET_GROUPS_FAILURE: 'GET_GROUPS_FAILURE',
  ADD_GROUP_REQUEST: 'ADD_GROUP_REQUEST',
  ADD_GROUP_SUCCESS: 'ADD_GROUP_SUCCESS',
  ADD_GROUP_FAILURE: 'ADD_GROUP_FAILURE',
  REMOVE_GROUP_REQUEST: 'REMOVE_GROUP_REQUEST',
  REMOVE_GROUP_SUCCESS: 'REMOVE_GROUP_SUCCESS',
  REMOVE_GROUP_FAILURE: 'REMOVE_GROUP_FAILURE',
};
