import { actionTypes } from './types';
import { goalStatus } from '../../../../utils/staticConstants';

interface IAction {
  type: string;
  data: {};
  error: string;
}

const initialState = {
  status: {},
};

export function goal(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.GOAL_LIST_SUCCESS:
      return {
        count: action.data['count'],
        list: action.data['data'],
        fetchedListing: true,
      };
    case actionTypes.GOAL_LIST_REQUEST:
      return { fetchingGoalListing: true };
    case actionTypes.GOAL_LIST_FAILURE:
    case actionTypes.GOAL_DETAILS_FAILURE:
      return { error: action.error };
    case actionTypes.CREATE_GOAL_SUCCESS:
      return {
        successForCreateGoal: true,
      };
    case actionTypes.GOAL_DETAILS_SUCCESS:
      return {
        ...state,
        goalDetails: action.data['data'],
        successForGoalDetails: true,
      };
    case actionTypes.GOAL_RESET_STATUS:
      return {
        ...state,
        successForGoalDetails: false,
      };
    case actionTypes.DELETE_GOAL_SUCCESS:
      return {
        ...state,
        successDeleteGoal: true,
      };
    case actionTypes.DELETE_GOAL_FAILURE:
      return {
        ...state,
        successDeleteGoal: false,
      };

    case actionTypes.UPDATE_GOAL_STATUS_SUCCESS:
      let newList: any;
      let count = state['count'];
      if (action.data['status'] === goalStatus.ACCEPT || action.data['status'] === goalStatus.REJECT) {
        if (state['goalDetails'] && state['goalDetails']['_id'] === action.data['goalId']) {
          state['goalDetails']['statusTimeline'] = action.data['statusTimeline'];
          state['goalDetails']['status'] = action.data['status'];
          newList = state['list'];
        } else {
          const findIndex = state['list'].findIndex((val) => val['_id'] === action.data['goalId']);
          state['list'].splice(findIndex, 1);
          newList = state['list'];
          count = state['count'] - 1;
        }
      } else {
        newList = state['list'].map(el => {
          if (el['_id'] === action.data['goalId']) {
            el['status'] = action.data['status'];
            el['statusTimeline'] = action.data['statusTimeline'];
          }
          return el;
        });
      }
      return {
        ...state,
        list: newList,
        count,
      };
    case actionTypes.UPDATE_GOAL_STATUS_FAILURE:
    case actionTypes.UPDATE_GOAL_STATUS_DETAIL_FAIlURE:
      return {
        ...state,
      };
    case actionTypes.UPDATE_GOAL_STATUS_DETAIL_SUCCESS:
      const goalDetails = state['goalDetails'];
      goalDetails['status'] = action.data['status'];
      goalDetails['statusTimeline'] = action.data['statusTimeline'];
      return {
        ...state,
        goalDetails,
      };
    case actionTypes.UPDATE_GOAL_PARTICIPANTS_SUCCESS:
      const gpalParticipantsDetail = state['goalDetails'];
      gpalParticipantsDetail['participantsRaw'] = action.data['participants'];
      gpalParticipantsDetail['isMe'] = action.data['isMe'];
      return {
        ...state,
        goalDetails: gpalParticipantsDetail,
      };
    default:
      return state;
  }
}
