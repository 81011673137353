import React, { Dispatch } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar } from './../../sidebar';
import { connect } from 'react-redux';
import { CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe } from 'react-stripe-elements';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import ReeValidate from 'ree-validate';
import { validator } from '../../../../helpers';
import { alertActions } from '../../../alert/redux/actions';
import { cardAction } from './../redux/actions';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from '../../../../interfaces';
import { LocationDescriptorObject } from 'history';
import { pageRoutes, staticConstants, validatePostalCode, customSelectStyles, MAGIC_NUMBER } from './../../../../utils';
import AddCard from './../../../../assets/images/icons/add-card.svg';
import { Back } from '../../back/back';
import countryList from 'react-select-country-list';
import Select from 'react-select';

validator(ReeValidate);

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  match?: any;
  location?: LocationDescriptorObject;
  user?: any;
  stripe?: any;
}

interface IState {
  isPaymentButtonDisable: boolean;
  cardHolderName: any;
  postalCode: string;
  countryCode: string;
}

class AddCardForm extends React.Component<IProps, IState> {
  public validator: IValidator;

  public cardBrandToPfClass = {
    visa: 'pf-visa',
    mastercard: 'pf-mastercard',
    amex: 'pf-american-express',
    discover: 'pf-discover',
    diners: 'pf-diners',
    jcb: 'pf-jcb',
    unknown: 'pf-credit-card',
  };

  /**
   * @description
   * constructor is used to define the initial state and property
   * @param fields {Object} props
   */
  constructor(props: IProps) {
    super(props);
    this.state = {
      isPaymentButtonDisable: true,
      cardHolderName: '',
      countryCode: '',
      postalCode: '',
    };
    this.handleCardChange = this.handleCardChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * @description
   * componentWillUnmount called before load the component
   */
  public componentWillUnmount() {
    this.props.dispatch(cardAction.resetState());
  }

  /**
   * @description
   * handleSubmit called after validation
   * @param @fields {Object} e
   */
  public async handleSubmit(e: any) {
    e.preventDefault();
    const { dispatch } = this.props;
    const { cardHolderName, countryCode, postalCode } =  this.state;
    if (cardHolderName.trim().length === MAGIC_NUMBER.ZERO || !postalCode || !countryCode) {
      dispatch(alertActions.errorAlert(`error: Some of the card information is missing.`));
      return;
    }
    if (postalCode && countryCode && !validatePostalCode(countryCode, postalCode) || String(postalCode).length < MAGIC_NUMBER.FIVE) {
      this.props.dispatch(alertActions.errorAlert(`error: Invalid zip code.`));
      return;
    }
    const res = await this.props.stripe.createToken({ name: cardHolderName, address_zip: postalCode, country: countryCode, address_country: countryCode });
    if (res.error) {
      const message = res.error.message;
      dispatch(alertActions.errorAlert(`error:${message}`));
    } else {
      const token = res.token;
      const { role } = this.props.user;
      dispatch(cardAction.addCard({ token: token.id, role }));
    }
  }

  /**
   * @description
   * setBrandIcon is used to update brand icon
   * @param @fields {String} brand
   */
  public setBrandIcon(brand: any) {
    const brandIconElement = document.getElementById('brand-icon');
    let pfClass = 'pf-credit-card';
    if (brand in this.cardBrandToPfClass) {
      pfClass = this.cardBrandToPfClass[brand];
    }
    for (let i = brandIconElement.classList.length - 1; i >= 0; i--) {
      brandIconElement.classList.remove(brandIconElement.classList[i]);
    }
    brandIconElement.classList.add('pf');
    brandIconElement.classList.add(pfClass);
  }

  /**
   * @description
   * handleCardChange is used to update card details
   * @param @fields {Object} event
   */
  public handleCardChange(event: { brand: any }) {
    if (event.brand) {
      this.setBrandIcon(event.brand);
    }
  }

  /**
   * @description
   * handleCardHolderChange is used to set the value on state from the only input/textarea boxes.
   * also remove the form validation. Once form validated the forward the control to parenet component
   * @param fields {Object} event
   */
  public handleCardHolderChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    this.setState({ cardHolderName: value });
  }
  public handleCountryChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ countryCode : event['value'] });
  }

  public handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ postalCode: value });
  }

  public render() {
    const { role } = this.props.user;
    const cancelRedirectUrl = role === staticConstants.ROLE.EXPERT
      ? `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.VIEW_PROFILE.PATH}`
      : role === staticConstants.ROLE.PROFESSIONAL
        ? `${pageRoutes.PROFESSIONAL.PATH}${pageRoutes.PROFESSIONAL.VIEW_PROFILE.PATH}#4`
        : `${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.VIEW_PROFILE.PATH}#4`;
    return (
      <>
        <Sidebar />
        <div className="dashboard-wrapper">
          <Back {...this.props} />
          <div className="tab-panel-wrapper add-card-box dashboard-content add-card-section shadow mb-0">
            <h2 className="heading heading-rg roboto-medium collapse-header">Card Information</h2>
            <div className="add-card-wrapper payment-wrapper">
              <Form className="flex-wrap" onSubmit={this.handleSubmit} noValidate>
                <FormGroup className="floating-label">
                  <Label>Card Number</Label>
                  <CardNumberElement
                    className="card-input"
                    placeholder="4242 4242 4242 4242"
                    onChange={this.handleCardChange}
                  />
                  <span className="brand"><i className="pf pf-credit-card" id="brand-icon" /></span>
                </FormGroup>
                <FormGroup className="floating-label">
                  <Label>Card Expiry</Label>
                  <CardExpiryElement className="card-input" />
                </FormGroup>
                <FormGroup className="floating-label">
                  <Label>CVC/CVV</Label>
                  <CardCVCElement className="card-input" placeholder="CVV" />
                </FormGroup>
                <FormGroup
                  className={`floating-label`}
                >
                  <Input
                    className="form-control border-0"
                    id="cardHolderName"
                    name="cardHolderName"
                    placeholder="Card Holder Name"
                    value={this.state.cardHolderName}
                    onChange={this.handleCardHolderChange}
                  />
                  <Label for="firstName">Card Holder Name</Label>

                </FormGroup>

                <FormGroup>
                    <Label>Country</Label>
                    <Select
                        name="Country"
                        styles={customSelectStyles}
                        onChange={this.handleCountryChange}
                        options={countryList().getData()}
                        id="Country"
                        placeholder="Country"
                        className="react-select-box select-box-group"
                      />
                      </FormGroup>

                      <FormGroup>
                        <Label>Postal code</Label>
                        <Input
                          type="text"
                          className="form-control"
                          minLength={MAGIC_NUMBER.THREE}
                          debounceTimeout={MAGIC_NUMBER.THOUSANT}
                          onChange={this.handleZipCodeChange} />
                      </FormGroup>

                <FormGroup className="login-button-group mb-0 d-flex justify-content-end border-0">
                  <Link to={cancelRedirectUrl} className="btn btn-regular mr-3">
                    Cancel
                      </Link>
                  <Button color="primary" className="">
                    Add Card
                      </Button>
                </FormGroup>
              </Form>
              <div className="lock-box">
                <div className="key-circle">
                  <img src={AddCard} alt="key" />
                  <p>Save card for faster payment</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: { card: any; authentication: any }) {
  return {
    user: state.authentication.user,
  };
}

const connectedPaymentCardForm = connect(mapStateToProps)(injectStripe(AddCardForm));
export { connectedPaymentCardForm as AddCardForm };
