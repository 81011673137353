import React from 'react';
import logoImage from './../../assets/images/logo.svg';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
} from 'reactstrap';

interface IProps {
  image?: any;
  title: string;
  subTitle?: string;
  banner?: string;
}
interface IState {
  isOpen: any;
}

class LandingHeader extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }
  public toggleMenu() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  public render() {
    const { title } = this.props;
    return (
      <div className={this.props.banner ? this.props.banner : ''} >
        <div className="my_nav">
          <div className="container">
            <nav className="navbar navbar-expand-lg">
              <Link to="/" className="navbar-brand" >
                <img src={logoImage} alt="logo" />
              </Link>
              <NavbarToggler onClick={this.toggleMenu} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <Link to="/pricing" className="nav-link" >Pricing</Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/register/expert" className="nav-link" >Share Your Expertise</Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/login" className="nav-link" >Log In</Link>
                  </NavItem>
                  <NavItem>
                    <Link to="/usertype" className="nav-link" >Sign Up</Link>
                  </NavItem>
                </Nav>
              </Collapse>
            </nav>
          </div>
        </div>
        <div className="container">
          <div className="inner_page_banner">
            <h2>{title}</h2>
            {this.props.subTitle ? <p>{this.props.subTitle}</p> : ''}
          </div>
        </div>
      </div>
    );
  }
}

export { LandingHeader };
