import React, { Component } from "react";
import { connect } from "react-redux";
import { staticConstants, pageRoutes } from "./../utils";
import { history } from "./../helpers";

export default function(ComposedComponent) {
  class CanAccessRoute extends Component {
    componentDidMount() {
    }
    componentWillUpdate() {
      const { match, user } = this.props;
      if (user) {
        if (!user.isPaid && [staticConstants.ROLE.ORGANIZATION_ADMIN, staticConstants.ROLE.PROFESSIONAL].indexOf(user.role) > -1) {
          if ([pageRoutes.PAYMENT.PATH].indexOf(match.path) <= -1) {
            history.push(`${pageRoutes.PAYMENT.PATH}${pageRoutes.PAYMENT.SUBSCRIPTION}`);
          }
        }
      }
    }
    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    const { user } = state.authentication;
    return { user };
  }
  return connect(mapStateToProps)(CanAccessRoute);
}
