import { actionTypes } from './types';

interface IAction {
  paymentData: {};
  data: Array<[]>;
  type: string;
  error: string;
}
export function systemAdminPayment(state = {}, action: IAction) {
  switch (action.type) {
    case actionTypes.TOTAL_PAYMENTS_REQUEST:
      return {
        totalPaymentRequest: true,
        updateDisputeStatus: false,
      };
    case actionTypes.TOTAL_PAYMENTS_SUCCESS:
      return {
        ...state,
        paymentData: action.data,
      };
    case actionTypes.PAYMENTS_LIST_REQUEST:
      return {
        ...state,
        updateDisputeStatus: false,
      };
    case actionTypes.PAYMENTS_LIST_SUCCESS:
      return {
        ...state,
        count: action.data && action.data['count'],
        list: action.data && action.data['data'],
      };
    case actionTypes.UPDATE_DISPUTE_SUCCESS:
      return {
        ...state,
        updateDisputeStatus: true,
      };
    case actionTypes.GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        downloadUrl: action.data['url'],
        downloadFileName: action.data['reportTitle'],
        successDownloadReport: true,
        updateDisputeStatus: false,
      };
    case actionTypes.RESET_GENERATE_REPORT:
      return {
        ...state,
        downloadUrl: undefined,
        successDownloadReport: false,
      };
    default:
      return state;
  }
}
