import React, { Dispatch, Component } from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { groupBy } from 'lodash';
import { Sidebar } from '../../shared/sidebar';
import { connect } from 'react-redux';
import Select from 'react-select';
import { customSelectStyles } from '../../../utils/common';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import profile from './../../../assets/images/profile.png';
import { dashboardActions } from './redux/actions';
import { AreaChart } from '../../shared/charts/areaChart/areaChart';
import { BarChart } from '../../shared/charts/barChart/barChart';
import { pageRoutes, staticConstants, formatCurrency, MAGIC_NUMBER } from '../../../utils';
import moment from 'moment';
import { chatAction } from '../../shared/chat/redux/actions';
import { history } from '../../../helpers';
import StarRatings from 'react-star-ratings';
import { bidAction } from '../../expert/bidManagement/redux/actions';

interface IProps {
  dashboardData: Object;
  upcomingEvents: Array<[]>;
  reviews: Array<[]>;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  bids: any;
}

interface IState {
  categoryOptionsTypes: object[];
  currentDate: Date;
  year: number;
  month: number;
  date: number;
  bidsChartData: object;
  totalData: object;
  latestUnreadMessages: Array<[]>;
  learningEvents: object;
  bidsDateSelected: string;
  eventsDateSelected: string;
  totalInvestmentChartData: object;
  investmentDateSelected: string;
}

class DashBoardPage extends Component<IProps, IState> {

  public static getDerivedStateFromProps(props: IProps, state: IState) {
    const newState = {};
    if (props.dashboardData) {
      if (props.dashboardData['learningGoals']) {
        newState['bidsChartData'] = props.dashboardData['learningGoals'];
      }
      if (props.dashboardData['totalData']) {
        newState['totalData'] = props.dashboardData['totalData'];
      }
      if (props.dashboardData['totalInvestment']) {
        newState['totalInvestmentChartData'] = props.dashboardData['totalInvestment'];
      }
      if (props.dashboardData['learningEvents']) {
        newState['learningEvents'] = props.dashboardData['learningEvents'];
      }
    }
    return newState;
  }
  constructor(props: IProps) {
    super(props);
    this.state = {
      categoryOptionsTypes: [
        { value: 'thisMonth', label: 'Current Month' },
        { value: 'lastMonth', label: 'Last Month' },
        { value: 'last6Months', label: 'Last 6 Months' },
        { value: 'lastYear', label: 'Last Year' },
      ],
      currentDate: new Date(),
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      date: new Date().getDate() + 1,
      bidsChartData: {},
      totalData: {},
      learningEvents: {},
      latestUnreadMessages: [],
      bidsDateSelected: 'thisMonth',
      eventsDateSelected: 'thisMonth',
      investmentDateSelected: 'thisMonth',
      totalInvestmentChartData: {}
    };
  }

  public componentDidMount = () => {
    this.getLatestUnreadMessages();
    this.getLatestBids();

    const { dispatch } = this.props;
    const fromDate = new Date(moment(new Date()).subtract(0, 'months').startOf('month').format(staticConstants.DASHBOARD_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(0, 'months').endOf('month').format(staticConstants.DASHBOARD_FORMAT,
      ));
    const obj = [{
      label: 'totalData',
      startDate: fromDate.toLocaleDateString('en-US'),
      endDate: toDate.toLocaleDateString('en-US'),
    },
      {
        label: 'learningGoals',
        startDate: fromDate.toLocaleDateString('en-US'),
        endDate: toDate.toLocaleDateString('en-US'),
      },
      {
        label: 'learningEvents',
        startDate: fromDate.toLocaleDateString('en-US'),
        endDate: toDate.toLocaleDateString('en-US'),
      },
      {
        label: 'totalInvestment',
        startDate: fromDate.toLocaleDateString('en-US'),
        endDate: toDate.toLocaleDateString('en-US'),
      }];
    this.getData(obj, dispatch);

    dispatch(dashboardActions.getUpcomingEvents());
    dispatch(dashboardActions.getReviews());
  }

  /**
   * @description Get recent 3 unread chat with message count
   */
  public getLatestUnreadMessages = () => {
    this.props.dispatch(chatAction.getTotalUnreadCount((response: Array<[]>) => {
      const sortedResponse = [...response].sort((a, b) => b['timeStamp'] - a['timeStamp']);
      const data = [];
      Object.values(groupBy(sortedResponse, 'roomId')).forEach(x => data.push(x));
      data.length = MAGIC_NUMBER.THREE;
      this.setState({ latestUnreadMessages: data });
    }));
  }
  /**
   * @description Get recent 3 latest bids
   */
  public getLatestBids = () => {
    this.props.dispatch(bidAction.getBids({
      page: MAGIC_NUMBER.ONE,
      limit: MAGIC_NUMBER.THREE,
      rfpType: 'newRequest',
    }));
  }

  public thisMonthDate = (dispatch, key) => {
    const fromDate = new Date(moment(new Date()).subtract(0, 'months').startOf('month').format(staticConstants.DATE_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(0, 'months').endOf('month').format(staticConstants.DATE_FORMAT,
      ));
    this.dataObjToChange(key, fromDate, toDate, dispatch);
  }

  public lastYearDate = (dispatch, key) => {
    const fromDate = new Date(moment(new Date()).subtract(1, 'years').startOf('year').format(staticConstants.DATE_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(1, 'years').endOf('year').format(staticConstants.DATE_FORMAT,
      ));
    this.dataObjToChange(key, fromDate, toDate, dispatch);
  }

  public lastMonthDate = (dispatch, key) => {
    const fromDate = new Date(moment(new Date()).subtract(1, 'months').startOf('month').format(staticConstants.DATE_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(1, 'months').endOf('month').format(staticConstants.DATE_FORMAT,
      ));
    this.dataObjToChange(key, fromDate, toDate, dispatch);
  }

  public lastSixMonthsDate = (dispatch, key) => {
    const fromDate = new Date(moment(new Date()).subtract(MAGIC_NUMBER.SIX, 'months').startOf('month').format(staticConstants.DATE_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(1, 'months').endOf('month').format(staticConstants.DATE_FORMAT,
      ));
    this.dataObjToChange(key, fromDate, toDate, dispatch);
  }

  public dataObjToChange = (key, dateStart, currentDate, dispatch) => {
    const dataToChange = [{
      label: key,
      startDate: dateStart.toLocaleDateString('en-US'),
      endDate: currentDate.toLocaleDateString('en-US'),
    }];
    this.getData(dataToChange, dispatch, key);
  }

  public getData = (obj, dispatch, key?: string) => {
    const reqObj = [];
    obj.map((el) => {
      reqObj.push({
        type: el.label,
        startDate: el.startDate,
        endDate: el.endDate,
      });
      dispatch(dashboardActions.getDashboardData({
        input: reqObj.filter(label => label.type === el.label),
      },                                         key));
      return reqObj;
    });
  }

  public handleChange = (e: any, key: string) => {
    if (key === 'learningGoals') {
      this.setState({ bidsDateSelected: e.value });
    } else if (key === 'learningEvents') {
      this.setState({ eventsDateSelected: e.value });
    } else if (key === 'totalInvestment') {
      this.setState({ investmentDateSelected: e.value});
    }
    const { dispatch } = this.props;
    if (e.value === 'last6Months') {
      this.lastSixMonthsDate(dispatch, key);
    } else if (e.value === 'lastMonth') {
      this.lastMonthDate(dispatch, key);
    } else if (e.value === 'lastYear') {
      this.lastYearDate(dispatch, key);
    } else {
      this.thisMonthDate(dispatch, key);
    }
  }

  public goToBidMgmt = () => {
    history.push({
      pathname: `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}`,
      state: { activeTab : staticConstants.BID_MANAGEMENT_TAB.SENT_BIDS },
    });
  }

  public goToEventMgmt = () => {
    history.push(pageRoutes.EVENTMANAGEMENT.PATH);
  }

  public getLatestBidLists = (latestBids) => {
    return latestBids.map((bid: any) => {
      const { title, _id } = bid;
      const dateAvailability = moment(bid.createdAt).format(staticConstants.DATE_FORMAT);
      const eventTime = moment(bid.createdAt).format(staticConstants.TIME_FORMAT);
      return (
        <div key={_id} className="event-table-row">
          <div className="event-table-col table-col-4">
            <span>Title</span>
            <p className="text-truncate">{title}</p>
          </div>
          <div className="event-table-col table-col-2">
            <span>Date</span>
            <p className="text-truncate">{dateAvailability}</p>
          </div>
          <div className="event-table-col table-col-2">
            <span>Time</span>
            <p className="text-truncate">{eventTime}</p>
          </div>
          <div className="event-table-col table-col-2 text-right pt-2">
            <Link
              to={{
                pathname: `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.DETAIL}/${_id}`,
                state: { activeTab: staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST },
              }}
            >
              <Button className="btn btn-primary">Bid Now</Button>
            </Link>
          </div>
        </div>
      );
    });
  }

  public getUpcommingEvents = (upcomingEvents: any) => {
    return upcomingEvents.map((event: any) => {
      const { title, _id, bid } = event;
      const dateAvailability = moment(bid[0].proposedDate).format(staticConstants.DATE_FORMAT);
      const eventTime = moment(bid[0].proposedTime).format(staticConstants.TIME_FORMAT);
      return (
        <div key={_id} className="event-table-row">
          <div className="event-table-col table-col-4">
            <span>Title</span>
            <p className="text-truncate">{title}</p>
          </div>
          <div className="event-table-col table-col-2">
            <span>Date</span>
            <p className="text-truncate">{dateAvailability}</p>
          </div>
          <div className="event-table-col table-col-2">
            <span>Time</span>
            <p className="text-truncate">{eventTime}</p>
          </div>
          <div className="event-table-col table-col-2 text-right pt-2">
            <Link
              to={{
                pathname: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}/${bid[0]._id}`,
                state: { activeTab: staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS },
              }}
            >
              <Button className="btn btn-primary">View</Button>
            </Link>
          </div>
        </div>
      );
    });
  }

  public render() {
    const { latestUnreadMessages,
      categoryOptionsTypes,
      totalInvestmentChartData,
      investmentDateSelected,
      bidsChartData,
      totalData,
      learningEvents,
      bidsDateSelected,
      eventsDateSelected } = this.state;
    const { upcomingEvents, bids, reviews } = this.props;
    const latestBids = bids.list;
    let result = '';
    if (learningEvents['totalDuration'] !== undefined) {
      const duration = learningEvents['totalDuration'];
      const hours = Math.trunc(duration);
      const mins = Math.trunc((duration - Math.floor(duration)) * MAGIC_NUMBER.SIXTY);
      result = `${hours} hrs ${mins} mins`;
    }
    const totalInvestments = totalInvestmentChartData['totalIncome'];
    return (
      <>
        <Sidebar  {...this.props} />
        <div className="dashboard-wrapper">
          <div className="dashboard-header">
            <h2 className="heading heading-lg roboto-medium text-uppercase mb-0">DashBoard</h2>
            <Select
              placeholder="Current Month"
              styles={customSelectStyles}
              onChange={(e) => this.handleChange(e, 'totalData')}
              options={categoryOptionsTypes}
              className="react-select-box"
            />
          </div>
          <div className="dashboard-cards mt-4">
            <div className="card card-col-3 pointer" onClick={this.goToBidMgmt}>
              <div className="card-data">
                <h2>{totalData && totalData['totalBids']}</h2>
                <p>Total Bids</p>
              </div>
              <span className="icon icon-bid"/>
            </div>
            <div className="card card-col-3 pointer">
              <div className="card-data" onClick={this.goToEventMgmt}>
                <h2>{totalData && totalData['totalEventGoal']}</h2>
                <p>Total Events</p>
              </div>
              <span className="icon icon-event"/>
            </div>
            <div className="card card-col-3">
              <div className="card-data">
                <h2>${totalData && formatCurrency(totalData['totalIncome'])}</h2>
                <p>Total Income</p>
              </div>
              <span className="icon icon-bill"/>
            </div>
          </div>
          <div className="graph-wrapper">
            <div className="dashboard-graph-header">
              <h2 className="heading heading-sm roboto-bold text-uppercase">Bids</h2>
              <Link to={`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}`}>

                Bid Management
              </Link>
            </div>
            <div>
              <Select
                placeholder="Current Month"
                styles={customSelectStyles}
                onChange={(e) => this.handleChange(e, 'learningGoals')}
                options={categoryOptionsTypes}
                className="react-select-box"
              />
              <AreaChart chartData={bidsChartData} type="bids" dateSelected={bidsDateSelected} />
            </div>
          </div>
          <div className="graph-wrapper">
            <div className="dashboard-graph-header">
              <h2 className="heading heading-sm roboto-bold text-uppercase">Events</h2>
              <Link to={pageRoutes.EVENTMANAGEMENT.PATH}>Events</Link>
            </div>
            <div>
            <div className="graph-filter-row">
              <p>Total Event Hours : <span>{result}</span></p>
            <Select
              placeholder="Current Month"
              styles={customSelectStyles}
              onChange={e => this.handleChange(e, 'learningEvents')}
              options={categoryOptionsTypes}
              className="react-select-box"
            />
            </div>
              <BarChart chartData={learningEvents} type="experts" dateSelected={eventsDateSelected} role={staticConstants.ROLE.EXPERT} />
            </div>
          </div>
          <div className="graph-wrapper">
            <div className="dashboard-graph-header">
              <h2 className="heading heading-sm roboto-bold text-uppercase">Total Income: ${totalInvestments ? formatCurrency(totalInvestments): 0.00}</h2>
              <Link to={`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.PAYMENT.PATH}`}>
                Income Management
              </Link>
            </div>
            <div>
              <Select
                placeholder="Current Month"
                styles={customSelectStyles}
                onChange={(e) => this.handleChange(e, 'totalInvestment')}
                options={categoryOptionsTypes}
                className="react-select-box"
              />
              <AreaChart chartData={totalInvestmentChartData} type="totalInvestment" dateSelected={investmentDateSelected}/>
            </div>
          </div>
          <div className="graph-box-sm">
            <div className="graph-wrapper graph-col-2">
              <div className="dashboard-graph-header">
                <h2 className="heading heading-sm roboto-bold text-uppercase">Latest Requests</h2>
                <Link to={`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}`}>View All</Link>
              </div>
              {latestBids ? this.getLatestBidLists(latestBids) : <p className="empty-data">No results found</p> }
            </div>
            <div className="graph-wrapper graph-col-2">
              <div className="dashboard-graph-header">
                <h2 className="heading heading-sm roboto-bold text-uppercase">Upcoming Events</h2>
                <Link to={pageRoutes.EVENTMANAGEMENT.PATH}>View All</Link>
              </div>
              {upcomingEvents[0] === undefined ? <p className="empty-data">No results found</p>
                : this.getUpcommingEvents(upcomingEvents)
              }
            </div>
          </div>
          <div className="graph-box-sm">
          <div className="graph-wrapper graph-col-2">
              <div className="dashboard-graph-header">
                <h2 className="heading heading-sm roboto-bold text-uppercase">Messages</h2>
                <Link to={pageRoutes.MESSAGE_BOARD.PATH}>View All</Link>
              </div>
              <div className="w-100">
                {latestUnreadMessages[0] === undefined ? <p className="empty-data">No unread messages.</p> :
                latestUnreadMessages.map((msg: any) => {
                  const { profileImage, content, roomId, name = '' } = msg[0];
                  return (
                    <div key={roomId} className="chat-row">
                      <span className="chat-user-icon">
                        <img src={profileImage || profile} alt="user" className="img-cover" />
                      </span>
                      <div className="chat-data">
                        <h3>{name}</h3>
                        <p className="text-truncate">{content}</p>
                      </div>
                      <span className="chat-count">{msg.length}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="graph-wrapper graph-col-2">
              <div className="dashboard-graph-header">
                <h2 className="heading heading-sm roboto-bold text-uppercase">Reviews</h2>
              </div>
              <div className="w-100">
                { reviews && reviews[0] === undefined ? <p className="empty-data">No results found</p> :
                  reviews && reviews.map((review: any, index: any) => {
                    return (
                    <div key={index} className="review-row">
                      <span className="review-user-icon">
                        <img src={review.userData['profileImage'] || profile} alt="user" className="img-cover" />
                      </span>
                      <div className="review-data">
                        <h3>{`${review.userData['firstName']} ${review.userData['lastName']}`}
                        <span>{moment(review.adminRatingGoal['createdAt']).format(staticConstants.TIME_FORMAT)}</span></h3>
                          <StarRatings
                            starHoverColor="#4be59e"
                            starEmptyColor="#dcdcdc"
                            starRatedColor="#4be59e"
                            starDimension="14px"
                            starSpacing="1px"
                            numberOfStars={MAGIC_NUMBER.FIVE}
                            rating={review.adminRatingGoal['expertRating']}
                            name="sessionRating"
                            />
                        <p className="text-truncate">{review.adminRatingGoal['comment']}</p>
                        <Link to={{
                          pathname: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}/${review._id}`,
                          state: { activeTab: 'PAST EVENTS' }}}>
                          <span className="icon icon-arrow-down-sign-to-navigate" />
                        </Link>
                      </div>
                    </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: { dashboard: { dashboardData: any; upcomingEvents: Array<[]>; reviews: Array<[]> }; bids }) {
  const { dashboardData, upcomingEvents, reviews } = state.dashboard;
  return {
    dashboardData,
    upcomingEvents,
    reviews,
    bids: state.bids,
  };
}
const connectedDashboardData = connect(mapStateToProps)(DashBoardPage);
export { connectedDashboardData as DashBoardPage };
