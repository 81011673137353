import { history } from '../../../../helpers';
import { ICreatePasswordReq, IUpdateProfileReq, IUploadImgaeReq, IChangePasswordReq } from '../../../../interfaces';
import { messages } from '../../../../utils/messages';
import { userServices } from './../../../../services/userServices';
import { paymentService } from './../../../../services/paymentServices';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { handleError, getNavToDashboard } from './../../../../utils';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';

export const profileAction = {
  getProfile,
  uploadProfileImage,
  getIndustries,
  getCategories,
  updateProfile,
  updatePaymentAccountID,
  getPaymentLoginLink,
  resetPaymentLoginLink,
  getSubscriptionHistory,
  cancelSubscription,
  changePassword,
  getCountForFeedBack,
  getPaymentsByType
};

function getProfile(userId: string = null) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    if (userId) {
      userServices.getShortProfile(userId)
        .then(
          (data) => {
            dispatch(endLoading('MAIN_LOADER'));
            dispatch(success(data));
          },
          (error) => {
            handleError(dispatch, failure, error.toString());
          },
        );
    } else {
      userServices.getProfile()
        .then(
          (data) => {
            dispatch(endLoading('MAIN_LOADER'));
            dispatch(success(data));
          },
          (error) => {
            handleError(dispatch, failure, error.toString());
          },
        );
    }
  };

  function request(data: ICreatePasswordReq) { return { type: actionTypes.GET_PROFILE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.GET_PROFILE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.GET_PROFILE_FAILURE, error }; }
}
function getPaymentLoginLink() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.getPaymentLoginLink()
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data.url));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request(data: any) { return { type: actionTypes.GET_PAYMENT_LOGIN_LINK_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.GET_PAYMENT_LOGIN_LINK_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.GET_PAYMENT_LOGIN_LINK_FAILURE, error }; }
}

function uploadProfileImage(reqData: IUploadImgaeReq) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updateProfileImage(reqData)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request(data: any) { return { type: actionTypes.UPDATE_PROFILE_IMAGE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_PROFILE_IMAGE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_PROFILE_IMAGE_FAILURE, error }; }
}

function getIndustries() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));

    userServices.getIndustries()
      .then(
        (res) => {
          dispatch(success(res));
        },
        (error) => {
          dispatch(alertActions.errorAlert(error.toString()));
          dispatch(failure(error.toString()));
        },
      );
  };

  function request(data: any) { return { type: actionTypes.FETCH_INDUSTRY_SUGGESTIONS_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.FETCH_INDUSTRY_SUGGESTIONS_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.FETCH_INDUSTRY_SUGGESTIONS_FAILURE, error }; }
}

function getCategories() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));

    userServices.getCategories()
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(alertActions.errorAlert(error.toString()));
          dispatch(failure(error.toString()));
        },
      );
  };

  function request(data: ICreatePasswordReq) { return { type: actionTypes.FETCH_SECTOR_SUGGESTIONS_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.FETCH_SECTOR_SUGGESTIONS_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.FETCH_SECTOR_SUGGESTIONS_FAILURE, error }; }
}

function updateProfile(reqData: IUpdateProfileReq, redirectUri: string = null) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updateProfile(reqData)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
          const url = redirectUri ? redirectUri : getNavToDashboard();
          history.push(url);
          dispatch(alertActions.successAlert(messages.profileUpdated));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request(data: IUpdateProfileReq) { return { type: actionTypes.UPDATE_PROFILE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_PROFILE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_PROFILE_FAILURE, error }; }
}

function updatePaymentAccountID(reqData: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updatePaymentAccountID(reqData)
      .then(
        (res) => {
          dispatch(alertActions.successAlert(messages.stripeConnectSuccess));
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request(data: IUpdateProfileReq) { return { type: actionTypes.UPDATE_PAYMENT_ACCOUNT_ID_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_PAYMENT_ACCOUNT_ID_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_PAYMENT_ACCOUNT_ID_FAILURE, error }; }
}

function resetPaymentLoginLink() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.RESET_PAYMENT_LOGIN_LINK });
  };
}

function getSubscriptionHistory(data: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.getSubscriptionHistory(data)
      .then(
        (res) => {
          dispatch(success(res));
          dispatch(endLoading('MAIN_LOADER'));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
          dispatch(endLoading('MAIN_LOADER'));
        },
      );
  };

  function success(datas: any) { return { type: actionTypes.GET_SUBSCRIPTION_HISTORY_SUCCESS, data: datas }; }
  function failure(error: string) { return { type: actionTypes.GET_SUBSCRIPTION_HISTORY_FAILURE, error }; }
}

function cancelSubscription(userId) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.cancelSubscription()
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
          dispatch(getProfile(userId));
          dispatch(getSubscriptionHistory({ userId }));
          dispatch(alertActions.successAlert(res.messages));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function success(data: any) { return { type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.CANCEL_SUBSCRIPTION_FAILURE, error }; }
}

function changePassword(data: IChangePasswordReq) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(data));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.changePasswordRequest(data)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
          dispatch(alertActions.successAlert(res.message));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request(datas: any) { return { type: actionTypes.CHANGE_PASSWORD_REQUEST, data: datas }; }
  function success(sucdata: any) { return { type: actionTypes.CHANGE_PASSWORD_SUCCESS, data: sucdata }; }
  function failure(error: string) { return { type: actionTypes.CHANGE_PASSWORD_FAILURE, error }; }
}

function getCountForFeedBack(reqData: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.getTotalCount(reqData)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
          dispatch(getProfile(reqData.userId));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function success(data: any) { return { type: actionTypes.SHOW_FEEDBACK_FORM, data }; }
}

function getPaymentsByType(reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(reqObj));
    paymentService.getPayments(reqObj)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res, reqObj));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request(data: string) { return { type: actionTypes.PAYMENTS_LIST_REQUEST, data }; }
  function success(data: any, userData: Array<[]>) { return { type: actionTypes.PAYMENTS_LIST_SUCCESS, data, userData }; }
  function failure(error: any) { return { type: actionTypes.PAYMENTS_LIST_FAILURE, error }; }
}
