import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Input,
  Jumbotron,
  Label,
} from 'reactstrap';
import { LocationDescriptorObject } from 'history';
import { history } from '../../../helpers';
import cancelCard from './../../../assets/images/icons/card-cancel.svg';
import cardImage from './../../../assets/images/icons/card.svg';
import verifyCard from './../../../assets/images/icons/verify-card.svg';
import { profileAction } from './../../shared/updateProfile/redux/actions';
import { subscriptionPlanAction } from './../../shared/subscriptionPlan/redux/actions';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../sidebar';
import { BreadcrumbRender } from '../breadcrumbRender/BreadcrumbRender';
import { IValidator, ILocalUser } from './../../../interfaces';
import { staticConstants, getLocalUserData, MAGIC_NUMBER, CONSTANTS } from './../../../utils';
import ReeValidate from 'ree-validate';

interface IProps {
  dispatch: OpenFieldTypes.RootAction;
  location?: LocationDescriptorObject;
  user: ILocalUser;
  licence?: number;
  isAddOn?: boolean;
  subscriptionProduct: { addOnAmount: number; licenceAmount: number; learnerAmount: number };
}

interface IState {
  licence: any;
  isAddOn: boolean;
  errors?: any;
}

class SubscriptionPlan extends React.Component<IProps, IState>  {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      licence: (this.props && this.props['location'] &&  this.props['location']['state'] &&
      this.props['location']['state']['action']) &&
      this.props['location']['state']['action'] === CONSTANTS.EXPERT_PURCHASE_ADDON ?  '' : 'required|min_value:0|max_value:14000',
    });
    this.state = {
      licence: 0,
      isAddOn: false,
      errors: this.validator.errors,
    };
    this.moveToPaymentPage = this.moveToPaymentPage.bind(this);
  }
  public componentDidMount() {
    const localData: ILocalUser = getLocalUserData();
    this.props.dispatch(subscriptionPlanAction.getSubscriptionProduct(localData.subscribedProductId ?
      { productId: localData.subscribedProductId } : null));
    this.props.dispatch(profileAction.getProfile());
    if (localData.role === staticConstants.ROLE.EXPERT) {
      this.validator = new ReeValidate({});
    }

  }

  public componentWillReceiveProps(props: IProps, nextProps: any) {
    const newState = { licence: props.licence, isAddOn: props.isAddOn };
    const { location: { state }, user } = this.props;
    if (state && (state['action'] === CONSTANTS.PURCHASE_ADDON || state['action'] === CONSTANTS.EXPERT_PURCHASE_ADDON)) {
      newState['isAddOn'] = true;
    }
    if (user && user.role === staticConstants.ROLE.PROFESSIONAL) {
      newState['licence'] = 1;
    }
    if (user && user.role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
      newState['licence'] = props.licence > MAGIC_NUMBER.ZERO ? props.licence - 1 : MAGIC_NUMBER.ZERO;
    }
    this.setState(newState);
  }

  public handleChange = (event: any) => {
    const { name, value } = event.target;
    const { errors } = this.validator;
    this.setState({ [name]: value } as any);
    errors.remove(name);
    this.setState({ ...this.state, [name]: value });
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit = (e: any) => {
    e.preventDefault();

    const { licence } = this.state;
    const { errors } = this.validator;

    this.validator.validateAll({ licence })
      .then((success: any) => {
        if (success) {
          this.moveToPaymentPage();
        } else {
          this.setState({ errors });
        }
      });
  }
  public moveToPaymentPage() {
    const { location: { state, hash = null }, subscriptionProduct, user: { role } } = this.props;
    const { licence, isAddOn } = this.state;
    let stateVars = { licence, isAddOn, subscriptionProduct };
    if (state && state['originStateVars']) {
      delete state['originStateVars']['isAddOn'];
    }
    if (state && state['redirectUri']) {
      stateVars['redirectUri'] = state['redirectUri'];
    }
    if (state && state['action']) {
      stateVars['action'] = state['action'];
      stateVars['activeTab'] = (state['action'] === CONSTANTS.EXPERT_PURCHASE_ADDON) && state['activeTab'];
    }
    if (state && state['originStateVars']) {
      stateVars = { ...state['originStateVars'], ...stateVars };
    }
    if (role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
      stateVars['licence'] = Number(stateVars['licence']) + 1;
    }
    if (role === staticConstants.ROLE.EXPERT) {
      stateVars['isAddOn'] = true;
    }
    history.push({
      pathname: `/payment/checkout/`,
      state: stateVars,
      hash,
    });
  }

  public cancelAddOnPurchase = () => {
    const { location: { state } } = this.props;
    // TODO:: state var error while moving to profile(originUri) page use API call instead for goal title
    if (state && state['originUri']) {
      history.goBack();
    }
  }

  public render() {
    const { licence } = this.state;
    const { location: { state }, user: { role, isPaid }, subscriptionProduct } = this.props;
    const getPaymentBtnLabel = () => {
      if (!state) { return 'Subscribe'; }
      switch (state['action']) {
        case CONSTANTS.PURCHASE_ADDON:
          return 'Purchase';
        case 'update-plan': return 'Update Plan';
        default: return 'Subscribe';
      }
    };
    return (
      <div className={(state && state['showSidebarNav']) ? 'dashboard-wrapper pt-0 addon-purchase' : 'w-100'}>
        {state && state['showSidebarNav'] && <Sidebar {...this.props} />}
        <div className="subscriptionPlan-wrapper">
          {state && state['breadcrumb'] &&
            <div className="mb-4"><BreadcrumbRender breadcrumb={state['breadcrumb']}/></div>}
          {state && (state['action'] === CONSTANTS.EXPERT_PURCHASE_ADDON || role === staticConstants.ROLE.EXPERT)
            ?
            <div className="plan-wrapper dashboard-content mb-0">
              <div className="crad-wrapper pb-0">
                <div className="price-card d-flex flex-wrap">
                  <Card>
                    <CardBody>
                      <div className="card-data">
                        <CardTitle>Monthly</CardTitle>
                        <CardSubtitle><em>$</em>{subscriptionProduct && subscriptionProduct.addOnAmount}</CardSubtitle>
                        <CardText>Add Ons</CardText>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="price-text">
                    <Jumbotron>
                      <p>Overlap limits access to user profile information to reduce selection bias. This add-on allows full visibility of user profiles.
                      </p>
                    </Jumbotron>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex">
                {state &&
                  <Button className="btn btn-icon danger" onClick={this.cancelAddOnPurchase}>
                    <span className="icon icon-cross" />
                    Cancel
                  </Button>
                }
                <Button
                  color="primary"
                  className="btn btn-rg ml-auto"
                  onClick={this.validateAndSubmit}
                >
                  {getPaymentBtnLabel()}
                </Button>
              </div>
            </div>
            :
            <div className="plan-wrapper">
              <div className="crad-wrapper pb-0">
                <div className="price-card d-flex flex-wrap">
                  <div className="price-card-box">
                    {role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                      <Card>
                        <CardBody>
                          <div className="card-data">
                            <CardTitle>Monthly</CardTitle>
                            <CardSubtitle><em>$</em>{subscriptionProduct && subscriptionProduct.licenceAmount}</CardSubtitle>
                            {role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                              <>
                                <CardText>Per Licence</CardText>
                                <div className="licence-count d-flex align-items-center justify-content-between">
                                  <span>Team Admin</span>
                                  <Label for="value" className="heading heading-sm roboto-bold">1</Label>
                                </div>
                              </>
                            }
                          </div>
                        </CardBody>
                      </Card>}
                    <Card>
                      <CardBody>
                        <div className="card-data">
                          <CardTitle>Monthly</CardTitle>
                          <CardSubtitle><em>$</em>
                            {subscriptionProduct && role === staticConstants.ROLE.ORGANIZATION_ADMIN ?
                              subscriptionProduct && subscriptionProduct.learnerAmount : subscriptionProduct && subscriptionProduct.licenceAmount}
                          </CardSubtitle>
                          {role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                            <>
                              <CardText>Per Licence</CardText>
                              <div className="licence-count d-flex align-items-center justify-content-between">
                                <span>Team Member</span>
                                <Input
                                  name="licence"
                                  onChange={this.handleChange}
                                  className="focus-none"
                                  type="number"
                                  value={licence}
                                />
                                {this.state.errors.has('licence') &&
                                  <div className="error-text">{this.state.errors.first('licence')}</div>
                                }
                              </div>
                            </>
                          }
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="price-text">
                    <Jumbotron>
                      {role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                        (<p>As a team leader, your profile will be the Team Admin.
                        Team Admins have access to more features than everyone else and charged at
                        ${subscriptionProduct && subscriptionProduct.licenceAmount}
                         per month. Team members are charged at ${subscriptionProduct &&
                            subscriptionProduct.learnerAmount} per month. Either way, the first month is
                          free. Select the number of team members
                        and you’re done. You can adjust this number at any time.</p>)}

                      {role === staticConstants.ROLE.PROFESSIONAL && (<p>If you are an individual user,
                      not looking to collaborate with your colleagues in a team,
                        you pay ${subscriptionProduct && subscriptionProduct.licenceAmount} per month for access to
                          Overlap. And, you get your first month free.</p>)}
                      <ul>
                        <li>Access to match-making technology.</li>
                        <li>Audio and video calls with experts.</li>
                        <li>Event media and custom dashboard.</li>
                      </ul>
                      <p>
                        Note: Users are responsible for paying for each session with an expert.
                        Session fees are calculated by (expert hourly rate) x (duration of event) x (number of participants).
                      </p>
                    </Jumbotron>
                  </div>
                </div>
                {isPaid && <div className="addon-wrapper">
                  <Jumbotron >
                    <label className="control control--checkbox">
                      <Input type="checkbox"
                        checked={this.state.isAddOn} name="isAddOn"
                        onChange={e => this.setState({ isAddOn: e.target.checked })}
                      />{' '}
                      <div className="control__indicator" />
                    </label>
                    <div className="addon-price"><em>$</em>
                      <span>{subscriptionProduct && subscriptionProduct.addOnAmount}</span>
                      Add On</div>
                    <p> Overlap limits access to user profile information to reduce selection bias. This add-on allows full visibility of user profiles.</p>
                  </Jumbotron>
                </div>}
              </div>
              <div className="card-footer d-flex">
                {
                  state && (state['action'] === CONSTANTS.PURCHASE_ADDON || state['action'] === 'update-plan') &&
                  <Button
                    className="btn btn-icon danger"
                    onClick={this.cancelAddOnPurchase}
                  >
                    <span className="icon icon-cross" />
                      Cancel
                  </Button>
                }
                <Button color="primary" className="btn btn-rg ml-auto" onClick={this.validateAndSubmit}>
                  {getPaymentBtnLabel()}
                </Button>
              </div>
            </div>
          }
          {(!state || state['action'] !== CONSTANTS.PURCHASE_ADDON) &&
            <div className="payment-option-wrapper">
              <h2>Easy and Secure Payment</h2>
              <p>100% Satisfaction guarantee policy</p>
              <Card>
                <CardImg top src={cardImage} alt="Card image" />
                <CardBody>
                  <CardTitle>Trusted payment methods</CardTitle>
                  <CardText>Pay with credit card</CardText>
                </CardBody>
              </Card>
              <Card>
                <CardImg top src={verifyCard} alt="Card image" />
                <CardBody>
                  <CardTitle>Secure payment</CardTitle>
                  <CardText>Your details will remain 100% private and secure.</CardText>
                </CardBody>
              </Card>
              <Card>
                <CardImg top src={cancelCard} alt="Card image" />
                <CardBody>
                  <CardTitle>Cancel any time</CardTitle>
                  <CardText>Cancelling your subscription is quick and simple.</CardText>
                </CardBody>
              </Card>
            </div>
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: { updateProfile: any; subscriptionPlan: any; authentication: any }) {
  const { user } = state.authentication;
  const { updateProfile, subscriptionPlan } = state;
  const licence = updateProfile.profile && updateProfile.profile.licence ? updateProfile.profile.licence : 0;
  const isAddOn = updateProfile.profile && updateProfile.profile.isAddOn ? updateProfile.profile.isAddOn : false;
  return {
    licence,
    isAddOn,
    user,
    subscriptionProduct: subscriptionPlan['subscriptionProduct'],
  };
}

const connectedSubscriptionPlan = connect(mapStateToProps)(SubscriptionPlan);
export { connectedSubscriptionPlan as SubscriptionPlan };
