import React, { Dispatch } from 'react';
import {
  Button,
  UncontrolledCollapse,
  Card,
  CardText,
  CardTitle,
} from 'reactstrap';
import { LocationDescriptorObject } from 'history';
import { IViewProfile, ISubscriptionInfo } from '../../../interfaces/index';
import { pageRoutes, staticConstants, cancelSubscriptionModalContent, formatCurrency, MAGIC_NUMBER } from './../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';
import { profileAction } from './redux/actions';
import { history } from '../../../helpers';
import { ConfirmatioPopup } from './../../shared/modals/confirmationPopup';
import moment from 'moment';
import { map } from 'lodash';
import { InvoiceLists } from './InvoiceLists';
import PaymentPortal from './../../expert/manulaPayments/PaymentPortal';

interface IProps {
  viewProfile: IViewProfile;
  subscriptionHistory: ISubscriptionInfo[];
  dispatch?: Dispatch<OpenFieldTypes.RootAction>;
  location?: LocationDescriptorObject;
  learnerList?: any;
  role: string;
  loggedInUserRole?: string;
  userId?: string;
}

interface IState {
  activeTab: any;
  // isStripeConnection: boolean;
  isAssignedLicences: boolean;
  isPlanHistory: boolean;
  isInvoiceHistory: boolean;
  showCancelSubscriptionModal: boolean;
  modalObj: any;
  sort: string;
  sortBy: number;
  isPaymentPortal: boolean;
}

class BillingInfo extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: '1',
      // isStripeConnection: false,
      isAssignedLicences: false,
      isPlanHistory: false,
      isInvoiceHistory: false,
      showCancelSubscriptionModal: false,
      modalObj: {},
      sort: '',
      sortBy: 0,
      isPaymentPortal: false,
    };
  }

  /**
   * @description redirect subscription page
   */
  public updatePlan = () => {
    const { role } = this.props;
    history.push({
      pathname: `${pageRoutes.PAYMENT.PATH}${pageRoutes.PAYMENT.SUBSCRIPTION}`,
      hash: '#3',
      state: {
        redirectUri: this.props.location['pathname'],
        action: role === staticConstants.ROLE.EXPERT ? 'expert-purchase-addon' : 'update-plan',
        originUri: this.props.location['pathname'],
        originStateVars: this.props.location['state'],
        showSidebarNav: true,
        breadcrumb: [{ title: 'USER INFORMATION', link: this.props.location['pathname'], hash: '#3' },
        { title: 'Change Plan', link: null }],
      },
    });
  }

  /**
   * @description
   * show cancel subscription popup.
   * @param fields {String} id
   */
  public showCancelSubscriptionPopup = () => {
    const modalObj = cancelSubscriptionModalContent;
    this.setState({
      showCancelSubscriptionModal: true,
      modalObj,
    });
  }
  /**
   * @description redirectToPaymentGateWay method to redirect the payment gateway
   */
  public cancelSubscription = () => {
    const { userId } = this.props;
    this.props.dispatch(profileAction.cancelSubscription(userId));
    this.setState({
      showCancelSubscriptionModal: false,
      modalObj: {},
    });
  }

  /**
   * @description
   * createPaymentGateWayDashBoardLink method is used to create payment gateway dashboard
   */
  public createPaymentGateWayDashBoardLink = () => {
    const { dispatch } = this.props;
    dispatch(profileAction.getPaymentLoginLink());
  }
  public handleAssignedLicences = (isAssignedLicences: boolean) => {
    this.setState({ isAssignedLicences });
  }

  /**
   * @description function to render assigned lerarner list
   */
  public renderLearnerList(learnerList: any) {
    return map(learnerList, (item: any, index: any) => {
      return item.isLicenced && (
        <Card key={index}>
          <CardTitle className="text-truncate">{`${item.firstName} ${item.lastName}`}</CardTitle>
          <CardText className="roboto-regular">{item.email}</CardText>
        </Card>
      );
    });
  }

  public fetchBillingData() {
    const { sort, sortBy } = this.state;
    const { userId } = this.props;
    const reqObj = {
      userId,
    };
    if (sort) {
      reqObj['sort'] = sort;
      reqObj['sortBy'] = sortBy;
    }
    this.props.dispatch(profileAction.getSubscriptionHistory(reqObj));
  }

  public sortData(sort, sortBy) {
    this.setState({ ...this.state, sort, sortBy },
                  () => this.fetchBillingData());
  }

  public render() {
    const { viewProfile, subscriptionHistory, learnerList, role, loggedInUserRole } = this.props;
    const {isAssignedLicences, isPlanHistory,
      isInvoiceHistory, modalObj, showCancelSubscriptionModal, sort, sortBy, isPaymentPortal } = this.state;
    const classVarArr = ['flex-table plan-history-table'];
    if (role !== staticConstants.ROLE.EXPERT && role !== staticConstants.ROLE.SYSTEM_ADMIN) {
      classVarArr.push('table-row-col-4');
    }

    if (role === staticConstants.ROLE.EXPERT || role === staticConstants.ROLE.SYSTEM_ADMIN) {
      classVarArr.push('table-row-col-3');
    }
    const classes = classVarArr.join(' ');
    const getPreviousPaymentHistory = () => {
      if (!subscriptionHistory || subscriptionHistory.length <= 0) { return 'No Previous History'; }
      return subscriptionHistory.map((el: ISubscriptionInfo) => {
        return <div key={Math.random()} className="table-row">
          <div className="table-col table-col-25 sm-50" data-label="Plan Name">
            <span>Monthly</span>
          </div>
          {role !== staticConstants.ROLE.EXPERT &&
            role !== staticConstants.ROLE.PROFESSIONAL && role !== staticConstants.ROLE.SYSTEM_ADMIN &&
            <>
              <div className="table-col table-col-25 sm-50" data-label="Plan details">
                <span>{el.quantity} Licences</span>
              </div>
              <div className="table-col table-col-20 sm-50" data-label="Plan Cost">
                <span>${formatCurrency(el.amount)}</span>
              </div>
            </>
          }
          {role === staticConstants.ROLE.PROFESSIONAL &&
            <>
              <div className="table-col table-col-20 sm-50" data-label="Plan Cost">
                <span>${formatCurrency(el.amount)}</span>
              </div>
            </>
          }
          <div className="table-col table-col-13 sm-50" data-label="Add Ons">
            <span>{el.isAddOn ? 1 : 'No Add On'}</span>
          </div>
          <div className="table-col table-col-17 mb-0" data-label="Next Due Date">
            <span>
              {moment(el.date).format(staticConstants.DATE_FORMAT)}</span>
          </div>
        </div>;
      });
    };
    const getExpertCurrentPlan = () => {
      if (!viewProfile.subscriptionId) { return <div className="plan-details"> No upcoming invoice</div>; }
      return <div className="plan-details">
        <div className="plan-box plan-col-2 plan-price">
          <span>Monthly (Add-Ons)</span>
          <h3><small>$</small> {formatCurrency(viewProfile.amount)}</h3>
        </div>
        <div className="plan-box plan-col-2 licence">
          <p className="mt-2">Next payment Due on</p>
          <div>
            <h4>{moment.unix(Number(viewProfile.current_period_end)).format(staticConstants.DATE_FORMAT)}</h4>
            <em>(Renew Automatically)</em>
          </div>
        </div>
      </div>;
    };

    const getOrgAdminCurrentPlan = () => {
      const amount = viewProfile.amount;
      if (!viewProfile.subscriptionId) { return <div className="plan-details"> No upcoming invoice</div>; }
      return <div className="plan-details">
        <div className={`plan-box plan-price ${role === staticConstants.ROLE.PROFESSIONAL ? ' plan-col-2' : ''}`}>
          <span>Monthly</span>
          <h3><small>$</small> {viewProfile.isAddOn ?
            formatCurrency(amount - staticConstants.SUBSRIPTION_PRICING.ADD_ON) : formatCurrency(amount)} </h3>
          <em>{viewProfile.isAddOn ? `+ $${staticConstants.SUBSRIPTION_PRICING.ADD_ON} Add On` : ''}</em>
        </div>
        <div className={`plan-box  ${role === staticConstants.ROLE.PROFESSIONAL ? ' plan-col-2 licence' : 'payment-date'}`}>
          <p>Next payment Due on</p>
          <div>
            <h4>  {moment.unix(Number(viewProfile.current_period_end)).format(staticConstants.DATE_FORMAT)}</h4>
            <em>(Renew Automatically)</em>
          </div>
        </div>
        {role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
          <div className="plan-box licence">
            <p>Total Licences</p>
            <h4>{viewProfile.licence}</h4>
          </div>
        }

        {role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
          <div className="plan-box licence">
            <p>Assigned Licences</p>
            <h4>{viewProfile && (viewProfile.totalLicenceAssign + 1)}</h4>
          </div>
        }
      </div>;
    };

    return (
      viewProfile && (loggedInUserRole === staticConstants.ROLE.SYSTEM_ADMIN ||
        viewProfile.isPaid || role === staticConstants.ROLE.EXPERT) ? <>
          {viewProfile.isPaid &&
            <div className="tab-panel-wrapper white-bg box-shadow">
              <div className="change-plan">
                <h2 className="heading heading-rg roboto-medium">Billing Information</h2>
                {(role === staticConstants.ROLE.EXPERT ||
                  viewProfile.role === staticConstants.ROLE.EXPERT) ? getExpertCurrentPlan() : getOrgAdminCurrentPlan()}

              </div>
              {role !== staticConstants.ROLE.EXPERT && loggedInUserRole !== staticConstants.ROLE.SYSTEM_ADMIN &&
                <div className="card-footer order-swipe-sm d-flex align-items-center justify-content-between">
                  {viewProfile.subscriptionId &&
                    <Button className="btn btn-icon danger" onClick={this.showCancelSubscriptionPopup}>
                      <span className="icon icon-cross" /> Cancel Plan</Button>}
                  <Button color="primary" className="btn btn-rg ml-auto" onClick={this.updatePlan}>
                    Change Plan</Button>
                </div>
              }
              {role === staticConstants.ROLE.EXPERT && loggedInUserRole !== staticConstants.ROLE.SYSTEM_ADMIN &&
                <div className="card-footer order-swipe-sm d-flex align-items-center justify-content-right">
                  {viewProfile.subscriptionId ?
                    <Button
                      color="primary"
                      className="btn btn-rg ml-auto" onClick={this.showCancelSubscriptionPopup}>Remove Add On</Button> :
                    <Button
                      color="primary"
                      className="btn btn-rg ml-auto" onClick={this.updatePlan}> Purchase Add On </Button>
                  }
                </div>
              }
            </div>
          }

          {learnerList && learnerList.length > MAGIC_NUMBER.ZERO && role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
            <div className="tab-panel-wrapper white-bg box-shadow mt-4">
              <div className="assigned-licence">
                <h2
                  className="heading heading-rg roboto-medium collapse-header cursor-pointer"
                  id="toggler"
                  onClick={() => this.setState({ isAssignedLicences: !isAssignedLicences })}
                  onTouchStart={() => this.setState({ isAssignedLicences: !isAssignedLicences })}
                >
                  Assigned Licences
               <span className={`icon icon-arrow-down-sign-to-navigate ${isAssignedLicences ? 'up' : ''}`} />
                </h2>
                <UncontrolledCollapse toggler="#toggler" isOpen={isAssignedLicences}>
                  <div className="collapse-body">
                    {this.renderLearnerList(learnerList)}
                  </div>
                </UncontrolledCollapse>
              </div>
            </div>}

          <div className="tab-panel-wrapper white-bg box-shadow mt-4">
            <div className="assigned-licence">
              <h2
                className="heading heading-rg roboto-medium collapse-header cursor-pointer"
                id="toggler2"
                onClick={() => this.setState({ isPlanHistory: !isPlanHistory })}
                onTouchStart={() => this.setState({ isPlanHistory: !isPlanHistory })}
              >
                Plan History
                <span className={`icon icon-arrow-down-sign-to-navigate ${isPlanHistory ? 'up' : ''}`} />
              </h2>
              <UncontrolledCollapse toggler="#toggler2" isOpen={isPlanHistory}>
                {subscriptionHistory && subscriptionHistory.length > MAGIC_NUMBER.ZERO ?
                  <div className="collapse-body table-collapse-body">
                    <div className={classes}>
                      <div className={`table-head ${role === staticConstants.ROLE.EXPERT ? 'expert-plan-history' : ''}`}>
                        <div className="table-row">
                          <div className="table-col table-col-25">
                            <span className="sorting-icon">Plan Name
                            <strong>
                                <em className={(sort === 'plan' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                                  onClick={() => { this.sortData('plan', MAGIC_NUMBER.ONE); }} />
                                <em className={(sort === 'plan' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                                  onClick={() => { this.sortData('plan', MAGIC_NUMBER.FOUND_INDEX); }} />
                              </strong>
                            </span>
                          </div>
                          {role !== staticConstants.ROLE.EXPERT && role !== staticConstants.ROLE.SYSTEM_ADMIN &&
                            <>
                              {
                                role !== staticConstants.ROLE.PROFESSIONAL &&
                                <div className="table-col table-col-25">
                                  <span>Plan details
                                  <strong>
                                      <em className={(sort === 'quantity' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                                        onClick={() => { this.sortData('quantity', MAGIC_NUMBER.ONE); }} />
                                      <em className={(sort === 'quantity' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                                        onClick={() => { this.sortData('quantity', MAGIC_NUMBER.FOUND_INDEX); }} />
                                    </strong>
                                  </span>
                                </div>
                              }
                              <div className="table-col table-col-20">
                                <span>Plan Cost
                                <strong>
                                    <em className={(sort === 'amount' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                                      onClick={() => { this.sortData('amount', MAGIC_NUMBER.ONE); }} />
                                    <em className={(sort === 'amount' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                                      onClick={() => { this.sortData('amount', MAGIC_NUMBER.FOUND_INDEX); }} />
                                  </strong>
                                </span>
                              </div>
                            </>
                          }
                          <div className="table-col table-col-13">
                            <span>Add Ons
                          </span>
                          </div>
                          <div className="table-col table-col-17">
                            <span>Previous Payment Date
                          </span>
                          </div>
                        </div>
                      </div>
                      <div className="table-body">
                        {getPreviousPaymentHistory()}
                      </div>
                    </div>
                  </div> : <div className="table-body">
                    <div className="table-row table-no-data pb-3 pt-3">
                      No Results Found.
                    </div>
                  </div>}
              </UncontrolledCollapse>
            </div>
          </div>
          {[staticConstants.ROLE.ORGANIZATION_ADMIN, staticConstants.ROLE.PROFESSIONAL,
            staticConstants.ROLE.SYSTEM_ADMIN].indexOf(loggedInUserRole) !== -1 &&
            <div className="tab-panel-wrapper white-bg box-shadow mt-4">
              <div className="assigned-licence">
                <h2
                  className="heading heading-rg roboto-medium collapse-header cursor-pointer"
                  id="toggler3"
                  onClick={() => this.setState({ isInvoiceHistory: !isInvoiceHistory })}
                  onTouchStart={() => this.setState({ isInvoiceHistory: !isInvoiceHistory })}
                >
                  Transaction History
                <span className={`icon icon-arrow-down-sign-to-navigate ${isInvoiceHistory ? 'up' : ''}`} />
                </h2>
                <UncontrolledCollapse toggler="#toggler3" isOpen={isInvoiceHistory}>
                  <div className="collapse-body">
                    <InvoiceLists role={role} />
                  </div>
                  <div className="card-footer d-flex align-items-center justify-content-end" />
                </UncontrolledCollapse>
              </div>
            </div>}
          <>
          <div className="tab-panel-wrapper white-bg box-shadow mt-4">
              <div className="assigned-licence">
                <h2
                  className="heading heading-rg roboto-medium collapse-header cursor-pointer"
                  id="paymentPortal"
                  onClick={() => this.setState({ isPaymentPortal: !isPaymentPortal })}
                  onTouchStart={() => this.setState({ isPaymentPortal: !isPaymentPortal })}
                >
                  Payment Portal
              <span className={`icon icon-arrow-down-sign-to-navigate ${isPaymentPortal ? 'up' : ''}`} />
                </h2>
                <UncontrolledCollapse toggler="#paymentPortal" isOpen={isPaymentPortal}>
                  <PaymentPortal createPaymentGateWayDashBoardLink={this.createPaymentGateWayDashBoardLink}
                  inStripeRegion={viewProfile && viewProfile.inStripeRegion}
                  stripeAccountId={viewProfile?.stripeAccountId} />
                </UncontrolledCollapse>
           </div>
           </div>
           </>
          {showCancelSubscriptionModal &&
            <ConfirmatioPopup
              isOpenModal={showCancelSubscriptionModal}
              modalPopupObj={modalObj}
              hideModal={() => this.setState({ showCancelSubscriptionModal: false })}
              onClickYes={this.cancelSubscription}
            />
          }
        </> : null
    );
  }

}

export { BillingInfo };
