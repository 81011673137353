import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';

interface IProps {
  dispatch?: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  modalPopupObj?: any;
  hideModal(): void;
  connectStripe(): void;
}

interface IState {
  modal: boolean;
}

class ErrorPopup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      modal: props.isOpenModal,
    };
    this.hideModal = this.hideModal.bind(this);
    this.connectStripe = this.connectStripe.bind(this);
  }

  public hideModal() {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public connectStripe() {
    this.setState({
      modal: false,
    });
    this.props.connectStripe();
  }
  public render() {
    const { modal } = this.state;
    return (
      <Modal isOpen={modal} className="add-on-modal">
        <span className="close-btn icon icon-cross" onClick={this.hideModal} />
        <ModalBody>
          <h2>Stripe account not connected</h2>
          <p>Go to the Billing section of your profile and create your Stripe account.</p>
        </ModalBody>
          <div className="button-group">
              <Button color="" className={'btn mx-2 btn-secondary'}
                onClick={this.hideModal}>Close</Button>

          <Button color="primary" className={'btn mx-2 btn-primary'}
                onClick={this.connectStripe}>Connect</Button>
          </div>
      </Modal>
    );
  }
}

const connectedErrorPopupPage = connect()(ErrorPopup);
export { connectedErrorPopupPage as ErrorPopup };
