import { contentManagementService } from '../../../../services';
import { alertActions } from '../../../alert/redux/actions';
import { endLoading, startLoading } from '../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { handleError } from './../../../../utils';

export const contentManagementAction = {
  getContent,
  deleteFaq,
  updateFaq,
  updateContent,
  clearContent,
};

function getContent(reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(reqObj));
    contentManagementService.getContent(reqObj)
            .then(
                (response) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(response));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };

  function request(data: string) { return { type: actionTypes.GET_CONTENT_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.GET_CONTENT_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.GET_CONTENT_FAILURE, error }; }
}

function deleteFaq(id: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(id));
    contentManagementService.deleteFaq(id)
                .then(
                    (response) => {
                      dispatch(endLoading('MAIN_LOADER'));
                      dispatch(success(response));
                      dispatch(alertActions.successAlert(response));
                    },
                    (error) => {
                      handleError(dispatch, failure, error.toString());
                    },
                );
  };

  function request(data: string) { return { type: actionTypes.DELETE_FAQ_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.DELETE_FAQ_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.DELETE_FAQ_FAILURE, error }; }
}

function updateFaq(reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(reqObj));
    contentManagementService.addFaq(reqObj)
                  .then(
                      (response) => {
                        dispatch(endLoading('MAIN_LOADER'));
                        dispatch(success(response));
                      },
                      (error) => {
                        handleError(dispatch, failure, error.toString());
                      },
                  );
  };

  function request(data: string) { return { type: actionTypes.UPDATE_FAQ_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_FAQ_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.UPDATE_FAQ_FAILURE, error }; }
}

function updateContent(reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(reqObj));
    contentManagementService.updateContent(reqObj)
                .then(
                    (response) => {
                      dispatch(endLoading('MAIN_LOADER'));
                      dispatch(alertActions.successAlert(response));
                      dispatch(success(response));
                    },
                    (error) => {
                      handleError(dispatch, failure, error.toString());
                    },
                );
  };

  function request(data: string) { return { type: actionTypes.UPDATE_CONTENT_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_CONTENT_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.UPDATE_CONTENT_FAILURE, error }; }
}

function clearContent() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(clearData());
    function clearData() { return { type: actionTypes.CONTENT_LIST_CLEAR }; }
  };
}
