import gql from 'graphql-tag';

const GET_CATEGORY_LIST_QUERY = gql`
  query getCategory($domain: String, $subDomain: String, $category: String, $subCategory: String, $topic: String, $activity: String,
    $limit: Int, $page: Int, $key: String, $sort: String, $sortBy: Int, $isPagination: Boolean, $previousCount: Int){
    category(domain: $domain, subDomain:$subDomain, category: $category, subCategory:$subCategory, topic: $topic,
      activities: $activity, limit: $limit, page: $page, key: $key, sort: $sort, sortBy: $sortBy, isPagination: $isPagination, previousCount: $previousCount ) {
      count,
      data {
        _id,
        name,
        type,
        connectingId {
          _id,
          name,
          type,
        },
        domainId {
          _id,
          name,
          type,
        },
      },
      expert
    }
}`;

const GET_SUB_CATEGORY_LIST_QUERY = gql`
  query getSubCategory($domain: String, $subDomain: String, $category: String, $subCategory: String, $topic: String, $activity: String,
    $limit: Int, $page: Int, $key: String, $sort: String, $sortBy: Int, $isPagination: Boolean, $previousCount: Int){
    subCategory(domain: $domain, subDomain:$subDomain, category: $category, subCategory:$subCategory, topic: $topic,
      activities: $activity, limit: $limit, page: $page, key: $key, sort: $sort, sortBy: $sortBy, isPagination: $isPagination, previousCount: $previousCount) {
      count,
      data {
        _id,
        name,
        type,
        connectingId {
          _id,
          name,
          type,
        },
        domainId {
          _id,
          name,
          type,
        },
        subDomainId {
          _id,
          name,
          type,
        },
      },
      expert
    }
}`;

const GET_DOMAIN_LIST_QUERY = gql`
  query getDomain($limit: Int, $page: Int, $key: String, $sort: String, $sortBy: Int, $isPagination: Boolean, $previousCount: Int){
    domain(limit: $limit, page: $page, key: $key, sort: $sort, sortBy: $sortBy, isPagination: $isPagination, previousCount: $previousCount){
      count,
      data {
        _id,
        name,
        type
      },
      expert
    }
}`;

const GET_SUB_DOMAIN_LIST_QUERY = gql`
  query getSubDomain($domain: String, $subDomain: String, $category: String $subCategory: String, $topic: String, $activity: String,
    $limit: Int, $page: Int, $key: String, $sort: String, $sortBy: Int, $isPagination: Boolean, $previousCount: Int){
    subDomain(domain: $domain, subDomain:$subDomain, category: $category, subCategory:$subCategory, topic: $topic,
      activities: $activity, limit: $limit, page: $page, key: $key, sort: $sort, sortBy: $sortBy, isPagination: $isPagination, previousCount: $previousCount) {
      count,
      data {
        _id,
        name,
        type,
        connectingId {
          _id,
          name,
          type,
        }
      },
      expert
    }
}`;

const GET_TOPICS_LIST_QUERY = gql`
  query getTopic($domain: String, $subDomain: String, $category: String, $subCategory: String, $topic: String, $activity: String,
    $limit: Int, $page: Int, $key: String, $sort: String, $sortBy: Int, $isPagination: Boolean, $previousCount: Int){
    topic(domain: $domain, subDomain:$subDomain, category: $category, subCategory:$subCategory, topic: $topic,
      activities: $activity, limit: $limit, page: $page, key: $key, sort: $sort, sortBy: $sortBy, isPagination: $isPagination, previousCount: $previousCount) {
      count,
      data {
        _id,
        name,
        type,
        connectingId {
          _id,
          name,
          type,
        },
        domainId {
          _id,
          name,
          type,
        },
        subDomainId {
          _id,
          name,
          type,
        },
        categoryId {
          _id,
          name,
          type,
        },
      },
      expert
    }
}`;

const GET_ACTIVITIES_LIST_QUERY = gql`
  query getActivities($domain: String, $subDomain: String, $category: String $subCategory: String, $topic: String,
    $activity: String, $limit: Int, $page: Int, $key: String, $sort: String, $sortBy: Int, $isPagination: Boolean, $previousCount: Int){
    activities(domain: $domain, subDomain:$subDomain, category: $category, subCategory:$subCategory, topic: $topic,
      activities: $activity, limit: $limit, page: $page, key: $key, sort: $sort, sortBy: $sortBy, isPagination: $isPagination, previousCount: $previousCount) {
      count,
      data {
        _id,
        name,
        type
      },
      expert
    }
}`;

const GET_TAGS = gql`
  query tags{
    tags {
      data {
        _id,
        name
      }
    }
}`;

const SEARCH_TAGS = gql`
  query allLinktag ($key: String,) {
    allLinktag (key: $key) {
      data {
        _id,
        name,
        type,
        domainId {
          name,
          type,
          _id
        },
        subDomainId {
          name,
          type,
          _id
        },
        categoryId {
          name,
          type,
          _id
        },
        subCategoryId {
          name,
          type,
          _id
        },
        connectingId {
          name,
          type,
          _id
        }
      },
      expert,
      count
    }
}`;

const GET_TOTAL_EXPERT = gql`
  query totalExpert($domain: String, $subDomain: String, $category: String
    $subCategory: String, $topic: String, $activity: String){
    totalExpert(domain: $domain, subDomain:$subDomain,
       category: $category, subCategory:$subCategory, topic: $topic, activities: $activity) {
      expert
    }
}`;

export {
  GET_CATEGORY_LIST_QUERY, GET_SUB_CATEGORY_LIST_QUERY, GET_DOMAIN_LIST_QUERY,
  GET_SUB_DOMAIN_LIST_QUERY, GET_TOPICS_LIST_QUERY, GET_ACTIVITIES_LIST_QUERY,
  GET_TAGS, GET_TOTAL_EXPERT, SEARCH_TAGS
};
