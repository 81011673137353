import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { Link } from 'react-router-dom';
import { Sidebar } from '../../../shared/sidebar';
import userProfile from '../../../../assets/images/profile.png';
import { userAction } from '../redux/actions';
import {
  tableItemsCount,
  updateActiveUserStatusMsg,
  updateInActiveUserStatusMsg,
  staticConstants,
  pageRoutes,
  userStatusTypes,
  getSelectedValue,
  MAGIC_NUMBER,
  CSS_ClASSES,
  CONSTANTS,
  bidStatusText
} from './../../../../utils';
import { ListingContainer } from '../../../shared/listingManagement/listingContainer';
import { ConfirmatioPopup } from '../../../shared/modals/confirmationPopup';
import { LearningGoal } from './../../adminGoalManagement/listings/learningGoal';
import { GoalRequests } from './../../requestManagement/listing/goalRequests';
import { BidManagementContainer } from './../../bidManagement/bidManagementContainer';
import { history } from '../../../../helpers';
import { FlaggedRemarks } from './../flaggedRemarks/flaggedRemarks';
import { FilterComponent } from '../../../shared/filter/filterComponent';
import { Back } from '../../../shared/back/back';
import { GoalEvents } from './../../eventManagment/listing/goalEvents';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  user: any;
  userInfo: any;
  match?: any;
  role: string;
  location: any;
}

const orgLearnerContent = {
  name: { name: 'Name', class: 'table-col-40', sort: true, dataLabel: 'Name' },
  email: { name: 'Email', class: 'table-col-40', sort: true },
  isActive: { name: 'Status', class: CONSTANTS.TABLE_COL_20, sort: true, dataLabel: 'Status' },
};

const adminLearningGoalContent = {
  title: { name: 'Title', class: CONSTANTS.TABLE_COL_20, sort: true, path: `${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.DETAIL}` },
  eventType: { name: CONSTANTS.EVENT_TYPE, class: CONSTANTS.TABLE_COL_13, sort: true },
  domain: { name: 'Domain', class: CONSTANTS.TABLE_COL_15, sort: true },
  subDomain: { name: CONSTANTS.SUB_DOMAIN, class: CONSTANTS.TABLE_COL_12, sort: true },
  createdAt: { name: bidStatusText.goalCreated, class: CONSTANTS.TABLE_COL_15, sort: true },
  totalParticipants: { name: 'Participants', class: 'table-col-15 text-center', sort: true },
  status: { name: 'Status', class: CONSTANTS.TABLE_COL_10, sort: true },
};

const goalRequestContent = {
  title: { name: 'Title', class: CONSTANTS.TABLE_COL_20, sort: true, path: `${pageRoutes.REQUESTMANAGEMENT.PATH}${pageRoutes.REQUESTMANAGEMENT.DETAIL}` },
  eventType: { name: CONSTANTS.EVENT_TYPE, class: CONSTANTS.TABLE_COL_13, sort: true },
  domain: { name: 'Domain', class: CONSTANTS.TABLE_COL_15, sort: true },
  subDomain: { name: CONSTANTS.SUB_DOMAIN, class: CONSTANTS.TABLE_COL_12, sort: true },
  dateAvailability: { name: 'Date Availability', class: CONSTANTS.TABLE_COL_20 },
  totalParticipants: { name: 'Participants', class: 'table-col-10 text-center', sort: true },
  status: { name: 'Status', class: CONSTANTS.TABLE_COL_10, sort: true },
};

const cancelRequestContent = {
  title: { name: 'Title', class: CONSTANTS.TABLE_COL_20, sort: true },
  eventType: { name: CONSTANTS.EVENT_TYPE, class: CONSTANTS.TABLE_COL_13, sort: true },
  domain: { name: 'Domain', class: CONSTANTS.TABLE_COL_15, sort: true },
  subDomain: { name: CONSTANTS.SUB_DOMAIN, class: CONSTANTS.TABLE_COL_12, sort: true },
  dateAvailability: { name: bidStatusText.goalCreated, class: CONSTANTS.TABLE_COL_15, sort: true },
  totalParticipants: { name: 'Participants', class: 'table-col-10 text-center', sort: true },
  requestCancelled: { name: 'Request Cancelled', class: CONSTANTS.TABLE_COL_15, sort: true },
};

const newRequest = {
  title: { name: 'Title', class: 'table-col-17', sort: true, path: `${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.DETAIL}` },
  eventType: { name: CONSTANTS.EVENT_TYPE, class: CONSTANTS.TABLE_COL_10, sort: true },
  domain: { name: 'Domain', class: CONSTANTS.TABLE_COL_15, sort: true },
  createdAt: { name: bidStatusText.goalCreated, class: CONSTANTS.TABLE_COL_12, sort: true },
  totalParticipants: { name: 'Participants', class: 'table-col-13 text-center', sort: true },
  createdBy: { name: 'Created By', class: CONSTANTS.TABLE_COL_13, sort: true },
  senTo: { name: 'Sent To', class: CONSTANTS.TABLE_COL_10, sort: true },
  status: { name: 'Status', class: CONSTANTS.TABLE_COL_10, sort: true },
};

class UserManagementDetailContainer extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: any, state: any) {
    const updateState = {};
    updateState['fetchGenerateReport'] = false;
    if (props.users && props.users.list) {
      updateState['users'] = props.users;
    }
    if (props.users && props.users.successDownloadReport) {
      updateState['downloadUrl'] = props.users.downloadUrl;
      updateState['downloadFileName'] = props.users.downloadFileName;
      updateState['fetchGenerateReport'] = true;
    }
    if (props.users && props.users.successForUpdateUserStatus) {
      props.dispatch(userAction.resetPopUpStatus());
      updateState['displayUpdateUserStatusPopup'] = false;
    }
    return updateState;
  }

  public state = {
    selectedOption: null,
    tooltipOpen: false,
    activePage: 1,
    title: '',
    items: tableItemsCount,
    status: null,
    sort: '',
    sortBy: 0,
    users: {
      count: 0,
      list: [],
      fetchedUsersListing: false,
    },
    displayUpdateUserStatusPopup: false,
    userId: '',
    actionId: '',
    isActionFromDetail: false,
    userStatus: null,
    reqObj: {},
    modalPopupObj: {},
    activeTab: staticConstants.USER_MANAGEMENT_FILTER_TAB.LEARNER,
    openFilter: false,
    tableContent: orgLearnerContent,
    learnerId: undefined,
    isOpenFlaggedByModal: false,
    downloadUrl: undefined,
    downloadFileName: undefined,
    fetchGenerateReport: false,
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call userAction.getUsers to fetch goal listings
   * call userAction.getUsers to fetch goal categories
   */
  public componentDidMount() {
    const { items, activePage } = this.state;
    const reqObj: any = {
      page: Number(activePage),
      limit: items,
    };
    const updateReqObj = this.updateReqObj(reqObj);
    const { id, learnerId, userType } = this.props.match.params;

    if (userType === staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL) {
      this.setState({
        tableContent: adminLearningGoalContent,
        activeTab: staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.PROFESSIONSAL_LEARNING_GOALS,
      });
    }
    let userId;
    if (learnerId) {
      this.setState({
        learnerId,
      });
      userId = learnerId;
    } else {
      userId = id;
      this.props.dispatch(userAction.getUsers(updateReqObj));
    }
    this.setState({ userId });
    if (userId) {
      this.props.dispatch(userAction.getUserProfile(userId));
    }
  }

  /**
   * @description componentDidUpdate is called when we need to fetch listing again.
   * call goalAction.getGoals to fetch goal listings
   */
  public componentDidUpdate() {
    if (this.state.fetchGenerateReport) {
      this.downloadReport();
    }
  }

  /**
   * @description
   * updateUserStatusAction is used to update user status
   * @param fields {String} id
   */
  public updateUserStatusAction = () => {
    const reqObj = {
      action: this.state.userStatus,
      userId: this.state.actionId,
      isFromDetailPage: this.state.isActionFromDetail,
    };
    this.props.dispatch(userAction.updateUserStatus(reqObj));
  }

  /**
   * @description
   * updateStatus is used to update use status
   * @param fields {Object} user
   */
  public updateUserStatus = (user: any, fromDetail: boolean) => {
    const { isActive } = user;
    let actionId = '';
    let isActionFromDetail = false;
    if (fromDetail) {
      actionId = this.state.userId;
      isActionFromDetail = true;
    } else {
      actionId = user._id;
    }
    let modalPopupObj = updateActiveUserStatusMsg;
    let status: any;
    if (isActive) {
      status = 'inactive';
      modalPopupObj = updateInActiveUserStatusMsg;
    } else {
      status = 'active';
    }
    this.setState({
      displayUpdateUserStatusPopup: true,
      userStatus: status,
      modalPopupObj,
      actionId,
      isActionFromDetail,
    });
  }

  /**
   * @description
   * hideModal is called when someone click on the cancel button
   */
  public hideModal = () => {
    this.setState({
      displayUpdateUserStatusPopup: false,
    });
  }

  /**
   * @description
   * generateReport is used to get the generate report
   */
  public generateReport = (title: string) => {
    const tableContent = this.state.tableContent;
    const columnHeaders = [];
    const columnKeys = [];
    for (const [key, value] of Object.entries(tableContent)) {
      columnHeaders.push(value.name);
      columnKeys.push(key);
    }
    const { id, learnerId } = this.props.match.params;
    let userId;
    if (!learnerId) {
      userId = id;
    }
    this.props.dispatch(userAction.generateReport({
      columnHeaders,
      columnKeys,
      reportTitle: `${title}.csv`,
      type: this.state.activeTab,
      isUserManagment: true,
      isGenerateReport: true,
      userId,
    }));
  }

  public downloadReport = () => {
    this.props.dispatch(userAction.resetGenerateReport());
    const filePath = this.state.downloadUrl;
    const a = document.createElement('a');
    a.setAttribute('href', `${filePath}`);
    a.setAttribute('download', this.state.downloadFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  /**
   * @description updateReqObj to update object before sending to the server
   */
  public updateReqObj(reqObj: any) {
    reqObj.type = this.state.activeTab;
    reqObj.isUserManagment = true;
    reqObj.searchKey = this.state.title;
    reqObj.status = getSelectedValue(this.state.status);
    const { id, learnerId } = this.props.match.params;
    if (!learnerId) {
      reqObj.userId = id;
    }
    return reqObj;
  }

  /**
   * @description fetchUsersData is used on initial load
   */
  public fetchUsersData(pageno: number) {
    const { sort, sortBy } = this.state;
    let reqObj = {
      page: Number(pageno),
      limit: this.state.items,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    const updateReqObj = this.updateReqObj(reqObj);
    this.props.dispatch(userAction.getUsers(updateReqObj));
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleChange = (value: any, key: string) => {
    this.setState({ ...this.state, [key]: value }, () => this.fetchUsersData(1));
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => {
    this.setState({
      activePage: pageno,
    }, () => this.fetchUsersData(pageno));
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData = (sort: any, sortBy: any) => {
    const { activePage } = this.state;
    this.setState({ ...this.state, ...{ sort, sortBy } },
      () => this.fetchUsersData(activePage));
  }

  public viewProfileDetails(profileId: string, role: string) {
    const { userInfo, location } = this.props;
    let pathname = '';
    let breadcrumb = [];
    switch (role) {
      case staticConstants.ROLE.ORGANIZATION_ADMIN:
        pathname = `${pageRoutes.VIEW_PROFILE_ORGADMIN.PATH}/${profileId}`;
        breadcrumb = [
          { title: staticConstants.DISPLAY_ROLE.orgAdmin, link: location.pathname },
          { title: 'Team Admin Profile' },
        ];
        break;
      case staticConstants.ROLE.EXPERT:
        pathname = `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.VIEW_PROFILE.PATH}/${profileId}`;
        break;
      case staticConstants.ROLE.PROFESSIONAL:
        pathname = `${pageRoutes.VIEW_PROFILE_PROFESSIONAL.PATH}/${profileId}`;
        breadcrumb = [
          { title: 'Individual', link: location.pathname },
          { title: 'Individual Profile' },
        ];
        break;
      case staticConstants.ROLE.ORGANIZATION_LEARNER:
        pathname = `${pageRoutes.VIEW_PROFILE_LEARNER.PATH}/${profileId}`;
        breadcrumb = [
          { title: 'Individual', link: location.pathname },
          { title: 'Member Profile' },
        ];
        break;
    }
    userInfo.firstName && userInfo.lastName && breadcrumb.push({ title: `${userInfo.firstName} ${userInfo.lastName}`, redirectTo: location.pathname });
    history.push({
      pathname,
      state: {
        breadcrumb,
        userRole: role,
      },
    });
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { tableContent, users, activeTab, modalPopupObj, displayUpdateUserStatusPopup,
      learnerId, userId, isOpenFlaggedByModal } = this.state;
    const { userInfo } = this.props;
    const classVarArr = ['table-head'];
    const classes = classVarArr.join(' ');
    const { userType } = this.props.match.params;
    const filterContent = ['search', 'status'];
    const filterObj = {
      activePage: this.state.activePage,
      sort: this.state.sort,
      sortBy: this.state.sortBy,
    };
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <FlaggedRemarks modalTitle="Flagged By" isOpenModal={isOpenFlaggedByModal}
            flags={userInfo && userInfo.flag}
            hideModal={() => this.setState({ isOpenFlaggedByModal: false })} />
          <Back {...this.props} />
          <div className="view-profile-box system-admin-profile-wrapper box-shadow white-bg mt-4 mb-0">
            <div className="user-profile-details w-100">
              <span className="profile-picture-view-only">
                {userInfo && userInfo.profileImage && userInfo.profileImage !== '' ?
                  <img src={userInfo.profileImage} alt="user profile" className="img-cover" />
                  : <img src={userProfile} alt="user profile" className="img-cover" />}
              </span>
              <div className="profile-email-view-only text-truncate ">
                {!learnerId && userType === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN ?
                  <h3 className="text-truncate"> {userInfo && userInfo.organizationName} </h3> : ''
                }
                {(userType === staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL || userType === staticConstants.USER_MANAGEMENT_TAB.EXPERTS) &&
                  <> <h3 className="text-truncate">
                    {userInfo && userInfo.firstName} {userInfo && userInfo.lastName}
                  </h3><p>{userInfo && userInfo.email}</p>
                  </>
                }
                {learnerId && userType === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN &&
                  <>
                    <h3 className="text-truncate">
                      {userInfo && userInfo.firstName} {userInfo && userInfo.lastName}
                    </h3>
                    <p>{userInfo && userInfo.email}</p>
                  </>
                }
                {!learnerId && userType === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN &&
                  <div className="profile-table">
                    <div className="profile-table-col">
                      <span>Address</span>
                      <p>{userInfo && userInfo.address}</p>
                    </div>
                    <div className="profile-table-col">
                      <span>No. of Users</span>
                      <p>{userInfo && userInfo.totalLearner}</p>
                    </div>
                    <div className="profile-table-col">
                      <span>No. of Licences</span>
                      <p>{userInfo && userInfo.licence}</p>
                    </div>
                    <div className="profile-table-col">
                      <span>Assigned Licences</span>
                      <p>{userInfo && userInfo.totalLicenceAssign && userInfo.totalLicenceAssign + 1}</p>
                    </div>
                  </div>
                }
                <div className="link-text">
                  {
                    (userInfo && !learnerId && userType === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN) &&
                    <Button
                      onClick={() => this.viewProfileDetails(userId, staticConstants.ROLE.ORGANIZATION_ADMIN)}> View Full Profile</Button>
                  }
                  {
                    (userInfo && userType === staticConstants.USER_MANAGEMENT_TAB.EXPERTS) &&
                    <Button onClick={() => this.viewProfileDetails(userId, staticConstants.ROLE.EXPERT)}> View Full Profile</Button>
                  }
                  {
                    (userInfo && learnerId && userType === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN) &&
                    <Button onClick={() => this.viewProfileDetails(userId, staticConstants.ROLE.ORGANIZATION_LEARNER)}> View Full Profile</Button>
                  }
                  {
                    (userInfo && userType === staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL) &&
                    <Button onClick={() => this.viewProfileDetails(userId, staticConstants.ROLE.PROFESSIONAL)}> View Full Profile</Button>
                  }
                </div>
              </div>
            </div>
            <div className="profile-box-btn-group">
              {userInfo &&
                <Button type="button" className="btn btn-inactive btn-rg"
                  onClick={() => this.updateUserStatus(userInfo, true)}>{userInfo && userInfo.isActive ? 'Active' : 'Inactive'}</Button>
              }
              <Link to={`${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.LOGS.PATH}/${userType}/${userId}`}
                className="btn btn-log btn-rg">View Logs
              </Link>
              {userInfo && userInfo.flag && userInfo.flag.length > MAGIC_NUMBER.ZERO &&
                <Button type="button"
                  className="btn btn-flag btn-rg"
                  onClick={() => this.setState({ isOpenFlaggedByModal: true })}>Flagged By</Button>
              }
            </div>
          </div>
          <div className="dashboard-content mt-5 system-admin-user-management-details">
            {userType !== staticConstants.USER_MANAGEMENT_TAB.EXPERTS &&
              <>
                {!learnerId ?
                  <>
                    <div className={`tab-btn-group multi-tab-buttons  
                    ${userType === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN ? 'button-count-4' : 'button-count-3'}`}>
                      {userType === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN &&
                        <Button
                          color="secondary"
                          className={`btn h-50 tab-btn text-uppercase 
                          ${activeTab === staticConstants.USER_MANAGEMENT_FILTER_TAB.LEARNER ? CSS_ClASSES.btnActive : ''}`}
                          onClick={() => this.setState({
                            activeTab: staticConstants.USER_MANAGEMENT_FILTER_TAB.LEARNER,
                            tableContent: orgLearnerContent
                          })}>
                          Team Members
                      </Button>
                      }
                      {
                        userType === staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL &&
                        <Button
                          color="secondary"
                          className={`btn h-50 tab-btn text-uppercase 
                          ${activeTab === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.PROFESSIONSAL_LEARNING_GOALS ? CSS_ClASSES.btnActive : ''}`}
                          onClick={() => this.setState({
                            activeTab: staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.PROFESSIONSAL_LEARNING_GOALS,
                            tableContent: adminLearningGoalContent
                          })}
                        >Learning Goals</Button>
                      }
                      {
                        userType === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN &&
                        <Button
                          color="secondary"
                          className={`btn h-50 tab-btn text-uppercase 
                          ${activeTab === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS ? CSS_ClASSES.btnActive : ''}`}
                          onClick={() => this.setState({
                            activeTab: staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS,
                            tableContent: adminLearningGoalContent
                          })}
                        >Admin Learning Goals</Button>
                      }
                      <Button
                        color="secondary"
                        className={`btn h-50 tab-btn text-uppercase 
                        ${activeTab === staticConstants.USER_MANAGEMENT_FILTER_TAB.REQUEST ? CSS_ClASSES.btnActive : ''}`}
                        onClick={() => this.setState({
                          activeTab: staticConstants.USER_MANAGEMENT_FILTER_TAB.REQUEST,
                          tableContent: goalRequestContent
                        })}
                      >
                        Requests
                      </Button>
                      <Button
                        color="secondary"
                        className={`btn h-50 tab-btn text-uppercase 
                        ${activeTab === staticConstants.USER_MANAGEMENT_FILTER_TAB.EVENTS ? CSS_ClASSES.btnActive : ''}`}
                        onClick={() => this.setState({
                          activeTab: staticConstants.USER_MANAGEMENT_FILTER_TAB.EVENTS,
                          tableContent: cancelRequestContent
                        })}
                      >
                        Events
                      </Button>
                    </div>
                    {activeTab === staticConstants.USER_MANAGEMENT_FILTER_TAB.ORG_ADMIN &&
                      <>
                        <div className="filter-wrapper">
                          <FilterComponent filterContent={filterContent}
                            handleFilter={this.handleChange}
                            placeholder="Search by Keywords, Name" status={userStatusTypes} />
                          <span className="btn btn-primary round-circle generate-report-btn"
                            onClick={() => this.generateReport(staticConstants.USER_MANAGEMENT_REPORT.ORG_ADMIN)}>
                            Generate Report
                          </span>
                        </div>
                        <ListingContainer tableContent={tableContent} listData={users}
                          tableHeaderClasses={classes}
                          filterObj={filterObj}
                          updateStatus={this.updateUserStatus}
                          handlePageChange={this.handlePageChange} handleSorting={this.sortData} />
                      </>
                    }
                    {activeTab === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.PROFESSIONSAL_LEARNING_GOALS &&
                      <LearningGoal userId={userId} activeTab={activeTab} tableContent={tableContent} />
                    }
                    {activeTab === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS &&
                      <LearningGoal userId={userId} activeTab={activeTab} tableContent={tableContent} />
                    }
                    {activeTab === staticConstants.USER_MANAGEMENT_FILTER_TAB.REQUEST &&
                      <GoalRequests userId={userId}
                        activeTab={userType === staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL ?
                          staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_PROFESSIONAL_REQUESTS : staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_ORGANIZATION_ADMIN_REQUESTS}
                        tableContent={tableContent} />
                    }
                    {activeTab === staticConstants.USER_MANAGEMENT_FILTER_TAB.EVENTS && userType === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN &&
                      <GoalEvents userId={userId}
                        activeTab={staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_ORGANIZATION_ADMIN_REQUESTS}
                        activeTabRole={staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS}
                        redirectFrom={'userManagement'} />
                    }
                    {activeTab === staticConstants.USER_MANAGEMENT_FILTER_TAB.EVENTS && userType === staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL &&
                      <GoalEvents userId={userId}
                        activeTab={staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_PROFESSIONAL_REQUESTS}
                        activeTabRole={staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.PROFESSIONSAL_LEARNING_GOALS}
                        redirectFrom={'userManagement'} />
                    }
                  </>
                  : <LearningGoal userId={userId}
                    activeTab={staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.LERNER_GOAL_REQUESTS}
                    tableContent={newRequest} />
                }
              </>
            }
            {userType === staticConstants.USER_MANAGEMENT_TAB.EXPERTS && userId !== '' &&
              <BidManagementContainer userId={userId} />
            }
            {
              displayUpdateUserStatusPopup ?
                <ConfirmatioPopup hideModal={this.hideModal}
                  isOpenModal={displayUpdateUserStatusPopup} modalPopupObj={modalPopupObj}
                  onClickYes={this.updateUserStatusAction}
                />
                : ''
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { users, users: { detail } } = state;
  const { successForUpdateUserStatus } = users;
  return {
    users,
    successForUpdateUserStatus,
    userInfo: detail,
  };
}

const connectedUserDetailContainer = connect(mapStateToProps)(UserManagementDetailContainer);
export { connectedUserDetailContainer as UserManagementDetailContainer };
