import { userServices, bidServices } from './../../../../services';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { handleError } from './../../../../utils';
import { alertActions } from './../../../alert/redux/actions';

export const viewProfileAction = {
  getShortProfile,
  getBidDetail,
  updateSettings,
};

function getShortProfile(profileId: string, isScheduleManagement = false) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.getShortProfile(profileId, isScheduleManagement)
            .then(
                (data) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(data));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };

  function success(data: any) { return { type: actionTypes.SHORT_PROFILE_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.SHORT_PROFILE_FAILURE, error }; }
}

function getBidDetail(bidId: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    bidServices.getBid(bidId)
            .then(
                (data) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(data));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };
  function success(data: any) { return { type: actionTypes.BID_DETAIL_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.BID_DETAIL_FAILURE, error }; }
}

function updateSettings(setting: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updateSettings(setting)
            .then(
                (res) => {
                  dispatch(alertActions.successAlert(res.message));
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(res.data));
                },
                (error) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  handleError(dispatch, failure, error.toString());
                },
            );
  };

  function success(data: any) { return { type: actionTypes.UPDATE_SETTING_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_SETTING_FAILURE, error }; }
}
