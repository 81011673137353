import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  Form,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator, IReferBid } from '../../../interfaces';
import { reeValidators } from './../../../utils/staticConstants';
import ReeValidate from 'ree-validate';
import { bidAction } from './redux/actions';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  hideModal: any;
  goalId: string;
}

interface IState {
  modal: boolean;
  errors?: any;
  formData?: IReferBid;
}

class ReferBid extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);

    this.validator = new ReeValidate({
      name: 'required',
      recepient: reeValidators.email,
    });
    this.state = {
      modal: props.isOpenModal,
      formData: {
        name: '',
        recepient: '',
        goalId: props.goalId,
      },
      errors: this.validator.errors,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  public handleSubmit = () => {
    const { dispatch } = this.props;
    const formData = this.state.formData;
    const data: IReferBid = formData;
    dispatch(bidAction.referBid(data));
    this.hideModal();
  }

  public validateAndSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { name, recepient } = this.state.formData;
    const { errors } = this.validator;

    this.validator.validateAll({ name, recepient })
      .then((success: boolean) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  public removeErrorAndReValidate = (name: string) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.formData[name])
      .then(() => {
        this.setState({ errors });
      });
  }

  public handleChange = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const { errors } = this.validator;
    this.setState({
      [name]: value,
    } as any);
    errors.remove(name);
    this.setState({ formData: { ...this.state.formData, [name]: value } });
    this.validator.validate(name, value)
      .then(() => { this.setState({ errors }); });
  }

  public hideModal() {
    this.props.hideModal();
    this.setState({
      modal: false,
      formData: {
        name: null,
        recepient: null,
      },
    });
    const { errors } = this.validator;
    errors.clear();
  }
  public render() {
    const { modal, errors, formData } = this.state;
    return (
      <Modal isOpen={modal} className="make-bid-modal" onClosed={this.hideModal}>
        <ModalHeader toggle={this.hideModal}>Refer an expert</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.validateAndSubmit} autoComplete="off" noValidate>
            <Row>
              <Col xs="12">
                <FormGroup
                className={`floating-label ${
                  errors.has('name') ? ' has-error' : ''
                  }`}
              >
                <Input
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={this.handleChange}
                  placeholder="Name"
                />
                <Label for="name">Name</Label>

                {errors.has('name') &&
                  <div className="error-text">{errors.first('name')}</div>
                }

              </FormGroup>
              </Col>
              <Col xs="12">
              <FormGroup
                className={`floating-label ${
                  errors.has('email') ? ' has-error' : ''
                  }`}
              >
                <Input
                  type="email"
                  className="form-control"
                  name="recepient"
                  id="recepient"
                  value={formData.recepient}
                  onChange={this.handleChange}
                  placeholder="Recepient Email"
                />
                <Label for="recepient">Recepient Email</Label>

                {errors.has('recepient') &&
                  <div className="error-text">{errors.first('recepient')}</div>
                }

              </FormGroup>
              </Col>
              </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular mr-2" onClick={this.hideModal}>Cancel</Button>{' '}
          <Button color="primary" onClick={this.validateAndSubmit}>Refer an Expert</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const connectedReferBidPopupPage = connect()(ReferBid);
export { connectedReferBidPopupPage as ReferBid };
