import { dashboardServices } from '../../../../services';
import { alertActions } from '../../../alert/redux/actions';
import { endLoading, startLoading } from '../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { handleError } from './../../../../utils';

export const dashboardActions = {
  getDashboardData,
  getLatestUsers,
};

function getDashboardData(data: any, type?: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(data));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dashboardServices.getAdminDashboardData(data)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          const response = res;
          if (type) {
            response.type = type;
            dispatch(successType(response));
          } else {
            dispatch(success(response));
          }
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request(res: any) { return { res, type: actionTypes.DASHBOARD_REQUEST }; }
  function success(res: any) { return { res, type: actionTypes.DASHBOARD_SUCCESS }; }
  function successType(res: any) { return { res, type: actionTypes.DASHBOARD_SUCCESS_TYPE }; }
  function failure(error: string) { return { error, type: actionTypes.DASHBOARD_FAILURE }; }
}

function getLatestUsers() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dashboardServices.getLatestUsers()
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          const response = res;
          dispatch(success(response));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request() { return { type: actionTypes.LATEST_USERS_REQUEST }; }
  function success(res: any) { return { res, type: actionTypes.LATEST_USERS_SUCCESS }; }
  function failure(error: string) { return { error, type: actionTypes.DASHBOARD_FAILURE }; }
}
