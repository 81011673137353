export const dashBoard =  '/dashboard'
export const viewProfile = '/viewProfile'

export const pageRoutes = {
  LOGIN: '/login',
  CREATE_PASSWORD: '/createPassword',
  USER_TYPE: '/usertype',
  ORGADMIN: {
    PATH: '/orgadmin',
    DASHBOARD: dashBoard,
    VIEW_PROFILE: { PATH: viewProfile },
    PROFILE: {
      PATH: '/profile',
    },
  },
  PROFESSIONAL: {
    PATH: '/professional',
    DASHBOARD: dashBoard,
    VIEW_PROFILE: { PATH: viewProfile },
    EDIT_PROFILE: { PATH: '/profile' },
    PROFILE: {
      PATH: '/profile',
    },
  },
  SYSTEM_ADMIN: {
    PATH: '/systemAdmin',
    DASHBOARD: dashBoard,
    USER_MANAGEMENT: {
      PATH: '/userManagement',
      DETAIL: '/detail', // With User ID Parameter
      LERNER_DETAIL: '/lerner',
    },
    APPLICATION_FEEDBACK: '/applicationFeedback',
    LOGS: {
      PATH: '/logs',
    },
    CONTENT_MANAGEMENT: {
      PATH: '/contentManagement',
    },
    VIEW_PROFILE: { PATH: viewProfile },
    PLANS: {
      PATH: '/plans',
    },
    TAGS: '/tags',
  },
  EXPERT: {
    PATH: '/expert',
    DASHBOARD: dashBoard,
    VIEW_PROFILE: { PATH: viewProfile },
    EDIT_PROFILE: { PATH: '/profile' },
    BID_MANAGEMENT: {
      PATH: '/bidManagement',
      DETAIL: '/detail', // With Goal ID Parameter
    },
    PAYMENT: {
      PATH: '/payments',
      CONNECT: '/connectWithUs',
    },
  },

  PAYMENT: {
    PATH: '/payment',
    SUBSCRIPTION: '/subscription',
    CHECKOUT: '/checkout',
    ADD_CARD: '/addCard',
    WITHDRAW: '/withdraw',
  },
  REGISTER: {
    SUCCESS: '/registerSuccess',
    PATH: '/register',
  },
  EXPERTREGISTER: {
    SUCCESS: '/expertRegisterSuccess',
    PATH: '/register',
  },
  FORGOT_PASSWORD: {
    SUCCESS: '/forgotPasswordSuccess',
    PATH: '/forgotPassword',
  },
  GOALMANAGEMENT: {
    PATH: '/goalManagement',
    CREATE_GOAL: '/createGoal',
    DETAIL: '/detail', // With Goal ID Parameter
    EDIT: '/editGoal', // With Goal ID Parameter
  },
  NOTIFICATION: {
    PATH: '/notifications',
  },
  LEARNERMANAGEMENT: {
    PATH: '/learnerManagement',
    DETAIL: '/detail', // With Goal ID Parameter
  },
  LEARNER: {
    PATH: '/learner',
    DASHBOARD: dashBoard,
    VIEW_PROFILE: { PATH: viewProfile },
    EDIT_PROFILE: { PATH: '/updateProfile' },
  },
  REQUESTMANAGEMENT: {
    PATH: '/requestManagement',
    DETAIL: '/detail',
  },
  VIEW_PROFILE_EXPERT: {
    PATH: '/viewExpertProfile',
    DETAIL: '/detail',
  },
  VIEW_PROFILE_PROFESSIONAL: {
    PATH: '/viewProfessionalProfile',
    DETAIL: '/detail',
  },
  EVENTMANAGEMENT: {
    PATH: '/eventManagement',
    DETAIL: '/detail',
  },
  MESSAGE_BOARD: {
    PATH: '/messageBoard',
    ADD_MESSAGE: '/addMessage',
  },
  VIEW_PROFILE_ORGADMIN: {
    PATH: '/viewOrgAdminProfile',
    DETAIL: '/detail',
  },
  VIEW_PROFILE_LEARNER: {
    PATH: '/viewLearnerProfile',
    DETAIL: '/detail',
  },
  LOGS: {
    PATH: '/logs',
  },
};
