import React, { Fragment } from 'react';
import { OrgAdminRegisterForm } from './OrgAdminRegisterForm';
import { ProfessionalRegisterForm } from './ProfessionalRegisterForm';
import { pageRoutes, staticConstants } from '../../../utils/';
import { LocationDescriptorObject } from 'history';

interface IProps {
  location: LocationDescriptorObject;
}

class RegisterContainer extends React.Component<IProps> {

  public render() {
    const { pathname } = this.props.location;
    let role = '';
    if (pathname.toLowerCase().includes(pageRoutes.ORGADMIN.PATH)) {
      role = staticConstants.ROLE.ORGANIZATION_ADMIN;
    } else if (pathname.toLowerCase().includes(pageRoutes.PROFESSIONAL.PATH)) {
      role = staticConstants.ROLE.PROFESSIONAL;
    }
    return (
        <Fragment>
          {role === staticConstants.ROLE.ORGANIZATION_ADMIN && <OrgAdminRegisterForm />}
          {role === staticConstants.ROLE.PROFESSIONAL && <ProfessionalRegisterForm />}
        </Fragment>
    );
  }
}

export { RegisterContainer };
