import gql from 'graphql-tag';

const CREATE_GOAL_MUTATION = gql`
  mutation addGoal(
    $modeOfDevivery: String!,
    $eventType: String,
    $title: String,
    $domain: tagsTypeSchemaInput,
    $subDomain: tagsTypeSchemaInput,
    $category: tagsTypeSchemaInput,
    $subCategory: tagsTypeSchemaInput,
    $activity: tagsTypeSchemaInput,
    $topic: tagsTypeSchemaInput,
    $duration: String,
    $fromDate: Date,
    $toDate: Date,
    $toTime: Date,
    $fromTime: Date,
    $description: String,
    $participants: [participantsInput],
    $isMe: Boolean,
    $isReviewTags: Boolean,
    $rehireId: String,
    $rehireOldGoalTitle: String
    ) {
      addGoal(
        modeOfDevivery: $modeOfDevivery,
        eventType: $eventType,
        title: $title,
        domain: $domain,
        subDomain: $subDomain,
        category: $category,
        subCategory:$subCategory,
        activity: $activity,
        topic: $topic,
        duration: $duration,
        fromDate: $fromDate,
        toDate: $toDate,
        toTime: $toTime,
        fromTime: $fromTime,
        description: $description,
        participants: $participants,
        isMe: $isMe
        isReviewTags: $isReviewTags,
        rehireId: $rehireId,
        rehireOldGoalTitle: $rehireOldGoalTitle
      ) {
        message
      }
}`;

const UPDATE_GOAL_PARTICIPATION_MUTATION = gql`
  mutation updateGoalParticipants(
    $isMe: Boolean,
    $goalId: String,
    $participants: [participantsInput],
    ) {
      updateGoalParticipants(
        goalId: $goalId,
        participants: $participants,
        isMe: $isMe,
      ) {
        message
      }
}`;

const UPDATE_GOAL_MUTATION = gql`
  mutation editGoal(
    $modeOfDevivery: String!,
    $eventType: String,
    $title: String,
    $domain: tagsTypeSchemaInput,
    $subDomain: tagsTypeSchemaInput,
    $category: tagsTypeSchemaInput,
    $subCategory: tagsTypeSchemaInput,
    $activity: tagsTypeSchemaInput,
    $topic: tagsTypeSchemaInput,
    $duration: String,
    $fromDate: Date,
    $toDate: Date,
    $toTime: Date,
    $fromTime: Date,
    $description: String,
    $participants: [participantsInput],
    $isMe: Boolean,
    $goalId: String,
    $isGoalAccept: Boolean
    $isReviewTags: Boolean
    ) {
      editGoal(
        goalId: $goalId,
        modeOfDevivery: $modeOfDevivery,
        eventType: $eventType,
        title: $title,
        domain: $domain,
        subDomain: $subDomain,
        category: $category,
        subCategory:$subCategory,
        activity: $activity,
        topic: $topic,
        duration: $duration,
        fromDate: $fromDate,
        toDate: $toDate,
        toTime: $toTime,
        fromTime: $fromTime,
        description: $description,
        participants: $participants,
        isMe: $isMe,
        isGoalAccept: $isGoalAccept
        isReviewTags: $isReviewTags
      ) {
        message
      }
}`;

const DELETE_GOAL_MUTATION = gql`
  mutation removeGoal( $goalId: String ) {
      removeGoal( goalId: $goalId ) {
        message
      }
}`;

const UPDATE_GOAL_STATUS_MUTATION = gql`
  mutation updateGoalStatus( $goalId: String!, $status: String!, $message: String) {
    updateGoalStatus( goalId: $goalId, status: $status, message: $message) {
        message,
        status,
        statusTimeline {
          status
          createdAt
        }
      }
}`;

const UPDATE_EXTEND_EVENT_STATUS_MUTATION = gql`
  mutation updateExtendEventStatus( $bidId: ID!, $status: String!, $duration: Int) {
    updateExtendEventStatus( bidId: $bidId, status: $status, duration: $duration) {
        message,
        data{
          extendMeeting{
            _id,
            status,
            duration,
            requestedId,
            role,
            isPaymentSuccessful,
            isExtendSuccessful,
          },
        }
    }
}`;
const FLAG_USER = gql`
  mutation flagUser( $bidId: ID!, $userId: String, $comment: String ) {
    flagUser( bidId: $bidId, userId: $userId, comment: $comment) {
        message,
        data {
          flaggedBy
        }
      }
}`;

const VALIDATE_ATTENDANCE = gql`
  mutation attendence( $bidId: ID!, $isExpertAttended: Boolean, $isExpertDeliver: Boolean, $comment: String, $isParticipantsAttended: Boolean, $isAbleToDeliver: Boolean ) {
    attendence( bidId: $bidId, isExpertAttended: $isExpertAttended, isExpertDeliver: $isExpertDeliver,
    comment: $comment, isParticipantsAttended: $isParticipantsAttended, isAbleToDeliver: $isAbleToDeliver) {
        message,
      }
}`;

const RATING_GOAL = gql`
  mutation rating( $bidId: ID!, $sessionRating: Int, $expertRating: Int, $comment: String, $overAllQuality: Int, $participantsEngagement: Int) {
    rating( bidId: $bidId, sessionRating: $sessionRating, expertRating: $expertRating, comment: $comment,
    overAllQuality: $overAllQuality, participantsEngagement: $participantsEngagement) {
        message,
      }
}`;

const CHANGE_GOAL_PARTICIPATION_MUTATION = gql`
  mutation updateParticipants(
    $isMe: Boolean,
    $goalId: String,
    $participants: [participantsInput],
    ) {
      updateParticipants(
        goalId: $goalId,
        participants: $participants,
        isMe: $isMe,
      ) {
        message
      }
}`;

const CANCEL_EVENT_MUTATION = gql`
  mutation cancelEvent(
    $goalId: String,
    $bidId: String,
    ) {
      cancelEvent(
        goalId: $goalId,
        bidId: $bidId,
      ) {
        message
      }
}`;

const UPDATE_COMPLETE_REQUEST_MUTATION = gql`
  mutation closeRequestOnGoal( $goalId: ID!, $saveGoal: Boolean!) {
    closeRequestOnGoal(goalId: $goalId, saveGoal: $saveGoal) {
        message
    }
}`;

const RESSHEDULE_BID = gql`
  mutation rescheduleBid($toDateRequested: Date!, $fromTime: Date!, $toTimeRequested: Date!, $bidId: ID!, $timezone: String) {
    rescheduleBid( toDateRequested: $toDateRequested, fromTime: $fromTime, toTimeRequested: $toTimeRequested, bidId: $bidId, timezone: $timezone) {
        message
      }
}
`;

export {
  CREATE_GOAL_MUTATION,
  UPDATE_GOAL_MUTATION,
  DELETE_GOAL_MUTATION,
  UPDATE_GOAL_STATUS_MUTATION,
  FLAG_USER,
  VALIDATE_ATTENDANCE,
  RATING_GOAL,
  UPDATE_EXTEND_EVENT_STATUS_MUTATION,
  UPDATE_GOAL_PARTICIPATION_MUTATION,
  CHANGE_GOAL_PARTICIPATION_MUTATION,
  CANCEL_EVENT_MUTATION,
  UPDATE_COMPLETE_REQUEST_MUTATION,
  RESSHEDULE_BID,
};
