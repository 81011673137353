import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Form,
  Row,
  FormGroup,
  Input,
  Label,
  Col,
} from 'reactstrap';
import InputError from './../../shared/formElements/InputError';
import { Country, City, State } from './../../shared/regionSelect';
import { pick } from 'lodash';
import { manualPaymentActions } from './redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { IBankInfo } from './../../../interfaces';

interface IProps {
  region: string;
  setEditBankInfoMode: (showEdit: boolean) => void;
}
const resetForm = (bankInfo: IBankInfo, reset: any) => {
  const { bankName, accountHolderName, accountNumber, branchCode, swiftNumber, routingNumber, other } = bankInfo.bankAccount;
  const { street1, street2, state , country, zipcode, city } = bankInfo.accountHolderAddress;

  reset({
    bankName,
    accountHolderName,
    accountNumber,
    branchCode,
    swiftNumber,
    routingNumber,
    zipcode,
    street1,
    street2,
    state: { label: state.label, value: state.value },
    country: { label: country.label, value: country.value },
    city: { label: city.label, value: city.value },
    other,
  });

};
function AccountForm({ region, setEditBankInfoMode }: IProps) {
  const {
    register, handleSubmit, errors, setValue, control, watch, reset,
  } = useForm({ mode: 'onBlur' });
  const dispatch = useDispatch();
  const bankInfo: IBankInfo = useSelector((state: any) => state.bankDetails.bankInfo);

  useEffect(() => {
    bankInfo.region && resetForm(bankInfo, reset);

  },        [bankInfo]);

  const getErrClass = (field: string) => {
    return errors[field] ? ' has-error' : '';
  };

  const onSubmit = (data) => {
    const bankAccount = pick(data, ['bankName', 'accountNumber', 'accountHolderName', 'routingNumber', 'branchCode', 'swiftNumber', 'other']);
    const accountHolderAddress = pick(data, ['street1', 'street2', 'zipcode', 'state', 'city', 'country']);
    setEditBankInfoMode(false);
    dispatch(manualPaymentActions.updateRegion({ bankAccount, accountHolderAddress, region  }));
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="profile-image-wrapper learner-profile-image w-100">
      <div className="payment-form-region-text-wrapper">
        <p> This region has not supported <span>Stripe</span>. Please enter your bank information for your further transactions</p>
        <p>Bank Account Information</p>
      </div>
      <div className="profile-fields flex-wrap payment-form-bank-detail-wrapper w-100">
        <Row>
          <Col xs="12" md="6">

            <FormGroup className={`floating-label ${getErrClass('bankName')}`}>
              <Input
                type="text"
                className="form-control"
                name="bankName"
                id="bankName"
                placeholder="Bank Name"
                innerRef={register({ required: true })}
              />
              <Label for="bankName">Bank Name</Label>
              <InputError error={errors.bankName} />
            </FormGroup>
          </Col>
          <Col xs="12" md="6">
            <FormGroup className={`floating-label ${getErrClass('accountNumber')}`}>
              <Input
                type="text"
                className="form-control"
                name="accountNumber"
                id="accountNumber"
                placeholder="Account Number"
                innerRef={register({ required: true })}
              />
              <Label for="accountNumber">Account Number</Label>
              <InputError error={errors.accountNumber} />
            </FormGroup>
          </Col>

        </Row>
        <Row />

        <Row>
          <Col xs="12" md="6">
            <FormGroup className={`floating-label ${getErrClass('accountHolderName')}`}>
              <Input
                type="text"
                className="form-control"
                name="accountHolderName"
                id="accountHolderName"
                placeholder="Account Holder Name"
                innerRef={register({ required: true })}
              />
              <Label for="accountHolderName">Account Holder Name</Label>
              <InputError error={errors.accountHolderName} />
            </FormGroup>
          </Col>
          <Col xs="12" md="6">
            <FormGroup className={`floating-label ${getErrClass('routingNumber')}`}>
              <Input
                type="text"
                className="form-control"
                name="routingNumber"
                id="routingNumber"
                placeholder="Routing No."
                innerRef={register({ required: true })}
              />
              <Label for="routingNumber">Routing No.</Label>
              <InputError error={errors.routingNumber} />
            </FormGroup>
          </Col>

        </Row>
        <Row>
          <Col xs="12" md="6">
            <FormGroup className={`floating-label ${getErrClass('branchCode')}`}>
              <Input
                type="text"
                className="form-control"
                name="branchCode"
                id="branchCode"
                placeholder="Branch Code"
                innerRef={register({ required: true })}
              />
              <Label for="branchCode">Branch Code</Label>
              <InputError error={errors.branchCode} />
            </FormGroup>
          </Col>
          <Col xs="12" md="6">
            <FormGroup className={`floating-label ${getErrClass('swiftNumber')}`}>
              <Input
                type="text"
                className="form-control"
                name="swiftNumber"
                id="swiftNumber"
                placeholder="Swift Code"
                innerRef={register({ required: true })}
              />
              <Label for="swiftNumber">Swift Code</Label>
              <InputError error={errors.swiftNumber} />
            </FormGroup>
          </Col>

        </Row>
        <Row> <Col xs="12" md="6"><p> Account Holder Address Information</p></Col></Row>
        <Row>
          <Col xs="12" md="6">
            <FormGroup className={`floating-label ${getErrClass('street1')}`}>
              <Input
                type="text"
                className="form-control"
                name="street1"
                id="street1"
                placeholder="Street 1"
                innerRef={register({ required: true })}
              />
              <Label for="street1">Street 1</Label>
              <InputError error={errors.street1} />
            </FormGroup>
          </Col>
          <Col xs="12" md="6">
            <FormGroup className={`floating-label ${getErrClass('street2')}`}>
              <Input
                type="text"
                className="form-control"
                name="street2"
                id="street2"
                placeholder="Street 2"
                innerRef={register({ required: true })}
              />
              <Label for="stree2">Street 2</Label>
              <InputError error={errors.street2} />
            </FormGroup>
          </Col>

        </Row>

        <Row>
          <Col xs="12" md="6">
            <FormGroup className={`floating-label disabled-input ${getErrClass('country')}`}>
              <Country control={control} setValue={setValue}/>
              <InputError error={errors.country} />
            </FormGroup>
          </Col>
          <Col xs="12" md="6">
            <FormGroup className={`floating-label disabled-input ${getErrClass('state')}`}>
              <State control={control} setValue={setValue} watch={watch} />
              <InputError error={errors.state} />
            </FormGroup>
          </Col>
          <Col xs="12" md="6">
            <FormGroup className={`floating-label disabled-input ${getErrClass('city')}`}>
              <City control={control} setValue={setValue} watch={watch} />
              <InputError error={errors.city} />
            </FormGroup>
          </Col>
          <Col xs="12" md="6">
            <FormGroup className={`floating-label ${getErrClass('zipcode')}`}>
              <Input
                type="text"
                className="form-control"
                name="zipcode"
                id="zipcode"
                placeholder="Zip Code"
                innerRef={register({ required: true })}
              />
              <Label for="zipcode">Zip Code</Label>
              <InputError error={errors.zipcode} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <FormGroup className={`floating-label disabled-input ${getErrClass('country')}`}>
            <Input
                type="text"
                className="form-control"
                name="other"
                id="other"
                placeholder="Intermediary Bank (Optional)"
                innerRef={register({ required: true })}
              />
              <Label for="other">Intermediary Bank (Optional)</Label>
              <InputError error={errors.other} />
            </FormGroup>
          </Col>
          </Row>
      </div>
      <div className="card-footer order-swipe-sm d-flex align-items-center justify-content-end">
        <Button
          type="submit"
          color="primary">
          Save
        </Button>
        <Button
          type="button"
          color="secondary"
          onClick={() => setEditBankInfoMode(false)}
          >
          Cancel
        </Button>
      </div>
    </Form>
  );
}

export default AccountForm;
