import _ from 'lodash';
import moment from 'moment';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Col,
  Row,
  Button,
  UncontrolledCollapse,
} from 'reactstrap';
import { bidAction } from '../redux/actions';
import profileImage from './../../../../assets/images/profile.png';
import { IParams, IGoalDetailWithId, ILocalUser } from './../../../../interfaces';
import OpenFieldTypes from 'OpenFieldTypes';
import {
  staticConstants, bidStatusText, bidStatusTimeline, bidStatus, pageRoutes, rescheduleRequest,
  cancelBid, checkAddOn, orgAdminAddOnMsg, learnerAddOnMsg, professionalAddOnMsg, capitalizeEveryWord,
  RESCHEDULE_STATUS, MAGIC_NUMBER
} from './../../../../utils';
import { Header } from '../../../shared/header';
import { Sidebar } from '../../../shared/sidebar';
import { MakeBid } from '../makeBid';
import { ErrorPopup } from '../../../shared/modals/errorPopup';
import { ReferBid } from '../referBid';
import { CancelBidPopup } from '../../../shared/modals/cancelBidPopup';
import { RescheduleResponsePopup } from '../../../shared/modals/rescheduleResponse';
import { SeeFullDetailPopup } from '../../../shared/modals/seeFullProfilePopup';
import { UnlockProfilePopup } from '../../../shared/modals/unlockProfilePopup';
import { history } from '../../../../helpers';
import { Back } from '../../../shared/back/back';

interface IProps {
  isOpenModal: boolean;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  match: {
    params: {
      [key: string]: IParams;
    };
  };
  bid: object;
  bids: any;
  bidDetails: IGoalDetailWithId;
  activeTab: any;
  location: any;
  user: ILocalUser;
  displayRSBPopup: boolean;
}

interface IState {
  displayErrorPopup: boolean;
  modal: boolean;
  bidDetails: IGoalDetailWithId;
  displayDeletePopup: boolean;
  bidId: string;
  reqObj: object;
  modalPopupObj: object;
  modalRSBPopupObj: object;
  displayRFPPopup: boolean;
  displayRSBPopup: boolean;
  isOpenBidModal: boolean;
  isOpenReferModal: boolean;
  displayCancelPopup: boolean;
  displaySeeFullDetailModal: boolean;
  displayUnlockProfilePopup: boolean;
  isOrgAdminProfile: boolean;
  arrowDownParticipant: boolean;
  isProfessionalProfile: boolean;
  bidButtonTxt: string;
}

class BidDetailContainer extends React.Component<IProps, IState> {

  public static getDerivedStateFromProps(props: IProps, state: IState) {
    let newState = null;
    if (props.bids && props.bids.bidDetails) {
      newState = {
        bidDetails: props.bids.bidDetails,
      };
      if (props.bids.bidDetails['rehireId']) {
        newState['bidButtonTxt'] = 'Accept';
      }
      if (props.bids.bidDetails.bid && props.bids.bidDetails.bid.length
        && props.bids.bidDetails.bid[0]['rescheduleRequestStatus'] === RESCHEDULE_STATUS.REQUESTED) {
        rescheduleRequest['toDateRequested'] = props.bids.bidDetails.bid[0]['toDateRequested'];
        rescheduleRequest['toTimeRequested'] = props.bids.bidDetails.bid[0]['toTimeRequested'];
        rescheduleRequest['fromTime'] = props.bids.bidDetails.bid[0]['fromTime'];
        newState['modalRSBPopupObj'] = rescheduleRequest;
        newState['displayRSBPopup'] = true;
      }
    }
    if (props.bids && props.bids.isOpenBidModal !== undefined &&
      props.bids.isOpenBidModal !== state.isOpenBidModal) {
      newState['isOpenBidModal'] = props.bids.isOpenBidModal;
    }
    return newState;
  }

  constructor(props: IProps) {
    super(props);

    this.state = {
      displayErrorPopup: false,
      modal: false,
      displayRSBPopup: false,
      bidDetails: {
        _id: '',
        participants: [],
        createdAt: '',
        fromDate: '',
        toDate: '',
        fromTime: '',
        toTime: '',
        title: '',
        eventType: '',
        modeOfDevivery: '',
        description: '',
        topic: {
          name: '',
          id: '',
        },
        domain: {
          name: '',
          id: '',
        },
        subDomain: {
          name: '',
          id: '',
        },
        category: {
          name: '',
          id: '',
        },
        subCategory: {
          name: '',
          id: '',
        },
        activity: {
          name: '',
          id: '',
        },
        duration: '',
        isMe: false,
        status: '',
        isReviewTags: false,
        rehireId: '',
      },
      displayDeletePopup: false,
      displayRFPPopup: false,
      bidId: '',
      reqObj: {
        redirect: true,
      },
      modalPopupObj: {},
      modalRSBPopupObj: {},
      isOpenBidModal: false,
      isOpenReferModal: false,
      displayCancelPopup: false,
      displaySeeFullDetailModal: false,
      displayUnlockProfilePopup: false,
      isOrgAdminProfile: false,
      isProfessionalProfile: false,
      arrowDownParticipant: false,
      bidButtonTxt: 'Bid',
    };
  }

  public buildRescheduleMsg(bid) {
    const pdate = moment(bid[0]['proposedDate']).format(staticConstants.DATE_FORMAT);
    const ptime = moment(bid[0]['proposedTime']).format(staticConstants.TIME_FORMAT);
    return `You got a reschedule request  for this bid at ${pdate} ${ptime}`;
  }

  /**
   * @description componentDidMount is called when component load first time.
   *  called getBidsDetail to get the detail of the bid
   */
  public componentDidMount() {
    const { match: { params } } = this.props;
    if (params.id) {
      this.props.dispatch(bidAction.getBidDetail({ id: String(params.id) }));
    }
  }

  /**
   * @description
   * isAdmin is used to check participant is added as
   */
  public isAdmin(participants: any, participantId: any) {
    let isAdmin = false;
    for (const participant of participants) {
      if (participantId === participant['userId'] && participant.isAdmin) {
        isAdmin = true;
        break;
      }
    }
    return isAdmin;
  }

  /**
   * @description function to render participants list for bid
   */
  public renderParticipantsList = () => {
    const { participants, participantsRaw } = this.state.bidDetails;
    const participantsDisplayList = _.map(participants, (item: any) => {
      const isAdmin = this.isAdmin(participantsRaw, item._id);
      return {
        isAdmin, firstName: item.firstName, lastName: item.lastName, _id: item._id,
        profileImage: item.profileImage, organizationId: item.organizationId, email: item.email,
        occupation: item.occupation, role: item.role,
      };
    });
    participantsDisplayList.sort((a, b) => a.isAdmin ? -1 : MAGIC_NUMBER.ONE);
    return _.map(participantsDisplayList, (item: any, index: number) => {
      const fetchedProfileImage = item && item.profileImage !== '' ?
        item.profileImage : profileImage;
      const { firstName, lastName, occupation = '', email = '' } = item;
      return item && (
        <div className={`tag-item footer-add ${!(item.organizationId) && item['role'] !== staticConstants.ROLE.PROFESSIONAL ? 'green-bg' : ''}`}
          key={`tag-${item._id}`}>
          <div className="tag-card-data">
            <h4>{checkAddOn() && firstName ? capitalizeEveryWord(`${firstName} ${lastName}`) :
              item['role'] === staticConstants.ROLE.PROFESSIONAL ? `Individual` : `Participant ${index + 1}`}</h4>
            {occupation && <p>{occupation}</p>}
            <p>{checkAddOn() ? email : ''}</p>
          </div>
          <span>
            <img className="img-cover" src={fetchedProfileImage || profileImage} alt="" />
          </span>
          <div className="tag-item-footer">
            <p onClick={() => this.showSeeFullDetailPopup(item, index)}>
              <span className="icon icon-visible" /> View Details
            </p>
          </div>
        </div>
      );
    });
  }

  /**
   * @description showSeeFullDetailPopup is used show showSeeFullDetailPopup.
   * @param {object} userDetail contain detail of selected user
   * @param {number} index contain selected user index value
   */
  public showSeeFullDetailPopup = (userDetail: object, index: number) => {

    userDetail['index'] = index;

    if (checkAddOn()) {
      let pathName = null;
      if (this.props.user.role !== staticConstants.ROLE.SYSTEM_ADMIN) {
        pathName = !userDetail['organizationId'] ? `${pageRoutes.VIEW_PROFILE_ORGADMIN.PATH}/${userDetail['_id']}/${userDetail['_id']}/complete` :
          `${pageRoutes.VIEW_PROFILE_LEARNER.PATH}/${userDetail['_id']}/${userDetail['_id']}/complete`;
      } else {
        pathName = !userDetail['organizationId'] ?
          `${pageRoutes.VIEW_PROFILE_ORGADMIN.PATH}/${userDetail['_id']}` : `/${pageRoutes.VIEW_PROFILE_LEARNER.PATH}/${userDetail['_id']}`;
      }
      history.push({
        pathname: `${pathName}`,
        state: {
          isBidManagement: true,
          userRole: userDetail['role'],
        },
      });
    } else {
      this.addOnPopup();
    }

  }

  public addOnPopup = () => {
    const modalPopupObj = this.state.isProfessionalProfile ? professionalAddOnMsg : this.state.isOrgAdminProfile ? orgAdminAddOnMsg : learnerAddOnMsg;
    this.setState(
      {
        displaySeeFullDetailModal: false,
        displayUnlockProfilePopup: true,
        modalPopupObj,
      });
  }

  public addToFavourite = (isFavourite: boolean) => {
    const { match: { params } } = this.props;
    const bidId = String(params.id);
    this.props.dispatch(bidAction.addToFavourite({
      isFavourite,
      bidId,
      isFromDetailPage: true,
    }));
  }
  /**
   * @description
   * open bid modal
   */
  public openMakeBidModal = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!Boolean(user.isStripeAccount)) {
      this.setState({ displayErrorPopup: true });
    } else {
      this.setState({
        ...this.state,
        isOpenBidModal: true,
      });
    }
  }

  /**
   * @description
   * open refer bid modal
   */
  public openReferBidModal = () => {
    this.setState({
      ...this.state,
      isOpenReferModal: true,
    });
  }

  /**
   * @description
   * showCancelPopup is used show confirmation popup.
   * @param fields {String} id
   */
  public showCancelPopup = (id: string) => {
    const modalPopupObj = cancelBid;
    this.setState({
      displayCancelPopup: true,
      bidId: id,
      modalPopupObj,
    });
  }

  /**
   * @description
   * cancelRequest is called when someone click on the ok button
   */
  public cancelRequest = () => {
    const { bidId } = this.state;
    this.props.dispatch(bidAction.cancelBid({ bidId }));
    this.setState({
      displayCancelPopup: false,
      modalPopupObj: {},
      bidId: '',
    });
  }

  public purchaseAddon = () => {
    this.setState({ displayUnlockProfilePopup: false });
    const { activeTab } = this.props['location']['state'];
    history.push({
      pathname: `${pageRoutes.PAYMENT.PATH}${pageRoutes.PAYMENT.SUBSCRIPTION}`,
      state: {
        breadcrumb: [
          { title: 'Bid Management', link: `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}` },
          { title: 'New Request', link: `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}` },
          {
            title: this.state.bidDetails.title,
            link: `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.DETAIL}/${this.state.bidDetails._id}`, state: { activeTab },
          },
          { title: 'PURCHASE ADD-ON', link: null },
        ],
        redirectUri: `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.DETAIL}/${this.state.bidDetails._id}`,
        action: 'expert-purchase-addon',
        originUri: this.props['location']['pathname'],
        showSidebarNav: true,
        activeTab,
      },
    });
  }

  public rejectRescheduleRequest = (data: any) => {
    const { bidDetails } = this.state;
    const selStatus = data ? RESCHEDULE_STATUS.APPROVED : RESCHEDULE_STATUS.REJECTED;
    this.setState({
      modalRSBPopupObj: {},
      displayRSBPopup: false,
    });
    if (data) {
      const tmeRequested = new Date(moment(data.toTimeRequested).hours(moment(data.toTimeRequested).hours()).minutes(moment(data.toTimeRequested).minutes()).format());
      this.props.dispatch(bidAction.rescheduleRequest({ bidId: String(bidDetails.bid[0]['_id']), status: selStatus, toTimeRequested: tmeRequested }));
    }
  }

  /**
   * @description function to get created date of bid
   */
  public getCreatedDate = (bidDetails: any) => {
    if (!bidDetails.statusTimeline) { return null; }
    const timeLine = bidDetails.statusTimeline.find(el => el.status === bidStatus.RFPREQUEST);
    return timeLine ? moment(timeLine.createdAt).format(staticConstants.DATE_FORMAT) : null;
  };

  /**
   * @description function to get formated date of bid
   */
  public getRequestSentDate = (timeLine) => {
    if (timeLine && timeLine.status === bidStatus.SCHEDULED) {
      const date = moment(timeLine.createdAt).format(staticConstants.DATE_FORMAT);
      const time = moment(timeLine.createdAt).format(staticConstants.TIME_FORMAT);
      return `${date}, at ${time}`;
    }
    return null;
  }

  /**
   * @description function to render al the bid timeline
   */
  public renderTimeline = (bidDetails: any, bid: any) => {
    return (
      <React.Fragment>
        <h2 className="heading roboto-medium">
          {bid && bid.length > MAGIC_NUMBER.ZERO ?
            `Request Proposed`
            : `Request Created`
          }
        </h2>
        <div className="timeline-row created-row">
          <small>Created</small>
          <p>{this.getCreatedDate(bidDetails)}</p>
          <span className="icon time-icon" />
        </div>
        {this.renderBidTimeline(bid)}
      </React.Fragment>
    );
  }

  public renderBidTimeline = (bid: any) => {
    const statusTimeline = (bid && bid.length > MAGIC_NUMBER.ZERO) ? bid[0].statusTimeline : [];
    return _.map(bidStatusTimeline, (elem) => {
      const currStatus = _.find(statusTimeline, (timeline) => {
        return timeline.status === elem;
      });
      const icon = (elem === bidStatus.SCHEDULED) ? 'play-icon' : 'check-icon';
      const classValue = (currStatus ?
        ((elem === bidStatus.ACCEPTED) ? 'active' : 'active selected')
        : '');
      return (
        <div className={`timeline-row ${classValue}`}>
          <small>{bidStatusText[elem]}</small>
          {currStatus ?
            <p>{this.getRequestSentDate(currStatus)}</p>
            : ''}
          <span className={`icon ${icon}`} />
        </div>
      );
    });
  }

  public getBidDetails = (bid: any) => {
    return (<div className="participant-wrapper box-shadow white-bg mt-4 mb-0">
      <h2 className="heading heading-rg roboto-medium collapse-header">
        Bid Information</h2>
      <div className="participant-card-wrapper proposed-card-wrapper form-details m-0">
        <Row className="w-100 form-data">
          <Col xs="12" md="3">
            <span>Proposed Date</span>
            <p>{moment(bid[0]['proposedDate']).format(staticConstants.DATE_FORMAT)} </p>
          </Col>
          <Col xs="12" md="3">
            <span>Proposed Time</span>
            <p>{moment(bid[0]['proposedTime']).format(staticConstants.TIME_FORMAT)}</p>
          </Col>
          <Col xs="12" md="3" />
          <Col xs="12" className="mb-0">
            <span>Proposal</span>
            <p>{bid[0]['bidDetails']}</p>
          </Col>
        </Row>
      </div>
    </div>);
  }

  public getFavourite = (bidDetails, activeTab) => {
    return (<>
      {activeTab !== staticConstants.BID_MANAGEMENT_TAB.SENT_BIDS && activeTab !== staticConstants.BID_MANAGEMENT_TAB.OPEN_REQUEST &&
        bidDetails.isFavourite ? <Button className="btn btn-icon danger" onClick={() => this.addToFavourite(false)}><span className="icon icon-unsave" />Unsave Request</Button>
          : <Button className="btn btn-icon" onClick={() => this.addToFavourite(true)}><span className="icon icon-save" />Save Request</Button>
      }</>);
  }

  public getSystemAdmin = (activeTab: any, bidDetails: any, bid: any, bidButtonTxt: string) => {
    return (<div className="card-footer d-flex mt-0">
      {this.getFavourite(bidDetails, activeTab)}
      
      {activeTab === staticConstants.BID_MANAGEMENT_TAB.OPEN_REQUEST &&
        <Link to={`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.EDIT_PROFILE.PATH}?isOpenRequest=true&bidId=${bidDetails._id}`} className="btn btn-primary ml-auto">
          Update Profile Tags
                </Link>
      }

      {(activeTab !== staticConstants.BID_MANAGEMENT_TAB.OPEN_REQUEST && bid) &&
        <div className="ml-auto">
          {activeTab !== staticConstants.BID_MANAGEMENT_TAB.OPEN_REQUEST ?
            <Button color="primary" className="btn mr-2 d-inline-flex align-items-center" onClick={() => this.openReferBidModal()}>
              <em className="icon icon-send font-size-increase" />
                      Refer an Expert </Button>
            : ''}
          {!bid.length && activeTab !== staticConstants.BID_MANAGEMENT_TAB.OPEN_REQUEST ?
            <Button color="primary" className="btn" onClick={() => this.openMakeBidModal()}>
              {bidButtonTxt} </Button>
            : ''}
          {bid.length > MAGIC_NUMBER.ZERO && bid[0].status === bidStatus.PROPOSED && (
            <Button color="icon" className="icon danger ml-2" onClick={() => this.showCancelPopup(bid[0]._id)}>
              <em className="icon icon-cross danger" />
                  Cancel Bid
            </Button>
          )}
        </div>
      }
    </div>);
  }

  public render() {
    const { bidDetails, displayCancelPopup, modalPopupObj, displaySeeFullDetailModal, displayRSBPopup, modalRSBPopupObj,
      displayUnlockProfilePopup, arrowDownParticipant, isOpenBidModal, isOpenReferModal, bidButtonTxt } = this.state;

    const { activeTab = null } = this.props['location']['state'] || {};
    const fromDate = moment(bidDetails.fromDate).format(staticConstants.DATE_FORMAT);
    const toDate = moment(bidDetails.toDate).format(staticConstants.DATE_FORMAT);
    const fromTime = moment(bidDetails.fromTime).format(staticConstants.TIME_FORMAT);
    const toTime = moment(bidDetails.toTime).format(staticConstants.TIME_FORMAT);
    const { bid } = bidDetails;
    const { user: { role } } = this.props;
    const tags = [];
    if (bidDetails) {
      const { domain, subDomain, category, subCategory, activity } = bidDetails;
      const tagDetails = { domain, subDomain, category, subCategory, activity };
      Object.values(tagDetails).forEach(tag => tag && tags.push(tag));
    }
    

    return bidDetails && (
      <React.Fragment>
        <Header />
        <Sidebar  {...this.props} />
        <div className="dashboard-wrapper">
          <Back {...this.props} />
          <div className="dashboard-content shadow mb-0">
            <div className="learning-goal-wrapper">
              <h2 className="heading heading-rg roboto-medium d-inline-block heading-border">
                {bidDetails.eventType}
              </h2>
              <div className="goal-view-wrapper">
                <h2 className="heading heading-lg roboto-medium">{bidDetails.title} <span>
                  {bidDetails.modeOfDevivery}</span>
                </h2>
                {bidDetails.topic && <em>({bidDetails.topic.name})</em>}
                <p>{bidDetails.description}</p>
                <div className="form-details">
                  <Row>
                    <Col md="12" lg="9">
                      <div className="form-data">
                        <Row>
                          <Col xs="12" md="4">
                            <span>Domain</span>
                            <p>{bidDetails.domain.name}</p>
                          </Col>
                          <Col xs="12" md="4">
                            <span>Sub-Domain</span>
                            <p>{bidDetails.subDomain.name}</p>
                          </Col>
                          {bidDetails.category && <Col xs="12" md="4">
                            <span>Category</span>
                            <p>{bidDetails.category.name}</p>
                          </Col>}
                          {bidDetails.subCategory &&  <Col xs="12" md="4">
                            <span>Sub-Category</span>
                            <p>{bidDetails.subCategory.name}</p>
                          </Col>}
                          {bidDetails.activity && <Col xs="12" md="4">
                            <span>Activity Code</span>
                            <p>{bidDetails.activity.name}</p>
                          </Col>}
                          <Col xs="12" md="4">
                            <span>No. of Participants</span>
                            <p>{bidDetails.participants.length}</p>
                          </Col>
                          </Row>
                          <Row>
                          <Col xs="12" md="4">
                            <span>Duration</span>
                            <p>{bidDetails.duration} {Number(bidDetails.duration) > MAGIC_NUMBER.ONE ?
                              `hrs` : `hr`}</p>
                          </Col>
                          <Col xs="12" md="4">
                            <span>Date Availability</span>
                            <p>{fromDate} - {toDate}</p>
                          </Col>
                          <Col xs="12" md="4">
                            <span>Time Availability</span>
                            <p>{fromTime} - {toTime}</p>
                          </Col>
                          <Col xs="12">
                            <span>Tags</span>
                            <div className="w-100">
                              {tags.map(tag =>
                                <span key={tag['value']} className="tags-view-only">{tag['name']}</span>)}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md="12" lg="3">
                      <div className="timeline-view">
                        {this.renderTimeline(bidDetails, bid)}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            {role !== staticConstants.ROLE.SYSTEM_ADMIN && this.getSystemAdmin(activeTab, bidDetails, bid, bidButtonTxt) }
          </div>
          {bid && bid.length > MAGIC_NUMBER.ZERO ?  this.getBidDetails(bid) : ''}
          <div className="participant-wrapper box-shadow white-bg mt-4 mb-0">
            <h2 className="heading heading-rg roboto-medium collapse-header cursor-pointer" id="Participant_toggler"
              onClick={() => this.setState({ arrowDownParticipant: !arrowDownParticipant })}
              onTouchStart={() => this.setState({ arrowDownParticipant: !arrowDownParticipant })}
            >
              Participants
              <span className={`icon icon-arrow-down-sign-to-navigate ${arrowDownParticipant ? 'up' : ''}`} />
            </h2>
            <UncontrolledCollapse toggler="#Participant_toggler" isOpen={arrowDownParticipant}>
              <div className="collapse-body">
                <div className="participant-card-wrapper">
                  {this.renderParticipantsList()}
                </div>
              </div>
            </UncontrolledCollapse>
          </div>
        </div>
        {
          <MakeBid isOpenModal={isOpenBidModal} goal={bidDetails}
            reqObj={null} fromDetail={true} hideModal={() => {
              this.setState({
                isOpenBidModal: false,
              });
            }} />
        }
        {
          isOpenReferModal &&
          <ReferBid goalId={bidDetails._id} isOpenModal={isOpenReferModal} hideModal={() => {
            this.setState({
              isOpenReferModal: false,
            });
          }} />
        }
        {
          displayCancelPopup &&
          <CancelBidPopup isOpenModal={displayCancelPopup} modalPopupObj={modalPopupObj}
            hideModal={() => this.setState({ displayCancelPopup: false })} onClickYes={this.cancelRequest}
          />
        }

        {
          displaySeeFullDetailModal ?
            <SeeFullDetailPopup isOpenModal={displaySeeFullDetailModal}
              modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displaySeeFullDetailModal: false })}
              addOnPopup={() => this.addOnPopup()}
            />
            : ''
        }
        {
          displayUnlockProfilePopup ?
            <UnlockProfilePopup isOpenModal={displayUnlockProfilePopup}
              onClickYes={this.purchaseAddon} modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displayUnlockProfilePopup: false })} />
            : ''
        }
        {displayRSBPopup && (
          <RescheduleResponsePopup
            isOpenModal={displayRSBPopup}
            modalPopupObj={modalRSBPopupObj}
            hideModal={() => this.rejectRescheduleRequest(false)}
            onClickYes={data => this.rejectRescheduleRequest(data)}
          />
        )}
        { this.state.displayErrorPopup &&
          <ErrorPopup
            isOpenModal={true}
            hideModal={() => this.setState({ displayErrorPopup: false })}
            connectStripe={() => {
              this.setState({ displayErrorPopup: false });
              history.push(`/${role.toLowerCase()}/viewProfile/#3`);
            }}
          />
        }
      </React.Fragment >
    );
  }
}

/**
 * @description function to map data state to props
 * @param {object} state object object value
 */
function mapStateToProps(state) {
  return {
    bids: state.bids,
    displayRSBPopup: state.bids.displayRSBPopup,
    user: state.authentication.user,
  };
}

const mapBidDetailContainer = connect(mapStateToProps)(BidDetailContainer);
export { mapBidDetailContainer as BidDetailContainer };
