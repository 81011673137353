import gql from 'graphql-tag';

const GET_GOAL_LIST_QUERY = gql`
  query goals($page: Int, $limit: Int, $title: String, $eventType: String, $goalStatus: String,
    $category: String, $status: String, $sort: String, $sortBy: Int, $isFetchGoalByAdmin: Boolean,
    $isRequestManagement: Boolean, $isScheduleManagement: Boolean, $isPast: Boolean, $userId: String,
    $filter: String, $columnHeaders:[String], $columnKeys: [String], $isGenerateReport: Boolean, $reportTitle: String, $domain: String ) {
    goals (
      page: $page,
      limit: $limit,
      title: $title,
      eventType: $eventType,
      category: $category,
      status: $status,
      sort: $sort,
      sortBy: $sortBy,
      goalStatus: $goalStatus,
      isFetchGoalByAdmin: $isFetchGoalByAdmin,
      isRequestManagement: $isRequestManagement,
      isScheduleManagement: $isScheduleManagement,
      isPast: $isPast,
      userId: $userId,
      filter: $filter,
      columnHeaders: $columnHeaders, columnKeys: $columnKeys,
      isGenerateReport: $isGenerateReport, reportTitle: $reportTitle,
      domain: $domain
    ) {
        message,
        date,
        count,
        url,
        reportTitle,
        data {
          _id,
          title,
          modeOfDevivery,
          eventType,
          domain{
            name,
            id
          },
          subDomain{
            name,
            id
          },
          category{
            name,
            id
          },
          subCategory{
            name,
            id
          },
          activity{
            name,
            id
          },
          topic{
            name,
            id
          },
          requestedAdmin {
            firstName
            lastName
            role
          },
          duration,
          fromDate,
          toDate,
          toTime,
          fromTime,
          isDispute,
          description,
          participants{
            firstName
            lastName
            profileImage
            _id
            organizationId,
            role,
          },
          statusTimeline{
            status,
            createdAt,
          },
          isMe,
          createdAt,
          status,
          userId {
            firstName,
            lastName,
            email,
            role,
            organizationName,
          },
          acceptedBidder {
            firstName,
            lastName
          },
          bid {
            statusTimeline{
              status,
              createdAt,
            },
            proposedDate,
            proposedTime,
            bidDetails,
            zoomEvent{
              meetingId,
              startUrl,
              joinUrl,
              isEnded,
            }
            isPast,
            sessionFee,
            expertEarn,
            commission,
            duration,
          },
          bidCount,

          goalAdmin {
            firstName
            lastName,
            organizationName,
          },
          isAcceptBidClosed
        }
    }
}`;

const GET_SCHEDULED_GOAL_LIST_QUERY = gql`
  query scheduleGoals($page: Int, $bidId: String, $limit: Int, $title: String, $eventType: String, $goalStatus: String,
    $category: String, $domain: String, $status: String, $sort: String, $sortBy: Int, $isFetchGoalByAdmin: Boolean,
    $isRequestManagement: Boolean, $isScheduleManagement: Boolean, $isPast: Boolean, $userId: String,
    $filter: String, $columnHeaders:[String], $columnKeys: [String], $isGenerateReport: Boolean, $reportTitle: String) {
      scheduleGoals (
      bidId: $bidId,
      page: $page,
      limit: $limit,
      title: $title,
      eventType: $eventType,
      category: $category,
      domain: $domain,
      status: $status,
      sort: $sort,
      sortBy: $sortBy,
      goalStatus: $goalStatus,
      isFetchGoalByAdmin: $isFetchGoalByAdmin,
      isRequestManagement: $isRequestManagement,
      isScheduleManagement: $isScheduleManagement,
      isPast: $isPast,
      userId: $userId,
      filter: $filter,
      columnHeaders: $columnHeaders, columnKeys: $columnKeys,
      isGenerateReport: $isGenerateReport, reportTitle: $reportTitle
    ) {
        message,
        date,
        count,
        url,
        reportTitle,
        data {
          _id,
          title,
          modeOfDevivery,
          eventType,
          domain{
            name,
            id
          },
          subDomain{
            name,
            id
          },
          category{
            name,
            id
          },
          subCategory{
            name,
            id
          },
          activity{
            name,
            id
          },
          topic{
            name,
            id
          },
          requestedAdmin {
            firstName
            lastName
            role
          },
          duration,
          fromDate,
          toDate,
          toTime,
          fromTime,
          isDispute,
          description,
          participants{
            firstName
            lastName
            profileImage
            _id
            organizationId,
            role,
          },
          statusTimeline{
            status,
            createdAt,
          },
          isMe,
          createdAt,
          status,
          userId {
            firstName,
            lastName,
            email,
            role,
            organizationName,
          },
          bid {
            status,
            isEnded,
            endedAt,
            _id
            statusTimeline{
              status,
              createdAt,
            },
            proposedDate,
            proposedTime,
            bidDetails,
            zoomEvent{
              meetingId,
              startUrl,
              joinUrl,
              isEnded,
            }
            sessionFee,
            expertEarn,
            commission,
            duration,
            isPast,
            participants{
            firstName
            lastName
            profileImage
            _id
            organizationId
            email,
            role,
          },
          participantsRaw {
            userId,
            isAdmin,
            comment
            isFlagged
          },
           payment {
            type,
            isPaymentSuccessful,
            bidId,
            extendEventId,
          },
          extendMeeting{
            _id,
            status,
            duration,
            requestedId,
            role,
            isPaymentSuccessful
          },
          adminRatingGoal{
            sessionRating,
            expertRating,
            comment,
            createdAt,
          },
          expertRatingGoal{
            overAllQuality,
            participantsEngagement,
            comment,
            createdAt,
          },
          adminAttendence{
            isExpertAttended,
            isExpertDeliver,
            comment,
          },
          expertAttendence{
            isParticipantsAttended,
            isAbleToDeliver,
            comment,
          },
          expertInfo {
              firstName,
              lastName,
              profileImage,
              _id
              bio,
              address,
              location,
              averageRating,
              averageSessionRating,
              hourlyRate{
                current
              }
              tags {
                _id,
                type
                name
              },
            },
            flag{
              userId,
              _id,
              isFlagged,
              comment,
            },
          },
          bidCount,
          goalAdmin {
            firstName
            lastName,
            organizationName,
          },
          isAcceptBidClosed,
        }
    }
}`;

const GET_GOAL_DETAILS_QUERY = gql`
  query goalDetails($id: String!,$isRequestManagement: Boolean, $isScheduleManagement: Boolean, $isPast: Boolean ) {
    goal(goalId: $id,isRequestManagement: $isRequestManagement, isScheduleManagement: $isScheduleManagement, isPast: $isPast) {
        message,
        date,
        isCreater,
        data {
          _id,
          bidderId,
          title,
          modeOfDevivery,
          isReviewTags,
          eventType,
          domain{
            name,
            id
          },
          subDomain{
            name,
            id
          },
          category{
            name,
            id
          },
          subCategory{
            name,
            id
          },
          activity{
            name,
            id
          },
          topic{
            name,
            id
          },
          duration,
          fromDate,
          isDispute,
          toDate,
          toTime,
          fromTime,
          description,
          participants{
            firstName
            lastName
            profileImage
            _id
            organizationId
            email,
            role,
          },
          participantsRaw {
            userId,
            isAdmin,
            comment
            isFlagged
          },
          isMe,
          createdAt,
          statusTimeline{
            status,
            createdAt,
          },
          status
          orgId,
          bidCount,
          bid {
            isEnded,
            proposedAt,
            toDateRequested,
            toTimeRequested,
            rescheduleRequestStatus,
            statusTimeline{
              status,
              createdAt,
            },
            payment {
            type,
            isPaymentSuccessful,
            bidId,
            extendEventId
            },
            proposedDate,
            proposedTime,
            bidDetails,
            _id,
            status,
            isShortlisted,
            userId {
              firstName,
              lastName,
              profileImage,
              address,
              location,
              _id
              bio,
              averageRating,
              averageSessionRating,
              hourlyRate{
                current
              }
              tags {
                _id,
                type
                name
              },
            },
            zoomEvent{
              meetingId,
              startUrl,
              joinUrl,
              isEnded,
            },
            flag{
              userId,
              _id,
              isFlagged,
              comment,
            },
          },
          userId {
            firstName,
            lastName,
            organizationName,
          },

          goalAdmin {
            firstName
            lastName,
            organizationName,
          },
          rejectedReason,
          isAcceptBidClosed
        },
    }
}
`;

const GET_SESSION_FEE_QUERY = gql`
  query getSessionFee($bidId: String!, $type: String, $duration: Int ) {
    getSessionFee(bidId: $bidId, type: $type, duration: $duration) {
        message,
        data {
          amount,
          commission,
        },
    }
}
`;

const GET_PAYMENT_LOGS = gql`
  query paymentLogs($filter: String!) {
    paymentLogs(filter: $filter) {
        message,
        data {
          goalData{
            title,
            sessionFee,
            _id,
          },
          commission,
        },
    }
}
`;

const GET_SESSION_DOWNLOAD_URL = gql`
  query downloadSession($bidId: String!) {
    downloadSession(bidId: $bidId) {
        message,
        data {
          download_url,
          play_url,
          file_size,
          file_type,
        },
    }
}
`;
export {
  GET_GOAL_LIST_QUERY,
  GET_GOAL_DETAILS_QUERY,
  GET_SESSION_FEE_QUERY,
  GET_PAYMENT_LOGS,
  GET_SESSION_DOWNLOAD_URL,
  GET_SCHEDULED_GOAL_LIST_QUERY,
};
