import React, { Dispatch } from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { connect } from 'react-redux';
import Linkify from 'react-linkify';
import classnames from 'classnames';
import { contentManagementAction } from '../../../systemAdmin/contentManagement/redux/actions';
import { staticConstants, faqTypesConst } from '../../../../utils';
import emailImage from './../../../../assets/images/email_2.png';
import { LandingHeader } from '../../landingHeader';
import { LandingFooter } from '../../landingFooter';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  content: Array<[]>;
}


interface IState {
  activeTab: any;
  title: string;
  accordian: any;
}

class Faq extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      title: staticConstants.LANDING_PAGE_FOOTER_TITLE.FAQ,
      activeTab: '1',
      accordian: {
        individual: [],
        team: [],
        expert: []
      }
    };
  }

  public componentDidMount() {
    this.props.dispatch(contentManagementAction.getContent({
      type: staticConstants.CONTENT_MANAGEMENT_TAB.FAQ,
    }));
  }

  public toggle = (tab: any) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  public accordianToggle(id: string, key: string) {
    const { accordian } = this.state;
    const dataIndex  = accordian[key].indexOf(id);
    if(dataIndex !== -1) {
      accordian[key].splice(dataIndex, 1);
    } else {
      accordian[key].push(id);
    }
    this.setState({ accordian });
  }

  public render() {
    const { title, activeTab, accordian } = this.state;
    const { content } = this.props;
    let individualFAQs = [];
    let teamFAQs = [];
    let expertFAQs = [];
    if (content && content.length) {
      individualFAQs = content.filter(item => item['faqType'] === faqTypesConst.INDIVIDUAL);
      teamFAQs = content.filter(item => item['faqType'] === faqTypesConst.TEAM);
      expertFAQs = content.filter(item => item['faqType'] === faqTypesConst.EXPERT);
    }
    const actieClassCss = 'active show';
    return (
      <>
        <LandingHeader title={title} banner={'faq_banner'} />
        <div className="common_padding pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="faq_tab">
                  <ul className="nav nav-tabs justify-content-center">
                    <li className={classnames({ active: activeTab === '1' })} >
                      <a href="javascript:void(0)"
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }} >Individual </a>
                    </li>
                    <li className={`ml-lg-5 mr-lg-5 ml-3 mr-3 ${classnames({ active: activeTab === '2' })}`}>
                      <a href="javascript:void(0)"
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { this.toggle('2'); }} >Team</a>
                    </li>
                    <li className={classnames({ active: activeTab === '3' })} >
                      <a href="javascript:void(0)"
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => { this.toggle('3'); }} >Expert</a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className={`tab-pane container fade ${activeTab === '1' ? actieClassCss : ''}`} >
                      {individualFAQs.map((data: any) => {
                        return (
                          <>
                            <div className="faq_colaps">
                              <div className="card-link" onClick={() => this.accordianToggle(data['_id'], 'individual')} >
                                {data.question} <span className={`fa fa-angle-down ${accordian.individual.indexOf(data['_id']) !== -1 ? 'rotate': ''}`} />
                              </div>
                              <div className={`collapse ${accordian.individual.indexOf(data['_id']) !== -1 ? 'show': ''}`}>
                                <div className="card-body">
                                  <Linkify properties={{target: '_blank'}} >{data.answer}</Linkify>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className={`tab-pane container fade ${activeTab === '2' ? actieClassCss : ''}`} >
                      {teamFAQs.map((data: any) => {
                        return (
                          <>
                            <div className="faq_colaps">
                              <div className="card-link" onClick={() => this.accordianToggle(data['_id'], 'team')} >
                                {data.question} <span className={`fa fa-angle-down ${accordian.team.indexOf(data['_id']) !== -1 ? 'rotate': ''}`} />
                              </div>
                              <div className={`collapse ${accordian.team.indexOf(data['_id']) !== -1 ? 'show': ''}`}>
                                <div className="card-body">
                                  <Linkify properties={{target: '_blank'}} >{data.answer}</Linkify>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className={`tab-pane container fade ${activeTab === '3' ? actieClassCss : ''}`} >
                      {expertFAQs.map((data: any) => {
                        return (
                          <>
                            <div className="faq_colaps">
                              <div className="card-link" onClick={() => this.accordianToggle(data['_id'], 'expert')}>
                                {data.question} <span className={`fa fa-angle-down ${accordian.expert.indexOf(data['_id']) !== -1 ? 'rotate': ''}`} />
                              </div>
                              <div className={`collapse ${accordian.expert.indexOf(data['_id']) !== -1 ? 'show': ''}`}>
                                <div className="card-body">
                                  <Linkify properties={{target: '_blank'}} >{data.answer}</Linkify>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 text-center mt-lg-5 mb-lg-5 mt-3 mb-3">
                <h2 className="common_heading mb-2">Still have questions?</h2>
                <p className="common_p">If you cannot find your question or answer in our FAQs, you can always contact us. We will get back to you as soon as we can.</p>
              </div>
              <div className="col-12">
                <div className="row justify-content-center">
                  <div className="col-lg-6 col-md-9 cols-m-12">
                    <div className="faq_email">
                      <img src={emailImage} alt="email" />
                      <a href="mailto:contact@ovlp.app"><h2 className="common_heading">contact@ovlp.app</h2></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LandingFooter activeTab={staticConstants.CONTENT_MANAGEMENT_TAB.FAQ} />
      </>
    );
  }
}

function mapStateToProps(state: { contentManagement: { content: Array<[]> } }) {
  const { content } = state.contentManagement;
  return {
    content,
  };
}

const connectedAboutUsContainer = connect(mapStateToProps)(Faq);
export { connectedAboutUsContainer as Faq };
