import React, { Dispatch } from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../../shared/sidebar';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import queryString from 'query-string';
import { LocationDescriptorObject } from 'history';
import { staticConstants, CSS_ClASSES } from '../../../utils';
import { history } from '../../../helpers';
import { TagsListingContainer } from './listing/tagsListing';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  location?: LocationDescriptorObject;
  activeTab?: string;
  filterTab?: string;
}

interface IState {
  activeTab: string;
}
class TagsPage extends React.Component<IProps, IState> {
  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps, state: IState) {
    const updateState = {};
    if (props.location && props.location.search) {
      const parsedParams = queryString.parse(props.location.search);
      const { tab = '' } = parsedParams;
      updateState['activeTab'] = tab;
    } else {
      updateState['activeTab'] = staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN;
      history.replace({ search: `tab=${staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN}` });
    }
    return updateState;
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
      activeTab: staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN,
    };
  }

  public handleActiveTabs = (activeTab: string) => {
    history.replace({ search: `?tab=${activeTab}` });
    this.setState({ activeTab });
  }

  public render() {
    const { activeTab } = this.state;
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <div className="connect-header">
            <Breadcrumb className="position-relative">
              <BreadcrumbItem className="heading heading-sm text-uppercase roboto-bold">
                Tags
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
          {/* Top tab bar */}
          <div className="tab-btn-group mt-4 multi-tab-buttons button-count-6">
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleActiveTabs(staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN)}
            >
              Domain
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_DOMAIN ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleActiveTabs(staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_DOMAIN)}
            >
              Sub-Domain
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.CATEGORY ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleActiveTabs(staticConstants.SYSTEM_ADMIN_TAGS_TAB.CATEGORY)}
            >
              Category
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_CATEGORY ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleActiveTabs(staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_CATEGORY)}
            >
              Sub-Category
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleActiveTabs(staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC)}
            >
              Topic
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.ACTIVITY_CODE ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleActiveTabs(staticConstants.SYSTEM_ADMIN_TAGS_TAB.ACTIVITY_CODE)}
            >
              Activity Code
            </Button>
          </div>
          <TagsListingContainer {...this.props} activeTab={activeTab} />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { paymentData } = state.systemAdminPayment;
  return { paymentData };
}

const connectedTagsPage = connect(mapStateToProps)(TagsPage);
export { connectedTagsPage as TagsPage };
