import { goalServices, bidServices } from './../../../../services';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { handleError } from './../../../../utils';
import { alertActions } from './../../../alert/redux/actions';
import { pageRoutes } from '../../../../utils/routeConstants';
import { history } from '../../../../helpers';
export const rfpAction = {
  getRequests,
  getRequestDetails,
  acceptBid,
  shortlistBid,
  updateRequestStatus,
  rejectBid,
  generateReport,
  resetGenerateReport,
  getSessionFee,
  completeRequest,
  rescheduleRequest,
};

function getRequests(rfp: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(rfp));
    goalServices.getGoals({ ...rfp, ...{ isRequestManagement: true } })
            .then(
                (requests) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(requests));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };

  function request(data: string) { return { type: actionTypes.RFP_LIST_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.RFP_LIST_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.RFP_LIST_FAILURE, error }; }
}

function generateReport(report: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(report));
    goalServices.getGoals(report)
      .then(
        (resReport) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(resReport));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: string) { return { type: actionTypes.GENERATE_REPORT_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.GENERATE_REPORT_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.GENERATE_REPORT_FAILURE, error }; }
}

function resetGenerateReport() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.RESET_GENERATE_REPORT });
  };
}

function getRequestDetails(rfp: { id: string }) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.getGoalDetails({ ...rfp, isRequestManagement: true })
            .then(
                (data) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(data));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };
  function success(data: any) { return { type: actionTypes.RFP_DETAIL_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.RFP_DETAIL_FAILURE, error }; }
}

function acceptBid(bidId: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    bidServices.acceptBid(bidId)
            .then(
                (data) => {
                  history.push(`${pageRoutes.REQUESTMANAGEMENT.PATH}`);
                  dispatch(alertActions.successAlert(data.message));
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(data));
                },
                (error) => {
                  handleError(dispatch, failure, error.message);
                },
            );
  };
  function request(data = null) { return { type: actionTypes.BID_ACCEPT_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.BID_ACCEPT_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.BID_ACCEPT_FAILURE, error }; }
}

function shortlistBid(bidObj: { bidId: string; isShortlisted: boolean }) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    bidServices.shortlistBid(bidObj)
            .then(
                (data) => {
                  dispatch(alertActions.successAlert(data.message));
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(data));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };
  function success(data: any) { return { type: actionTypes.BID_SHORTLIST_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.BID_SHORTLIST_FAILURE, error }; }
}

function updateRequestStatus(rfp: object, reqObj: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    goalServices.updateGoalStatus(rfp)
            .then(
                (goals) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(alertActions.successAlert(goals.message));
                  dispatch(success(goals));
                  if (reqObj) {
                    dispatch(getRequests(reqObj));
                  } else {
                    history.push(pageRoutes.REQUESTMANAGEMENT.PATH);
                  }
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };
  function success(data: any) { return { type: actionTypes.UPDATE_RFP_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.UPDATE_RFP_FAILED, error }; }
}

function rejectBid(bidId: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    bidServices.rejectBid({ bidId })
            .then(
                (data) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success({ ...data, bidId }));
                  dispatch(alertActions.successAlert(data.message));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };
  function success(data: any) { return { type: actionTypes.BID_REJECT_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.BID_REJECT_FAILURE, error }; }
}

function getSessionFee(bidId: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.getSessionFee(bidId)
            .then(
                (data) => {
                  history.push({
                    pathname: `/payment/checkout/`,
                    state: {
                      amount: data.data.amount,
                      commission:data.data.commission,
                      action: 'sessionFee',
                      bidId,
                      redirectUri: history.location.pathname },
                  });
                  dispatch(endLoading('MAIN_LOADER'));
                },
                (error) => {
                  handleError(dispatch, failure, error.message);
                },
            );
  };
  function failure(error: any) { return { type: actionTypes.GET_SESSION_FEE_FAILURE, error }; }
}

function completeRequest(goalId: string, saveGoal: boolean) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    goalServices.completeRequest(goalId, saveGoal)
            .then(
                (goals) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(alertActions.successAlert(goals.message));
                  history.push(pageRoutes.REQUESTMANAGEMENT.PATH);
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };
  function failure(error: any) { return { type: actionTypes.UPDATE_RFP_FAILED, error }; }
}

function rescheduleRequest(formData: object, goalId: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    goalServices.rescheduleRequest(formData)
            .then(
                (resp) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  (goalId) && dispatch(getRequestDetails({ id: String(goalId) }));
                  dispatch(alertActions.successAlert(resp.message));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };
  function failure(error: any) { return { type: actionTypes.UPDATE_RESCHEDULE_FAILURE, error }; }
}
