import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from '../../../interfaces';
import ReeValidate from 'ree-validate';
import DatePicker from 'react-datepicker';
import { setHours, setMinutes } from 'date-fns';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  modalPopupObj: any;
  hideModal(): void;
  onClickYes(arg: any): void;
}

interface IState {
  modal: boolean;
  errors?: any;
  minTime?: Date;
  maxTime?: Date;
  formData: any;
}

class RescheduleResponsePopup extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);

    this.validator = new ReeValidate({
      toDateRequested: 'required',
      toTimeRequested: 'required',
    });
    this.state = {
      modal: props.isOpenModal,
      formData: {
        toDateRequested: null,
        toTimeRequested: null,
      },
      errors: this.validator.errors,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  public componentDidMount = () => {
    if (this.props.modalPopupObj) {
      const formData = this.state.formData;
      const minTimeDateUTC = new Date(this.props.modalPopupObj.fromTime);
      const maxTimeDateUTC = new Date(this.props.modalPopupObj.toTimeRequested);
      formData['toDateRequested'] = new Date(this.props.modalPopupObj.toDateRequested);

      this.setState({
        modal: this.props.isOpenModal,
        minTime: setHours(setMinutes(new Date(), minTimeDateUTC.getMinutes()), minTimeDateUTC.getHours()),
        maxTime: setHours(setMinutes(new Date(), maxTimeDateUTC.getMinutes()), maxTimeDateUTC.getHours()),
        formData,
      });
    }
  }
  public validateAndSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { toDateRequested, toTimeRequested } = this.state.formData;
    const { errors } = this.validator;

    this.validator.validateAll({ toDateRequested, toTimeRequested })
      .then((success: boolean) => {
        if (success) {
          this.props.onClickYes(this.state.formData);
          this.hideModal();
        } else {
          this.setState({ errors });
        }
      });
  }

  public removeErrorAndReValidate = (name: string) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.formData[name])
      .then(() => {
        this.setState({ errors });
      });
  }

  public handleChange = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const { errors } = this.validator;
    this.setState({
      [name]: value,
    } as any);
    errors.remove(name);
    this.setState({ formData: { ...this.state.formData, [name]: value } });

    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }
  public hideModal() {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }
  
  public handleDateChange = (date: Date, name: string) => {
    this.setState({ formData: { ...this.state.formData, [name]: date } }, () => {
      this.removeErrorAndReValidate(name);
    });
  }

  

  public render() {
    const { modal, errors, formData, minTime, maxTime } = this.state;
    const isDateDisabled = true;
    return (
      <Modal isOpen={modal} className="make-bid-modal" onClosed={this.hideModal}>
        <ModalHeader toggle={this.hideModal}>Reschedule Bid Request</ModalHeader>
        <ModalBody >
          <Form onSubmit={this.validateAndSubmit} autoComplete="off" noValidate>
            <Label>Select a time to deliver on the date below: </Label>
            <Row>
              <Col xs="12">
                <FormGroup
                  className={`floating-label  disabled-input datepicker-wrapper ${
                    errors.has('firstName') ? ' has-error' : ''
                    }`}>
                  <DatePicker
                    placeholderText="Date"
                    className="form-control"
                    dateFormat="MMM dd, yyyy"
                    onChange={e => this.handleDateChange(e, 'toDateRequested')}
                    selected={formData.toDateRequested}
                    disabled={isDateDisabled}
                    showYearDropdown
                    showMonthDropdown
                    strictParsing />
                  <Label for="toDateRequested" className={formData.toDateRequested ? 'selected' : ''}>Proposed Date</Label>
                  <span className="icon icon-calendar" />
                  {errors.has('toDateRequested') &&
                    <div className="error-text">
                      {errors.first('toDateRequested').replace('toDateRequested', 'Proposed Date')}</div>
                  }
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup
                  className={`floating-label disabled-input time-form${
                    errors.has('lastName') ? ' has-error' : ''
                    }`}
                >
                  <DatePicker
                    placeholderText="Time"
                    className="form-control"
                    selected={formData.toTimeRequested}
                    onChange={(e) => this.handleDateChange(e, 'toTimeRequested')}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="hh:mm aa"
                    minTime={minTime}
                    maxTime={maxTime}
                    strictParsing
                  />
                  <Label for="toTimeRequested" className={formData.toTimeRequested ? 'selected' : ''}>Time</Label>
                  <span className="icon icon-clock" />
                  {errors.has('toTimeRequested') &&
                    <div className="error-text">{errors.first('toTimeRequested').replace('toTimeRequested', 'Time')}
                    </div>
                  }
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular mr-2" onClick={this.hideModal}>Cancel</Button>{' '}
          <Button color="primary" onClick={this.validateAndSubmit}>Send</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const connectedRescheduleResponsePopupPopupPage = connect()(RescheduleResponsePopup);
export { connectedRescheduleResponsePopupPopupPage as RescheduleResponsePopup };
