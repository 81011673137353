import React, { Dispatch } from 'react';
import { find } from 'lodash';
import { connect } from 'react-redux';
import {
  staticConstants,
  deleteDomainMsg,
  deleteSubDomainMsg,
  deleteCategoryMsg,
  deleteSubCategoryMsg,
  deleteTopicMsg,
  deleteActivityCodeMsg,
  publishTopicMsg,
  MAGIC_NUMBER,
  CONSTANTS
} from '../../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';
import { Button } from 'reactstrap';
import { LocationDescriptorObject } from 'history';
import { ListingContainer } from '../../../shared/listingManagement/listingContainer';
import { FilterComponent } from '../../../shared/filter/filterComponent';
import { tagAction } from '../../../shared/tags/redux/actions';
import { ConfirmatioPopup } from '../../../shared/modals/confirmationPopup';
import { CreateTag } from '../addTagModalPopup';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  location?: LocationDescriptorObject;
  activeTab: string;
  tags?: Array<[]>;
}

const domainHeaderContant = {
  sdomain: { name: CONSTANTS.DOMAIN_NAME, dataLabel: CONSTANTS.DOMAIN_NAME, class: 'table-col-90', sort: true },
  domainAction: { name: 'Action', dataLabel: 'Action', class: CONSTANTS.TABLE_COL_10 },
};

const subDomainHeaderContant = {
  ssubDomain: { name: CONSTANTS.SUB_DOMAIN_NAME, dataLabel: CONSTANTS.SUB_DOMAIN_NAME, class: 'table-col-45', sort: true },
  'connectingId.name': { name: CONSTANTS.DOMAIN_NAME, dataLabel: CONSTANTS.DOMAIN_NAME, class: 'table-col-45', sort: true },
  subDomainAction: { name: 'Action', dataLabel: 'Action', class: CONSTANTS.TABLE_COL_10 },
};

const categoryHeaderContant = {
  catagory: { name: CONSTANTS.CATEGORY_NAME, dataLabel: CONSTANTS.CATEGORY_NAME, class: CONSTANTS.TABLE_COL_30, sort: true },
  'connectingId.name': { name: CONSTANTS.SUB_DOMAIN_NAME, dataLabel: CONSTANTS.SUB_DOMAIN_NAME, class: CONSTANTS.TABLE_COL_30, sort: true },
  'domainId.name': { name: CONSTANTS.DOMAIN_NAME, dataLabel: CONSTANTS.DOMAIN_NAME, class: CONSTANTS.TABLE_COL_30, sort: true },
  categoryAction: { name: 'Action', dataLabel: 'Action', class: CONSTANTS.TABLE_COL_10 },
};

const subCategoryHeaderContant = {
  subCatagory: { name: CONSTANTS.SUB_CATEGORY_NAME, dataLabel: CONSTANTS.SUB_CATEGORY_NAME, class: 'table-col-25', sort: true },
  'connectingId.name': { name: CONSTANTS.CATEGORY_NAME, dataLabel: CONSTANTS.CATEGORY_NAME, class: CONSTANTS.TABLE_COL_20, sort: true },
  'subDomainId.name': { name: CONSTANTS.SUB_DOMAIN_NAME, dataLabel: CONSTANTS.SUB_DOMAIN_NAME, class: 'table-col-25', sort: true },
  'domainId.name': { name: CONSTANTS.DOMAIN_NAME, dataLabel: CONSTANTS.DOMAIN_NAME, class: CONSTANTS.TABLE_COL_20, sort: true },
  subCategoryAction: { name: 'Action', dataLabel: 'Action', class: CONSTANTS.TABLE_COL_10 },
};

const topicHeaderContant = {
  topic: { name: 'Topic', dataLabel: 'Topic', class: CONSTANTS.TABLE_COL_20, sort: true },
  'connectingId.name': { name: CONSTANTS.SUB_CATEGORY_NAME, dataLabel: CONSTANTS.SUB_CATEGORY_NAME, class: CONSTANTS.TABLE_COL_20, sort: true },
  'categoryId.name': { name: CONSTANTS.CATEGORY_NAME, dataLabel: CONSTANTS.CATEGORY_NAME, class: 'table-col-17', sort: true },
  'subDomainId.name': { name: CONSTANTS.SUB_DOMAIN_NAME, dataLabel: CONSTANTS.SUB_DOMAIN_NAME, class: 'table-col-18', sort: true },
  'domainId.name': { name: CONSTANTS.DOMAIN_NAME, dataLabel: CONSTANTS.DOMAIN_NAME, class: 'table-col-15', sort: true },
  topicAction: { name: 'Action', dataLabel: 'Action', class: 'table-col-10 text-center' },
};

const activityCodeHeaderContant = {
  activityCode: { name: 'Activity Code Name', dataLabel: 'Activity Code Name', class: 'table-col-90', sort: true },
  activityCodeAction: { name: 'Action', dataLabel: 'Action', class: CONSTANTS.TABLE_COL_10 },
};

const headerContentMappings = {
  domain: domainHeaderContant,
  subDomain: subDomainHeaderContant,
  category: categoryHeaderContant,
  subCategory: subCategoryHeaderContant,
  topic: topicHeaderContant,
  activityCode: activityCodeHeaderContant,
};

/**
 * @description Check duplicate data for rendering list
 * @param {array}  currentDataList containg array of object data
 * @param {string} matchKey contain object id only
 */
function checkDuplicateData(currentDataList: Array<[]>, matchKey: string) {
  return find(currentDataList, { _id: matchKey });
}

class TagsListingContainer extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param {Object} props
   */
  public static getDerivedStateFromProps(props: IProps, state: any) {
    const updateState = {};
    if (props.activeTab !== state.activeTab) {
      updateState['tableContent'] = headerContentMappings[props.activeTab];
      updateState['activeTab'] = props.activeTab;
      updateState['title'] = '';
    }

    if (props.activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN
      && props.tags && props.tags['domains'] && props.tags['domains'].length > MAGIC_NUMBER.ZERO) {
      if (state.page < staticConstants.TWO) {
        updateState['tagsData'] = { list: props.tags['domains'] };
      } else {
        if (!checkDuplicateData(state.tagsData['list'], props.tags['domains'][0]['_id'])) {
          const tagData = props.tags['domains'].length ? state.tagsData['list'].concat(props.tags['domains'])
          : state.tagsData['list'];
          updateState['tagsData'] = { list: tagData };
        }
      }
    }

    if (props.activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_DOMAIN
      && props.tags && props.tags['subDomains'] && props.tags['subDomains'].length > MAGIC_NUMBER.ZERO) {
      if (state.page < staticConstants.TWO) {
        updateState['tagsData'] = { list: props.tags['subDomains'] };
      } else {
        if (!checkDuplicateData(state.tagsData['list'], props.tags['subDomains'][0]['_id'])) {
          const tagData = props.tags['subDomains'].length ? state.tagsData['list'].concat(props.tags['subDomains'])
            : state.tagsData['list'];
          updateState['tagsData'] = { list: tagData };
        }
      }
    }

    if (props.activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.CATEGORY
      && props.tags && props.tags['categories'] && props.tags['categories'].length > MAGIC_NUMBER.ZERO) {
      if (state.page < staticConstants.TWO) {
        updateState['tagsData'] = { list: props.tags['categories'] };
      } else {
        if (!checkDuplicateData(state.tagsData['list'], props.tags['categories'][0]['_id'])) {
          const tagData = props.tags['categories'].length ? state.tagsData['list'].concat(props.tags['categories'])
            : state.tagsData['list'];
          updateState['tagsData'] = { list: tagData };
        }
      }
    }

    if (props.activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_CATEGORY
      && props.tags && props.tags['subCategories'] && props.tags['subCategories'].length > MAGIC_NUMBER.ZERO) {
      if (state.page < staticConstants.TWO) {
        updateState['tagsData'] = { list: props.tags['subCategories'] };
      } else {
        if (!checkDuplicateData(state.tagsData['list'], props.tags['subCategories'][0]['_id'])) {
          const tagData = props.tags['subCategories'].length ? state.tagsData['list'].concat(props.tags['subCategories'])
            : state.tagsData['list'];
          updateState['tagsData'] = { list: tagData };
        }
      }
    }
    if (props.activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC
      && props.tags && props.tags['topics'] && props.tags['topics'].length > MAGIC_NUMBER.ZERO) {
      if (state.page < staticConstants.TWO) {
        updateState['tagsData'] = { list: props.tags['topics'] };
      } else {
        if (!checkDuplicateData(state.tagsData['list'], props.tags['topics'][0]['_id'])) {
          const tagData = props.tags['topics'].length ? state.tagsData['list'].concat(props.tags['topics'])
            : state.tagsData['list'];
          updateState['tagsData'] = { list: tagData };
        }
      }
    }
    if (props.activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.ACTIVITY_CODE
      && props.tags && props.tags['activities'] && props.tags['activities'].length > MAGIC_NUMBER.ZERO) {
      if (state.page < staticConstants.TWO) {
        updateState['tagsData'] = { list: props.tags['activities'] };
      } else {
        if (!checkDuplicateData(state.tagsData['list'], props.tags['activities'][0]['_id'])) {
          const tagData = props.tags['activities'].length ? state.tagsData['list'].concat(props.tags['activities'])
            : state.tagsData['list'];
          updateState['tagsData'] = { list: tagData };
        }
      }
    }
    updateState['count'] = props.tags['count'];
    if (updateState['count'] === MAGIC_NUMBER.ZERO) {
      updateState['tagsData'] = { list: [] };
    }
    return updateState;
  }

  public selectedGoalId = [];

  public state = {
    tagId: '',
    title: '',
    sort: '',
    sortBy: 0,
    tagsData: {
      list: [],
    },
    modalPopupObj: {},
    selectedTagData: {},
    activeTab: staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN,
    tableContent: domainHeaderContant,
    page: 1,
    limit: staticConstants.TWENTY,
    count: 0,
    modalTitle: '',
    modalButtonTitle: '',
    displayTagDeletePopup: false,
    displayPublishTagPopup: false,
    displayAddTagPopup: false,
  };

  /**
   * @description get tag list data and update object before sending to the server
   */
  public getTagListData(activeTab: string, pageCount?: number) {
    const { page, limit, sort, sortBy, title } = this.state;
    let requestObj = {
      page: pageCount ? pageCount : page,
      limit,
      key: title,
    };
    if (sort) {
      requestObj = { ...requestObj, ...{ sort, sortBy } };
    }
    switch (activeTab) {
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN:
        this.props.dispatch(tagAction.getDomains(requestObj));
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_DOMAIN:
        this.props.dispatch(tagAction.getSubDomains({}, requestObj));
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.CATEGORY:
        this.props.dispatch(tagAction.getCategories('', requestObj));
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_CATEGORY:
        this.props.dispatch(tagAction.getSubCategories('', requestObj));
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC:
        this.props.dispatch(tagAction.getTopics('', requestObj));
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.ACTIVITY_CODE:
        this.props.dispatch(tagAction.getActivities('', requestObj));
        break;
      default:
        break;
    }
  }

  /**
   * @description componentDidMount is called when component is loaded
   * call userAction.getUsers to fetch goal listings
   * call userAction.getUsers to fetch goal categories
   */
  public componentDidMount() {
    this.setState({
      tagsData: {
        list: [],
      },
    },            () => {
      this.getTagListData(this.props.activeTab);
    });
  }

  /**
   * @description Runs before React applies the result of `render` to the document
   * @param prevProps
   */
  public getSnapshotBeforeUpdate(prevProps: IProps) {
    if (prevProps.activeTab !== this.state.activeTab) return true;
    return null;
  }

  /**
   * @description componentDidUpdate is called when we need to fetch listing again.
   * call goalAction.getGoals to fetch goal listings
   */
  public componentDidUpdate(prevProps: IProps, prevState) {
    if (this.props.activeTab !== prevProps.activeTab) {
      this.setState({
        page: 1,
        tagsData: {
          list: [],
        },
      },            () => {
        this.getTagListData(this.props.activeTab);
      });
    }
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleChange = (value: any, key: string) => {
    this.setState({
      ...this.state, [key]: value, page: 1, tagsData: {
        list: [],
      } },        () => {
      this.getTagListData(this.props.activeTab);
    });
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData = (sort: string, sortBy: string) => {
    this.setState({ ...this.state, sort, sortBy }, () =>
      this.setState({
        page: 1,
        tagsData: {
          list: [],
        },
      },            () => {
        this.getTagListData(this.props.activeTab);
      }),
    );
  }

  public addTags = (type, itemData?: object) => {
    const { activeTab } = this.state;
    (type === 'edit') && this.props.dispatch(tagAction.setTagStatus());
    this.setState({
      displayAddTagPopup: true,
      modalTitle: type === 'edit' ? staticConstants.EDIT_TAGS_TITLE[activeTab] : staticConstants.TAGS_TITLE[activeTab],
      modalButtonTitle: type === 'edit' ? 'Update' : 'Add',
      selectedTagData: type === 'edit' && itemData,
    });
  }

  public createTagsAction = (objData: object) => {
    const { activeTab } = this.props;
    objData['type'] = activeTab;
    if (this.state.modalButtonTitle === 'Add') {
      this.props.dispatch(tagAction.createTag(objData, () => {
        this.setState({ page: 1 }, () => {
          this.getTagListData(activeTab, 1);
        });
      }));
    } else {
      this.props.dispatch(tagAction.editTag(objData, () => {
        this.getTagListData(activeTab, 1);
      }));
    }
    this.setState({ displayAddTagPopup: false });
  }

  /**
   * @description
   * deleteItem is used to delete tag
   * @param fields {Object} tag
   */
  public deleteTag = (tag: object) => {
    switch (this.props.activeTab) {
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN:
        this.setState({
          displayTagDeletePopup: true,
          tagId: tag['_id'],
          modalPopupObj: deleteDomainMsg,
        });
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_DOMAIN:
        this.setState({
          displayTagDeletePopup: true,
          tagId: tag['_id'],
          modalPopupObj: deleteSubDomainMsg,
        });
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.CATEGORY:
        this.setState({
          displayTagDeletePopup: true,
          tagId: tag['_id'],
          modalPopupObj: deleteCategoryMsg,
        });
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_CATEGORY:
        this.setState({
          displayTagDeletePopup: true,
          tagId: tag['_id'],
          modalPopupObj: deleteSubCategoryMsg,
        });
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC:
        this.setState({
          displayTagDeletePopup: true,
          tagId: tag['_id'],
          modalPopupObj: deleteTopicMsg,
        });
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.ACTIVITY_CODE:
        this.setState({
          displayTagDeletePopup: true,
          tagId: tag['_id'],
          modalPopupObj: deleteActivityCodeMsg,
        });
        break;
      default:
        break;
    }
  }

  /**
   * @description
   * deleteItem is used to delete tag
   * @param fields {Object} tag
   */
  public publishTopicTag = (tag: object) => {
    this.setState({
      displayPublishTagPopup: true,
      tagId: tag['_id'],
      modalPopupObj: publishTopicMsg,
    });
  }

  /**
   * @description hideModal is called when someone click on the cancel button
   */
  public hideModal = () => {
    this.setState({
      displayTagDeletePopup: false,
      displayPublishTagPopup: false,
    });
  }

  /**
   * @description  deleteUserAction is used to delete user
   * @param fields {String} id
   */
  public deleteUserAction = () => {
    const { tagId, activeTab } = this.state;
    const reqObj = { tagId, type: activeTab };
    this.props.dispatch(tagAction.removeTag(reqObj, () => {
      this.setState({ page: MAGIC_NUMBER.ONE }, () => {
        this.getTagListData(activeTab, MAGIC_NUMBER.ONE);
      });
    }));
    this.setState({ displayTagDeletePopup: false });
  }

  /**
   * @description
   * publishTopicAction is used to publish topic
   * @param fields {String} id
   */
  public publishTopicAction = () => {
    const { tagId, activeTab } = this.state;
    this.props.dispatch(tagAction.publishTag({ tagId },
      () => {
      this.getTagListData(activeTab, 1);
    }));
    this.setState({ displayPublishTagPopup: false });
  }

  /**
   * fetch logs data on scroll
   */
  public fetchMoreData = () => {
    const { page, count, limit } = this.state;
    if (this.state && page <= count / limit) {
      this.setState({ page: page + 1 }, () => {
        this.getTagListData(this.props.activeTab);
      });
    }
  }

  /**
   * @description
   * render is used to render HTML
   */
  public render() {
    const filterContent = ['search'];
    const filterObj = {
      sort: this.state.sort,
      sortBy: this.state.sortBy,
    };
    const { tableContent, tagsData, activeTab, count, modalPopupObj, displayTagDeletePopup, displayPublishTagPopup, modalTitle,
      modalButtonTitle, displayAddTagPopup, selectedTagData } = this.state;
    const classVarArr = ['table-head'];
    const classes = classVarArr.join(' ');
    return (
        <React.Fragment>
          <div className="filter-wrapper">
            <FilterComponent
              filterContent={filterContent}
              handleFilter={this.handleChange}
              placeholder="Search by Keywords, Name"
              activeTab={activeTab}
            />
            <Button className={`btn btn-primary round-circle add-topic-btn`}
              onClick={() => this.addTags('add')}>
              {staticConstants.TAGS_TITLE[activeTab]}
            </Button>
          </div>
          <ListingContainer
            tableContent={tableContent}
            listData={tagsData}
            filterObj={filterObj}
            tableHeaderClasses={classes}
            handleSorting={this.sortData}
            activeTab={this.state.activeTab}
            parentTab={staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN}
            isFromTags={true}
            fetchMoreData={this.fetchMoreData}
            tagCount={count}
            deleteItem={this.deleteTag}
            publishTopic={this.publishTopicTag}
            editTags={this.addTags}
          />
          {
            displayTagDeletePopup ?
              <ConfirmatioPopup hideModal={this.hideModal} isOpenModal={displayTagDeletePopup} modalPopupObj={modalPopupObj}
                onClickYes={this.deleteUserAction}
              />
              : ''
          }
          {
            displayPublishTagPopup ?
              <ConfirmatioPopup hideModal={this.hideModal} isOpenModal={displayPublishTagPopup} modalPopupObj={modalPopupObj}
                onClickYes={this.publishTopicAction}
              />
              : ''
          }
          {
            displayAddTagPopup ?
            <CreateTag
              modalTitle={modalTitle} modalButtonTitle={modalButtonTitle} selectedTagData={selectedTagData}
              isOpenModal={displayAddTagPopup} onClickAddTag={this.createTagsAction} activeTab={activeTab}
                hideModal={() => this.setState({ displayAddTagPopup: false })} domainData={this.props.tags['domains']}
              isEditTag={this.props.tags['successForEditFlag']} previousCount={count}
      />
      : ''
    }
        </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { tags } = state;
  return {
    tags,
  };
}

const connectedTagsListingContainer = connect(mapStateToProps)(TagsListingContainer);
export { connectedTagsListingContainer as TagsListingContainer };
