import {
    GET_DASHBOARD_QUERY,
    GET_DASHBOARD_UPCOMING_EVENTS,
    GET_DASHBOARD_REVIEWS,
    GET_ADMIN_DASHBOARD_QUERY,
    GET_LATEST_USERS,
} from '../graphql-client/queries/dashboardQuery';
import { client } from '../index';
import { handleResponse } from '../utils/common';

export const dashboardServices = {
  getDashboardData,
  getUpcomingEvents,
  getReviews,
  getAdminDashboardData,
  getLatestUsers,
};

async function getDashboardData(reqObj: any) {
  const response = await client.query({
    query: GET_DASHBOARD_QUERY,
    variables: reqObj,
  });
  const resp = await handleResponse(response);
  return resp.dashboard.data;
}

async function getAdminDashboardData(reqObj: any) {
  const response = await client.query({
    query: GET_ADMIN_DASHBOARD_QUERY,
    variables: reqObj,
  });
  const resp = await handleResponse(response);
  return resp.dashboard.data;
}

async function getUpcomingEvents() {
  const response = await client.query({
    query: GET_DASHBOARD_UPCOMING_EVENTS,
  });
  const resp = await handleResponse(response);
  return resp.upcommingEvents;
}

async function getReviews() {
  const response = await client.query({
    query: GET_DASHBOARD_REVIEWS,
  });
  const resp = await handleResponse(response);
  return resp.rating.data;
}

async function getLatestUsers() {
  const response = await client.query({
    query: GET_LATEST_USERS,
  });
  const resp = await handleResponse(response);
  return resp.newUser.data;
}
