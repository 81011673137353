import React, { Dispatch } from 'react';
import ReeValidate from 'ree-validate';
import { connect } from 'react-redux';
import { FormGroup, Label, Button, Input, Form } from 'reactstrap';
import { IValidator } from './../../../../interfaces';
import OpenFieldTypes from 'OpenFieldTypes';
import { contentManagementAction } from '../../redux/actions';
import { MAGIC_NUMBER } from '../../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

interface IState {
  submitted: boolean;
  name: string;
  email: string;
  message: string;
  errors?: any;
}
class ContactForm extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);

    // reset login status
    this.validator = new ReeValidate({
      name: 'required',
      email: 'required',
      message: 'required',
    });

    this.state = {
      name: '',
      email: '',
      message: '',
      submitted: false,
      errors: this.validator.errors,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  public handleChange(e: React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>) {
    const { name, value } = e.target;
    const { errors } = this.validator;
    this.setState({ [name]: value } as any);
    errors.remove(name);
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public handleSubmit() {
    this.setState({ submitted: true });
    const { name, email, message } = this.state;
    this.props.dispatch(contentManagementAction.contactUs(name, email, message));
    this.setState({
      name: '',
      email: '',
      message: '',
    });
  }

  public validateAndSubmit(e: any) {
    e.preventDefault();
    const { name, email, message } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll({ name, email, message })
      .then((success: any) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  public render() {
    const { name, email, message, errors } = this.state;
    return (
      <>
        <div className="common_padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-center">
                <h2 className="common_heading">We love feedback and value your<br />
              input. It makes us better.</h2>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="contact_us_form">
                  <Form noValidate>
                    <FormGroup
                      className="floating-label">
                      <Input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        onChange={this.handleChange}
                        value={name}
                        placeholder="Name"
                        maxLength={MAGIC_NUMBER.FIFTY}
                      />
                      <Label for="email">Name</Label>
                      {errors.has('name') &&
                        <div className="error-text">
                          {errors.first('name').replace('name', '')}
                        </div>
                      }
                    </FormGroup>
                    <FormGroup
                      className="floating-label sibling-child-textarea">
                      <Input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        value={email}
                        onChange={this.handleChange}
                        placeholder="Email"
                        maxLength={MAGIC_NUMBER.FIFTY}
                      />
                      <Label for="email">Email</Label>
                      {errors.has('email') &&
                        <div className="error-text">
                          {errors.first('email').replace('email', '')}
                        </div>
                      }
                    </FormGroup>
                    <FormGroup
                      className="floating-label disabled-input textarea-label contact-textarea">
                      <Input
                        className="form-control textarea-md"
                        id="message"
                        value={message}
                        name="message"
                        onChange={this.handleChange}
                        placeholder="Message"
                        maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                      />
                      <Label for="answer">Message</Label>
                      <span className="text-count">{message.length}/500</span>
                      {errors.has('message') &&
                        <div className="error-text">
                          {errors.first('message').replace('message', '')}
                        </div>
                      }
                    </FormGroup>
                    <div className="d-flex justify-content-end pt-3">
                      <Button color="send_btn" className="get_start_btn cta_btn d-block text-center w-100" onClick={this.validateAndSubmit}>Send</Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state: any) {
  return {};
}

const contactForm = connect(mapStateToProps)(ContactForm);

export { contactForm as ContactForm };
