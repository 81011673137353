import React, { useState } from 'react';
import Select from 'react-select';
import csc from 'country-state-city';
import { Controller } from 'react-hook-form';
import {customSelectStyles } from '../../../utils/';
import {
  Label,
} from 'reactstrap';
interface IProps {
  control: any;
  setValue: any;
  watch: any;
}

export default function State(props: IProps) {
  const [stateOptions, setStateOptions] = useState([]);
  const {
    control, setValue, watch,
  } = props;
  const [countryVal, setCountryVal] = useState({
    label: '', value: '',
  });

  const getStateOptions = (val) => {
    const options = csc.getStatesOfCountry(val);
    const updateStateOptions = options.map(state => ({
      label: state.name,
      value: state.id,
    }));
    setStateOptions(updateStateOptions);
  };

  if (watch().country) {
    const { country } = watch();
    if (country && countryVal.label !== country.label) {
      setCountryVal(country);
      getStateOptions(country.value);
    }
  }

  return (
    <Controller
      control={control}
      name="state"
      defaultValue={null}
      render={({ onChange, value }) => (
        <>
        <Select
          placeholder="Select state"
          className="react-select-box select-box-group"
          isDisabled={!watch('country')}
          styles={customSelectStyles}
          name="state"
          onChange={onChange}
          options={stateOptions}
          value={value}
          handleChange={(e) => {
            onChange(e);
            setValue('city', {});
          }}
        />
        <Label for="state" className={value ? 'selected' : ''}>State</Label>
        </>
      )}
      rules={{ required: true }}
    />
  );
}
