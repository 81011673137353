import { IBidDetail } from './bids';
export * from './learner';
export * from './bids';
export * from './rfp';
export * from './messageBoard';

export interface IValidator {
  errors?: any;
  validate?: any;
  validateAll?: any;
}
export interface ILoginUser {
  email: string;
  password: string;
}

export interface IUser {
  username: string;
  email: string;
  password?: string;
}

export interface IOrgCode {
  code: string;
}
export interface IOrgAdmin {
  email: string;
  password?: string;
  organizationName: string;
  licence: number;
  userType: string;
}

export interface IProfessional {
  email: string;
  firstName?: string;
  lastName: string;
  userType: string;
}

export interface ISuccess {
  action?: object;
  title?: boolean;
  message: any;
}

export interface ICreatePasswordReq {
  password: string;
  email: string;
  token: string;
}

export interface IResetPasswordReq {
  password: string;
  email: string;
  token: string;
}

export interface IProfile {
  inStripeRegion?: boolean;
  firstName: string;
  lastName: string;
  education: string;
  dateOfBirth: Date;
  phoneNumber: string;
  sector: string;
  occupation: string;
  position: string;
  location: string;
  address: string;
  tags?: any;
  industry: string;
  title: { label: string; value: string };
  profileImage?: File;
  sectorId?: string;
  industryId?: string;
  selectedLocation?: { label: string; value: string };
  selectedEducation?: { value: string; label: string };
  selectedSector?: { value: string; label: string };
  selectedIndustry?: { value: string; label: string };
  publishLink?: string;
}

export interface IExpertReq {
  firstName: string;
  lastName: string;
  email: string;
  address?: string;
  myPassword: string;
  occupation: string;
  position: string;
  bio: string;
  linkedinProfile?: string;
  publishLink?: string;
  phone?: string;
  title: { label: string; value: string };
  education?: [];
  experiences?: [];
  references?: [];
}

export interface IUploadImgaeReq {
  upload: string;
  type: string;
}
export interface IPayment {
  token: string;
  quantity: number;
  isAddOn: boolean;
  amount: number;
  isNewCard: boolean;
}

export interface IUpdateProfileReq {
  firstName: string;
  lastName: string;
  education?: any;
  dateOfBirth: string;
  phoneNumber: string;
  sector?: string;
  occupation: string;
  position?: string;
  location?: string;
  address?: string;
  industry?: string;
  title: string;
  tags?: Array<{ id: string; name: string }>;
  sectorId?: string;
  industryId?: string;
  publishLink?: string;
  bio?: string;
  employer?: string;
  experiences?: any;
  expertEducation?: any;
  linkedin?: string;
  google?: string;
  twitter?: string;
  notifications?: boolean;
}

export interface IUpdateExpertProfileReq {
  firstName: string;
  lastName: string;
  education?: any;
  dateOfBirth: string;
  phoneNumber: string;
  sector: string;
  occupation: string;
  position?: string;
  location: string;
  address: string;
  industry: string;
  title: string;
  tags?: Array<{ id: string; name: string }>;
  sectorId: string;
  industryId: string;
  publishLink: string;
  bio?: string;
  employer?: string;
  experiences: any;
  expertEducation: any;
}

export interface IGoalDetail {

  createdAt: string;
  fromDate: string;
  rejectedReason?: string;
  toDate: string;
  fromTime: string;
  toTime: string;
  title: string;
  participants: [];
  eventType: string;
  modeOfDevivery: string;
  description: string;
  topic: {
    name: string;
    id: string;
  };
  domain: {
    name: string;
    id: string;
  };
  subDomain: {
    name: string;
    id: string;
  };
  category: {
    name: string;
    id: string;
  };
  subCategory: {
    name: string;
    id: string;
  };
  activity: {
    name: string;
    id: string;
  };
  userId?: any;
  duration: string;
  isMe: boolean;
  status?: string;
  statusTimeline?: Array<{ createdAt: string; status: string }>;
  isFavourite?: boolean;
  favourite?: any;
  orgId?: string;
  participantsRaw?: Array<{ userId: string; isAdmin: boolean }>;
  // isEnded?: boolean;
  // adminRatingGoal?: { sessionRating?: number; expertRating?: number; comment?: string; createdAt?: string };
  // expertRatingGoal?: { overAllQuality: number; participantsEngagement: number; comment: string; createdAt?: string };
  // adminAttendence?: { isExpertAttended: boolean; isExpertDeliver: boolean; comment: string };
  // expertAttendence?: { isParticipantsAttended: boolean; isAbleToDeliver: boolean; comment: string };
  // scheduleAt?: string;
  // endedAt?: string;
  // extendMeeting?: Array<{ status: string; duration: number; requestedId: string; role: string }>;
  // isPast?: boolean;
  goalAdmin?: Array<{ firstName: boolean; lastName: boolean; organizationName: string }>;
  requestedBy?: Array<{ firstName: boolean; lastName: boolean; role: string }>;
  isDispute?: boolean;
  sessionFee?: number;
  expertEarn?: number;
  commission?: number;
  isReviewTags?: boolean;
  rehireId?: string;
  isAcceptBidClosed?: boolean;
}

export interface IEventPayment {
  payment?: [{ type: string; isPaymentSuccessful: boolean; bidId?: string; extendEventId?: string }];
}

export interface IParams {
  id: string;
}

export interface IGoalDetailWithId extends IGoalDetail {
  _id: string;
  bid?: [IBidDetail];
  tags?: any;
  bidCount?: number;
  payment?: [{ type: string; isPaymentSuccessful: boolean; bidId?: string; extendEventId?: string }];

}

export interface IViewProfile {
  inStripeRegion?: boolean;
  firstName?: string;
  lastName?: string;
  education?: string;
  dateOfBirth?: Date;
  phoneNumber?: string;
  sector?: string;
  occupation?: string;
  position?: string;
  location?: string;
  address?: string;
  tags?: any;
  industry?: string;
  title?: string;
  profileImage?: string;
  selectedLocation?: { label: string; value: string };
  selectedEducation?: { value: string; label: string };
  email?: string;
  organizationName?: string;
  employer?: string;
  stripeAccountId?: string;
  languages?: any;
  bio?: string;
  linkedinProfile?: string;
  publishLink?: string;
  phone?: string;
  experiences?: IWorkExperiance[];
  references?: [];
  modeOfDelivery?: string;
  availableDays?: string[];
  licence?: number;
  amount?: number;
  subscriptionId?: string;
  current_period_end?: string;
  isAddOn?: boolean;
  isPaid?: boolean;
  linkedIn?: string;
  twitter?: string;
  facebook?: string;
  notifications?: boolean;
  isLicenced?: boolean;
  role?: string;
  systemAdmin?: any;
  licenceMovement?: {
    count: number;
  };
  hourlyRate?: { current: number; requested: number; comment: string };
  totalLicenceAssign?: number;
  averageRating?: number;
  averageSessionRating?: number;
  referred?: any;
}

export interface IWorkExperiance {
  employer?: string;
  position?: string;
  lengthOfServices?: string;
  location?: string;
  summary?: string;
}

export interface ISubscriptionInfo {
  amount: string;
  date: string;
  isAddOn: string;
  plan: string;
  quantity: string;
  updatedAt: string;
  current_period_end?: string;
  current_period_start?: string;
  customer?: string;
  subscriptionId?: string;
}

export interface ILocalUser {
  email?: string;
  isAddOn?: boolean;
  feedback?: { message: string; isFeedback: boolean; isReply: boolean; createdAt: any };
  isFirstTime?: boolean;
  isPaid?: boolean;
  isStripeAccount?: boolean;
  profileImage?: string;
  role?: string;
  token?: string;
  _id?: string;
  firstName?: string;
  lastName?: string;
  subscribedProductId?: string;
  subscriptionId?: string;
}

export interface IChangePasswordReq {
  oldPassword: string;
  confirmPassword: string;
}

export interface ICreateUser {
  userType: string;
  firstName: string;
  lastName: string;
  email: string;
  organizationName?: string;
  hourlyRate?: number;
  licence?: number;
}

export interface ISystemAdminProfile {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  commission?: { professional: number; organization: number };
  profileImage?: File;
  professional?: number;
  organization?: number;
}

export interface IUpdateContent {
  question: string;
  answer: string;
  content: string;
  id: string;
  type: string;
}

export interface IInvoiceLog {
  amount: number;
  description: string;
  period: {start: number; end: number};
  quantity: number;
  unitPrice: number;
}

export interface IReschedule {
  proposedDate: Date;
  proposedTime: Date;
  fromTime: Date;
  bidId: string;
}

export interface IRescheduleStatus {
  bidId: string;
  status: string;
  toTimeRequested?: Date;
}

export interface IBankInfo {
  region: string;
  bankAccount: {bankName: string;
    accountNumber: string;
    accountHolderName: string;
    routingNumber: string;
    branchCode: string;
    swiftNumber: string;
    other: string;
  };
  accountHolderAddress: { street1: string;
    street2: string;
    city: {label: string; value: string};
    state: {label: string; value: string};
    zipcode: string;
    country: {label: string; value: string}; };
}
