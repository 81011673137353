import React from 'react';
import {
    Button,
    Form,
    FormGroup,
    Label,
    ModalFooter,
} from 'reactstrap';
import { IValidator } from './../../../interfaces';
import { reeValidators, MAGIC_NUMBER } from '../../../utils';
import ReeValidate from 'ree-validate';

interface IProps {
  handleSubmit: any;
}

interface IState {
  errors?: any;
  formData: {
      comment: string;
      isParticipantsAttended: string;
      isAbleToDeliver: string;
    };
}

class ExpertAttendancePopup extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
      super(props);
      this.validator = new ReeValidate({
          comment: reeValidators.required_min_3_max_500,
          isParticipantsAttended: 'required',
          isAbleToDeliver: 'required',
        });

      this.state = {
          formData: {
              comment: '',
              isAbleToDeliver: 'true',
              isParticipantsAttended: 'true',
            },
          errors: this.validator.errors,
        };
    }

  public handleChange = (event: any) => {
      const { name, value } = event.target;
      const { errors } = this.validator;
      this.setState({ formData: { ...this.state.formData, [name]: value } });
      errors.remove(name);
      this.validator.validate(name, value)
            .then(() => {
              this.setState({ errors });
            });
    }

  public validateAndSubmit = (e) => {
      e.preventDefault();
      const { comment } = this.state.formData;
      const { errors } = this.validator;
      this.validator.validateAll({ comment })
            .then((success: boolean) => {
              if (success) {
                  this.props.handleSubmit(this.state.formData);
                } else {
                  this.setState({ errors });
                }
            });
    }

  public render() {
      const { formData, errors } = this.state;
      return (
            <>
                <Form onSubmit={this.validateAndSubmit}>
                    <div className="p-4">
                        <FormGroup className="learning-goal-form">
                            <span className="attendance-text d-block">Did your participants attend your event?</span>
                            <div className="d-flex">
                                <label className="control control--radio">
                                    <input type="radio" name="isParticipantsAttended" value="true"
                                        checked={formData['isParticipantsAttended'] === 'true'}
                                        onChange={this.handleChange} /> Yes
                                    <div className="control__indicator"/>
                                </label>
                                <label className="control control--radio">
                                    <input type="radio" name="isParticipantsAttended" value="false"
                                        checked={formData['isParticipantsAttended'] === 'false'}
                                        onChange={this.handleChange} />No
                                    <div className="control__indicator"/>
                                </label>
                            </div>
                        </FormGroup>
                        <FormGroup className="learning-goal-form">
                            <span className="attendance-text d-block">Were you able to deliver the event?</span>
                            <div className="d-flex">
                                <label className="control control--radio">
                                    <input type="radio" name="isAbleToDeliver" value="true"
                                        checked={formData['isAbleToDeliver'] === 'true'}
                                        onChange={this.handleChange} /> Yes
                                    <div className="control__indicator"/>
                                </label>
                                <label className="control control--radio">
                                    <input type="radio" name="isAbleToDeliver" value="false"
                                        checked={formData['isAbleToDeliver'] === 'false'}
                                        onChange={this.handleChange} />No
                                    <div className="control__indicator"/>
                                </label>
                            </div>
                        </FormGroup>
                        <FormGroup
                            className={`floating-label disabled-input textarea-label ${
                                errors.has('comment') ? ' has-error' : ''
                                }`}>
                            <textarea
                                className="form-control textarea-md"
                                name="comment"
                                id="comment"
                                value={formData.comment}
                                onChange={this.handleChange}
                                maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                                placeholder="Comments"
                            />
                            <span className="text-count">Character left {MAGIC_NUMBER.FIVE_HUNDRED - formData.comment.length}/500</span>
                            <Label for="summary" className={formData.comment ? 'selected' : ''}>Comments</Label>
                            {errors.has('comment') &&
                                <div className="error-text">{errors.first('comment').replace('comment', '')}</div>
                            }
                        </FormGroup>
                    </div>
                </Form>
                <ModalFooter>
                    <Button color="primary" onClick={this.validateAndSubmit}>Submit</Button>
                </ModalFooter>
            </>
        );
    }
}

export { ExpertAttendancePopup };
