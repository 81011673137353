import React, { Dispatch } from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { contentManagementAction } from '../../../systemAdmin/contentManagement/redux/actions';
import { staticConstants } from '../../../../utils';
import { LandingHeader } from '../../landingHeader';
import { LandingFooter } from '../../landingFooter';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  content: Array<[]>;
}

class Pricing extends React.Component<IProps> {

  public state = {
    title: staticConstants.LANDING_PAGE_FOOTER_TITLE.PRICING,
  };

  public componentDidMount() {
    this.props.dispatch(contentManagementAction.getContent({
      type: staticConstants.CONTENT_MANAGEMENT_TAB.FAQ,
    }));
  }

  public render() {
    const { title } = this.state;
    return (
      <>
        <LandingHeader title={title} banner={'pricing_banner'} />
        <div className="common_padding">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-lg-5 mb-3">
                <h2 className="common_heading">Sign up and get your first month free!</h2>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="membership_outer">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 pr-lg-0">
                      <div className="membership_div">
                        <div className="header_text">
                          <h2 className="common_heading mt-4">Pro for individuals</h2>
                          <p>Getting information with us is customized and fun.</p>
                        </div>
                        <div className="amount_text_div">
                          <h3><sup>$</sup>35</h3>
                          <p>/individual/month, billed monthly </p>
                        </div>
                        <div className="img_and_text">
                          <p className="pr-lg-5 pr-0">Your monthly subscription gives you access to our matchmaking technology and our network
                                                     of experts from around the world. All your requests, events and knowledge providers, in one place.</p>
                        </div>
                        <div className="list_div">
                          <p>Goal-to-expert matchmaking<span id="list_01">?</span> </p>
                          <UncontrolledTooltip placement="top" target="list_01" >Send unlimited requests and get matched with expert profiles in our
                                                     network</UncontrolledTooltip>
                          <p>Remote conferencing technology<span id="list_02">?</span> </p>
                          <UncontrolledTooltip placement="top" target="list_02" >Connect directly on Zoom and download your completed
                                                    sessions to watch anytime</UncontrolledTooltip>
                          <p>Quick re-booking<span id="list_03">?</span> </p>
                          <UncontrolledTooltip placement="top" target="list_03" >Rehire an expert at the end of a call with ease</UncontrolledTooltip>
                          <p>Zero conflict scheduling<span id="list_04">?</span></p>
                          <UncontrolledTooltip placement="top" target="list_04" >Choose more than one knowledge provider for your request
                                                    without experiencing scheduling conflicts</UncontrolledTooltip>
                          <p>5000+ Sector tags<span id="list_05">?</span> </p>
                          <UncontrolledTooltip placement="top" target="list_05" >Our list of domains, categories, topics and activities to help
                                                    identify your specific needs</UncontrolledTooltip>
                          <p>Knowledge management<span id="list_06">?</span> </p>
                          <UncontrolledTooltip placement="top" target="list_06" >Dashboard with at-a-glance metrics, automatically saved events,
                                                     and searchable lists</UncontrolledTooltip>
                          <p>&nbsp;</p>
                        </div>
                        <div className="img_and_text pricing_amount">
                          <Link to="/usertype" className="get_start_btn d-inline-block blue_bg_btn mt-3 mb-3" >Start a free trial</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 pl-lg-0">
                      <div className="membership_div">
                        <div className="header_text">
                          <h2 className="common_heading mt-4">Enterprise for teams</h2>
                          <p className="pr-lg-5 pr-0">Getting access to expertise with us is simple and cheap.</p>
                        </div>
                        <div className="amount_text_div">
                          <h3><sup>$</sup>70</h3>
                          <p>/team admin/month, billed monthly </p>
                        </div>
                        <div className="img_and_text">
                          <p>Your monthly subscription gives your team access to our matchmaking technology and our
                                                         network of experts from around the world. All your requests, events and knowledge providers in one place.*</p>
                        </div>
                        <div className="list_div">
                          <p>Goal-to-expert matchmaking<span id="list_1">?</span></p>
                          <UncontrolledTooltip placement="top" target="list_1" >Send unlimited requests and get matched with expert
                                                    profiles in our network</UncontrolledTooltip>
                          <p>Remote conferencing technology<span id="list_2">?</span></p>
                          <UncontrolledTooltip placement="top" target="list_2" >Connect directly on Zoom and download
                                                     your completed sessions to watch anytime</UncontrolledTooltip>
                          <p>Quick re-booking<span id="list_3">?</span></p>
                          <UncontrolledTooltip placement="top" target="list_3" >Rehire an expert at the end of
                                                    a call with ease</UncontrolledTooltip>
                          <p>Zero conflict scheduling<span id="list_4">?</span></p>
                          <UncontrolledTooltip placement="top" target="list_4" >Choose more than one knowledge provider for your
                                                    request without experiencing scheduling conflicts</UncontrolledTooltip>
                          <p>5000+ Sector tags<span id="list_5">?</span></p>
                          <UncontrolledTooltip placement="top" target="list_5" >Our list of domains, categories, topics and activities to
                                                    help identify your specific needs</UncontrolledTooltip>
                          <p>Knowledge management<span id="list_6">?</span></p>
                          <UncontrolledTooltip placement="top" target="list_6" >Dashboard with at-a-glance metrics, automatically saved events,
                                                    and searchable lists</UncontrolledTooltip>
                          <p>User management<span id="list_7">?</span></p>
                          <UncontrolledTooltip placement="top" target="list_7" >Manage all team members requests for
                                                    expertise</UncontrolledTooltip>
                        </div>
                        <div className="img_and_text pricing_amount">
                          <Link to="/usertype" className="get_start_btn d-inline-block blue_bg_btn mt-3 mb-3" href="ShareYourExpertise.html">
                            Start a free trial</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3">
                <p className="font-italic"> Team Admins have extra features to manage their account and each
                                team member is charged at the Individual price.</p>
              </div>
            </div>
          </div>
        </div>
        <LandingFooter activeTab={staticConstants.CONTENT_MANAGEMENT_TAB.FAQ} />
      </>
    );
  }
}

function mapStateToProps(state: { contentManagement: { content: Array<[]> } }) {
  const { content } = state.contentManagement;
  return {
    content,
  };
}

const connectePricingContainer = connect(mapStateToProps)(Pricing);
export { connectePricingContainer as Pricing };
