import { handleResponse } from '../utils/common';
import { PAYMENT_MUTATION, PAYMENT_MUTATION_EXISTING_CARD, 
  ADD_PAYMENT_CARD_MUTATION, UPDATE_PAYMENT_CARD_MUTATION, DELETE_PAYMENT_CARD_MUTATION, 
  WITHDRAW_AMOUNT_MUTATION, UPDATE_DISPUTE_MUTATION } from './../graphql-client/mutations/paymentMutations';
import { GET_CARDS_QUERY, GET_TOTAL_PAYMENT_QUERY, GET_PAYMENT_QUERY, GET_TAX_RATE } from './../graphql-client/queries/paymentQuery';
import { client } from './../index';

export const paymentService = {
  payment,
  getCards,
  addCard,
  updateCard,
  deleteCard,
  paymentWithExistingCard,
  getTotalPayments,
  getPayments,
  withdrawAmount,
  updateDisputeStatus,
  getTaxRate,
};

function updateDataToLocalStorage(updateObj) {
  const user = JSON.parse(localStorage.getItem('user'));
  for (const i in updateObj) {
    user[i] = updateObj[i];
  }
  localStorage.setItem('user', JSON.stringify(user));
}

async function getCards() {
  const response = await client.query({
    query: GET_CARDS_QUERY,
  });
  const resp = await handleResponse(response);
  return resp.getCards;
}

async function addCard(paymentObj) {
  const response = await client.mutate({
    mutation: ADD_PAYMENT_CARD_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: paymentObj,
  });
  const res = await handleResponse(response);
  return res.addCard;
}

async function updateCard(paymentObj) {
  const response = await client.mutate({
    mutation: UPDATE_PAYMENT_CARD_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: paymentObj,
  });
  const res = await handleResponse(response);
  return res.updateCard;
}

async function deleteCard(paymentObj) {
  const response = await client.mutate({
    mutation: DELETE_PAYMENT_CARD_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: paymentObj,
  });
  const res = await handleResponse(response);
  return res.deleteCard;
}

async function payment(paymentObj) {
  const response = await client.mutate({
    mutation: PAYMENT_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: { ...paymentObj, date: new Date() },
  });
  const res = await handleResponse(response);
  if (res.payment) {
    updateDataToLocalStorage({
      isPaid: true,
      isAddOn: paymentObj['isAddOn'],
    });
  }
  return res.payment;
}

async function paymentWithExistingCard(paymentObj) {
  const response = await client.mutate({
    mutation: PAYMENT_MUTATION_EXISTING_CARD,
    context: { useApolloNetworkStatus: true },
    variables: paymentObj,
  });
  const res = await handleResponse(response);
  if (res.payment) {
    updateDataToLocalStorage({
      isPaid: true,
      isAddOn: paymentObj['isAddOn'],
    });
  }
  return res.payment;
}

async function getTotalPayments() {
  const response = await client.query({
    query: GET_TOTAL_PAYMENT_QUERY,
  });
  const resp = await handleResponse(response);
  return resp.income.data;
}

async function getPayments(paymentObj) {
  const response = await client.query({
    query: GET_PAYMENT_QUERY,
    variables: paymentObj,
  });
  const res = await handleResponse(response);
  return res.incomeLogs;
}

async function withdrawAmount() {
  const response = await client.mutate({
    mutation: WITHDRAW_AMOUNT_MUTATION,
    context: { useApolloNetworkStatus: true },
  });
  const res = await handleResponse(response);
  return res.withdraw;
}

async function updateDisputeStatus(paymentObj) {
  const response = await client.mutate({
    mutation: UPDATE_DISPUTE_MUTATION,
    variables: paymentObj,
  });
  const res = await handleResponse(response);
  return res.updateDisputeStatus.message;
}

async function getTaxRate(postalCode: string, countryCode: string) {
  const response = await client.query({
    query: GET_TAX_RATE,
    variables: { postalCode, countryCode },
  });
  const res = await handleResponse(response);
  return res.getTaxRate;
}
