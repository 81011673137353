import React from 'react';
import { connect } from 'react-redux';
import { LandingHeader } from '../../landingHeader';
import { LandingFooter } from '../../landingFooter';
import { ContactForm } from '../contact/contactForm';
import { staticConstants } from '../../../../utils';

class Contact extends React.Component {
  public state = {
    title: staticConstants.LANDING_PAGE_FOOTER_TITLE.CONTACT_US,
  };
  public render() {
    const { title } = this.state;
    return (
      <>
        <LandingHeader title={title}  banner="contactus_banner" />
        <ContactForm />
        <LandingFooter activeTab={staticConstants.CONTENT_MANAGEMENT_TAB.FAQ}/>
      </>
    );
  }
}
function mapStateToProps(state: any) {
  return {};
}

const contactFormPage = connect(mapStateToProps)(Contact);

export { contactFormPage as Contact };
