import React, { Dispatch, createRef } from 'react';
import {
    Button,
    TabContent,
    TabPane,
    Nav,
    Label,
    Form,
    Col,
    Row,
    Input,
    FormGroup,
} from 'reactstrap';
import classnames from 'classnames';
import { Sidebar } from '../../shared/sidebar';
import lockIcon from './../../../assets/images/icons/key.svg';
import { reeValidators, messages, MAGIC_NUMBER, CSS_ClASSES } from '../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';
import { connect } from 'react-redux';
import profileImage from './../../../assets/images/profile.png';
import { ISystemAdminProfile, IValidator, IUploadImgaeReq } from '../../../interfaces/index';
import { LocationDescriptorObject } from 'history';
import { ChangePasswordForm } from '../../shared/updateProfile/ChangePassword';
import { systemAdminAction } from './redux/actions';
import NumberFormat from 'react-number-format';
import ReeValidate from 'ree-validate';
import { validator } from './../../../helpers';
import { profileAction } from './../../shared/updateProfile/redux/actions';
import { ImageCropperPopup } from './../../shared/modals/imageCropperPopup';
import { Header } from '../../shared/header';

validator(ReeValidate);

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  updateProfile: any;
  industries: [];
  history: any;
  location?: LocationDescriptorObject;
}

interface IState {
  activeTab: string;
  formData: ISystemAdminProfile;
  profileImageUpload: File;
  errors: any;
  isOpenCropModal: boolean;
  image: any;
  clearPasswordData: boolean;
}

enum tabs {
  PROFILE = '1',
  PASSWORD = '2',
}
class SystemAdminViewProfile extends React.Component<IProps, IState> {
  public validator: IValidator;
  private readonly fileInput = createRef<HTMLInputElement>();
  constructor(props: IProps) {
    super(props);

    this.validator = new ReeValidate({
      firstName: reeValidators.required_min_3_max_100,
      lastName: reeValidators.required_min_3_max_100,
      phoneNumber: 'required|min:5|max:15',
      professional: 'required|verify_commissioon_limit',
      organization: 'required|verify_commissioon_limit',
      profileImageUpload: 'verify_profile_image|verify_profile_image_size',
    });
    const hash = this.props.location.hash;
    let activeTab = String(tabs.PROFILE);
    if (hash) {
      const num = Number(hash.replace('#', ''));
      if (num && num > MAGIC_NUMBER.ZERO && num < MAGIC_NUMBER.THREE) {
        activeTab = String(num);
      }
    }
    this.state = {
      activeTab,
      clearPasswordData: false,
      formData: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        professional: 0,
        organization: 0,
      },
      profileImageUpload: null,
      errors: this.validator.errors,
      isOpenCropModal: false,
      image: null,
    };
    this.toggle = this.toggle.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  public componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(systemAdminAction.getSystemAdminProfile());
  }

  public componentWillReceiveProps(nextProps: {
    updateProfile: {
      profile: ISystemAdminProfile;
      updateProfile: any;
      uploadingImage: boolean;
    };
  }) {
    const newProfile = nextProps.updateProfile.profile;
    if (newProfile) {
      const formData = {
        firstName: newProfile.firstName,
        lastName: newProfile.lastName,
        phoneNumber: newProfile.phoneNumber,
        professional: newProfile.commission && newProfile.commission.professional,
        organization: newProfile.commission && newProfile.commission.organization,
      };
      this.setState({ formData });
    }
    this.hideModal();
  }

  public toggle(tab: string) {
    this.props.history.replace(`#${tab}`);
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        clearPasswordData: true,
      });
    }
  }

  public validateAndSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { errors } = this.validator;
    this.validator.validateAll(this.state.formData)
      .then((success: boolean) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  public handleSubmit = () => {
    const { dispatch } = this.props;
    const formData = this.state.formData;
    const reqObj: ISystemAdminProfile = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      phoneNumber: formData.phoneNumber,
      commission: {
        professional: Number(formData.professional),
        organization: Number(formData.organization),
      },
    };
    dispatch(systemAdminAction.updateProfile(reqObj));
  }

  public handlePhoneChange = (num: { formattedValue: string }) => {
    this.setState({
      formData: {
        ...this.state.formData,
        phoneNumber: num.formattedValue.replace('-', ''),
      },
    },            () => {
      this.removeErrorAndReValidate('phoneNumber');
    });
  }

  public handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState({ formData: { ...this.state.formData, [name]: value } }, () => {
      this.removeErrorAndReValidate(name);
    });
  }

  public formatInput = (event: any) => {
    const { name, value } = event.target;
    this.setState({ formData: { ...this.state.formData, [name]: parseFloat(value).toFixed(MAGIC_NUMBER.TWO) } });
  }

  public onKeyPress = (event) => {
    if (event.which === MAGIC_NUMBER.THIRTEEN) {
      event.preventDefault();
    }
  }

  public removeErrorAndReValidate = (name: string) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.formData[name])
      .then(() => {
        this.setState({ errors });
      });
  }

  public handleUploadImageSubmit = (e) => {
    e.preventDefault();
    const profileImageUpload = this.fileInput.current.files[0];
    const { errors } = this.validator;
    Object.assign(this.state, { profileImageUpload });
    errors.remove('profileImageUpload');
    this.validator.validate('profileImageUpload', this.state.profileImageUpload)
        .then((success: boolean) => {
          if (success) {
            this.setState({
              isOpenCropModal: true,
              image: profileImageUpload,
            });
          } else {
            this.setState({ errors });
          }
        });
  }

  public savedCroppedImage = (data) => {
    const up: IUploadImgaeReq = { type: 'image/png', upload: data };
    const { dispatch } = this.props;
    dispatch(profileAction.uploadProfileImage(up));
  }

  public hideModal() {
    this.setState({ isOpenCropModal: false });
  }

  public render() {
    const { formData, errors, isOpenCropModal, image } = this.state;
    const { updateProfile } = this.props;
    const getErrClass = (field: string) => {
      return errors.has(field) ? ' has-error' : '';
    };
    return (
            <>
        <Header />
                <Sidebar {...this.props} />
                <div className="dashboard-wrapper">
                    <h2 className="heading heading-sm roboto-medium text-uppercase">User Information</h2>
                    <div className="dashboard-content">
                        <div className="tab-btn-group">
                            <Nav tabs className="border-0">
                                <Button color="secondary" className={`btn btn-50 h-50 tab-btn text-uppercase 
                                ${classnames({ [CSS_ClASSES.btnActive]: this.state.activeTab === '1' })}`}
                                    onClick={() => { this.toggle('1'); }}
                                > Profile</Button>
                                <Button color="secondary" className={`btn btn-50 h-50 tab-btn text-uppercase 
                                ${classnames({ [CSS_ClASSES.btnActive]: this.state.activeTab === '2' })}`}
                                    onClick={() => { this.toggle('2'); }}
                                >Password</Button>
                            </Nav>
                        </div>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <div className="dashboard-content shadow white-bg p-0 m-0">
                                    <Form noValidate onSubmit={this.validateAndSubmit} onKeyPress={this.onKeyPress}>
                                    <div className="create-profile-wrapper w-100 p-0 m-0">
                                        <div className="profile-wrapper box-shadow-none">
                                            <div className="profile-image-wrapper align-items-start flex-wrap">
                                                <div className="profile-upload-wrapper">
                                                    <span className="image-upload">
                                                        <img src={updateProfile && updateProfile.profile &&
                                                                updateProfile.profile.profileImage ?
                                                                updateProfile.profile.profileImage : profileImage}
                                                            className="img-cover" alt="profile"
                                                        />
                                                        <label className="file-icon icon-edit">
                                                            <input ref={this.fileInput} type="file"
                                                                name="profileImageUpload" onChange={this.handleUploadImageSubmit}
                                                                id="uploadCaptureInputFile"
                                                            />
                                                        </label>
                                                    </span>
                                                    <div className={`hint-text`}>
                                                        <p>Image size should be maximum 5mb. <br />File format: jpeg, png.</p>
                                                    </div>
                                                    <div className="error-text">{errors.first('profileImageUpload')}</div>
                                                </div>
                                                <div className="profile-fiels-wrapper">
                                                    <div className="form-wrapper">
                                                        <Row>
                                                            <Col xs="12" sm="6">
                                                                <FormGroup className={`floating-label ${getErrClass('firstName')}`}>
                                                                    <Input type="text"
                                                                        className="form-control" name="firstName" id="firstName"
                                                                        placeholder="First Name" value={formData.firstName}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                    <Label for="firstName">First Name</Label>
                                                                    {errors.has('firstName') &&
                                                                        <div className="error-text">{
                                                                          errors.first('firstName').replace('firstName', 'first name')}
                                                                        </div>}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs="12" sm="6">
                                                                <FormGroup className={`floating-label ${getErrClass('lastName')}`}>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="lastName"
                                                                        id="lastName"
                                                                        placeholder="Last Name"
                                                                        value={formData.lastName}
                                                                        onChange={this.handleChange}
                                                                    />
                                                                    <Label for="lastname">Last Name</Label>
                                                                    {errors.has('lastName') &&
                                                                      <div className="error-text"> { errors.first('lastName').replace('lastName', 'last name')} </div>
                                                                    }
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs="12" sm="6">
                                                                <FormGroup className="floating-label disabled-input disabled">
                                                                    <label>Email</label>
                                                                    <p>{updateProfile && updateProfile.profile &&
                                                                        updateProfile.profile.email}</p>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs="12" sm="6">
                                                                <FormGroup className={`floating-label ${getErrClass('phoneNumber')}`}>
                                                                    <NumberFormat prefix={'+'} className="form-control"
                                                                        name="phoneNumber" mask=""
                                                                        maxLength={MAGIC_NUMBER.FIFTEEN}
                                                                        placeholder="Phone Number"
                                                                        onValueChange={this.handlePhoneChange}
                                                                        value={formData.phoneNumber}
                                                                    />
                                                                    <Label for="phoneNumber">Phone Number</Label>
                                                                    {errors.has('phoneNumber') && <div className="error-text">
                                                                      {messages.invalidPhoneNumber}</div>
                                                                    }
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xs="12">
                                                                <h2 className="heading heading-rg roboto-medium my-4">Commission (%)</h2>
                                                            </Col>
                                                            <Col xs="12">
                                                                <FormGroup className={`floating-label ${getErrClass('professional')}`}>
                                                                    <Input
                                                                        type="number"
                                                                        className="form-control"
                                                                        name="professional"
                                                                        id="professional"
                                                                        placeholder="Individuals"
                                                                        onChange={this.handleChange}
                                                                        value={formData.professional}
                                                                        onBlur={this.formatInput}
                                                                    />
                                                                    <Label for="professional">Individuals</Label>
                                                                    {errors.has('professional') &&
                                                                        <div className="error-text">{errors.first('professional')}</div>
                                                                    }
                                                                </FormGroup>
                                                            </Col>

                                                            <Col xs="12">
                                                                <FormGroup
                                                                    className={`floating-label ${getErrClass('organization')}`}>
                                                                    <Input
                                                                        type="number"
                                                                        className="form-control"
                                                                        name="organization"
                                                                        id="organization"
                                                                        placeholder="Teams"
                                                                        onChange={this.handleChange}
                                                                        value={formData.organization}
                                                                        onBlur={this.formatInput}
                                                                    />
                                                                    <Label for="organization" className="selected">Team</Label>
                                                                    {errors.has('organization') &&
                                                                        <div className="error-text">{errors.first('organization')}</div>
                                                                    }
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="card-footer d-flex align-items-center w-100 justify-content-end">
                                            <Button className="btn btn-primary btn-rg">Update</Button>
                                        </div>
                                    </Form>
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className="tab-panel-wrapper white-bg box-shadow">
                                    <div className="profile-password-wrapper">
                                        <h2 className="heading heading-rg roboto-medium">
                                            Change Password
                                            </h2>
                                        <ChangePasswordForm {...this.props} toggle={this.toggle} clearData={this.state.clearPasswordData} />
                                        <div className="lock-box">
                                            <div className="key-circle">
                                                <img src={lockIcon} alt="key" />
                                                <p>It's a good idea to use a strong password that you don't use elsewhere.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
                <ImageCropperPopup
                    isOpenModal={isOpenCropModal}
                    image={image}
                    savedCroppedImage={this.savedCroppedImage}
                    hideModal={this.hideModal}
                />
            </>
    );
  }
}
function mapStateToProps(state: any) {
  const { updateProfile, systemAdmimUser } = state;
  return {
    updateProfile: systemAdmimUser['isProfileUpdate'] ? systemAdmimUser : updateProfile,
  };
}
const connectedSystemAdminViewProfile = connect(mapStateToProps)(SystemAdminViewProfile);
export { connectedSystemAdminViewProfile as SystemAdminViewProfile };
