import { Line } from 'react-chartjs-2';
import React from 'react';
import { formatCurrency, staticConstants, MAGIC_NUMBER, CSS_ClASSES } from '../../../../utils';

interface IProps {
  chartData?: any;
  type?: string;
  dateSelected?: string;
}

interface IState {
  learningGoalsData: any;
  learnersData: any;
  bidsData: any;
  isProfessional: boolean;
  isExpert: boolean;
  legend: any;
  scales: object;
  isLegend: any;
}

class AreaChart extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      learningGoalsData: {},
      learnersData: {},
      bidsData: {},
      isProfessional: false,
      isExpert: false,
      isLegend: true,
      legend: {
        display: true,
        position: 'top', // place legend on the right side of chart
        labels: {
          fontColor: '#000000',
          boxWidth: 16,
          fontSize: 13,
          padding: 20,
          boxHeight: 16,
          borderWidth: 0,
        },
      },
      scales: {
        yAxes: [{
          ticks: {
            suggestedMin: 0,
            suggestedMax: MAGIC_NUMBER.TEN,
          },
        }],
      },
    };
  }

  public componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    const commonYAsix = {
      yAxes: [{
        ticks: {
          stepSize: 2,
          suggestedMin: 0,
          suggestedMax: MAGIC_NUMBER.TEN,
        },
      }],
    };
    if (user && user.role === staticConstants.ROLE.PROFESSIONAL) {
      this.setState({ isProfessional: true, scales: commonYAsix });
    } else if (user && user.role === staticConstants.ROLE.EXPERT) {
      this.setState({ isExpert: true, scales: commonYAsix });
    } else if (user && (user.role === staticConstants.ROLE.ORGANIZATION_ADMIN
      || user.role === staticConstants.ROLE.ORGANIZATION_LEARNER)) {
      this.setState({ scales: commonYAsix });
    }

    if (window.innerWidth <= MAGIC_NUMBER.FIVE_HUNDRED) {
      const { legend } = this.state;
      legend.display = false;
      this.setState({ isLegend: false, legend });
    }
  }

  public render() {
    const rgbaColorCode = 'rgba(78, 173, 237, 0.2)';
    const rgbaColorCode1 = 'rgba(255, 99, 132, 0.3)';
    const rgbaColorCode2 = 'rgba(184, 248, 220, 0.4)';
    const chartData = JSON.parse(JSON.stringify(this.props.chartData));
    const type = this.props.type;
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May',
      'Jun', 'Jul', 'Aug', 'Sep',
      'Oct', 'Nov', 'Dec',
    ];
    const weeks = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6'];
    const learningGoalslabels = [];
    const investmentGoalslabels = [];
    const learnersLabels = [];
    const activeLearnerData = [];
    const adminGoalData = [];
    const investmentGoalData = [];
    const newRequestData = [];
    const submittedData = [];
    const shortlistedData = [];
    const acceptedData = [];
    const bidsLabels = [];
    const professionalLearningGoal = [];
    const sessionData = [];
    const chartColor = 'rgba(0,0,0,0.8)';
    let data = {};
    let options = {};
    if (type === 'learningGoals' && !this.state.isProfessional) {
      const learningGoalsData = chartData ? chartData : {};
      const yearToLabel = learningGoalsData !== undefined &&
        Object.keys(learningGoalsData).length > MAGIC_NUMBER.ZERO &&
        learningGoalsData.adminGoalCreated !== null &&
        learningGoalsData.adminGoalCreated[0].year;
      if (Object.keys(learningGoalsData).length > MAGIC_NUMBER.ZERO &&
        learningGoalsData.adminGoalCreated !== null &&
        learningGoalsData.adminGoalCreated !== undefined) {
        for (let i = 0; i < learningGoalsData.adminGoalCreated.length; i += 1) {
          let dataMonth = learningGoalsData.newRequest[i].month;
          dataMonth = this.props.dateSelected !== 'lastYear' ? `${months[dataMonth - 1]} ${learningGoalsData.newRequest[i].year}` :
            `${months[dataMonth - 1]}`;
          const dataWeek = weeks[i];
          learningGoalsData && (learningGoalsData.newRequest[i].month === undefined ||
            learningGoalsData.newRequest[i].month === null) ?
            learningGoalslabels.push(dataWeek) :
            learningGoalslabels.push(dataMonth);
          adminGoalData.push(learningGoalsData.adminGoalCreated[i].count);
          newRequestData.push(learningGoalsData.newRequest[i].count);
        }
        data = {
          labels: learningGoalslabels,
          datasets: [
            {
              label: `Total Admin Goals: ${learningGoalsData.totalAdminLearningGoal}`,
              data: adminGoalData, borderWidth: MAGIC_NUMBER.ONE,
              borderColor: '#4eaded',
              backgroundColor: rgbaColorCode,
              index: MAGIC_NUMBER.ZERO
            },
            {
              label: `Total User Goals: ${learningGoalsData.totalLearnerLearningGoal}`,
              data: newRequestData, borderWidth: MAGIC_NUMBER.ONE,
              borderColor: '#fd7290', backgroundColor: rgbaColorCode1,
              index: MAGIC_NUMBER.ONE
            },
          ],
        };
      }
      options = {
        tooltips: {
          custom: (tooltip) => {
            if (!tooltip) return;
          },
          callbacks: {
            title: (tooltipItem: any) => {
              if (tooltipItem[0]['datasetIndex'] === MAGIC_NUMBER.ZERO) {
                return `${data['labels'][tooltipItem[0]['index']]}`;
              }
              if (tooltipItem[0]['datasetIndex'] === MAGIC_NUMBER.ONE) {
                return `${data['labels'][tooltipItem[0]['index']]}`;
              }
            },
            label: (tooltipItem: any) => {
              if (tooltipItem['datasetIndex'] === MAGIC_NUMBER.ZERO) {
                return [`Admin Goals: ${tooltipItem['value']}`];
              }
              if (tooltipItem['datasetIndex'] === MAGIC_NUMBER.ONE) {
                return [`User Goals: ${tooltipItem['value']}`];
              }
            },
          },
          backgroundColor: chartColor,
          bodyAlign: 'left',
          bodyFontColor: '#fff',
          bodySpacing: 8,
          borderColor: CSS_ClASSES.BORDER_COLOR,
          borderWidth: 0,
          cornerRadius: MAGIC_NUMBER.THREE,
          footerFontColor: '#fff',
          footerFontStyle: 'normal',
          titleFontColor: '#fff',
          titleFontStyle: 'normal',
          titleSpacing: 2,
          titleMarginBottom: 15,
          xPadding: 12,
          yPadding: 12,
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              fontSize: 13,
              fontColor: '#2e3543',
              labelString: this.props.dateSelected === 'lastYear' && yearToLabel !== null ? yearToLabel : '',
            },
          }],
          ...this.state.scales
        },
        legend: this.state.legend,
      };

    }
    if (type === 'learningGoals' && this.state.isProfessional) {
      const learningGoalsData = chartData ? chartData : {};
      const yearToLabel = learningGoalsData !== undefined &&
        Object.keys(learningGoalsData).length > MAGIC_NUMBER.ZERO &&
        learningGoalsData.createdGoals !== null &&
        learningGoalsData.createdGoals[0].year;
      if (Object.keys(learningGoalsData).length > MAGIC_NUMBER.ZERO &&
        learningGoalsData.createdGoals !== null &&
        learningGoalsData.createdGoals !== undefined) {
        for (let i = 0; i < learningGoalsData.createdGoals.length; i += 1) {
          let dataMonth = learningGoalsData.createdGoals[i].month;
          dataMonth = this.props.dateSelected !== 'lastYear' ?
            `${months[dataMonth - 1]} ${learningGoalsData.createdGoals[i].year}` :
            `${months[dataMonth - 1]}`;
          const dataWeek = weeks[i];
          learningGoalsData && (learningGoalsData.createdGoals[i].month === undefined ||
            learningGoalsData.createdGoals[i].month === null) ?
            learningGoalslabels.push(dataWeek) :
            learningGoalslabels.push(dataMonth);
          adminGoalData.push(learningGoalsData.createdGoals[i].count);
        }
        data = {
          labels: learningGoalslabels,
          datasets: [
            {
              label: `Total Goals : ${learningGoalsData.totalCreatedGoals}
                  Total Requests Sent : ${learningGoalsData.totalRequestSent}
                  Total Accepted : ${learningGoalsData.totalAccepted}`,
              data: adminGoalData,
              borderWidth: 1,
              borderColor: '#4eaded',
              backgroundColor: rgbaColorCode
            },
          ],
        };
      }
      options = {
        tooltips: {
          custom: (tooltip) => {
            if (!tooltip) return;
          },
          callbacks: {
            title: (tooltipItem: any) => {
              return `${data['labels'][tooltipItem[0]['index']]}:`;
            },
            label: (tooltipItem: any) => {
              return [
                `Goals Created: ${chartData.createdGoals[tooltipItem['index']].count}`,
                `Total Requests Sent: ${chartData.requestSent[tooltipItem['index']].count}`,
                `Total Accepted: ${chartData.accepted[tooltipItem['index']].count}`];
            },
          },
          backgroundColor: chartColor,
          bodyAlign: 'left',
          bodyFontColor: '#fff',
          bodySpacing: 8,
          borderColor: CSS_ClASSES.BORDER_COLOR,
          borderWidth: 0,
          cornerRadius: MAGIC_NUMBER.THREE,
          footerFontColor: '#fff',
          footerFontStyle: 'normal',
          titleFontColor: '#fff',
          titleFontStyle: 'normal',
          titleSpacing: 2,
          titleMarginBottom: 15,
          xPadding: 12,
          yPadding: 12,
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              fontSize: 13,
              fontColor: '#2e3543',
              labelString: this.props.dateSelected === 'lastYear' && yearToLabel !== null ? yearToLabel : '',
            },
          }],
          ...this.state.scales
        },
        legend: {
          display: this.state.isLegend,
          position: 'top', // place legend on the right side of chart
          labels: {
            fontColor: '#000000',
            boxWidth: 0,
            fontSize: 13,
            padding: 20,
            boxHeight: 16,
            borderWidth: 0,
            displayColors: false,
          },
        },
      };
    }
    if (type === 'LearnerlearningGoals') {
      const learningGoalsData = chartData ? chartData : {};
      const yearToLabel = learningGoalsData !== undefined &&
        Object.keys(learningGoalsData).length > MAGIC_NUMBER.ZERO &&
        learningGoalsData.acceptedRequest !== null &&
        learningGoalsData.acceptedRequest[0].year;
      if (Object.keys(learningGoalsData).length > MAGIC_NUMBER.ZERO
        && learningGoalsData.acceptedRequest !== null &&
        learningGoalsData.acceptedRequest !== undefined) {
        for (let i = 0; i < learningGoalsData.acceptedRequest.length; i += 1) {
          let dataMonth = learningGoalsData.requestSent[i].month;
          dataMonth = this.props.dateSelected !== 'lastYear' ?
            `${months[dataMonth - 1]} ${learningGoalsData.requestSent[i].year}` :
            `${months[dataMonth - 1]}`;
          const dataWeek = weeks[i];
          learningGoalsData && (learningGoalsData.requestSent[i].month === undefined ||
            learningGoalsData.requestSent[i].month === null) ?
            learningGoalslabels.push(dataWeek) :
            learningGoalslabels.push(dataMonth);
          adminGoalData.push(learningGoalsData.acceptedRequest[i].count);
          newRequestData.push(learningGoalsData.requestSent[i].count);
        }
        data = {
          labels: learningGoalslabels,
          datasets: [
            {
              label: `Total User Goals: ${learningGoalsData.totalLearnerLearningGoal}`,
              data: newRequestData, borderWidth: 1,
              borderColor: '#fd7290', backgroundColor: rgbaColorCode1
            },
            {
              label: `Total Admin Goals: ${learningGoalsData.totalAdminLearningGoal}`,
              data: adminGoalData, borderWidth: 1, borderColor: '#4eaded',
              backgroundColor: rgbaColorCode
            },
          ],
        };
      }
      options = {
        tooltips: {
          custom: (tooltip) => {
            if (!tooltip) return;
          },
          callbacks: {
            title: (tooltipItem: any) => {
              if (tooltipItem[0]['datasetIndex'] === MAGIC_NUMBER.ZERO
                || tooltipItem[0]['datasetIndex'] === MAGIC_NUMBER.ONE) {
                return `${data['labels'][tooltipItem[0]['index']]}`;
              }
            },
            label: (tooltipItem: any) => {
              if (tooltipItem['datasetIndex'] === MAGIC_NUMBER.ZERO) {
                return `Team Goals: ${tooltipItem['value']}`;
              }
              if (tooltipItem['datasetIndex'] === MAGIC_NUMBER.ONE) {
                return `Admin Goals: ${tooltipItem['value']}`;
              }
            },
          },
          backgroundColor: chartColor,
          bodyAlign: 'left',
          bodyFontColor: '#fff',
          bodySpacing: 8,
          borderColor: CSS_ClASSES.BORDER_COLOR,
          borderWidth: 0,
          cornerRadius: MAGIC_NUMBER.THREE,
          footerFontColor: '#fff',
          footerFontStyle: 'normal',
          titleFontColor: '#fff',
          titleFontStyle: 'normal',
          titleSpacing: 2,
          titleMarginBottom: 15,
          xPadding: 12,
          yPadding: 12,
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              fontSize: 13,
              fontColor: '#2e3543',
              labelString: this.props.dateSelected === 'lastYear' &&
                yearToLabel !== null && yearToLabel !== false ? yearToLabel : '',
            },
          }],
          ...this.state.scales
        },
        legend: this.state.legend,
      };
    }
    if (type === 'learners') {
      const learnersData = chartData ? chartData : {};
      const yearToLabel = learnersData !== undefined &&
        Object.keys(learnersData).length > MAGIC_NUMBER.ZERO &&
        learnersData.activeLearner !== null && learnersData.activeLearner[0].year;
      if (Object.keys(learnersData).length > MAGIC_NUMBER.ZERO &&
        learnersData.activeLearner !== null && learnersData.activeLearner !== undefined) {
        for (let i = 0; i < learnersData.activeLearner.length; i += 1) {
          let dataMonth = learnersData.activeLearner[i].month;
          dataMonth = this.props.dateSelected !== 'lastYear' ?
            `${months[dataMonth - 1]} ${learnersData.activeLearner[i].year}` :
            `${months[dataMonth - 1]}`;
          const dataWeek = weeks[i];
          learnersData && (learnersData.activeLearner[i].month === undefined ||
            learnersData.activeLearner[i].month === null) ?
            learnersLabels.push(dataWeek) :
            learnersLabels.push(dataMonth);
          activeLearnerData.push(learnersData.activeLearner[i].count);
        }
        data = {
          labels: learnersLabels,
          datasets: [
            {
              label: `Total Active Users: ${learnersData.totalActiveLearner}`,
              data: activeLearnerData, borderWidth: 1, borderColor: '#4eed97', backgroundColor: rgbaColorCode2
            },
          ],
        };
      }
      options = {
        scales: {
          ...this.state.scales,
          xAxes: [{
            scaleLabel: {
              display: true,
              fontSize: 13,
              fontColor: '#2e3543',
              labelString: yearToLabel !== null && this.props.dateSelected === 'lastYear' ? yearToLabel : '',
            },
          }]
        },
        legend: this.state.legend,
        tooltips: {
          custom: (tooltip) => {
            if (!tooltip) return;
          },
          callbacks: {
            title: (tooltipItem: any) => {
              const dataset = data['datasets'][0];
              if (tooltipItem[0]['datasetIndex'] === MAGIC_NUMBER.ZERO) {
                return `${data['labels'][tooltipItem[0]['index']]}: ${dataset['data'][tooltipItem[0]['index']]} Active`;
              }
            },
            label: () => {
              return null;
            },
          },
          backgroundColor: chartColor,
          bodyAlign: 'left',
          bodyFontColor: '#fff',
          bodySpacing: 8,
          borderColor: CSS_ClASSES.BORDER_COLOR,
          borderWidth: MAGIC_NUMBER.ZERO,
          cornerRadius: MAGIC_NUMBER.THREE,
          footerFontColor: '#fff',
          footerFontStyle: 'normal',
          titleFontColor: '#fff',
          titleFontStyle: 'normal',
          xPadding: MAGIC_NUMBER.TEN,
          yPadding: MAGIC_NUMBER.TEN,
        },
      };
    }
    if (type === 'bids' && !this.state.isProfessional) {
      const bidsData = chartData ? chartData : {};
      const yearToLabel = bidsData !== undefined &&
        Object.keys(bidsData).length > MAGIC_NUMBER.ZERO &&
        bidsData.newRequest !== null && bidsData.newRequest[0].year;
      if (Object.keys(bidsData).length > MAGIC_NUMBER.ZERO &&
        bidsData.newRequest !== null && bidsData.newRequest !== undefined) {
        for (let i = 0; i < bidsData.newRequest.length; i += 1) {
          let dataMonth = bidsData.newRequest[i].month;
          dataMonth = this.props.dateSelected !== 'lastYear' ?
            `${months[dataMonth - 1]} ${bidsData.newRequest[i].year}` :
            `${months[dataMonth - 1]}`;
          const dataWeek = weeks[i];
          bidsData && (bidsData.newRequest[i].month === undefined ||
            bidsData.newRequest[i].month === null) ?
            bidsLabels.push(dataWeek) :
            bidsLabels.push(dataMonth);
          submittedData.push(bidsData.requestedBids !== null && bidsData.requestedBids[i].count);
          shortlistedData.push(bidsData.shortlistedBids !== null && bidsData.shortlistedBids[i].count);
          acceptedData.push(bidsData.acceptedBids !== null && bidsData.acceptedBids[i].count);
        }
        data = {
          labels: bidsLabels,
          datasets: [
            {
              label: `Submitted: ${bidsData.submitted}`,
              data: submittedData, borderWidth: 1, borderColor: '#fd7290',
              backgroundColor: rgbaColorCode1, index: 0
            },
            {
              label: `Shortlisted: ${bidsData.shortlisted}`,
              data: shortlistedData, borderWidth: 1, borderColor: '#4eaded', backgroundColor: rgbaColorCode, index: 1
            },
            {
              label: `Accepted: ${bidsData.acceptedCount}`,
              data: acceptedData, borderWidth: 1,
              borderColor: '#4eed97', backgroundColor: rgbaColorCode2, index: 2
            },
          ],
        };
      }
      options = {
        tooltips: {
          custom: (tooltip) => {
            if (!tooltip) return;
          },
          callbacks: {
            title: (tooltipItem: any, dataVal: any) => {
              if (tooltipItem[0]['datasetIndex'] === MAGIC_NUMBER.ZERO ||
                tooltipItem[0]['datasetIndex'] === MAGIC_NUMBER.ONE
                || tooltipItem[0]['datasetIndex'] === MAGIC_NUMBER.TWO) {
                return `${dataVal['labels'][tooltipItem[0]['index']]}`;
              }
            },
            label: (tooltipItem: any) => {
              if (tooltipItem['datasetIndex'] === MAGIC_NUMBER.ZERO) {
                return `Submitted: ${tooltipItem['value']}`;
              }
              if (tooltipItem['datasetIndex'] === MAGIC_NUMBER.ONE) {
                return `Shortisted: ${tooltipItem['value']}`;
              }
              if (tooltipItem['datasetIndex'] === MAGIC_NUMBER.TWO) {
                return `Accepted: ${tooltipItem['value']}`;
              }
            },
          },
          backgroundColor: chartColor,
          bodyAlign: 'left',
          bodyFontColor: '#fff',
          bodySpacing: 8,
          borderColor: CSS_ClASSES.BORDER_COLOR,
          borderWidth: 0,
          cornerRadius: MAGIC_NUMBER.THREE,
          footerFontColor: '#fff',
          footerFontStyle: 'normal',
          titleFontColor: '#fff',
          titleFontStyle: 'normal',
          titleSpacing: 2,
          titleMarginBottom: 15,
          xPadding: 12,
          yPadding: 12,
        },
        scales: {
          ...this.state.scales,
          xAxes: [{
            scaleLabel: {
              display: true,
              fontSize: 13,
              fontColor: '#2e3543',
              labelString: yearToLabel !== null && this.props.dateSelected === 'lastYear' ? yearToLabel : '',
            },
          }]
        },
        legend: this.state.legend,
      };
    }
    if (type === 'adminLearningGoals' && !this.state.isProfessional) {
      const learningGoalsData = chartData ? chartData : {};
      const yearToLabel = learningGoalsData !== undefined &&
        Object.keys(learningGoalsData).length > MAGIC_NUMBER.ZERO &&
        learningGoalsData.createdGoalAdmin !== null && learningGoalsData.createdGoalAdmin[0].year;
      if (Object.keys(learningGoalsData).length > MAGIC_NUMBER.ZERO &&
        learningGoalsData.createdGoalAdmin !== null && learningGoalsData.createdGoalAdmin !== undefined) {
        for (let i = 0; i < learningGoalsData.createdGoalAdmin.length; i += 1) {
          let dataMonth = learningGoalsData.createdGoalAdmin[i].month;
          dataMonth = this.props.dateSelected !== 'lastYear' ?
            `${months[dataMonth - 1]} ${learningGoalsData.createdGoalAdmin[i].year}` :
            `${months[dataMonth - 1]}`;
          const dataWeek = weeks[i];
          learningGoalsData && (learningGoalsData.createdGoalAdmin[i].month === undefined ||
            learningGoalsData.createdGoalAdmin[i].month === null) ?
            learningGoalslabels.push(dataWeek) :
            learningGoalslabels.push(dataMonth);
          adminGoalData.push(learningGoalsData.createdGoalAdmin[i].count);
          newRequestData.push(learningGoalsData.createdGoalOrgLearner[i].count);
          professionalLearningGoal.push(learningGoalsData.createdGoalProfessional[i].count);
        }
        data = {
          labels: learningGoalslabels,
          datasets: [
            {
              label: `Total Admin Goals: ${learningGoalsData.totalOrgAdminLearningGoal}`,
              data: adminGoalData, borderWidth: 1, borderColor: '#4eaded',
              backgroundColor: rgbaColorCode
            },
            {
              label: `Total User Goals: ${learningGoalsData.totalOrgLearnerLearningGoal}`,
              data: newRequestData, borderWidth: 1, borderColor: '#fd7290',
              backgroundColor: rgbaColorCode1
            },
            {
              label: `Total Individual Goals: ${learningGoalsData.totalProfessionalLearningGoal}`,
              data: professionalLearningGoal, borderWidth: 1, backgroundColor: 'rgba(171, 247, 214, 0.8)'
            },
          ],
        };
      }
      options = {
        tooltips: {
          custom: (tooltip) => {
            if (!tooltip) return;
          },
          callbacks: {
            title: (tooltipItem: any) => {
              if (tooltipItem[0]['datasetIndex'] === MAGIC_NUMBER.ZERO || tooltipItem[0]['datasetIndex'] === MAGIC_NUMBER.ONE
                || tooltipItem[0]['datasetIndex'] === MAGIC_NUMBER.TWO) {
                return `${data['labels'][tooltipItem[0]['index']]}`;
              }
            },
            label: (tooltipItem: any) => {
              if (tooltipItem['datasetIndex'] === MAGIC_NUMBER.ZERO) {
                return `Admin Goals: ${tooltipItem['value']}`;
              }
              if (tooltipItem['datasetIndex'] === MAGIC_NUMBER.ONE) {
                return `User Goals: ${tooltipItem['value']}`;
              }
              if (tooltipItem['datasetIndex'] === MAGIC_NUMBER.TWO) {
                return `Individual Goals: ${tooltipItem['value']}`;
              }
            },
          },
          backgroundColor: chartColor,
          bodyAlign: 'left',
          bodyFontColor: '#fff',
          bodySpacing: 8,
          borderColor: CSS_ClASSES.BORDER_COLOR,
          borderWidth: 0,
          cornerRadius: MAGIC_NUMBER.THREE,
          footerFontColor: '#fff',
          footerFontStyle: 'normal',
          titleFontColor: '#fff',
          titleFontStyle: 'normal',
          titleSpacing: 2,
          titleMarginBottom: 15,
          xPadding: 12,
          yPadding: 12,
        },
        scales: {
          ...this.state.scales,
          xAxes: [{
            scaleLabel: {
              display: true,
              fontSize: 13,
              fontColor: '#2e3543',
              labelString: yearToLabel !== null && this.props.dateSelected === 'lastYear' ? yearToLabel : '',
            },
          }]
        },
        legend: this.state.legend,
      };
    }
    if (type === 'adminUsers') {
      const usersData = chartData ? chartData : {};
      const yearToLabel = usersData !== undefined &&
        Object.keys(usersData).length > MAGIC_NUMBER.ZERO &&
        usersData.openfielduser !== null && usersData.openfielduser[0].year;
      if (Object.keys(usersData).length > MAGIC_NUMBER.ZERO &&
        usersData.openfielduser !== null && usersData.openfielduser !== undefined) {
        for (let i = 0; i < usersData.openfielduser.length; i += 1) {
          let dataMonth = usersData.openfielduser[i].month;
          dataMonth = this.props.dateSelected !== 'lastYear' ? `${months[dataMonth - 1]} ${usersData.openfielduser[i].year}` :
            `${months[dataMonth - 1]}`;
          const dataWeek = weeks[i];
          usersData && (usersData.openfielduser[i].month === undefined ||
            usersData.openfielduser[i].month === null) ?
            learnersLabels.push(dataWeek) :
            learnersLabels.push(dataMonth);
          activeLearnerData.push(usersData.openfielduser[i].count);
        }
        data = {
          labels: learnersLabels,
          datasets: [
            {
              label: `Total Active: ${usersData.totalUser}`,
              data: activeLearnerData, borderWidth: 1, borderColor: '#4eed97',
              backgroundColor: 'rgba(184, 248, 220, 0.4)'
            },
          ],
        };
      }
      options = {
        scales: {
          ...this.state.scales,
          xAxes: [{
            scaleLabel: {
              display: true,
              fontSize: 13,
              fontColor: '#2e3543',
              labelString: yearToLabel !== null && this.props.dateSelected === 'lastYear' ? yearToLabel : '',
            },
          }]
        },
        legend: this.state.legend,
        tooltips: {
          custom: (tooltip) => {
            if (!tooltip) return;
          },
          callbacks: {
            title: (tooltipItem: any) => {
              const dataset = data['datasets'][0];
              if (tooltipItem[0]['datasetIndex'] === MAGIC_NUMBER.ZERO) {
                return `${data['labels'][tooltipItem[0]['index']]}: ${dataset['data'][tooltipItem[0]['index']]} Active`;
              }
            },
            label: (tooltipItem: any) => {
              return null;
            },
          },
          backgroundColor: chartColor,
          bodyAlign: 'left',
          bodyFontColor: '#fff',
          bodySpacing: 8,
          borderColor: CSS_ClASSES.BORDER_COLOR,
          borderWidth: 0,
          cornerRadius: MAGIC_NUMBER.THREE,
          footerFontColor: '#fff',
          footerFontStyle: 'normal',
          titleFontColor: '#fff',
          titleFontStyle: 'normal',
          xPadding: MAGIC_NUMBER.TEN,
          yPadding: MAGIC_NUMBER.TEN,
        },
      };
    }
    if (type === 'adminRevenue') {
      const revenueData = chartData ? chartData : {};
      const yearToLabel = revenueData !== undefined &&
        Object.keys(revenueData).length > MAGIC_NUMBER.ZERO &&
        revenueData.openfieldSessionFee !== null && revenueData.openfieldSessionFee[0].year;
      if (Object.keys(revenueData).length > MAGIC_NUMBER.ZERO &&
        revenueData.openfieldSessionFee !== null && revenueData.openfieldSessionFee !== undefined) {
        for (let i = 0; i < revenueData.openfieldSessionFee.length; i += 1) {
          let dataMonth = revenueData.openfieldSessionFee[i].month;
          dataMonth = this.props.dateSelected !== 'lastYear'
            ? `${months[dataMonth - 1]} ${revenueData.openfieldSessionFee[i].year}` :
            `${months[dataMonth - 1]}`;
          const dataWeek = weeks[i];
          revenueData && (revenueData.openfieldSessionFee[i].month === undefined ||
            revenueData.openfieldSessionFee[i].month === null) ?
            learningGoalslabels.push(dataWeek) :
            learningGoalslabels.push(dataMonth);
          sessionData.push(revenueData.openfieldSessionFee[i].count);
        }
        data = {
          labels: learningGoalslabels,
          datasets: [
            {
              label: `Total Session Fee: $${formatCurrency(revenueData.sessionFee.toFixed(MAGIC_NUMBER.TWO))}`,
              data: sessionData, borderWidth: 1,
              borderColor: '#4eaded', backgroundColor: rgbaColorCode
            },
            {
              label: `Total Overlap Commission: $${formatCurrency(revenueData.commissionFee.toFixed(MAGIC_NUMBER.TWO))}`,
              borderWidth: MAGIC_NUMBER.ZERO
            },
            {
              label: `Total Expert Fee: $${formatCurrency(revenueData.expertFee.toFixed(MAGIC_NUMBER.TWO))}`,
              borderWidth: MAGIC_NUMBER.ZERO
            },
          ],
        };
      }
      options = {
        tooltips: {
          custom: (tooltip) => {
            if (!tooltip) return;
          },
          callbacks: {
            title: (tooltipItem: any) => {
              const dataset = data['datasets'][0];
              if (tooltipItem[0]['datasetIndex'] === MAGIC_NUMBER.ZERO) {
                return `${data['labels'][tooltipItem[0]['index']]}: $${dataset['data'][tooltipItem[0]['index']]} Session Fee`;
              }
            },
            label: (tooltipItem: any) => {
              if (tooltipItem['datasetIndex'] === MAGIC_NUMBER.ZERO) {
                return [`Overlap Commission : $${formatCurrency(chartData.openfieldCommission[tooltipItem['index']].count.toFixed(MAGIC_NUMBER.TWO))}`,
                `Expert Fee : $${formatCurrency(chartData.openfieldExpertFee[tooltipItem['index']].count.toFixed(MAGIC_NUMBER.TWO))}`];
              }
            },
          },
          backgroundColor: chartColor,
          bodyAlign: 'left',
          bodyFontColor: '#fff',
          bodySpacing: 8,
          borderColor: CSS_ClASSES.BORDER_COLOR,
          borderWidth: MAGIC_NUMBER.ZERO,
          cornerRadius: MAGIC_NUMBER.THREE,
          footerFontColor: '#fff',
          footerFontStyle: 'normal',
          titleFontColor: '#fff',
          titleFontStyle: 'normal',
          titleSpacing: 2,
          titleMarginBottom: 15,
          xPadding: 12,
          yPadding: 12,
        },
        scales: {
          ...this.state.scales,
          xAxes: [{
            scaleLabel: {
              display: true,
              fontSize: 13,
              fontColor: '#2e3543',
              labelString: yearToLabel !== null && this.props.dateSelected === 'lastYear' ? yearToLabel : '',
            },
          }]
        },
        legend: this.state.legend,
      };
    }
    if (type === 'totalInvestment') {
      const investmentData = chartData ? chartData : {};
      let yearToLabel = null;
      if (this.state.isExpert) {
        yearToLabel = investmentData !== undefined &&
          Object.keys(investmentData).length > MAGIC_NUMBER.ZERO &&
          investmentData.groupedIncome !== null && investmentData.groupedIncome[0].year;
        if (Object.keys(investmentData).length > MAGIC_NUMBER.ZERO &&
          investmentData.groupedIncome !== null && investmentData.groupedIncome !== undefined) {
          for (let i = 0; i < investmentData.groupedIncome.length; i += 1) {
            let dataMonth = investmentData.groupedIncome[i].month;
            dataMonth = this.props.dateSelected !== 'lastYear' ?
              `${months[dataMonth - 1]} ${investmentData.groupedIncome[i].year}` :
              `${months[dataMonth - 1]}`;
            const dataWeek = weeks[i];
            investmentData && (investmentData.groupedIncome[i].month === undefined ||
              investmentData.groupedIncome[i].month === null) ?
              investmentGoalslabels.push(dataWeek) :
              investmentGoalslabels.push(dataMonth);
            investmentGoalData.push(investmentData.groupedIncome[i].totalEvent);
          }
        }
      } else {
        yearToLabel = investmentData !== undefined
          && Object.keys(investmentData).length > MAGIC_NUMBER.ZERO &&
          investmentData.totalInvestment !== null && investmentData.totalInvestment[0].year;
        if (Object.keys(investmentData).length > MAGIC_NUMBER.ZERO &&
          investmentData.totalInvestment !== null && investmentData.totalInvestment !== undefined) {
          for (let i = 0; i < investmentData.totalInvestment.length; i += 1) {
            let dataMonth = investmentData.totalInvestment[i].month;
            dataMonth = this.props.dateSelected !== 'lastYear'
              ? `${months[dataMonth - 1]} ${investmentData.totalInvestment[i].year}` :
              `${months[dataMonth - 1]}`;
            const dataWeek = weeks[i];
            investmentData && (investmentData.totalInvestment[i].month === undefined ||
              investmentData.totalInvestment[i].month === null) ?
              investmentGoalslabels.push(dataWeek) :
              investmentGoalslabels.push(dataMonth);
            investmentGoalData.push(investmentData.totalInvestment[i].totalEvent);
          }
        }
      }

      data = {
        labels: investmentGoalslabels,
        datasets: [
          {
            label: '',
            data: investmentGoalData,
            borderWidth: 1,
            borderColor: this.state.isExpert ? '#4eaded' : '#00cc99',
            backgroundColor: this.state.isExpert ? rgbaColorCode : 'rgba(0, 204, 153, 0.2)'
          },
        ],
      };

      options = {
        tooltips: {
          custom: (tooltip) => {
            if (!tooltip) return;
          },
          callbacks: {
            title: (tooltipItem: any) => {
              return this.state.isExpert ? 'Total Income' : 'Total Spend';
            },
            label: (tooltipItem: any) => {
              if (this.state.isExpert) {
                const totalIncomeEvents = chartData.groupedIncome[tooltipItem['index']].totalEvent;
                const amountIncomeSpent = chartData.groupedIncome[tooltipItem['index']].totalIncome;
                return [`Events: ${totalIncomeEvents} `,
                `Cost: $${amountIncomeSpent ? formatCurrency(chartData.groupedIncome[tooltipItem['index']].totalIncome.toFixed(MAGIC_NUMBER.TWO)) : '0.00'}`];
              }
              const totalEvents = chartData.totalInvestment[tooltipItem['index']].totalEvent;
              const amountSpent = chartData.totalInvestment[tooltipItem['index']].amountSpent;
              return [`Events: ${totalEvents} `,
              `Cost: $${amountSpent ? formatCurrency(chartData.totalInvestment[tooltipItem['index']].amountSpent.toFixed(MAGIC_NUMBER.TWO)) : '0.00'}`];
            },
          },
          backgroundColor: chartColor,
          bodyAlign: 'left',
          bodyFontColor: '#fff',
          bodySpacing: 8,
          borderColor: CSS_ClASSES.BORDER_COLOR,
          borderWidth: 0,
          cornerRadius: MAGIC_NUMBER.THREE,
          footerFontColor: '#fff',
          footerFontStyle: 'normal',
          titleFontColor: '#fff',
          titleFontStyle: 'normal',
          titleSpacing: 2,
          titleMarginBottom: 15,
          xPadding: 12,
          yPadding: 12,
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              fontSize: 13,
              fontColor: '#2e3543',
              labelString: this.props.dateSelected === 'lastYear' && yearToLabel !== null ? yearToLabel : '',
            },
          }],
          ...this.state.scales
        },
        legend: {
          position: 'top',
          display: this.state.isLegend, // place legend on the right side of chart
          labels: {
            fontColor: '#000000',
            boxWidth: 0,
            fontSize: 13,
            padding: 20,
            boxHeight: 16,
            borderWidth: 0,
            displayColors: false,
          },
        },
      };
    }
    return (
      <>
        <Line data={data} options={options} />
      </>
    );
  }
}
export { AreaChart };
