import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { MAGIC_NUMBER } from '../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  modalPopupObj: any;
  acceptedBidId?: string;
  role: string;
  getExtendSessionFee: (bidId: string, duration: number) => void;
  hideModal(): void;
  onClickYes(): void;
}

interface IState {
  modal: boolean;
  bidamount?: number;
}

// TODO: use this popup on event detail page
class EventExtendConfirmPopup extends React.Component<IProps, IState> {

  public static getDerivedStateFromProps(props: IProps, state: IState) {
    return null;
  }
  constructor(props: IProps) {
    super(props);
    this.state = {
      modal: props.isOpenModal,
      bidamount: 0,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  public componentDidMount() {
    const { modalPopupObj, acceptedBidId } = this.props;
    this.props.getExtendSessionFee(acceptedBidId, modalPopupObj.duration);
  }
  public hideModal() {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public render() {
    const { modal } = this.state;
    const { modalPopupObj } = this.props;
    function createMarkup() { return { __html: `${modalPopupObj.body}<br /> ${modalPopupObj.areYouSure}` }; }
    return (
      <Modal isOpen={modal} className="delete-modal">
        <ModalBody>
          <h2 className="heading heading-lg roboto-bold text-center">
            {modalPopupObj.title}</h2>

          <div className="form-details w-100 text-left mt-2">
            <div className="form-data">
              <span className="d-block text-center">Duration</span>
              <p className="p-0 mb-0 w-100 text-center">
                {modalPopupObj.duration > MAGIC_NUMBER.TWO ?
                  modalPopupObj.duration / MAGIC_NUMBER.SIXTY : modalPopupObj.duration}
                {modalPopupObj.duration > MAGIC_NUMBER.ONE ? 'hrs' : 'hr'}</p>
            </div>
          </div>
          <p dangerouslySetInnerHTML={createMarkup()} />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          {modalPopupObj.noBtnText ?
            <Button className="btn btn-regular mx-1"
              onClick={this.hideModal}>{modalPopupObj.noBtnText}</Button>
            : ''}
          {modalPopupObj.yesBtnText ?
            <Button color="primary" className={`btn mx-2 ${modalPopupObj.yesBtnClass
              ? modalPopupObj.yesBtnClass : 'btn-delete'}`}
              onClick={this.props.onClickYes}>{modalPopupObj.yesBtnText}</Button>
            : ''}
        </ModalFooter>
      </Modal>
    );
  }
}

const connectedEventExtendConfirmPopupPage = connect(null)(EventExtendConfirmPopup);
export { connectedEventExtendConfirmPopupPage as EventExtendConfirmPopup };
