import { actionTypes } from './types';

interface IAction {
  type: string;
  data: {
    linkedIn?: string;
    twitter?: string;
    notifications?: boolean;
    facebook?: string;
  };
  error: string;
}

export function updateProfile(state = { status: {} }, action: IAction) {
  switch (action.type) {
    case actionTypes.GET_PROFILE_REQUEST:
      return { fetchingProfile: true };
    case actionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.data,
        fetchingProfile: false,
        updateProfile: true,
        feedBackCount: 0,
      };
    case actionTypes.GET_PROFILE_FAILURE:
      return {
        ...state,
        fetchingProfile: false,
      };

    case actionTypes.UPDATE_PROFILE_IMAGE_REQUEST:
      return {
        ...state,
        uploadingImage: true,
      };

    case actionTypes.UPDATE_PROFILE_IMAGE_SUCCESS:
      const data = state['profile'];
      data['profileImage'] = action.data['path'];
      return {
        ...state,
        profile: data,
        updateProfile: false,
        uploadingImage: false,
      };
    case actionTypes.UPDATE_PROFILE_IMAGE_FAILURE:
      return { requestingForgetPassword: false, error: action.error, uploadingImage: false };

    case actionTypes.FETCH_INDUSTRY_SUGGESTIONS_REQUEST:
      return {
        ...state,
        requestingIndustries: true,
        updateProfile: false,
      };

    case actionTypes.FETCH_INDUSTRY_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        requestingIndustries: false,
        industries: action.data,
        updateProfile: false,
      };

    case actionTypes.FETCH_INDUSTRY_SUGGESTIONS_FAILURE:
      return {
        ...state,
        requestingIndustries: false,
        updateProfile: false,
        error: action.error,
      };

    case actionTypes.FETCH_SECTOR_SUGGESTIONS_REQUEST:
      return {
        ...state,
        requestingSectors: true,
        updateProfile: false,
      };

    case actionTypes.FETCH_SECTOR_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        requestingSectors: false,
        sectors: action.data,
        updateProfile: false,
      };

    case actionTypes.FETCH_SECTOR_SUGGESTIONS_FAILURE:
      return {
        ...state,
        requestingSectors: false,
        error: action.error,
        updateProfile: false,
      };

    case actionTypes.GET_PAYMENT_LOGIN_LINK_SUCCESS:
      return {
        ...state,
        loginLink: action.data,
      };

    case actionTypes.RESET_PAYMENT_LOGIN_LINK:
      return {
        ...state,
        loginLink: undefined,
      };

    case actionTypes.UPDATE_PAYMENT_ACCOUNT_ID_SUCCESS:
      const profile = state['profile'];
      profile['stripeAccountId'] = action.data['stripeAccountId'];
      return {
        ...state,
        profile,
      };

    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        error: action.error,
        updateProfile: true,
        profile: action.data,
      };

    case actionTypes.GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: action.data,
        status: { getSubscriptionSuccess: true },
      };

    case actionTypes.GET_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        status: { getSubscriptionSuccess: false },
        error: action.error,
      };

    case actionTypes.GET_SUBSCRIPTION_HISTORY_SUCCESS:
      return {
        ...state,
        subscriptionHistory: action.data,
        status: { getSubscriptionHistorySuccess: true },
      };

    case actionTypes.GET_SUBSCRIPTION_HISTORY_FAILURE:
      return {
        ...state,
        status: { getSubscriptionHistorySuccess: false },
        error: action.error,
      };

    case actionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      state['profile'] = { ...state['profile'] };
      return {
        ...state,
        status: { cancelSubscriptionSuccess: true },
      };

    case actionTypes.CANCEL_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        status: { cancelSubscriptionSuccess: false },
        error: action.error,
      };

    case actionTypes.UPDATE_SETTING_SUCCESS:
      const { linkedIn, twitter, notifications, facebook } = action.data;
      state['profile'] = { ...state['profile'], linkedIn, twitter, notifications, facebook };
      return {
        ...state,
      };

    case actionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
      };
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordSuccess: true,
      };
    case actionTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        requestingChangePassword: false, error: action.error,
      };
    case actionTypes.SHOW_FEEDBACK_FORM:
      return {
        ...state,
        feedBackCount: action.data,
      };
    case actionTypes.PAYMENTS_LIST_REQUEST:
      return {
        ...state,
        updateDisputeStatus: false,
      };
    case actionTypes.PAYMENTS_LIST_SUCCESS:
      return {
        ...state,
        count: action.data && action.data['count'],
        list: action.data && action.data['data'],
      };
    default:
      return state;
  }
}
