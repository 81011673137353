import React from 'react';
import { Sidebar } from '../../shared/sidebar';
import classnames from 'classnames';
import {
  Button,
  Nav,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  UncontrolledTooltip
} from 'reactstrap';
import Select from 'react-select';
import { Back } from '../back/back';
import { customSelectStyles, MAGIC_NUMBER, CSS_ClASSES } from '../../../utils/';
interface Istate {
  activeTab: any;
  modal: any;
}
class ContentManagementContainer extends React.Component<Istate> {
  public state = {
    activeTab: '1',
    modal: false,
  };
  public toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }
  public render() {
    return (
      <React.Fragment>
        <Sidebar />
        <div className="dashboard-wrapper">
          <Back {...this.props} />
          <h2 className="heading heading-sm roboto-medium text-uppercase">Content Management</h2>
          <div className="dashboard-content box-shadow add-message-box">
            <h2 className="heading heading-md roboto-medium">Add Message</h2>
            <p className="form-small-text">Select User Type</p>
            <div className="d-flex mb-4">
              <label className="control control--radio mr-4 pl-4">
                <Input
                  type="radio"
                  name="modeOfDelivery"
                  value="both"
                />
                  Team
                    <div className="control__indicator" />
              </label>
              <label className="control control--radio mr-4 pl-4">
                <Input
                  type="radio"
                  name="modeOfDelivery"
                  value="audio"
                />
                  Users (Experts, Individual & Team Members)
                    <div className="control__indicator" />
              </label>
            </div>
            <div className="select-group-box">
              <FormGroup className="floating-label disabled-input">
                <Select
                  name="title"
                  styles={customSelectStyles}
                  id="title"
                  placeholder="Select Group"
                  className="react-select-box select-box-group select-box-group-static"
                />
                <Label for="title" className="selected">Select Group</Label>
              </FormGroup>
              <div className="d-flex justify-content-between add-button-group mt-2">
                <Button className="btn btn-icon "><em>+</em>Add Group</Button>
              </div>
            </div>
            <div className="select-group-box">
              <FormGroup className="floating-label">
                <Input
                  type="text"
                  className="form-control"
                  id="groupName"
                  placeholder="Group Name"
                />
                <Label for="groupName">Group Name</Label>
              </FormGroup>
              <div className="d-flex justify-content-between add-button-group mt-2">
                <Button className="btn btn-icon delete"><span className="icon icon-delete" />Remove</Button>
                <Button className="btn btn-icon "><em>+</em>Add Group</Button>
              </div>
            </div>
          </div>

          <div className="dashboard-content box-shadow white-bg">
            <div className="w-100 add-message-box">
              <FormGroup
                className="floating-label disabled-input textarea-label mb-3">
                <textarea
                  className="form-control textarea-md"
                  id="bidDetails"
                  name="bidDetails"
                  placeholder="Bid Details"
                  maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                />
                <span className="text-count">34/500</span>
                <Label for="bidDetail" className="selected">Bid Detail</Label>
              </FormGroup>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-end mt-0">
              <Button className="btn btn-regular">Cancel</Button>
              <Button color="primary" className="btn ml-3">Send</Button>
            </div>
          </div>
          <div className="tab-btn-group content-tabs">
            <Nav tabs className="border-0">
              <Button
                color="secondary"
                className={`btn w-25 h-50 tab-btn text-uppercase ${classnames({ [CSS_ClASSES.btnActive]: this.state.activeTab === '1' })}`}
                onClick={() => { this.toggle('1'); }}
              >
                About Us
                </Button>
              <Button
                color="secondary"
                className={`btn w-25 h-50 tab-btn text-uppercase ${classnames({ [CSS_ClASSES.btnActive]: this.state.activeTab === '2' })}`}
                onClick={() => { this.toggle('2'); }}
              >
                FAQs
                </Button>
              <Button
                color="secondary"
                className={`btn w-25 h-50 tab-btn text-uppercase ${classnames({ [CSS_ClASSES.btnActive]: this.state.activeTab === '3' })}`}
                onClick={() => { this.toggle('3'); }}
              >
                Terms & Conditions
                </Button>
              <Button
                color="secondary"
                className={`btn w-25 h-50 tab-btn text-uppercase ${classnames({ [CSS_ClASSES.btnActive]: this.state.activeTab === '4' })}`}
                onClick={() => { this.toggle('4'); }}
              >Privacy Policy
                </Button>
            </Nav>
          </div>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">About Us
                <div className="w-100 d-flex justify-content-end mt-4">
                <button type="button" className="btn btn-regular">Cancel</button>
                <button type="button" className="btn btn-primary ml-3">Update</button>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="filter-wrapper">
                <div className="search-bar">
                  <input type="search" placeholder="Search by Keywords, Title" />
                  <span className="icon icon-search-icon" />
                </div>
                <button type="button" className="btn btn-primary">Add FAQs</button>
              </div>
              <div className="faq-list-wrapper">
                <div className="faq-list">
                  <FormGroup
                    className="floating-label">
                    <Input
                      type="text"
                      className="form-control"
                      name="question"
                      id="question"
                      placeholder="Question"
                    />
                    <Label for="question">Question</Label>
                  </FormGroup>
                  <FormGroup
                    className="floating-label disabled-input textarea-label answer-textarea">
                    <textarea
                      className="form-control textarea-md"
                      id="answer"
                      name="answer"
                      placeholder="Answer"
                      maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                    />
                    <Label for="answer">Answer</Label>
                    <span className="text-count">50/500</span>
                  </FormGroup>
                  <div className="w-100 d-flex mt-4">
                    <button type="button" className="btn btn-regular">Cancel</button>
                    <button type="button" className="btn btn-primary ml-3">Update</button>
                  </div>
                </div>
                <div className="faq-list">
                  <h2 className="heading heading-md roboto-medium">Ut lectus arcu bibendum at varius?
                    <span>
                      <em className="icon icon-edit" id="edit-content" />
                      <UncontrolledTooltip placement="top" target="edit-content" >Edit</UncontrolledTooltip>
                      <em className="icon icon-delete" id="delete-content" />
                      <UncontrolledTooltip placement="top" target="delete-content" >Delete</UncontrolledTooltip>
                    </span>
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                       nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                </div>
                <div className="faq-list">
                  <h2 className="heading heading-md roboto-medium">Ut lectus arcu bibendum at varius?
                    <span>
                      <em className="icon icon-edit" />
                      <em className="icon icon-delete" />
                    </span>
                  </h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                     sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                     Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                       in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                </div>
                <div className="add-faqs">
                  <div className="faq-list">
                    <h2 className="heading heading-md roboto-medium mb-5">Add FAQs</h2>
                    <FormGroup
                      className="floating-label">
                      <Input
                        type="text"
                        className="form-control"
                        name="question"
                        id="question"
                        placeholder="Question"
                      />
                      <Label for="question">Question</Label>
                    </FormGroup>
                    <FormGroup
                      className="floating-label disabled-input textarea-label answer-textarea">
                      <textarea
                        className="form-control textarea-md"
                        id="answer"
                        name="answer"
                        placeholder="Answer"
                        maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                      />
                      <Label for="answer">Answer</Label>
                      <span className="text-count">50/500</span>
                    </FormGroup>
                  </div>
                  <div className="card-footer mb-5 justify-content-end">
                    <button type="button" className="btn btn-regular">Cancel</button>
                    <button type="button" className="btn btn-primary ml-3">Update</button>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="3">Terms & Conditions
                <div className="w-100 d-flex justify-content-end mt-4">
                <button type="button" className="btn btn-regular">Cancel</button>
                <button type="button" className="btn btn-primary ml-3">Update</button>
              </div>
            </TabPane>
            <TabPane tabId="4">Privacy Policy
                <div className="w-100 d-flex justify-content-end mt-4">
                <button type="button" className="btn btn-regular">Cancel</button>
                <button type="button" className="btn btn-primary ml-3">Update</button>
              </div>
            </TabPane>
          </TabContent>
          <div className="dashboard-content" />
        </div>
        <Modal isOpen={this.state.modal} className="feedback-modal">
          <span className="close-btn icon icon-cross" />
          <ModalBody>
            <h2 className="heading heading-lg roboto-medium mb-0">Chester Myers</h2>
            <em>chester.myers@gmail.com</em>
            <div className="feedback-data">
              <span>Phone Number</span>
              <p>025-203-9649</p>
            </div>
            <div className="feedback-data">
              <span>Message</span>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                 sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation.</p>
            </div>
            <FormGroup
              className="floating-label disabled-input textarea-label answer-textarea mb-4 mt-5">
              <textarea
                className="form-control textarea-md"
                id="reply"
                name="reply"
                placeholder="Feedback Reply"
                maxLength={MAGIC_NUMBER.CHAR_LENGTH}
              />
              <Label for="reply">Feedback Reply</Label>
              <span className="text-count">50/500</span>
            </FormGroup>
          </ModalBody>
          <div className="card-footer d-flex mt-0">
            <button type="button" className="btn btn-icon delete">
               <span className="icon icon-delete" /> Delete</button>
            <button type="button" className="btn btn-primary ml-auto">send</button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export { ContentManagementContainer };
