import { appFeedbackServices } from '../../../../services';
import { alertActions } from '../../../alert/redux/actions';
import { endLoading, startLoading } from '../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { handleError } from './../../../../utils';

export const appFeedbackAction = {
  getApplicationFeedback,
  sendFeedbackReply,
  deleteFeedback,
};

function getApplicationFeedback(reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
      dispatch(startLoading('MAIN_LOADER', 'Loading'));
      dispatch(request(reqObj));
      appFeedbackServices.getAppFeedbackLists(reqObj)
            .then(
                (response) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(response));
                },
                (error) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
    };

  function request(data: string) { return { type: actionTypes.APPLICATION_FEEDBACK_LIST_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.APPLICATION_FEEDBACK_LIST_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.APPLICATION_FEEDBACK_LIST_FAILURE, error }; }
}

function sendFeedbackReply(reqObj: object, listReqObj: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
      appFeedbackServices.replyFeedback(reqObj)
            .then(
                (response) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(response));
                  dispatch(getApplicationFeedback(listReqObj));
                  dispatch(alertActions.successAlert(response.message));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
    };
  function success(data: any) { return { type: actionTypes.SEND_REPLY_FEEDBACK_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.SEND_REPLY_FEEDBACK_FAILURE, error }; }
}

function deleteFeedback(userId: string, reqObj: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
      appFeedbackServices.deleteFeedback({ userId })
            .then(
                (data) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(userId));
                  dispatch(getApplicationFeedback(reqObj));
                  dispatch(alertActions.successAlert(data.message));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
    };
  function success(data: any) { return { type: actionTypes.DELETE_FEEDBACK_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.DELETE_FEEDBACK_FAILURE, error }; }
}
