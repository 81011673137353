import gql from 'graphql-tag';

const GET_NOTIFICATION_LIST_QUERY = gql`
  query getNotifications($limit: Int, $page: Int, $filterTypes: String) {
        getNotifications(limit: $limit, page: $page, filterTypes: $filterTypes) {
      message,
      count,
      data {
        goalId {
          title,
          _id,
          status,
        }
        bidId {
          proposedAt,
          proposedDate,
        }
        duration,
        operation,
        amount,
        isRead,
        userId {
          firstName,
          lastName,
          role,
          _id,
        },
        notifierId {
           firstName,
           lastName,
           _id
        },
        actionOnUserId{
          firstName,
           lastName
           role,
           _id,
        },
        createdAt,
        _id
      },
      unreadCount,
    }
}`;

export { GET_NOTIFICATION_LIST_QUERY };
