import moment from 'moment';
import React, {Dispatch}  from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { goalStatus, ACTION_CLASSES, staticConstants, pageRoutes, getGoalStatusText, formatCurrency, MAGIC_NUMBER } from '../../../utils';
import { Input, UncontrolledTooltip } from 'reactstrap';
import { history } from '../../../helpers';
import { userAction } from '../../systemAdmin/userManagement/redux/actions';
import OpenFieldTypes from 'OpenFieldTypes';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  user?: any
  tableContent: any;
  item?: any;
  updateStatus?: any;
  selectedUsers?: Array<[]>;
  selectedOrganization?: any;
  deleteItem?: any;
  publishTopic?: any;
  updateHourlyRate?: any;
  updateUserRequest?: any;
  updateLicenceMovementRequest?: any;
  updateFlaggedComment?: any;
  activeSubTab?: string;
  activeTab?: string;
  parentTab?: string;
  reVerifyUser?: (userId: string) => void;
  editTags?: (type: string, itemData: object) => void;
}

class ListItem extends React.Component<IProps> {

  public handleAction = (item) => {
    const { tableContent, selectedUsers, selectedOrganization } = this.props;
    if (tableContent.action &&
       tableContent.action['actionType'] === 'checkbox' &&
        tableContent.action['type'] === 'payment') {
          return selectedUsers.includes(item.bidData && item.bidData._id);
        }

    if (tableContent.action &&
       tableContent.action['actionType'] === 'checkbox') return selectedUsers.includes(item._id);

    if (tableContent.action &&
       tableContent.action['actionType'] === 'radio' &&
        selectedOrganization) return selectedOrganization._id === item._id;

    return false;
  }

  /**
   * @description
   * reVerifyUser is used to re send mail to un verified user
   * @param fields {string} userId
   */
  public teamMemberVerifyUser = (userId: string) => {
    const reqObj = {
      userId,
    };
    this.props.dispatch(userAction.reVerifyUserEmail(reqObj));
  }

  public renderDate(item: any, key: string) {
    let date = '';
    switch (key) {
      case 'createdAt':
        date = moment(item['createdAt']).format(staticConstants.DATE_FORMAT);
        break;
      case 'bid.rejectedAt':
        date = moment(item['bid'][0]['rejectedAt']).format(staticConstants.DATE_FORMAT);
        break;
      case 'createdTime':
        date = moment(item.scheduleAt).format(staticConstants.TIME_FORMAT);
        break;
      case 'proposedDate':
        const proposedDate = item['bid'] && item['bid'].length > MAGIC_NUMBER.ZERO &&
         item['bid'][0] !== null && item['bid'][0]['proposedDate'];
        date = moment(proposedDate).format(staticConstants.DATE_FORMAT);
        break;
      case 'goalCreated':
        const createdAt = item['createdAt'];
        date = moment(createdAt).format(staticConstants.DATE_FORMAT);
        break;
      case 'dateAvailability':
        const fromDate = item['fromDate'];
        const toDate = item['toDate'];
        date = `${moment(fromDate).format(staticConstants.DATE_FORMAT)} - ${moment(toDate).format(staticConstants.DATE_FORMAT)}`;
        break;
      case 'rejectedAt':
      case 'acceptedAt':
        const status = key === 'rejectedAt' ? goalStatus.REJECT : goalStatus.ACCEPT;
        const timeline = _.find(item['statusTimeline'], (it) => {
          return it.status === status;
        });
        if (timeline) { date = moment(timeline['createdAt']).format(staticConstants.DATE_FORMAT); }
        break;
      case 'licenceMovementRequestDate':
        const licenceRequest = item['licenceMovement']['date'];
        date = moment(licenceRequest).format(staticConstants.DATE_FORMAT);
        break;
      case 'date':
        date = moment(item.date).format(staticConstants.DATE_FORMAT);
        break;
      case 'bidData.endedAt':
        const eventDate = item['bidData'] && item['bidData']['endedAt'];
        date = moment(eventDate).format(staticConstants.DATE_FORMAT);
        break;
      default:
        break;
    }
    return date;
  }

  /**
   * @description
   * renderData is called to render the table data
   */
  public renderData = () => {
    const { tableContent, item } = this.props;
    const htmlContent = [];
    let dateAvailability, eventTime, bidStatus = '';
    let expertName;
    if (item) {
      bidStatus =
        (item.bidCount) < 1
          ? 'Awaiting Bids'
          : item.bidCount === MAGIC_NUMBER.ONE
            ? ' 1 Bid Received'
            : `${item.bidCount} Bids Received`;
    }
    
    if (item && item.bid && item.bid.length > MAGIC_NUMBER.ZERO && item.bid[0] !== null && item.bid[0].expertInfo) {
      const bidData = item.bid[0];
      expertName = `${bidData.expertInfo.firstName} ${bidData.expertInfo.lastName}`;
      dateAvailability = moment(bidData.proposedDate).format(staticConstants.DATE_FORMAT);
      eventTime = moment(bidData.proposedTime).format(staticConstants.TIME_FORMAT);
    }

    for (const key in tableContent) {
      switch (key) {
        case 'category':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Category"
              key={key}>
              <span>{item.category && item.category.name}</span>
            </div>,
          );
          break;
        case 'subCategory':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Sub Category"
              key={key}>
              <span>{item.subCategory && item.subCategory.name}</span>
            </div>,
          );
          break;
        case 'domain':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Domain"
              key={key}>
              <span>{item.domain && item.domain.name}</span>
            </div>,
          );
          break;
        case 'subDomain':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Sub Domain"
              key={key}>
              <span>{item.subDomain && item.subDomain.name}</span>
            </div>,
          );
          break;
        case 'title':
         let isSystemAdmin = false;
          if (item.bid && item.bid.length && this.props.user && this.props.user.role === staticConstants.ROLE.SYSTEM_ADMIN) {
            isSystemAdmin = true;
          }
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Title" key={key}>
              { !isSystemAdmin ? <span className="roboto-medium link-btn text-truncate">
                {item[key]}
              </span> : <span >{item[key]}</span>}
            </div>,
          );
          break;
        case 'type':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={tableContent[key]['name']} key={key}>
              <span>{this.props.activeTab === staticConstants.EXPERT_PAYMENT_TAB.PENDING ?
                staticConstants.PENDING_PAYMENT_STATUS_VALUE[item[key]] : staticConstants.RECEIVED_PAYMENT_STATUS_VALUE[item[key]]}</span>
            </div>,
          );
          break;
        case 'goalData.title':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Title" key={key}>
              <span className="roboto-medium link-btn text-truncate" >{item['goalData'] && item['goalData']['title']}</span>
            </div>,
          );
          break;
        case 'bidData.id':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Goal ID" key={key}>
              {item['goalData'] && <span 
                className="roboto-medium link-btn text-truncate">
                <span>{item['bidData']['_id']}</span>
              </span>}
            </div>,
          );
          break;
        case 'expertName':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={tableContent[key]['expertName']} key={key}>
              <span>{item['receiverData'] && `${item['receiverData']['firstName']} ${item['receiverData']['lastName']}`}</span>
            </div>,
          );
          break;
        case 'senderData.organizationName':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Participant Name" key={key}>
              {item['goalData'] && <span className="roboto-medium link-btn text-truncate">
                <span>{`${(item['senderData']['role'] === staticConstants.ROLE.ORGANIZATION_ADMIN && item['senderData']['organizationName'])
                  || (item['senderData']['role'] !== staticConstants.ROLE.ORGANIZATION_ADMIN && `${item['senderData']['firstName']} ${item['senderData']['lastName']}`)} (${
                  (item['senderData']['role'] === staticConstants.ROLE.ORGANIZATION_ADMIN && staticConstants.DISPLAY_ROLE.orgAdmin) ||
                  (item['senderData']['role'] === staticConstants.ROLE.PROFESSIONAL && 'Individual') ||
                  (item['senderData']['role'] === staticConstants.ROLE.EXPERT && 'Expert') ||
                  (item['senderData']['role'] === staticConstants.ROLE.ORGANIZATION_LEARNER && 'Learner')
                  })`}</span></span>}
            </div>,
          );
          break;
        case 'expertFee':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Expert Fee" key={key}>
              <span>{`$${formatCurrency(item.expertFee)}`}</span>
            </div>,
          );
          break;
        case 'openfieldCommissionAmt':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Overlap Commission" key={key}>
              <span>{`$${formatCurrency(item.openfieldCommissionAmt && item.openfieldCommissionAmt.toFixed(MAGIC_NUMBER.TWO))}`}</span>
            </div>,
          );
          break;
        case 'orgName':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Name" key={key}>
              <span>{this.props.activeSubTab === staticConstants.USER_MANAGEMENT_FILTER_TAB.ORG_ADMIN ?
                item['senderData']['organizationName'] : `${item['senderData']['firstName']} ${item['senderData']['lastName']}`}</span>
            </div>,
          );
          break;
        case 'senderData.email':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Email ID" key={key}>
              <span>{item['senderData']['email']}</span>
            </div>,
          );
          break;
        case 'goalData.endedAt':
        case 'acceptedAt':
        case 'goalCreated':
        case 'createdAt':
        case 'createdTime':
        case 'proposedDate':
        case 'dateAvailability':
        case 'licenceMovementRequestDate':
        case 'rejectedAt':
        case 'bid.rejectedAt':
        case 'bidData.endedAt':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={tableContent[key]['name']} key={key}>
              <span>{this.renderDate(item, key)}</span>
            </div >,
          );
          break;
        case 'date':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={tableContent[key]['name']} key={key}>
              <span className="roboto-medium link-btn text-truncate">{this.renderDate(item, key)}</span>
            </div >,
          );
          break;

        case 'participants':
        case 'totalParticipants':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Participants" key={key}>
              <span>{item.participants && item.participants.length}</span>
            </div >,
          );
          break;
        case 'createdBy':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Created By" key={key}>
              <span>{((item.userId && item.userId.firstName) || (item.userId && item.userId.lastName))
                ? `${item.userId && item.userId.firstName} ${item.userId && item.userId.lastName}`
                : `${item.userId && item.userId.email}`} </span>
            </div >,
          );
          break;
        case 'sentTo':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Sent To" key={key}>
              <span>sent to </span>
            </div>,
          );
          break;
        case 'status':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={tableContent[key]['name']} key={key}>
              <span>{getGoalStatusText(item[key]) || item[key]}</span>
            </div>,
          );
          break;
        case 'eventType':
        case 'email':
        case 'address':
        case 'totalFlag':
        case 'totalLearner':
          const data = item[key];
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={tableContent[key]['name']} key={key}>
              <span>{data}</span>
            </div>,
          );
          break;
        case 'amount':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={tableContent[key]['name']} key={key}>
              <span>{`$${formatCurrency(item[key])}`}</span>
            </div>,
          );
          break;
        case 'subscriptionId':
        case 'quantity':
        case 'invoice':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={tableContent[key]['name']} key={key}>
              <span>{item[key]}</span>
            </div>,
          );
          break;
        case 'userType':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`} key={key}>
              <span>
                {
                  item['senderData']['role'] === staticConstants.ROLE.ORGANIZATION_ADMIN && staticConstants.DISPLAY_ROLE.orgAdmin
                }
                {
                  item['senderData']['role'] === staticConstants.ROLE.ORGANIZATION_LEARNER && 'Learner'
                }
                {
                  item['senderData']['role'] === staticConstants.ROLE.PROFESSIONAL && 'Individual'
                }
                {
                  item['senderData']['role'] === staticConstants.ROLE.EXPERT && 'Expert'
                }
              </span>
            </div>,
          );
          break;
        case 'sessionFee':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={tableContent[key]['name']} key={key}>
              <span>${formatCurrency(item[key])}</span>
            </div>,
          );
          break;
        case 'duration':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={tableContent[key]['name']} key={key}>
              <span>{item[key]} Hrs</span>
            </div>,
          );
          break;
        case 'licenceCount':
          const count = item['licenceMovement']['requestedCount'];
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={tableContent[key]['name']} key={key}>
              <span>{count}</span>
            </div>,
          );
          break;
        case 'action':
          const isSelected = this.handleAction(item);
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={tableContent[key]['name']} key={key}>
              <span className="row-action-col">
                <label className={`control control--${tableContent[key]['actionType']}`}>
                  <Input
                    type={tableContent[key]['actionType']}
                    checked={isSelected}
                    name={tableContent[key]['name']}
                    onChange={e => this.props.updateStatus(item, e)}
                  />{' '}
                  <div className="control__indicator" />
                </label>
              </span>
            </div>,
          );
          break;
        case 'organizationName':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              {<span className="roboto-medium link-btn text-truncate">
                <span>{item.organizationName}<em /></span>
              </span>
              }
            </div>,
          );
          break;
        case 'organizationList':
          htmlContent.push(
            <div
              className={`table-col ${tableContent[key]['class']} roboto-medium link-btn text-truncate`}
              data-label={`${tableContent[key]['dataLabel']}`}
              key={key}
            >
              <span>{item.organizationName}<em /></span>
            </div>,
          );
          break;
        case 'userName':
          htmlContent.push(
            <div
              className={`table-col ${tableContent[key]['class']} roboto-medium link-btn text-truncate`}
              data-label={`${tableContent[key]['dataLabel']}`}
              key={key}
            >
              <span>{item.firstName} {item.lastName}</span>
            </div>,
          );
          break;
        case 'addOn':
          htmlContent.push(
            <div
              className={`table-col ${tableContent[key]['class']} link-btn text-truncate`}
              data-label={`${tableContent[key]['dataLabel']}`}
              key={key}
            >
              <span>{item.addOnAmount >= 0  ? item.addOnAmount : '$15'}</span>
            </div>,
          );
          break;
        case 'name':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span className="roboto-medium link-btn text-truncate">
                <span>{item.firstName} {item.lastName}</span>
              </span>
            </div>,
          );
          break;
        case 'role':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>
                {
                  item.role === staticConstants.ROLE.ORGANIZATION_ADMIN && staticConstants.DISPLAY_ROLE.orgAdmin
                }
                {
                  item.role === staticConstants.ROLE.ORGANIZATION_LEARNER && 'Learner'
                }
                {
                  item.role === staticConstants.ROLE.PROFESSIONAL && 'Individual'
                }
                {
                  item.role === staticConstants.ROLE.EXPERT && 'Expert'
                }
              </span>
            </div>,
          );
          break;
        case 'requestedBy':
          const requested = item.requestedAdmin && item.requestedAdmin.length > MAGIC_NUMBER.ZERO ? item.requestedAdmin[0] : item.requestedBy[0];
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              {requested &&
                <>
                  <span> {requested.firstName} {requested.lastName}
                    <strong className="d-block table-small">(
                        {
                        requested.role === staticConstants.ROLE.ORGANIZATION_ADMIN && staticConstants.DISPLAY_ROLE.orgAdmin
                      }
                      {
                        requested.role === staticConstants.ROLE.ORGANIZATION_LEARNER && 'Learner'
                      }
                      {
                        requested.role === staticConstants.ROLE.PROFESSIONAL && 'Individual'
                      }
                      {
                        requested.role === staticConstants.ROLE.EXPERT && 'Expert'
                      })
                    </strong>
                  </span>
                </>
              }
            </div>,
          );
          break;
        case 'requestedByEvent':
          const requestedByEvent = item.requestedAdmin && item.requestedAdmin.length > MAGIC_NUMBER.ZERO ? item.requestedAdmin[0] : item.userId;
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              {requestedByEvent &&
                <>
                  <span> {requestedByEvent.firstName} {requestedByEvent.lastName}
                    <strong className="d-block table-small">(
                          {
                        requestedByEvent.role === staticConstants.ROLE.ORGANIZATION_ADMIN && staticConstants.DISPLAY_ROLE.orgAdmin
                      }
                      {
                        requestedByEvent.role === staticConstants.ROLE.ORGANIZATION_LEARNER && 'Learner'
                      }
                      {
                        requestedByEvent.role === staticConstants.ROLE.PROFESSIONAL && 'Individual'
                      }
                      {
                        requestedByEvent.role === staticConstants.ROLE.EXPERT && 'Expert'
                      })
                      </strong>
                  </span>
                </>
              }
            </div>,
          );
          break;
        case 'licence':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>{item.licence}</span>
            </div>,
          );
          break;
        case 'licenceMovement':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>{item.licenceMovement && item.licenceMovement.count ? item.licenceMovement.count : 0}</span>
            </div>,
          );
          break;
        case 'isActive':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              {
                item.isVerified ? <span 
                className="action-button"
                 onClick={() => this.props.updateStatus && this.props.updateStatus(item)}>{item.isActive ? 'Active' : 'Inactive'} </span>
                  : <span
                   className="action-button" onClick={() => this.teamMemberVerifyUser(item._id)}>{`${'Request Sent'}`}</span>
              }
            </div>,
          );
          break;
        case 'isDelete':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              {
                item.isVerified
                  ? <>
                  <em id={`tooltip_delete_${key}`} onClick={() => this.props.deleteItem(item)} className="icon icon-delete" />
                  <UncontrolledTooltip placement="top" target={`tooltip_delete_${key}`} >Delete</UncontrolledTooltip>
                  </>
                  : <>
                  <em className="icon icon-resend"  id={`tooltip_verify_${key}`}
                   onClick={() => this.props.reVerifyUser && this.props.reVerifyUser(item._id)} />
                  <UncontrolledTooltip placement="top" target={`tooltip_verify_${key}`} >Verify User</UncontrolledTooltip>
                  </>
              }

            </div>,
          );
          break;
        case 'hourlyRateAction':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <em onClick={() => this.props.updateHourlyRate(item._id, 'approve')} className="icon icon-accept success" />
              <em onClick={() => this.props.updateHourlyRate(item._id, 'reject')} className="icon icon-cross danger" />
            </div>,
          );
          break;
        case 'commentAction':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <em onClick={() => this.props.updateFlaggedComment(item._id)} className="icon icon-comment" />
            </div>,
          );
          break;
        case 'registerRequestAction':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <em onClick={() => this.props.updateUserRequest(item._id, 'approve')} className="icon icon-accept success" />
              <em onClick={() => this.props.updateUserRequest(item._id, 'reject')} className="icon icon-cross danger" />
            </div>,
          );
          break;
        case 'licenceMovementAction':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <em id={`tooltip_accept_${item._id}`}
               onClick={() => this.props.updateLicenceMovementRequest(item._id, 'approve')} 
               className="icon icon-accept success" />
              <UncontrolledTooltip placement="top" target={`tooltip_accept_${item._id}`} >Accept</UncontrolledTooltip>
              <em id={`tooltip_deny_${item._id}`} onClick={() => this.props.updateLicenceMovementRequest(item._id, 'reject')}
               className="icon icon-cross danger" />
              <UncontrolledTooltip placement="top" target={`tooltip_deny_${item._id}`} >Deny</UncontrolledTooltip>
            </div>,
          );
          break;
        case 'senTo':
        case 'acceptedBy':
        case 'rejecteddBy':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['name']}`}
              key={key}>
              {(item.goalAdmin && item.goalAdmin.length > MAGIC_NUMBER.ZERO) &&
                <span>{item.goalAdmin[0].firstName} {item.goalAdmin[0].lastName}</span>
              }
            </div>,
          );
          break;
        case 'currentRate':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['name']}`}
              key={key}>
              {item.hourlyRate &&
                <span>${formatCurrency(item.hourlyRate.current)}</span>
              }
            </div>,
          );
          break;
        case 'requestedRate':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['name']}`}
              key={key}>
              {item.hourlyRate &&
                <span>${formatCurrency(item.hourlyRate.requested)}</span>
              }
            </div>,
          );
          break;
        case 'scheduleAt':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Schedule For" key={key}>
              {dateAvailability}
            </div >,
          );
          break;
        case 'scheduleTime':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label="Schedule Time" key={key}>
              {eventTime}
            </div >,
          );
          break;
        case 'userId.organizationName':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>{item['userId']['organizationName']}</span>
            </div>,
          );
          break;
        case 'userId.name':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>{`${item['userId']['firstName']} ${item['userId']['lastName']}`}</span>
            </div>,
          );
          break;
        case 'acceptedBidder.name':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>{expertName}</span>
            </div>,
          );
          break;
        case 'bidStatus':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>{bidStatus}</span>
            </div>,
          );
          break;
        case 'requestedBy.role':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>{item['bid']['requestedBy'][0].role}</span>
            </div>,
          );
          break;
        case 'sdomain':
        case 'ssubDomain':
        case 'catagory':
        case 'subCatagory':
        case 'topic':
        case 'activityCode':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>{item['name']}</span>
            </div>,
          );
          break;
        case 'connectingId.name':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>{item['connectingId'] && item['connectingId']['name']}</span>
            </div>,
          );
          break;
        case 'domainId.name':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>{item['domainId'] && item['domainId']['name']}</span>
            </div>,
          );
          break;
        case 'subDomainId.name':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>{item['subDomainId'] && item['subDomainId']['name']}</span>
            </div>,
          );
          break;
        case 'categoryId.name':
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>{item['categoryId'] && item['categoryId']['name']}</span>
            </div>,
          );
          break;
        case 'domainAction':
        case 'subDomainAction':
        case 'categoryAction':
        case 'subCategoryAction':
        case 'topicAction':
        case 'activityCodeAction':
          htmlContent.push(
            <div className={`table-col action-col p-0 ${tableContent[key]['class']}`}
              key={key}>
                {
                  key === 'topicAction' && <>
                <em className="icon icon-publish" id={`tooltip_publish_${item['_id']}`} onClick={() => this.props.publishTopic(item)}/>
                <UncontrolledTooltip placement="top" target={`tooltip_publish_${item['_id']}`} >Publish Topic</UncontrolledTooltip>
                </>
                }
              <em className="icon icon-edit" id={`tooltip_edit_${item['_id']}`} onClick={() => this.props.editTags('edit', item)} />
              <UncontrolledTooltip placement="top" target={`tooltip_edit_${item['_id']}`} >Edit</UncontrolledTooltip>
              < em className="icon icon-delete" id={`tooltip_delete_${item['_id']}`} onClick={() => this.props.deleteItem(item)} />
              <UncontrolledTooltip placement="top" target={`tooltip_delete_${item['_id']}`} >Delete</UncontrolledTooltip>
            </div>,
          );
          break;
        case 'customPlan':
          const customName = item && item.customPlan && item.customPlan[0] && item.customPlan[0].productName;
          htmlContent.push(
            <div className={`table-col ${tableContent[key]['class']}`} data-label={`${tableContent[key]['dataLabel']}`}
              key={key}>
              <span>{`${customName || 'Default Plan'}`}</span>
            </div>,
          );
          break;
        default:
          break;
      }
    }
    return htmlContent;
  }

  public getLinkPathByRole(item: any) {
    let path: string;
    if (item.role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
      path = `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}
        ${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.DETAIL}/${staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN}/${item._id}`;
    }
    if (item.role === staticConstants.ROLE.ORGANIZATION_LEARNER) {
      path = `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}
        ${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.DETAIL}/${staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN}/${item.organizationId}/learner/${item._id}`;
    }
    if (item.role === staticConstants.ROLE.PROFESSIONAL) {
      path = `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}
        ${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.DETAIL}/${staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL}/${item._id}`;
    }
    if (item.role === staticConstants.ROLE.EXPERT && item.systemAdmin && !item.systemAdmin.isRequest) {
      path = `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}
      ${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.DETAIL}/${staticConstants.USER_MANAGEMENT_TAB.EXPERTS}/${item._id}`;
    }
    if (item.role === staticConstants.ROLE.EXPERT && item.systemAdmin && item.systemAdmin.isRequest) {
      path = `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.VIEW_PROFILE.PATH}/${item._id}`;
    }
    if (item.role === staticConstants.ROLE.EXPERT && !item.systemAdmin) {
      path = `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}
      ${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.DETAIL}/${staticConstants.USER_MANAGEMENT_TAB.EXPERTS}/${item._id}`;
    }
    return path;
  }

  public redirectOnDetailPage (event: any, tableContent: any, item: any) {
    let redirected = false;
    if (ACTION_CLASSES.indexOf(event.target.className) === MAGIC_NUMBER.FOUND_INDEX) {
      for (const key in tableContent) {
        if (redirected) return;
        switch (key) {
          case 'title':
            const checkEventManagementPath = tableContent[key]['path'].includes(pageRoutes.EVENTMANAGEMENT.PATH);
            let redirectId;
            let isSystemAdmin = false;
            if (checkEventManagementPath && item &&  item.bid && item.bid.length > MAGIC_NUMBER.ZERO && item.bid[0] && item.bid[0]._id) {
              redirectId = item.bid[0]._id;
            } else {
              redirectId = item._id;
            }
            if (item.bid && item.bid.length && this.props.user && this.props.user.role === staticConstants.ROLE.SYSTEM_ADMIN) {
              isSystemAdmin = true;
            }
            if(!isSystemAdmin) {
              history.push({
                pathname: `${tableContent[key]['path']}/${redirectId}`,
                state: { activeTab: tableContent[key]['activeTab'] },
              });
              redirected = true;
            } else if (isSystemAdmin && tableContent[key]['path']) {
              history.push({
                pathname: `${tableContent[key]['path']}/${redirectId}`,
                state: {},
              });
              redirected = true;
            }
          break;
          case 'bidData.id':
            if(item['goalData']) {
              history.push({
                pathname: `${tableContent[key]['path']}/${item['bidData']['_id']}`,
                state: { activeTab: this.props.activeTab, parentTab: this.props.parentTab, activeSubTab: this.props.activeSubTab },
              });
              redirected = true;
            }
          break;
          case 'senderData.organizationName':
            if (item['goalData']) {
              history.push({
                pathname: `${tableContent[key]['path']}/${item['bidData']['_id']}`,
                state: { activeTab: tableContent[key]['activeTab'] },
              });
              redirected = true;
            }
          break;
          case 'organizationName':
            history.push({
              pathname: `${tableContent[key]['path']}/${item._id}`,
              state: { },
            });
            redirected = true;
          break;
          case 'name':
            const pathLink = this.getLinkPathByRole(item);
            history.push({
              pathname: `${pathLink}`,
              state: { },
            });
            redirected = true;
          break;
        }
      }
    }
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { tableContent } = this.props;
    const isSelected = this.handleAction(this.props.item);
    return (
      <div onClick={(event) => this.redirectOnDetailPage(event, tableContent, this.props.item)} 
      className={`table-row ${(this.props.item.isDispute) ||
        (this.props.item.type === staticConstants.PENDING_PAYMENT_STATUS_VALUE.disputed &&
          this.props.activeTab === staticConstants.EXPERT_PAYMENT_TAB.PENDING) && 'red'} ${isSelected && 'green'}`}>
        {this.renderData()}
      </div>
    );
  }
}

const ListItemPage = connect(null)(ListItem);
export { ListItemPage as ListItem };
