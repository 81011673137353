import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator, IMakeBid, IGoalDetailWithId } from './../../../interfaces';
import ReeValidate from 'ree-validate';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { setHours, setMinutes } from 'date-fns';
import { bidAction } from './redux/actions';
import { BID_MANAGEMENT_CONST, MAGIC_NUMBER } from '../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  hideModal: any;
  goal: IGoalDetailWithId;
  reqObj?: any;
  fromDetail?: boolean;
}

interface IState {
  modal: boolean;
  errors?: any;
  formData?: IMakeBid;
  minDate?: Date;
  maxDate?: Date;
  minTime?: Date;
  maxTime?: Date;
  goal?: IGoalDetailWithId;
  isExpired?: boolean;
  btnText: string;
}

class MakeBid extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);

    this.validator = new ReeValidate({
      proposedDate: 'required',
      proposedTime: 'required',
      bidDetails: 'required',
    });
    this.state = {
      modal: props.isOpenModal,
      isExpired: false,
      btnText: 'Bid',
      formData: {
        proposedDate: null,
        proposedTime: null,
        bidDetails: '',
      },
      errors: this.validator.errors,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  public componentWillReceiveProps = (props: IProps) => {
    if (props.goal) {
      const minTimeDateUTC = new Date(props.goal.fromTime);
      const maxTimeDateUTC = new Date(props.goal.toTime);
      const  minDate = moment(props.goal.fromDate).isBefore() ? new Date() : new Date(props.goal.fromDate);
      const  maxDate = new Date(props.goal.toDate);
      this.setState({
        isExpired: moment(maxDate).isBefore(),
        btnText: props.goal['rehireId'] ? 'Accept' : 'Bid',
        minDate,
        maxDate,
        modal: props.isOpenModal,
        goal: props.goal,
        minTime: setHours(setMinutes(new Date(), minTimeDateUTC.getMinutes()), minTimeDateUTC.getHours()),
        maxTime: setHours(setMinutes(new Date(), maxTimeDateUTC.getMinutes()), maxTimeDateUTC.getHours()),
      });
    }

  }
  public validateAndSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { proposedDate, proposedTime, bidDetails } = this.state.formData;
    const { errors } = this.validator;

    this.validator.validateAll({ proposedDate, proposedTime, bidDetails })
      .then((success: boolean) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  
  public removeErrorAndReValidate = (name: string) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.formData[name])
      .then(() => {
        this.setState({ errors });
      });
  }

  public handleSubmit = () => {
    const { dispatch, goal } = this.props;
    const formData = this.state.formData;
    const data: IMakeBid = formData;
    data.goalId = goal._id;
    data.proposedTime = new Date(moment(data.proposedDate).hours(moment(data.proposedTime).hours()).minutes(moment(data.proposedTime).minutes()).format());
    dispatch(bidAction.makeBid(data, this.props.reqObj, this.props.fromDetail));
    this.hideModal();
  }

  public handleChange = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const { errors } = this.validator;
    this.setState({
      [name]: value,
    } as any);
    errors.remove(name);
    this.setState({ formData: { ...this.state.formData, [name]: value } });

    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }
  
  public handleProposedDateChange = (date: Date, name: string) => {
    this.setState({ formData: { ...this.state.formData, [name]: date } }, () => {
      this.removeErrorAndReValidate(name);
    });
  }

  public hideModal() {
    this.setState({
      modal: false,
      formData: {
        proposedDate: null,
        proposedTime: null,
        bidDetails: '',
      },
    }, () => { this.props.hideModal(); });
    const { errors } = this.validator;
    errors.clear();
  }

  public render() {
    const { modal, errors, formData, minDate, maxDate, minTime, maxTime , isExpired, btnText } = this.state;
    const errorStr = BID_MANAGEMENT_CONST.ERROR_CLASS;
    return (
      <Modal isOpen={modal} className="make-bid-modal" onClosed={this.hideModal}>
        <ModalHeader toggle={this.hideModal}> {btnText}</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.validateAndSubmit} autoComplete="off" noValidate>
            <Row>
              <Col xs="12">
                <FormGroup
                  className={`floating-label  disabled-input datepicker-wrapper ${
                    errors.has('firstName') ? errorStr : ''
                    }`}>
                  <DatePicker
                    placeholderText="Proposed Date"
                    className="form-control"
                    dateFormat="MMM dd, yyyy"
                    onChange={e => this.handleProposedDateChange(e, 'proposedDate')}
                    selected={formData.proposedDate}
                    maxDate={maxDate || moment().toDate()}
                    minDate={minDate}
                    showYearDropdown
                    showMonthDropdown
                    strictParsing />
                  <Label for="proposedDate" className={formData.proposedDate ? 'selected' : ''}>Proposed Date</Label>
                  <span className="icon icon-calendar"/>
                  {errors.has('proposedDate') &&
                    <div className="error-text">
                      {errors.first('proposedDate').replace('proposedDate', 'Proposed Date')}</div>
                  }
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup
                  className={`floating-label disabled-input time-form${
                    errors.has('lastName') ? errorStr : ''
                    }`}
                >
                  <DatePicker
                    placeholderText="Proposed Time"
                    className="form-control"
                    selected={formData.proposedTime}
                    onChange={(e) => this.handleProposedDateChange(e, 'proposedTime')}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="hh:mm aa"
                    minTime={minTime}
                    maxTime={maxTime}
                    strictParsing
                  />
                  <Label for="proposedTime" className={formData.proposedTime ? 'selected' : ''}>Proposed Time</Label>
                  <span className="icon icon-clock"/>
                  {errors.has('proposedTime') &&
                    <div className="error-text">{errors.first('proposedTime').replace('proposedTime', 'Proposed Time')}
                    </div>
                  }
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup
                  className={`floating-label disabled-input textarea-label mb-3 ${
                    errors.has('bidDetails') ? errorStr : ''
                    }`}>
                  <textarea
                    className="form-control textarea-md"
                    id="bidDetails"
                    name="bidDetails"
                    placeholder="Describe your game plan for this request"
                    value={formData.bidDetails}
                    onChange={this.handleChange}
                    maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                  />
                  <span className="text-count">{formData.bidDetails.length}/500</span>
                  <Label for="bidDetail" className={formData.bidDetails ? 'selected' : ''}>Describe your game plan for this request</Label>
                  {errors.has('bidDetails') &&
                    <div className="error-text">{errors.first('bidDetails').replace('bidDetails', 'Game Plan')}</div>
                  }
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular mr-2" onClick={this.hideModal}>Cancel</Button>{' '}
                <Button color="primary" disabled={isExpired} onClick={this.validateAndSubmit}>{btnText}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const connectedMakeBidPopupPage = connect()(MakeBid);
export { connectedMakeBidPopupPage as MakeBid };
