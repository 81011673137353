import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import OpenFieldTypes from 'OpenFieldTypes';
import {
  eventTypes, reactSelectFilters, getUpdatedOptions} from '../../../utils';
import { customSelectStyles } from '../../../utils/common';
import { Button } from 'reactstrap';
const resetOption = [{ value: '', label: 'Reset Filter' }];

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  filterContent: any;
  domainOptions: any;
  status?: any;
  flagType?: any;
  activeTab?: string;
  placeholder?: string;
  filterTab?: string;
  tabChange?: string;
  generateReport?: () => void;
  handleFilter(event: any, key: string): void;
}

class FilterComponent extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      openFilter: false,
      eventTypeOptions: resetOption.concat(eventTypes),
      statusTypeOptions: resetOption.concat(this.props.status),
      flagTypeOptions: resetOption.concat(this.props.flagType),
      title: '',
      userStatusType: null,
      eventType: null,
      flagType: null,
      domain: null,
      status: null,
      activeTab: null,
    };

  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps['activeTab'] !== this.props.activeTab || prevProps['tabChange'] !== this.props.tabChange) {
      this.setState({ status: null, eventType: null, domain: null, title: '' });
    }
  }

  public filterToggle = () => {
    this.setState({
      openFilter: true,
    });
  }
  public filterRemove = () => {
    this.setState({
      openFilter: false,
    });
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} e
   * @param fields {String} key
   */
  public handleChange = (e: { target: { value: string } }, key: string) => {
    let value = key === 'title' ? e.target.value : e;
    value = key !== 'title' ? (value['value'] ? value : null) : value;
    this.setState({ ...this.state, inputValue: value, [key]: value },
                  () => this.props.handleFilter(value, key));
  }

  public render() {
    const { filterContent, domainOptions, placeholder, generateReport } = this.props;
    const { eventType, status, domain, eventTypeOptions, statusTypeOptions, flagType, flagTypeOptions, title } = this.state;
    const domainOptionTypes = resetOption.concat(domainOptions);
    return (
      <React.Fragment>
        {filterContent.includes('search') &&
          <div className="search-bar">
            <input type="search" id="searchinput" value={title} placeholder={placeholder ? placeholder : `Search by Keywords, Title`}
              onChange={e => this.handleChange(e, 'title')} />
            <span className="icon icon-search-icon" />
            {
              filterContent && (filterContent.includes('domain') || filterContent.includes('eventType') || filterContent.includes('status')) &&
              <div className="filter-icon" onClick={this.filterToggle} />
            }
          </div>
        }
        {
          (filterContent && (filterContent.includes('domain') || filterContent.includes('eventType') || filterContent.includes('status') || filterContent.includes('flagType'))) &&
          <div className={`filter-bar ${this.state.openFilter ? 'filter-open' : ''}`}>
            {filterContent.includes('eventType') &&
              <div className="filter-dropdown">
                <Select
                  placeholder="Event Type"
                  value={eventType}
                  onChange={e => this.handleChange(e, 'eventType')}
                  options={eventTypeOptions}
                  styles={customSelectStyles}
                  className="react-select-box"
                  createFilter={createFilter(reactSelectFilters)}
                />
              </div>
            }
            {filterContent.includes('domain') &&
              <div className="filter-dropdown">
                <Select
                  placeholder="Domain"
                  value={domain}
                  styles={customSelectStyles}
                  onChange={e => this.handleChange(e, 'domain')}
                  options={domainOptionTypes}
                  className="react-select-box"
                />
              </div>
            }
            {filterContent.includes('flagType') &&
              <div className="filter-dropdown">
                <Select
                  placeholder="Flag Type"
                  value={flagType}
                  onChange={e => this.handleChange(e, 'flagType')}
                  options={flagTypeOptions}
                  styles={customSelectStyles}
                  className="react-select-box"
                  createFilter={createFilter(reactSelectFilters)}
                />
              </div>
            }
            {filterContent.includes('status') &&
              <div className="filter-dropdown">
                <Select
                  placeholder="Status"
                  value={status}
                  onChange={e => this.handleChange(e, 'status')}
                  options={statusTypeOptions}
                  styles={customSelectStyles}
                  className="react-select-box"
                  createFilter={createFilter(reactSelectFilters)}
                />
              </div>
            }
            <span className="icon icon-cross icon-sm-device" onClick={this.filterRemove} />
          </div>
        }
        {generateReport && <Button color="primary" className="filter-sm-device" onClick={generateReport}>Generate Report</Button>}
      </React.Fragment>
    );
  }
}
function mapStateToProps(state: any) {
  const { authentication, tags: { domains } } = state;
  const { user } = authentication;
  return {
    user,
    role: user.role,
    domainOptions: getUpdatedOptions(domains, 'name'),
  };
}

const connectedFilterComponent = connect(mapStateToProps)(FilterComponent);
export { connectedFilterComponent as FilterComponent };
