import { Bar } from 'react-chartjs-2';
import React from 'react';
import { formatCurrency, staticConstants, MAGIC_NUMBER } from '../../../../utils';

interface IProps {
  chartData?: any;
  type?: string;
  role?: string;
  dateSelected?: string;
}
interface IState {
  isLegend: any;
}
class BarChart extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isLegend: true,
    };
  }
  public componentDidMount = () => {
    if(window.innerWidth <= MAGIC_NUMBER.FIVE_HUNDRED) {
      this.setState({isLegend: false});
    }
  }
  public render() {
    const chartData = this.props.chartData && JSON.parse(JSON.stringify(this.props.chartData));
    const learningEventslabels = [];
    const adviceData = [];
    const assessmentData = [];
    const briefingData = [];
    const facilitationData = [];
    const interviewData = [];
    const talkData = [];
    const trainingData = [];
    const problemSolvingData = [];
    const adviceAvgRating = [];
    const assessmentAvgRating = [];
    const briefingAvgRating = [];
    const facilitationAvgRating = [];
    const interviewAvgRating = [];
    const talkAvgRating = [];
    const trainingAvgRating = [];
    const problemSolvingAvgRating = [];
    const adviceEvent = [];
    const adviceIncome = [];
    const adviceSpent = [];
    const assessmentEvent = [];
    const assessmentIncome = [];
    const assessmentSpent = [];
    const briefingEvent = [];
    const briefingIncome = [];
    const briefingSpent = [];
    const facilitationEvent = [];
    const facilitationIncome = [];
    const facilitationSpent = [];
    const interviewEvent = [];
    const interviewIncome = [];
    const interviewSpent = [];
    const talkEvent = [];
    const talkIncome = [];
    const talkSpent = [];
    const trainingEvent = [];
    const trainingIncome = [];
    const trainingSpent = [];
    const problemSolvingEvent = [];
    const problemSolvingIncome = [];
    const problemSolvingSpent = [];
    let data = {};
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May',
      'Jun', 'Jul', 'Aug', 'Sep',
      'Oct', 'Nov', 'Dec',
    ];
    const weeks = ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6'];
    const learningEventsData = chartData ? chartData : {};
    const yearToLabel = Object.keys(learningEventsData).length > MAGIC_NUMBER.ZERO && learningEventsData.Facilitation !== null && learningEventsData.Facilitation[0].year;
    if (Object.keys(learningEventsData).length > MAGIC_NUMBER.ZERO && learningEventsData.Facilitation !== null) {
      for (let i = 0; i < learningEventsData.Facilitation.length; i += 1) {
        let dataMonth = learningEventsData.Facilitation[i].month;
        dataMonth = this.props.dateSelected !== 'lastYear' ? `${months[dataMonth - 1]} ${learningEventsData.Facilitation[i].year}` :
        `${months[dataMonth - 1]}`;
        const dataWeek = weeks[i];
        learningEventsData && (learningEventsData.Facilitation[i].month === undefined  ||
          learningEventsData.Facilitation[i].month === null) ?
          learningEventslabels.push(dataWeek) :
          learningEventslabels.push(dataMonth);

        adviceData.push(learningEventsData.Advice[i].count);
        adviceAvgRating.push(learningEventsData.Advice[i].avgRating);
        adviceEvent.push(learningEventsData.Advice[i].totalEvent);
        adviceIncome.push(learningEventsData.Advice[i].totalIncome);
        adviceSpent.push(learningEventsData.Advice[i].amountSpent);

        assessmentData.push(learningEventsData.Assessment[i].count);
        assessmentAvgRating.push(learningEventsData.Assessment[i].avgRating);
        assessmentEvent.push(learningEventsData.Assessment[i].totalEvent);
        assessmentIncome.push(learningEventsData.Assessment[i].totalIncome);
        assessmentSpent.push(learningEventsData.Assessment[i].amountSpent);

        briefingData.push(learningEventsData.Briefing[i].count);
        briefingAvgRating.push(learningEventsData.Briefing[i].avgRating);
        briefingEvent.push(learningEventsData.Briefing[i].totalEvent);
        briefingIncome.push(learningEventsData.Briefing[i].totalIncome);
        briefingSpent.push(learningEventsData.Briefing[i].amountSpent);

        facilitationData.push(learningEventsData.Facilitation[i].count);
        facilitationAvgRating.push(learningEventsData.Facilitation[i].avgRating);
        facilitationEvent.push(learningEventsData.Facilitation[i].totalEvent);
        facilitationIncome.push(learningEventsData.Facilitation[i].totalIncome);
        facilitationSpent.push(learningEventsData.Facilitation[i].amountSpent);

        interviewData.push(learningEventsData.Interview[i].count);
        interviewAvgRating.push(learningEventsData.Interview[i].avgRating);
        interviewEvent.push(learningEventsData.Interview[i].totalEvent);
        interviewIncome.push(learningEventsData.Interview[i].totalIncome);
        interviewSpent.push(learningEventsData.Interview[i].amountSpent);

        talkData.push(learningEventsData.Talk[i].count);
        talkAvgRating.push(learningEventsData.Talk[i].avgRating);
        talkEvent.push(learningEventsData.Talk[i].totalEvent);
        talkIncome.push(learningEventsData.Talk[i].totalIncome);
        talkSpent.push(learningEventsData.Talk[i].amountSpent);

        trainingData.push(learningEventsData.Training[i].count);
        trainingAvgRating.push(learningEventsData.Training[i].avgRating);
        trainingEvent.push(learningEventsData.Training[i].totalEvent);
        trainingIncome.push(learningEventsData.Training[i].totalIncome);
        trainingSpent.push(learningEventsData.Training[i].amountSpent);

        problemSolvingData.push(learningEventsData.ProblemSolving[i].count);
        problemSolvingAvgRating.push(learningEventsData.ProblemSolving[i].avgRating);
        problemSolvingEvent.push(learningEventsData.ProblemSolving[i].totalEvent);
        problemSolvingIncome.push(learningEventsData.ProblemSolving[i].totalIncome);
        problemSolvingSpent.push(learningEventsData.ProblemSolving[i].amountSpent);
      }
      data = {
        labels: learningEventslabels,
        datasets: [{
          label: 'Advice',
          data: adviceData,
          rating: adviceAvgRating,
          totalEvent: adviceEvent,
          totalIncome: adviceIncome,
          amountSpent: adviceSpent,
          backgroundColor: 'rgba(253, 114, 144, 0.6)',
        }, {
          label: 'Assessment',
          data: assessmentData,
          rating: assessmentAvgRating,
          totalEvent: assessmentEvent,
          totalIncome: assessmentIncome,
          amountSpent: assessmentSpent,
          backgroundColor: 'rgba(171, 247, 214, 0.8)',
        }, {
          label: 'Briefing',
          data: briefingData,
          rating: briefingAvgRating,
          totalEvent: briefingEvent,
          totalIncome: briefingIncome,
          amountSpent: briefingSpent,
          backgroundColor: 'rgba(184, 222, 248, 0.8)',
        }, {
          label: 'Facilitation',
          data: facilitationData,
          rating: facilitationAvgRating,
          totalEvent: facilitationEvent,
          totalIncome: facilitationIncome,
          amountSpent: facilitationSpent,
          backgroundColor: 'rgba(254, 208, 99, 0.8)',
        }, {
          label: 'Interview',
          data: interviewData,
          rating: interviewAvgRating,
          totalEvent: interviewEvent,
          totalIncome: interviewIncome,
          amountSpent: interviewSpent,
          backgroundColor: 'rgba(162, 116, 255, 0.8)',
        }, {
          label: 'Talk',
          data: talkData,
          rating: talkAvgRating,
          totalEvent: talkEvent,
          totalIncome: talkIncome,
          amountSpent: talkSpent,
          backgroundColor: 'rgba(96, 199, 199, 0.8)',
        }, {
          label: 'Training',
          data: trainingData,
          rating: trainingAvgRating,
          totalEvent: trainingEvent,
          totalIncome: trainingIncome,
          amountSpent: trainingSpent,
          backgroundColor: 'rgba(21, 212, 63, 0.4)',
        }, {
          label: 'Problem Solving',
          data: problemSolvingData,
          rating: problemSolvingAvgRating,
          totalEvent: problemSolvingEvent,
          totalIncome: problemSolvingIncome,
          amountSpent: problemSolvingSpent,
          backgroundColor: 'rgba(253, 164, 75, 0.7)',
        }],
      };
    }
    const options = {
      responsive: true,
      legend: {
        display: this.state.isLegend,
        position: 'top', // place legend on the right side of chart
        labels: {
          fontColor: '#000000',
          boxWidth: 16,
          fontSize: 13,
          padding: 45,
          boxHeight: 16,
          borderWidth: 0,
          lineWidth: 0,
        },
      },
      tooltips: {
        custom: (tooltip) => {
          if (!tooltip) return;
          // disable displaying the color box;
          tooltip.displayColors = false;
        },
        callbacks: {
          title: (tooltipItem: any, item: any) => {
            if (tooltipItem[0]['yLabel'] === MAGIC_NUMBER.ONE) {
              return `${item['labels'][tooltipItem[0]['index']]}: ${[tooltipItem[0]['yLabel']]} hours`;
            }
            return `${item['labels'][tooltipItem[0]['index']]}: ${[tooltipItem[0]['yLabel']]} hours`;
          },
          label: (tooltipItem: any, item: any) => {
            const rating = item['datasets'][tooltipItem['datasetIndex']]['rating'][tooltipItem['index']];
            const totalEvent = item['datasets'][tooltipItem['datasetIndex']]['totalEvent'][tooltipItem['index']];
            const totalIncome = item['datasets'][tooltipItem['datasetIndex']]['totalIncome'][tooltipItem['index']];
            const amountSpent = item['datasets'][tooltipItem['datasetIndex']]['amountSpent'][tooltipItem['index']];
            if (this.props.type === 'experts') {
              return[`Events : ${totalEvent}`,`Income : $${formatCurrency(totalIncome)}`];
            }
            return [`Events : ${totalEvent}`,
              `Cost : $${formatCurrency(amountSpent)}`,
              `Avg. Session Ratings : ${rating.toString().indexOf('.') < 0 ? rating : rating.toFixed(1)}`];
          },
        },
        backgroundColor: 'rgba(0,0,0,0.8)',
        bodyAlign: 'left',
        bodyFontColor: '#fff',
        bodySpacing: 8,
        borderColor: 'rgba(0,0,0,0)',
        borderWidth: MAGIC_NUMBER.ZERO,
        cornerRadius: MAGIC_NUMBER.THREE,
        footerFontColor: '#fff',
        footerFontStyle: 'normal',
        titleFontColor: '#fff',
        titleFontStyle: 'normal',
        titleSpacing: MAGIC_NUMBER.TWO,
        titleMarginBottom: 15,
        xPadding: 12,
        yPadding: 12,
      },
      scales: {
        xAxes: [{
          barPercentage: 1.0,
          categoryPercentage: 1.0,
          barThickness: 40,
          maxBarThickness: 40,
          minBarLength: 0,
          gridLines: {
            offsetGridLines: true,
          },
          scaleLabel: {
            display: true,
            fontSize: 13,
            fontColor: '#2e3543',
            labelString: yearToLabel !== null && this.props.dateSelected === 'lastYear' ? yearToLabel : '',
          },
          stacked: true,
        }],
        yAxes: [{
          stacked: true,
          scaleLabel: {
            display: true,
            fontSize: 10,
            fontColor: '#2e3543',
            labelString: 'Number of hours (Time)',
          },
          ticks: {
            suggestedMin: MAGIC_NUMBER.ZERO,
            suggestedMax: MAGIC_NUMBER.TEN,
          },
        }],
      },
    };

    if (this.props.role !== staticConstants.ROLE.SYSTEM_ADMIN) {
      options.scales.yAxes[0].ticks['stepSize'] = MAGIC_NUMBER.TWO;
    }

    return (
            <>
              <Bar data={data} options={options}/>
            </>
    );
  }
}

export { BarChart };
