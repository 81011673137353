import { actionTypes } from './types';

interface IAction {
  type: string;
  data: object;
}

const initialState = {
  status: {},
};

export function createPayment(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.PAYMENT_REQUEST:
      return { ...state, requestingPayment: true };
    case actionTypes.PAYMENT_SUCCESS:
      return { ...state, requestedPaymentSuccess: true };
    // case actionTypes.PAYMENT_FAILURE:
    case actionTypes.RESET_STATE:
      return {};
    case actionTypes.GET_PRORATED_AMOUNT_REQUEST:
      return { ...state, status: { isProratedAmountRequested: true } };
    case actionTypes.GET_PRORATED_AMOUNT_SUCCESS:
      return { ...state,
        currentPeriodInvoiceLogs: action.data['data']['currentPeriodInvoiceLogs'],
        upcomingPeriodInvoiceLogs: action.data['data']['upcomingPeriodInvoiceLogs'],
        proRatedAmount: action.data['data']['amount'],
        canAvailTrialPeriod: action.data['data']['canAvailTrialPeriod'],
        amountDue: action.data['data']['amountDue'],
        invoiceTaxRate: action.data['data']['invoiceTaxRate'],
        invoiceTaxAmount: action.data['data']['invoiceTaxAmount'],
        invoiceSubtotal: action.data['data']['invoiceSubtotal'],
        invoiceTotal: action.data['data']['invoiceTotal'],
        subscription_proration_date: action.data['data']['subscription_proration_date'] };
    case actionTypes.GET_TAX_RATE_REQUEST:
      return { ...state, status: { isTaxRateRequested: true } };
    case actionTypes.GET_TAX_RATE_SUCCESS:
      return { ...state, status: {},
        taxRate:  action.data['taxRate']};
    case actionTypes.GET_TAX_RATE_FAIULURE:
      return { ...state, status: { isTaxRateRequesFailure: true } };

    default:
      return state;
  }
}
