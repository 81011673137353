import React from 'react';
import { IViewProfile, IInvoiceLog } from '../../../interfaces';
import { staticConstants, formatCurrency, MAGIC_NUMBER } from '../../../utils';
import {
    Button,
} from 'reactstrap';
import moment from 'moment';
import { map } from 'lodash';

interface IProps {
  profile: IViewProfile;
  amountDue: number;
  licence: number;
  amount: number;
  isAddOn: boolean;
  invoiceTotal: number;
  invoiceSubtotal: number;
  invoiceTaxAmount: number;
  invoiceTaxRate: number;
  upcomingPeriodInvoiceLogs: IInvoiceLog[];
  currentPeriodInvoiceLogs: IInvoiceLog[];
  upgradeSubscription: () => void;
}

const renderLicencePlan = (role: string, heading: string, orgadminLicence: number, learnerLicence: number, isAddOn: boolean, amount: number, bgClass = '') => {
  return <div className={`payment-wrapper change-plan-payment-wrapper ${bgClass}`}>
        <h2 className="heading roboto-medium upgrade-plan-heading mb-0">{heading}</h2>
        <div className="license-box-wrapper">
            { role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                <>
                <div className="license-box"> <p>Admin Licence</p> <span>{orgadminLicence}</span></div>
                <div className="license-box"> <p>Member Licence</p> <span>{learnerLicence}</span></div>
        </>}
            {renderAddOnPlan(isAddOn)}
     </div>
     <div>
     <h2 className={heading === 'New Plan' ? 'roboto-medium amount-heading' : 'roboto-medium amount-heading-black'}> <em>$</em> {formatCurrency(amount)}</h2>
     </div>
 </div>;
};

const renderAddOnPlan = (isAddOn: boolean) => {
  return <div className="license-box add-on-license-box">
           <p> Add-On</p><span>{isAddOn ? 1 : 0}</span>
   </div>;
};

const renderNote = () => {
  return <div className="note-text-wrapper">
      <p>
      Note: You will be able to use your new licences immediately.
      The billing, however, will only be adjusted in next month’s invoice.
      Please click here to view the billing for next month.
      </p>
  </div>;
};

const renderInvoiceLog = (invoiceLog: IInvoiceLog[]) => {

  return  <div className="table-body">
                    <div className="table-row blank-row">
                      <span>{moment.utc(invoiceLog[0].period.start * MAGIC_NUMBER.THOUSANT).format(staticConstants.DATE_FORMAT)} -
                      {moment.utc(invoiceLog[0].period.end * MAGIC_NUMBER.THOUSANT).format(staticConstants.DATE_FORMAT)}</span>
                    </div>
                    {map(invoiceLog, (inv: IInvoiceLog) => {
                      return   <div className="table-row"><div className="table-col table-col-55" data-label="Description">
                    <span>{inv.description}</span>
                    </div>
                    <div className="table-col table-col-15" data-label="Qty">
                    <span>{inv.quantity}</span>
                    </div>
                    <div className="table-col table-col-15" data-label="Qty">
                    <span>{inv.unitPrice}</span>
                    </div>
                    <div className="table-col table-col-15" data-label="Qty">
                    <span>{inv.amount}</span>
                    </div>
                    </div>;
                    })}
                  </div>;
};

const SubscriptionUpgrade = (props: IProps) => {
  const { profile, amountDue, licence, isAddOn, currentPeriodInvoiceLogs, upcomingPeriodInvoiceLogs } = props;

  return (
        <div className="change-plan-history-wrapper ">
          <div className="change-plan-details">
            {renderLicencePlan(profile.role, 'Old Plan', 1, profile.licence > MAGIC_NUMBER.ONE ?  profile.licence - 1  : 0, profile.isAddOn, profile.amount)}
            {renderLicencePlan(profile.role, 'New Plan', 1, licence > MAGIC_NUMBER.ONE ?  licence - 1  : 0, isAddOn, amountDue, 'green-bg')}
            <div className="card-footer">
              <Button color="primary" className="btn-rg" onClick={props.upgradeSubscription}>
                Update
              </Button>
            </div>
          </div>
          <div className="plan-invoice-wrapper">
            {renderNote()}
            <div className="invoice-table">
              <div className="table-wrapper">
                <div className="flex-table">
                  <div className="table-head">
                    <div className="table-row border-0">
                      <div className="table-col table-col-55">
                        Description
                      </div>
                      <div className="table-col table-col-15">
                        Qty
                      </div>
                      <div className="table-col table-col-15">
                        Unit Price
                      </div>
                      <div className="table-col table-col-15">
                        Amount
                      </div>
                    </div>
                  </div>
                {currentPeriodInvoiceLogs && currentPeriodInvoiceLogs.length > MAGIC_NUMBER.ZERO  && renderInvoiceLog(currentPeriodInvoiceLogs)}
                {upcomingPeriodInvoiceLogs && upcomingPeriodInvoiceLogs.length > MAGIC_NUMBER.ZERO && renderInvoiceLog(upcomingPeriodInvoiceLogs)}
                </div>
              </div>
              <div className="total-payment-wrapper">
                <div className="total-payment-row">
                  <p>Subtototal <span>${props.invoiceSubtotal}</span></p>
                  <p>Tax({props.invoiceTaxRate}) <span>${props.invoiceTaxAmount}</span></p>
                  <p>Total <span>${props.invoiceTotal}</span></p>
                  <p>Amount due<span>${amountDue}</span></p>
                </div>
              </div>
            </div>
          </div>
      </div>
  );
};

export default SubscriptionUpgrade;
