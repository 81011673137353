import React from 'react';
import { connect } from 'react-redux';
import { FilterComponent } from '../../../shared/filter/filterComponent';
import { staticConstants, getUpdatedOptions, goalStatusTypes, getSelectedValue, pageRoutes, MAGIC_NUMBER } from '../../../../utils';
import { ListingContainer } from '../../../shared/listingManagement/listingContainer';
import { rfpAction } from '../../../shared/requestManagement/redux/actions';
import { tagAction } from '../../../shared/tags/redux/actions';

interface IProps {
  activeTab: string;
  goals: any;
  userId?: string;
  tableContent?: object;
  getRequests: (data: object) => void;
  generateReport: (data: object) => void;
  resetGenerateReport: () => void;
  getDomains: (data: object) => void;
  resetAllTags: () => void;
}

interface IState {
  activeTab: string;
  fetchGoal: boolean;
  goals: { count: number; list: any[]; fetchedListing: boolean };
  items: number;
  activePage: number;
  activeSecondaryTab: string;
  tableContent: object;
  sort: string;
  sortBy: any;
  title: string;
  eventType: string;
  domain: string;
  status: string;
}

const newRequests = {
  title: { name: 'Title', class: 'table-col-17', sort: true, path: `${pageRoutes.REQUESTMANAGEMENT.PATH}${pageRoutes.REQUESTMANAGEMENT.DETAIL}` },
  eventType: { name: 'Event Type', class: 'table-col-12', sort: true },
  domain: { name: 'Domain', class: 'table-col-15', sort: true },
  dateAvailability: { name: 'Date Availability', class: 'table-col-20' },
  totalParticipants: { name: 'Participants', class: 'table-col-13 text-center', sort: true },
  createdBy: { name: 'Created By', class: 'table-col-13', sort: true },
  bidStatus: { name: 'Status', class: 'table-col-10', sort: true, dataLabel: 'Status' },
};

class GoalRequests extends React.Component<IProps, IState> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps, state: IState) {
    const updateState = {};
    updateState['fetchGenerateReport'] = false;
    if (props.activeTab !== state.activeTab) {
      const tableContent = props && props.tableContent ? props.tableContent : newRequests;
      updateState['activeTab'] = props.activeTab;
      updateState['tableContent'] = tableContent;
      updateState['fetchGoal'] = true;
      updateState['activePage'] = 1;
      updateState['sort'] = '';
      updateState['sortBy'] = 0;
      updateState['eventType'] = null;
      updateState['domain'] = null;
      updateState['status'] = null;
      updateState['title'] = '';
    }
    if (props.goals && props.goals.list) {
      updateState['goals'] = props.goals;
    }
    if (props.goals && props.goals.successDownloadReport) {
      updateState['downloadUrl'] = props.goals.downloadUrl;
      updateState['downloadFileName'] = props.goals.downloadFileName;
      updateState['fetchGenerateReport'] = true;
    }
    return updateState;
  }

  public state = {
    activePage: 1,
    items: MAGIC_NUMBER.TEN,
    goals: {
      count: 0,
      list: [],
      fetchedListing: false,
    },
    activeSecondaryTab: staticConstants.ADMIN_REQUEST_MANAGEMENT_SECONDARY_TAB.NEW_REQUESTS.TYPE,
    tableContent: this.props && this.props.tableContent ? this.props.tableContent : newRequests,
    activeTab: this.props.activeTab,
    sort: '',
    sortBy: 0,
    title: '',
    eventType: null,
    domain: null,
    status: null,
    fetchGoal: false,
    downloadUrl: undefined,
    downloadFileName: undefined,
    fetchGenerateReport: false,

  };

  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getGoals to fetch goal listings
   * call goalAction.getGoals to fetch goal categories
   */
  public componentDidMount() {
    const { items, activePage, activeTab, activeSecondaryTab } = this.state;
    const reqObj = {
      page: Number(activePage),
      userId: this.props.userId,
      limit: items,
      filter: activeTab,
      status: activeSecondaryTab,
    };
    this.props.getRequests(reqObj);
    this.props.getDomains({});
  }

  /**
   * @description componentDidUpdate is called when we need to fetch listing again.
   * call goalAction.getGoals to fetch goal listings
   */
  public componentDidUpdate() {
    if (this.state.fetchGoal) {
      this.fetchGoalsData(this.state.activePage);
      this.setState({ fetchGoal: false });
    }
    if (this.state.fetchGenerateReport) {
      this.downloadReport();
    }
  }

  /**
   * @description componentWillUnmount is used when component destroyed
   * call tagAction.resetAllTags() to reset the tags
   */
  public componentWillUnmount() {
    this.props.resetAllTags();
  }

  /**
   * @description fetchGoalsData is used on initial load
   */
  public fetchGoalsData(pageno: number) {
    const { sort, sortBy, activeSecondaryTab, activeTab } = this.state;
    let reqObj = {
      page: pageno,
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      domain: getSelectedValue(this.state.domain),
      title: this.state.title,
      fetchGoalsData: getSelectedValue(this.state.status),
      filter: activeTab,
      status: activeSecondaryTab,
      userId: this.props.userId,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }

    this.props.getRequests(reqObj);
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleChange = (value: string, key: string) => {
    this.setState({ ...this.state, [key]: value },
                  () => { this.fetchGoalsData(1); });
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData = (sort: string, sortBy: string) => {
    const { activePage } = this.state;
    this.setState({ ...this.state, sort, sortBy },
                  () => this.fetchGoalsData(activePage));
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => {
    this.setState({
      activePage: pageno,
    },            () => {
      this.fetchGoalsData(pageno);
    });
  }

  /**
   * @description
   * generateReport is used to get the generate report
   */
  public generateReport = (title: string) => {
    const tableContent = this.state.tableContent;
    const columnHeaders = [];
    const columnKeys = [];
    for (const [key, value] of Object.entries(tableContent)) {
      columnHeaders.push(value.name);
      columnKeys.push(key);
    }
    this.props.generateReport({
      columnHeaders,
      columnKeys,
      reportTitle: `${title}.csv`,
      filter: this.state.activeTab,
      status: this.state.activeSecondaryTab,
      isRequestManagement: true,
      isGenerateReport: true,
    });
  }

  public downloadReport = () => {
    this.props.resetGenerateReport();
    const filePath = this.state.downloadUrl;
    const a = document.createElement('a');
    a.setAttribute('href', `${filePath}`);
    a.setAttribute('download', this.state.downloadFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public render() {
    const filterContent = ['search', 'eventType', 'domain'];
    const { goals, tableContent, activePage, sort, sortBy, activeTab } = this.state;
    const filterObj = {
      activePage,
      sort,
      sortBy,
    };
    return (
      <React.Fragment>
        <div className="filter-wrapper">
          <FilterComponent filterContent={filterContent} handleFilter={this.handleChange} status={goalStatusTypes} activeTab={activeTab} />
          {activeTab === staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_ORGANIZATION_ADMIN_REQUESTS &&
            <>
              <span className="btn btn-primary round-circle generate-report-btn" onClick={() => this.generateReport(staticConstants.USER_MANAGEMENT_REPORT.ORG_ADMIN)}>
                Generate Report
              </span>
            </>
          }
          {activeTab === staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_PROFESSIONAL_REQUESTS &&
            <>
              <span className="btn btn-primary round-circle generate-report-btn" onClick={() => this.generateReport(staticConstants.USER_MANAGEMENT_REPORT.PROFESSIONAL)}>
                Generate Report
              </span>
            </>
          }
        </div>
        <ListingContainer filterObj={filterObj} tableContent={tableContent} listData={goals}
          handlePageChange={this.handlePageChange} handleSorting={this.sortData} />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { tags: { domains }, authentication } = state;
  const { user } = authentication;
  return {
    user,
    role: user.role,
    goals: state.rfpRequest,
    domainOptions: getUpdatedOptions(domains, 'name'),
  };
}

const connectedGoalRequests = connect(mapStateToProps, { ...rfpAction, ...tagAction })(GoalRequests);
export { connectedGoalRequests as GoalRequests };
