import React, { Dispatch } from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../../shared/sidebar';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import queryString from 'query-string';
import { LocationDescriptorObject } from 'history';
import { staticConstants, formatCurrency, MAGIC_NUMBER, CSS_ClASSES } from '../../../utils';
import { history } from '../../../helpers';
import { PaymentListingContainer } from './listing/listingContainer';

interface IProps {
  dispatch?: Dispatch<OpenFieldTypes.RootAction>;
  location: LocationDescriptorObject;
  paymentData?: Array<[]>;
}
interface IState {
  activeTab: string;
}
class PaymentPage extends React.Component<IProps, IState> {
  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps, state: IState) {
    const updateState = {};
    if (props.location && props.location.search) {
      const parsedParams = queryString.parse(props.location.search);
      const { page = 1, tab = '' } = parsedParams;
      updateState['activeTab'] = tab;
      updateState['activePage'] = Number(page);
    } else {
      updateState['activeTab'] = staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.DISPUTE_PAYMENTS;
      updateState['activePage'] = 1;
      history.replace({ search: `tab=${staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.DISPUTE_PAYMENTS}&page=1` });
    }
    return updateState;
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
      activeTab: staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.DISPUTE_PAYMENTS,
    };
  }

  public handleActiveTabs = (activeTab: string) => {
    history.replace({ search: `?tab=${activeTab}&page=1` });
    this.setState({ activeTab });
  }

  public render() {
    const { activeTab } = this.state;
    const { paymentData } = this.props;
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <div className="connect-header">
            <Breadcrumb className="position-relative">
              <BreadcrumbItem className="heading heading-sm text-uppercase roboto-bold">
                Payment Management
              </BreadcrumbItem>
            </Breadcrumb>
          </div>
          <div className="dashboard-cards mt-4">
            <div className="card card-col-3">
              <div className="card-data">
                <h2>
                  <em>$</em>{paymentData && formatCurrency(paymentData['sessionFee'].toFixed(MAGIC_NUMBER.TWO))}
                </h2>
                <p>Total Session Fee</p>
              </div>
              <span className="icon icon-payment" />
            </div>
            <div className="card card-col-3">
              <div className="card-data">
                <h2>
                  <em>$</em>{paymentData && formatCurrency(paymentData['expertFee'].toFixed(MAGIC_NUMBER.TWO))}
                </h2>
                <p>Total Expert Fee</p>
              </div>
              <span className="icon icon-payment icon-payment-blue" />
            </div>
            <div className="card card-col-3">
              <div className="card-data">
                <h2>
                  <em>$</em>{paymentData && formatCurrency(paymentData['commission'].toFixed(MAGIC_NUMBER.TWO))}
                </h2>
                <p>Overlap Commissions</p>
              </div>
              <span className="icon icon-bill" />
            </div>
          </div>
          <div className="dashboard-cards mt-4">
            <div className="card card-col-2">
              <div className="card-data">
                <h2>
                  <em>$</em>{paymentData && formatCurrency(paymentData['subscriptionFee'].toFixed(MAGIC_NUMBER.TWO))}
                </h2>
                <p>Total Subscription Fee</p>
              </div>
              <span className="icon icon-total-subscription"/>
            </div>
            <div className="card card-col-2">
              <div className="card-data">
                <h2>
                  <em>$</em>{paymentData && formatCurrency(paymentData['addonFee'].toFixed(MAGIC_NUMBER.TWO))}
                </h2>
                <p>Total Add-On Fee</p>
              </div>
              <span className="icon icon-addon"/>
            </div>
          </div>
          <div className="tab-btn-group mt-4 multi-tab-buttons flex-fill">
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.DISPUTE_PAYMENTS ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleActiveTabs(staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.DISPUTE_PAYMENTS)}
            >
              Dispute Payments
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.PENDING_PAYMENTS ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleActiveTabs(staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.PENDING_PAYMENTS)}
            >
              Pending Payments
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.RECEIVED_PAYMENTS ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleActiveTabs(staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.RECEIVED_PAYMENTS)}
            >
              Received Payments
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.RELEASED_REFUNDED_PAYMENTS ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleActiveTabs(staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.RELEASED_REFUNDED_PAYMENTS)}
            >
              Released/Refunded Payments
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.SUBSCRIPTION_FEE ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleActiveTabs(staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.SUBSCRIPTION_FEE)}
            >
              Subscription Fee
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.ADD_ON_FEE ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleActiveTabs(staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.ADD_ON_FEE)}
            >
              Add-On Fee
            </Button>
          </div>
          <PaymentListingContainer {...this.props} activeTab={activeTab} />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { paymentData } = state.systemAdminPayment;
  return { paymentData };
}

const connectedPaymentPage = connect(mapStateToProps)(PaymentPage);
export { connectedPaymentPage as PaymentPage };
