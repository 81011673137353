import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { debounce } from 'throttle-debounce';
import OpenFieldTypes from 'OpenFieldTypes';
import { searchTagsAction } from './redux/actions';
import { MAGIC_NUMBER } from '../../../utils';
interface IProps {
  tags: any;
  dispatch?: Dispatch<OpenFieldTypes.RootAction>;
  onAdd: any;
  limitTags: number;
  children?: any;
  selectedTags: any;
}

interface IState {
  selectedTags: any;
  tags: any;
  value: any;
  suggestedTags: any;
}

class SearchTags extends React.Component<IProps, IState> {

  constructor(props: Readonly<IProps>) {
    super(props);
    this.state = {
        value: '',
        selectedTags: null,
        tags: [],
        suggestedTags: [],
      };
  }

  public componentWillReceiveProps(props: IProps) {
    if (props.tags) {
        this.setState({ tags: props.tags });
      }
  }

  public componentWillMount() {
      this.onSuggestionsFetchRequested = debounce(
        MAGIC_NUMBER.TREE_HUNDRED,
        this.onSuggestionsFetchRequested,
      );
    }

  public getSuggestions = (value: string) => {
      const { tags } = this.state;
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      return inputLength === 0 ? [] : tags.filter((tag: { name: string; }) =>
        tag.name.toLowerCase().slice(0, inputLength) === inputValue,
      );
    }

  public getSuggestionValue = (suggestion: { name: any; }) => suggestion.name;

    // Use your imagination to render suggestions.
  public renderSuggestion = (suggestion: { name: React.ReactNode; }) => (
      <div>
        {suggestion.name}
      </div>
    )

  public onSuggestionsFetchRequested = ({ value }) => {
    this.setState({ value }, () => {
      if (value) {
        this.props.dispatch(searchTagsAction.searchTags(value));
      }
    });
  }

    // Autosuggest will call this function every time you need to clear suggestions.
  public onSuggestionsClearRequested = () => {
    this.setState({
        tags: [],
      });
  }

  public onChange = (event: any, { newValue }: any) => {
    const { tags } = this.state;
    this.setState({ value: newValue }, () => {
      if (tags) {
        const filterData = tags.filter((item: { name: any; }) => item.name === newValue);
        filterData.length && this.props.onAdd(filterData[0]);
      }
    });
  }

  public render() {
    const { tags, value } = this.state;

    const inputProps = {
        placeholder: 'Search here',
        className:'auto-complete-tag',
        value,
        onChange: this.onChange,
      };

    return (
            // <Autocomplete
            //     delay={400}
            //     suggestions={tags}
            //     onChange={this.handleTagChange}
            //     onAdd={onAdd}
            //     tags={selectedTags}
            //     limitTags={limitTags}
            //     allowCreateTag={false}
            //     className="auto-complete-tag"
            //     placeholder="Search here"
            // />
            <Autosuggest
              suggestions={tags}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={(suggestion: { name: any; }) => suggestion.name}
              renderSuggestion={this.renderSuggestion}
              inputProps={inputProps}
            />
      );
  }
}

function mapStateToProps(state: any) {
  const { searchTags } = state;
  return {
    tags: searchTags['tags'],
  };
}

const connectediSearchTags = connect(mapStateToProps)(SearchTags);
export { connectediSearchTags as SearchTags };
