import React, { createRef, Dispatch } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import OpenFieldTypes from 'OpenFieldTypes';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,

} from 'reactstrap';
import ReeValidate from 'ree-validate';
import Select, { createFilter } from 'react-select';
import { history } from '../../../helpers';
import {
  locations,
  reeValidators,
  mapTagsFromAPIRequest, mapTagstoAPIRequest,
  messages,
  customSelectStyles,
  nameTitle,
  mapNameTitleFromApi,
  getLocalUserData,
  reactSelectFilters,
  MAGIC_NUMBER
} from '../../../utils';
import { pageRoutes } from '../../../utils/routeConstants';
import { Header } from '../../shared/header';
import profileImage from './../../../assets/images/profile.png';
import { validator } from './../../../helpers';
import { IUploadImgaeReq, IValidator, ILearnerProfile, IUpdateExpertProfileReq } from './../../../interfaces';
import { learnerProfileAction } from './redux/actions';
import * as _ from 'lodash';
import { ImageCropperPopup } from '../../shared/modals/imageCropperPopup';
import { UpdateEducationForm } from '../register/UpdateEducation';
import { ExperienceForm } from '../register/ExperienceForm';
import NumberFormat from 'react-number-format';
import { bidAction } from '../../expert/bidManagement/redux/actions';

validator(ReeValidate);

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  updateProfile: any;
  industries: [];
  history: any;
  location: any;
  bids: any;
  user?: any;
}

interface IState {
  formData: any;
  profileImageUpload: File;
  errors: any;
  tagsSuggestions: Array<{ value: string; label: string }>;
  isOpenCropModal: boolean;
  image: any;
  loggedInUser: any;
}

class UpdateProfile extends React.Component<IProps, IState> {
  public scrollRef: any;
  public educationFormRef: any;
  public experienceFormRef: any;
  public validator: IValidator;
  public educationValid: boolean;
  public experienceValid: boolean;
  private readonly fileInput = createRef<HTMLInputElement>();
  constructor(props: IProps) {
    super(props);
    this.scrollRef = React.createRef();
    this.validator = new ReeValidate({
      firstName: reeValidators.required_min_3_max_100,
      lastName: reeValidators.required_min_3_max_100,
      occupation: reeValidators.required_min_3_max_100,
      position: reeValidators.min_3_max_100,
      profileImageUpload: 'verify_profile_image|verify_profile_image_size',
      title: 'required',
      phoneNumber: 'min:5|max:15',
      bio: reeValidators.required_min_3_max_500,
      address: 'required',
      location: 'required'
    });

    this.state = {
      formData: {
        firstName: '',
        lastName: '',
        education: {},
        dateOfBirth: null,
        occupation: '',
        position: '',
        location: null,
        address: '',
        tags: [],
        title: null,
        employer: '',
        selectedLocation: null,
        selectedEducation: null,
        phoneNumber: '',
        bio: '',
      },
      tagsSuggestions: [],
      profileImageUpload: null,
      errors: this.validator.errors,
      isOpenCropModal: false,
      image: null,
      loggedInUser: {},
    };
    this.educationValid = false;
    this.experienceValid = false;
    this.educationFormRef = React.createRef();
    this.experienceFormRef = React.createRef();
  }

  public handleTagAdd = (tag: { value: string; label: string }) => {
    const ind = this.state.formData.tags.findIndex((t: { [x: string]: string }) => t['value'] === tag['value']);
    const tags = [].concat(this.state.formData.tags, tag);
    this.setState({ formData: { ...this.state.formData, tags } }, () => {
      this.removeErrorAndReValidate('tags');
      if (ind > -1) {
        const lind = _.findLastIndex(tags, (o) => o.value === tag.value);
        this.handleTagDelete(lind);
      }
    });
  }

  public handleTagDelete = (i: number) => {
    const tags = this.state.formData.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ formData: { ...this.state.formData, tags } }, () => {
      this.removeErrorAndReValidate('tags');
    });
  }

  public componentDidMount = () => {
    const { dispatch, location } = this.props;
    const params = queryString.parse(location.search);
    if (params.isOpenRequest && params.bidId) {
      dispatch(bidAction.getBidDetail({ id: String(params.bidId) }));
    }
    dispatch(learnerProfileAction.getProfile());
    if (this.props.location) {
      const paramsNew = queryString.parse(this.props.location.search);
      paramsNew['isOpenRequest'] && paramsNew['bidId'] &&  setTimeout(() => {
        this.scrollRef && this.scrollRef.current &&
         this.scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, MAGIC_NUMBER.CHAR_LENGTH);
    }
    this.setState({ loggedInUser: getLocalUserData() });
  }

  public componentWillReceiveProps(nextProps: {
    updateProfile: {
      profile: ILearnerProfile;
      updateProfile: boolean;
      uploadingImage: boolean;
    };
  }) {
    const newProfile = nextProps.updateProfile.profile;
    if (newProfile && !nextProps.updateProfile.uploadingImage && nextProps.updateProfile.updateProfile) {
      const formData = {
        firstName: newProfile.firstName,
        lastName: newProfile.lastName,
        education: newProfile.education,
        dateOfBirth: newProfile.dateOfBirth ? new Date(newProfile.dateOfBirth) : null,
        occupation: newProfile.occupation,
        position: newProfile.position,
        location: newProfile.location,
        address: newProfile.address,
        phoneNumber: newProfile.phoneNumber,
        publishLink: newProfile.publishLink,
        bio: newProfile.bio || '',
        tags: mapTagsFromAPIRequest(newProfile.tags),
        title: mapNameTitleFromApi(newProfile.title) || null,
        employer: newProfile.employer,
        selectedLocation: newProfile.location ?
          { label: newProfile.location, value: newProfile.location } : null,
        selectedEducation: newProfile.education ?
          { label: newProfile.education, value: newProfile.education } : null,
      };
      this.setState({ formData });
    }
    this.hideModal();
  }

  public handleChange = (event: any, type: string = null) => {
    if (type) {
      switch (type) {
        case 'eventLocation':
          this.setState({
            formData: {
              ...this.state.formData, location: event['value'],
              selectedLocation: { value: event['value'], label: event['label'] },
            },
          },            () => {
            this.removeErrorAndReValidate('location');
          });
          return;
        case 'eventEducation':
          this.setState({
            formData: {
              ...this.state.formData, education: event['value'],
              selectedEducation: { value: event['value'], label: event['label'] },
            },
          },            () => {
            this.removeErrorAndReValidate('education');
          });
          return;
      }
    }
    const { name, value } = event.target;
    this.setState({ formData: { ...this.state.formData, [name]: value } }, () => {
      if (!(name === 'address' || name === 'publishLink')) this.removeErrorAndReValidate(name);
    });

  }

  public handleTitleChange = selectedOption => {
    const { formData } = this.state;
    formData.title = selectedOption;
    this.setState({ formData }, () => this.removeErrorAndReValidate('title'));
  }

  public handleLocationChange = selectedOption => {
    const { formData } = this.state;
    formData.location = selectedOption.value;
    this.setState({ formData }, () => this.removeErrorAndReValidate('location'));
  }

  public handleDOBChange = (date: Date) => {
    Object.assign(this.state, { formData: { ...this.state.formData, dateOfBirth: date } });
    this.removeErrorAndReValidate('dateOfBirth');
  }

  public moveToDashboardPage = () => {
    history.push(`${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.DASHBOARD}`);
  }

  public removeErrorAndReValidate = (name: string) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.formData[name])
      .then(() => {
        this.setState({ errors });
      });
  }

  public removeIndexFromArr(reqArr: any) {
    const length = reqArr.length;
    const upatedArr = [];
    for (let i = 0; i < length; i++) {
      const obj = reqArr[i];
      const updatedObj = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const updateKey = key.replace(`-${i}`, '');
          updatedObj[updateKey] = obj[key];
        }
      }
      upatedArr.push(updatedObj);
    }
    return upatedArr;
  }

  public getEducationData = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { formData } = this.state;
    const { education, selectedEducation } = this.educationFormRef.current.state;
    education.availableDays = this.educationFormRef.current.selectedAvailabilities;
    formData.expertEducation = {
      ...education,
      tags: mapTagstoAPIRequest(education.tags),
      education: selectedEducation && selectedEducation.value,
    };
    this.setState({ formData }, () => {
      this.validateAndSubmit();
    });
  }

  public successValidateEducation = () => {
    this.educationValid = true;
  }

  public successValidateExperience = () => {
    if (this.educationValid) this.experienceValid = true;
  }

  public validateAndSubmit = () => {
    const { errors } = this.validator;
    this.educationFormRef.current.validateOnEditProfile();
    this.experienceFormRef.current.validateAndSubmit();
    this.validator.validateAll(this.state.formData)
      .then((success: boolean) => {
        if (success && this.educationValid && this.experienceValid) {
          this.educationValid = false;
          this.experienceValid = false;
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  public handleSubmit = () => {

    const { dispatch, location } = this.props;
    const params = queryString.parse(location.search);
    const isOpenRequest = !!(params.isOpenRequest && params.bidId);
    const formData = this.state.formData;
    const { experiences } = this.experienceFormRef.current.state;
    const updatedExperiences = this.removeIndexFromArr(experiences);
    const data: IUpdateExpertProfileReq = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      expertEducation: formData.expertEducation,
      dateOfBirth: formData.dateOfBirth ? formData.dateOfBirth.toDateString() : '',
      occupation: formData.occupation,
      position: formData.position,
      location: formData.location, // formData.selectedLocation ? formData.selectedLocation.value : '',
      phoneNumber: formData.phoneNumber,
      publishLink: formData.publishLink,
      bio: formData.bio,
      address: formData.address,
      title: formData.title.value,
      employer: formData.employer,
      experiences: updatedExperiences,
      industry: '',
      industryId: '',
      sector: '',
      sectorId: '',
    };
    dispatch(learnerProfileAction.updateExpertProfile(data, isOpenRequest));
  }

  /* industry autosuggestions */
  public fetchIndustrySuggestions = () => {
    const { dispatch } = this.props;
    dispatch(learnerProfileAction.getIndustries());
  }

  public getIndustrySuggestionsValue = (suggestion: any) => {
    return suggestion;
  }

  public handleIndustryChange = (_event, { newValue }) => {
    const formData = this.state.formData;
    formData['industry'] = newValue.subDomain || newValue;
    formData['industryId'] = newValue._id || '';
    this.setState({ formData });
    this.removeErrorAndReValidate('industry');
    this.removeErrorAndReValidate('industryId');

  }
  public renderSuggestion = (suggestion) => (
    <div>
      {suggestion.subDomain}
    </div>
  )

  public clearIndustrySuggestions = () => {
    // TODO: clearIndustrySuggestions
  }

  public fetchSectorSuggestions = () => {
    const { dispatch } = this.props;
    dispatch(learnerProfileAction.getCategories());
  }
  public getSectorSuggestionsValue = (suggestion: any) => {
    return suggestion;
  }
  public handleSectorChange = (event, { newValue }) => {
    const newValue1 = newValue.category || newValue;
    const formData = this.state.formData;
    formData['sector'] = newValue1;
    formData['sectorId'] = newValue._id || '';
    this.setState({ formData });
    this.removeErrorAndReValidate('sector');
    this.removeErrorAndReValidate('sectorId');
  }
  public renderSectorSuggestion = (suggestion) => (
    <div>
      {suggestion.category}
    </div>
  )

  /* sector autosuggestions */

  public handleUploadImageSubmit = (e) => {
    e.preventDefault();
    const profileImageUpload = this.fileInput.current.files[0];
    const { errors } = this.validator;
    Object.assign(this.state, { profileImageUpload });
    errors.remove('profileImageUpload');
    this.validator.validate('profileImageUpload', this.state.profileImageUpload)
      .then((success: boolean) => {
        if (success) {
          this.setState({
            isOpenCropModal: true,
            image: profileImageUpload,
          });
        } else {
          this.setState({ errors });
        }
      });
  }

  public savedCroppedImage = data => {
    const up: IUploadImgaeReq = { type: 'image/png', upload: data };
    const { dispatch } = this.props;
    dispatch(learnerProfileAction.uploadProfileImage(up));
  }
  public hideModal = () => {
    this.setState({ isOpenCropModal: false });
  }

  /**
   * @description
   * handlePhoneChange is used to set the value on state from the phone.
   * @param fields {Object} num
   */
  public handlePhoneChange = (num: { formattedValue: string }) => {
    const { errors } = this.validator;
    this.setState({
      formData: {
        ...this.state.formData,
        phoneNumber: num.formattedValue.replace('-', ''),
      },
    },            () => {
      errors.remove('phoneNumber');
      this.validator.validate('phoneNumber', this.state.formData['phoneNumber'])
        .then(() => {
          this.setState({ errors });
        });
    });
  }

  public render() {
    const { formData, errors, isOpenCropModal, image } = this.state;
    const { updateProfile, location, bids } = this.props;
    const params = queryString.parse(location.search);
    const tags = [];
    if (params.isOpenRequest && params.bidId && bids.bidDetails) {
      const { domain, subDomain, category, subCategory, activity } = bids.bidDetails;
      const tagDetails = { domain, subDomain, category, subCategory, activity };
      Object.values(tagDetails).forEach(tag => tags.push(tag));
    }

    let locationValue = null;
    if (formData.location) {
      locationValue = { label: formData.location , value: formData.location };
    }

    const getErrClass = (field: string) => {
      return errors.has(field) ? ' has-error' : '';
    };
    return (
      <>
        <Header />
        <div className="create-profile-wrapper edit-profile mb-5">
          <h2 className="heading heading-sm text-uppercase roboto-bold mb-4">Complete Profile</h2>
          <div className="profile-wrapper">
            <Form noValidate className="profile-image-wrapper learner-profile-image flex-wrap">
              <span className="image-upload">
                <img src={updateProfile && updateProfile.profile &&
                  updateProfile.profile.profileImage ?
                  updateProfile.profile.profileImage : profileImage}
                  className="img-cover" alt="profile" />
                <label className="file-icon icon-edit">
                  <input ref={this.fileInput} type="file" name="profileImageUpload"
                    onChange={this.handleUploadImageSubmit} />
                </label>
              </span>
              <div className="profile-fields">
                <Row>
                  <Col xs="12" md="2">
                    <FormGroup className={`floating-label disabled-input ${getErrClass('title')}`}>
                      <Select
                        name="title"
                        styles={customSelectStyles}
                        value={formData.title}
                        onChange={this.handleTitleChange}
                        options={nameTitle}
                        id="title"
                        placeholder="Title"
                        className="react-select-box select-box-group"
                      />
                      <Label for="eventType" className={formData.title ? 'selected' : ''}>Title</Label>

                      {errors.has('title') &&
                        <div className="error-text">
                          {
                            errors.first('title')}
                        </div>
                      }
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="5">
                    <FormGroup className={`floating-label ${getErrClass('firstName')}`}>
                      <Input
                        type="text"
                        className="form-control"
                        name="firstName"
                        id="firstName"
                        placeholder="First Name"
                        value={formData.firstName}
                        onChange={this.handleChange}
                      />
                      <Label for="firstName">First Name</Label>
                      {errors.has('firstName') &&
                        <div className="error-text">
                          {
                            errors.first('firstName').replace('firstName', 'first name')}
                        </div>
                      }
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="5">
                    <FormGroup className={`floating-label ${getErrClass('lastName')}`}>
                      <Input
                        type="text"
                        className="form-control"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        value={formData.lastName}
                        onChange={this.handleChange}
                      />
                      <Label for="lastname">Last Name</Label>
                      {errors.has('lastName') &&
                        <div className="error-text">
                          {
                            errors.first('lastName').replace('lastName', 'last name')}</div>
                      }
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="hint-text p-0">
                <p className="text-center">Image size should be maximum 5mb. <br />File format: jpeg, png.</p>
              </div>
              {
                <div className="error-text">{errors.first('profileImageUpload')}</div>
              }

            </Form>
          </div>

          <div className="edit-profile-section">
            <div className="basic-details">
              <h2 className="heading heading-rg roboto-medium mb-5">Personal Information</h2>
              <Form>
                <div className="form-wrapper">
                  <Row>
                    <Col xs="12" sm="6">
                      <FormGroup className="floating-label disabled-input disabled">
                        <label>Email</label>
                        <p className="text-left">{updateProfile && updateProfile.profile &&
                          updateProfile.profile.email}</p>
                      </FormGroup>
                    </Col>

                    <Col xs="12" sm="6">
                      <FormGroup className={`floating-label ${getErrClass('occupation')}`}>
                        <Input
                          type="text"
                          className="form-control"
                          name="occupation"
                          id="occupation"
                          placeholder="Occupation"
                          onChange={this.handleChange}
                          value={formData.occupation}
                        />
                        <Label for="occupation">Occupation</Label>
                        {errors.has('occupation') &&
                          <div className="error-text">{errors.first('occupation')}</div>
                        }
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup className={`floating-label ${getErrClass('position')}`}>
                        <Input
                          type="text"
                          className="form-control"
                          name="position"
                          id="position"
                          placeholder="Position (Optional)"
                          onChange={this.handleChange}
                          value={formData.position}
                        />
                        <Label for="position">Position (Optional)</Label>
                        {errors.has('position') &&
                          <div className="error-text">{errors.first('position')}</div>
                        }
                      </FormGroup>
                    </Col>

                    <Col xs="12" sm="6">
                      <FormGroup className="floating-label">
                        <Input
                          type="text"
                          className="form-control"
                          name="address"
                          id="address"
                          placeholder="City or Town"
                          onChange={this.handleChange}
                          value={formData.address}
                        />
                        <Label for="address">City or Town</Label>
                        {errors.has('address') &&
                          <div className="error-text">City or Town is required.</div>
                        }
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                      <FormGroup className="floating-label">
                        <NumberFormat
                          prefix={'+'}
                          className="form-control"
                          name="phoneNumber"
                          mask=""
                          placeholder="Phone Number (Optional)"
                          maxLength={MAGIC_NUMBER.FIFTEEN}
                          onValueChange={this.handlePhoneChange}
                          value={formData.phoneNumber}
                        />
                        <Label for="phoneNumber">Phone Number (Optional)</Label>
                        {errors.has('phoneNumber') &&
                          <div className="error-text">
                            {messages.invalidPhoneNumber}
                          </div>
                        }
                      </FormGroup>
                    </Col>
                    <Col xs="12" sm="6">
                    <FormGroup className="floating-label disabled-input">
                      <Select
                        name="location"
                        options={locations}
                        id="location"
                        styles={customSelectStyles}
                        placeholder="Country"
                        filterOption={createFilter(reactSelectFilters)}
                        onChange={this.handleLocationChange}
                        className="react-select-box select-box-group"
                        value={locationValue}
                      />
                      <Label for="location" className={formData.location !== null ? 'selected' : ''} >Country</Label>
                      {errors.has(`location`) &&
                        <div className="error-text">The Country field is required.</div>
                      }
                    </FormGroup>
                    </Col>
                    <Col xs="12">
                      <FormGroup className="floating-label">
                        <Input
                          type="text"
                          className="form-control"
                          name="publishLink"
                          id="publishLink"
                          placeholder=" Links to Published Work (Optional)"
                          onChange={this.handleChange}
                          value={formData.publishLink}
                        />
                        <Label for="work"> Links to Published Work (Optional)</Label>
                      </FormGroup>
                    </Col>

                    <Col xs="12">
                      <FormGroup className={`floating-label disabled-input textarea-label ${getErrClass('timeZone')}`}>
                        <textarea
                          className="form-control textarea-md"
                          name="bio"
                          maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                          id="text-count"
                          placeholder="Describe Your Area of Expertise"
                          onChange={this.handleChange}
                          value={formData.bio}
                        />
                        <span className="text-count">{formData.bio.length}/500</span>
                        <Label for="text-count" className={formData.bio.length > MAGIC_NUMBER.ZERO ? 'selected' : ''}>Area of Expertise</Label>
                        {errors.has('bio') &&
                          <div className="error-text">{errors.first('bio')}</div>
                        }
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
          </div>
          <div className="edit-profile-section" ref={this.scrollRef}>
            <div className="basic-details">
              <h2 className="heading heading-rg roboto-medium mb-5">Education Details</h2>
              <UpdateEducationForm profile={updateProfile && updateProfile.profile} ref={this.educationFormRef} successValidateSubmit={this.successValidateEducation}
                props={this.props} tagList={tags} />
            </div>
          </div>
          <div className="edit-profile-section">
            <div className="basic-details">
              <h2 className="heading heading-rg roboto-medium mb-5">Experience Details</h2>
              <ExperienceForm profile={updateProfile && updateProfile.profile} ref={this.experienceFormRef} successValidateSubmit={this.successValidateExperience} />
            </div>
            <div className="card-footer d-flex align-items-center justify-content-end">
              {this.state.loggedInUser && !this.state.loggedInUser.isFirstTime &&
                <Button className="btn btn-regular mr-2" onClick={() => this.props.history.goBack()}>Cancel</Button> }
              <Button color="primary" className="btn btn-rg" onClick={this.getEducationData}>Update</Button>
            </div>
          </div>
        </div>
        <ImageCropperPopup
          isOpenModal={isOpenCropModal}
          image={image}
          savedCroppedImage={this.savedCroppedImage}
          hideModal={this.hideModal}
        />
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { updateProfile, bids, authentication: { user } } = state;
  return {
    updateProfile,
    bids,
    user,
  };

}
const connectedUpdateExpertProfile = connect(mapStateToProps)(UpdateProfile);
export { connectedUpdateExpertProfile as UpdateProfile };
