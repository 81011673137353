import gql from 'graphql-tag';

const SEND_FEEDBACK = gql`
  mutation feedback($feedback: String!) {
    feedback( feedback: $feedback) {
        message
      }
}
`;

const SYSTEM_ADMIN_REPLY_FEEDBACK = gql`
  mutation replyFeedback($userId: String!, $message: String!) {
    replyFeedback( userId: $userId, message: $message) {
        message
      }
}
`;

const SYSTEM_ADMIN_REMOVE_FEEDBACK = gql`
  mutation removeFeedback($userId: String!) {
    removeFeedback( userId: $userId) {
        message
      }
}
`;

export {
  SEND_FEEDBACK, SYSTEM_ADMIN_REPLY_FEEDBACK, SYSTEM_ADMIN_REMOVE_FEEDBACK,
};
