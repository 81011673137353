import React, { useState } from 'react';
import Select from 'react-select';
import csc from 'country-state-city';
import { Controller } from 'react-hook-form';
import {customSelectStyles } from '../../../utils';
import {
  Label,
} from 'reactstrap';
interface IProps {
  control: any;
  setValue: any;
  watch: any;
}

export default function City(props: IProps) {

  const [cityOptions, setCityOptions] = useState([]);
  const { control, setValue, watch } = props;
  const [stateVal, setStateVal] = useState({ label: '', value: '' });
  const getCityOptions = (val) => {
    const options = csc.getCitiesOfState(val);
    const updateCityOptions = options.map(city => ({
      label: city.name, value: city.id,
    }));
    setCityOptions(updateCityOptions);
  };

  if (watch().country) {
    const { state } = watch();
    if (state && stateVal.label !== state.label) {
      setStateVal(state);
      getCityOptions(state.value);
    }
  }

  return (
    <Controller
      control={control}
      name="city"
      defaultValue={null}
      render={({ onChange, value }) => (
        <>
        <Select
          placeholder="Select city"
          className="react-select-box select-box-group"
          isDisabled={!watch('country')}
          styles={customSelectStyles}
          name="city"
          onChange={onChange}
          options={cityOptions}
          value={value}
          handleChange={(e) => {
            onChange(e);
            setValue('city', {});
          }}
        />
        <Label for="city" className={value ? 'selected' : ''}>City</Label>
        </>
      )}
      rules={{ required: true }}
    />
  );
}
