import React, { Dispatch } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ReeValidate from 'ree-validate';
import {
  Col,
  Form,
  Button,
  FormGroup,
  Label,
  Row,
  Input
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { validator } from './../../../helpers';
import { locations, staticConstants, reactSelectFilters, MAGIC_NUMBER } from '../../../utils/';
import { customSelectStyles } from '../../../utils/common';
import Select, { createFilter } from 'react-select';
import deleteImage from './../../../assets/images/delete_icon.png';
validator(ReeValidate);

interface IProps {
  experiences?: any;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  successValidateSubmit: any;
  profile?: any;
}

interface IState {
  experiences?: any;
  isSubmitted: boolean;
  errors?: any;
  user: any;
  isExperience: boolean;
}

class ExperienceForm extends React.Component<IProps, IState> {

  public selectedProfeciencies: string[] = [];
  public selectedAvailabilities: string[] = [];
  public validator: any;

  /**
   * @description
   * constructor is used to define the initial state and property
   * @param fields {Object} props
  * */
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      'employer-0': 'required',
      'position-0': 'required',
      'lengthOfServices-0': 'required',
      'summary-0': 'required',
      'location-0': 'required',
    });
    this.state = {
      experiences: [{
        'employer-0': '',
        'position-0': '',
        'lengthOfServices-0': '',
        'summary-0': '',
        'location-0': '',
      }],
      errors: this.validator.errors,
      isSubmitted: false,
      user: {},
      isExperience: false,
    };
  }

  public componentWillReceiveProps(nextProps: any) {
    const { profile } = nextProps;
    if (profile && profile.experiences.length !== 0) {
      const indexedExperience = profile.experiences.map((exp, index) => {
        delete exp.__typename;
        const newKeys = {};
        Object.keys(exp).forEach(key => newKeys[`${key}-${index}`] = exp[key]);
        return newKeys;
      });
      this.setState({ experiences: indexedExperience });
    } else {
      const newArr = [{
        'employer-0': '',
        'position-0': '',
        'lengthOfServices-0': '',
        'summary-0': '',
        'location-0': '',
      }];
      this.setState({ experiences: newArr });
    }
  }

  /**
   * @description
   * addExperience is called when someone click on the addExperience button
   */
  public addExperience = () => {
    this.setState({ isExperience: true });
    const { experiences } = this.state;
    const experiencesLength = experiences.length; // Set index again
    experiences.push({
      [`employer-${experiencesLength}`]: '',
      [`position-${experiencesLength}`]: '',
      [`lengthOfServices-${experiencesLength}`]: '',
      [`summary-${experiencesLength}`]: '',
      [`location-${experiencesLength}`]: '',
    });
    this.setState({
      experiences,
    });
    this.reIntializeValidation();
  }

  /**
   * @description
   * removeExperience is called when someone click on the remove button
   */
  public removeExperience = (index: any) => {
    const { experiences } = this.state;
    if (experiences.indexOf(MAGIC_NUMBER.FIVE)) {
      experiences.splice(index, MAGIC_NUMBER.ONE);
    }
    this.setState({
      experiences,
    });
    this.reIntializeValidation();
  }

  /**
   * @description
   * reIntializeValidation is used to add validation again
   */
  public reIntializeValidation = () => {
    const { experiences } = this.state;
    const experiencesLength = experiences.length; // Set index again
    const validateObj = {};
    for (let i = 0; i < experiencesLength; i++) {
      validateObj[`employer-${i}`] = 'required';
      validateObj[`position-${i}`] = 'required';
      validateObj[`lengthOfServices-${i}`] = 'required';
      validateObj[`summary-${i}`] = 'required';
      validateObj[`location-${i}`] = 'required';
    }
    validateObj['experience'] = 'required';
    this.validator = new ReeValidate(validateObj);
  }

  /**
   * @description
   * handleChange is used to set the value on state from the only input/textarea boxes.
   * @param fields {Object} event
   */
  public handleChange = (index: any, event: any) => {
    const { value, name } = event.target;
    const { experiences } = this.state;
    experiences[index][name] = value;
    this.setState({
      experiences,
    });
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  /**
   * @description
   * handleLocation is used to set the value on state from location select box
   * @param fields {Object} location
   */
  public handleLocation = (index: any, location: any) => {
    const { experiences } = this.state;
    experiences[index][`location-${index}`] = location.value;
    this.setState({
      experiences,
    });
    const { errors } = this.validator;
    errors.remove(`location-${index}`);
    this.validator.validate(`location-${index}`, location.value)
      .then(() => {
        this.setState({ errors });
      });
  }


  /**
   * @description
   * validateAndSubmit is used to validate the form by clicking on the next button.
   * @param fields {Object} event
   */
  public validateAndSubmit = () => {
    const { errors } = this.validator;
    const { experiences } = this.state;
    const experiencesLength = experiences.length; // Set index again
    const validateObj = {};
    for (let i = 0; i < experiencesLength; i++) {
      const experience = experiences[i];
      validateObj[`employer-${i}`] = experience[`employer-${i}`];
      validateObj[`position-${i}`] = experience[`position-${i}`];
      validateObj[`lengthOfServices-${i}`] = experience[`lengthOfServices-${i}`];
      validateObj[`summary-${i}`] = experience[`summary-${i}`];
      validateObj[`location-${i}`] = experience[`location-${i}`];
    }
    this.validator.validateAll(validateObj)
      .then((success: boolean) => {
        if (success) {
          this.props.successValidateSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  /**
   * @description
   * renderExperienceForm is used to render the reference form
  * */
  public renderExperienceForm() {

    const { errors } = this.state;
    return _.map(this.state.experiences, (item: any, index: any) => {
      let value = null;
      if (this.state.experiences) {
        value = item[`location-${index}`] ? { label: item[`location-${index}`], value: item[`location-${index}`] } : null;
      }
      return (
        <Row key={index} className={`${index > MAGIC_NUMBER.ZERO ? 'experience-row' : 'first-row'}`}>
          <Col xs="12" className="Experience_tital">
            <h5>Experience {Number(index) + 1} (Add up to 3)</h5>
          </Col>
          <Col xs="12">
            <div className="Experience_section">
              <Col xs="12" sm="6">
                <FormGroup className="floating-label">
                  <Input
                    type="text"
                    className="form-control"
                    name={`employer-${index}`}
                    id="employer"
                    value={item[`employer-${index}`]}
                    onChange={(e) => this.handleChange(index, e)}
                    placeholder="Employer"
                  />
                  <Label for="employer">Employer</Label>
                  {errors.has(`employer-${index}`) &&
                    <div className="error-text">The employer field is required.</div>
                  }
                </FormGroup>
              </Col>
              <Col xs="12" sm="6">
                <FormGroup className="floating-label">
                  <Input
                    type="text"
                    className="form-control"
                    name={`position-${index}`}
                    id="position"
                    value={item[`position-${index}`]}
                    onChange={(e) => this.handleChange(index, e)}
                    placeholder="Position"
                  />
                  {errors.has(`position-${index}`) &&
                    <div className="error-text">The position field is required.</div>
                  }
                  <Label for="position">Position</Label>
                </FormGroup>
              </Col>
              <Col xs="12" sm="6">
                <FormGroup className="floating-label">
                  <Input
                    type="text"
                    className="form-control"
                    name={`lengthOfServices-${index}`}
                    id="lengthOfServices"
                    value={item[`lengthOfServices-${index}`]}
                    onChange={(e) => this.handleChange(index, e)}
                    placeholder="Length of Service (Eg. Years/Months)"
                  />
                  <Label for="lengthOfServices">Length of Service (Eg. Years/Months)</Label>
                  {errors.has(`lengthOfServices-${index}`) &&
                    <div className="error-text">The Length of Service field is required.</div>
                  }
                </FormGroup>
              </Col>
              <Col xs="12" sm="6">
                <FormGroup className="floating-label disabled-input">
                  <Select
                    name={`location-${index}`}
                    options={locations}
                    id={`location-${index}`}
                    styles={customSelectStyles}
                    placeholder="Country"
                    filterOption={createFilter(reactSelectFilters)}
                    onChange={(e) => this.handleLocation(index, e)}
                    className="react-select-box select-box-group"
                    value={value}
                    ref={ref => this[`select-${index}`] = ref}
                  />
                  <Label for={`location-${index}`} className={item[`location-${index}`] !== '' ? 'selected' : ''}>Country</Label>
                  {errors.has(`location-${index}`) &&
                    <div className="error-text">The Country field is required.</div>
                  }
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup className={`floating-label disabled-input textarea-label`}>
                  <textarea
                    className="form-control textarea-md"
                    name={`summary-${index}`}
                    id="summary"
                    onChange={(e) => this.handleChange(index, e)}
                    maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                    placeholder="Work Experience Summary"
                    value={item[`summary-${index}`]}
                  />
                  <span className="text-count">{item[`summary-${index}`].length}/500</span>
                  <Label for="summary" className={item[`summary-${index}`] && item[`summary-${index}`].length > MAGIC_NUMBER.ZERO ? 'selected' : ''}>Work Experience Summary</Label>
                  {errors.has(`summary-${index}`) &&
                    <div className="error-text">The Work Experience Summary field is required.</div>
                  }
                </FormGroup>
              </Col>
            </div>
          </Col>
          <Col xs="12" className="d-flex justify-content-end add_remove add-button-group">
            {index > MAGIC_NUMBER.ZERO ?
              <Button color="add-new" className="cta_btn service_btn remove" onClick={() => this.removeExperience(index)}><img src={deleteImage} alt="delete" /> Remove</Button>
              : ''}
            {index === (this.state.experiences.length - 1) && this.state.experiences.length < staticConstants.MAX_LENGTH.EXPERIENCE &&
              <Button color="add-new" className="cta_btn service_btn mb-10" onClick={this.addExperience}><em>+</em>Add New</Button>
            }
          </Col>
        </Row>
      );
    });
  }

  /**
   * @description
   * render method is used to render the form
  * */
  public render() {
    return (
      <div className="form-wrapper">
        <Form autoComplete="off">
          {this.renderExperienceForm()}
          {!this.state.isExperience ? this.addExperience() : ''}
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { registering } = state.registeration;
  return {
    registering,
  };
}

const connectedExperienceFormPage = connect(mapStateToProps, null, null, { forwardRef: true })(ExperienceForm);
export { connectedExperienceFormPage as ExperienceForm };
