import { pageRoutes } from '../utils';

const routeConstant = '/viewLearnerProfile/:id/:bidId/:complete';
const routeConstant2 = '/viewOrgAdminProfile/:id/:bidId/:complete';
const routeConstant3 = '/requestManagement/detail/:id';
const routeConstant4 = '/requestManagement';
const routeConstant5 = '/payment/subscription';
const routeConstant6 = '/goalManagement/editGoal/:id';
const routeConstant7 = '/goalManagement/createGoal';
const routeConstant8 = '/payment/checkout';
const routeConstant9 = '/goalManagement/detail/:id';

export default {
  orgAdmin: {
    routes: [
      {
        component: 'OrgAdminDashBoardContainer',
        url: '/orgadmin/dashboard',
      },
      {
        component: 'OrgAdminViewProfileContainer',
        url: '/orgadmin/viewProfile',
      },
      {
        component: 'UpdateProfile',
        url: '/orgadmin/profile',
      },
      {
        component: 'LearnerManagementContainer',
        url: '/learnerManagement',
      },
      {
        component: 'GoalManagementContainer',
        url: pageRoutes.GOALMANAGEMENT.PATH
      },
      {
        component: 'CreateGoalContainer',
        url: routeConstant7,
      },
      {
        component: 'GoalDetailContainer',
        url: routeConstant9,
      },
      {
        component: 'CreateGoalContainer',
        url: routeConstant6,
      },
      {
        component: 'PaymentContainer',
        url: routeConstant8,
      },
      {
        component: 'CardContainer',
        url: '/addCard',
      },
      {
        component: 'SubscriptionPlan',
        url: routeConstant5,
      },
      {
        component: 'RequestManagementContainer',
        url: routeConstant4,
      },
      {
        component: 'RequestDetailContainer',
        url: routeConstant3,
      },
      {
        component: 'EventManagementContainer',
        url: pageRoutes.EVENTMANAGEMENT.PATH
      },
      {
        component: 'EventManagementDetail',
        url: '/eventManagement/detail/:id',
      },
      {
        component: 'ViewProfileContainer',
        url: '/viewExpertProfile/:id/:bidId/:complete',
      },
      {
        component: 'ViewProfileContainer',
        url: '/viewExpertProfile/:id/:bidId',
      },
      {
        component: 'ViewCompleteContainer',
        url: '/viewExpertProfile/complete/:id/:bidId',
      },
      {
        component: 'ViewOrgAdminCompleteProfile',
        url: routeConstant2,
      },
      {
        component: 'ViewLearnerCompleteProfile',
        url: routeConstant,
      },
      {
        component: 'LearnerDetailContainer',
        url: '/learnerManagement/detail/:id',
      },
    ],
  },
  professional: {
    routes: [
      {
        component: 'RequestManagementContainer',
        url: routeConstant4,
      },
      {
        component: 'ProfessionalDashBoardContainer',
        url: '/professional/dashboard',
      },
      {
        component: 'PaymentContainer',
        url: routeConstant8,
      },
      {
        component: 'EventManagementContainer',
        url: pageRoutes.EVENTMANAGEMENT.PATH
      },
      {
        component: 'EventManagementDetail',
        url: '/eventManagement/detail/:id',
      },
      {
        component: 'OrgAdminViewProfileContainer',
        url: '/professional/viewProfile',
      },
      {
        component: 'SubscriptionPlan',
        url: routeConstant5,
      },
      {
        component: 'UpdateProfile',
        url: '/professional/profile',
      },
      {
        component: 'GoalManagementContainer',
        url: pageRoutes.GOALMANAGEMENT.PATH
      },
      {
        component: 'CreateGoalContainer',
        url: routeConstant7,
      },
      {
        component: 'GoalDetailContainer',
        url: routeConstant9,
      },
      {
        component: 'CreateGoalContainer',
        url: routeConstant6,
      },
      {
        component: 'RequestManagementContainer',
        url: pageRoutes.REQUESTMANAGEMENT.PATH
      },
      {
        component: 'RequestDetailContainer',
        url: routeConstant3,
      },
      {
        component: 'ViewOrgAdminCompleteProfile',
        url: routeConstant2,
      },
      {
        component: 'ViewLearnerCompleteProfile',
        url: routeConstant,
      },
      {
        component: 'ViewProfileContainer',
        url: '/viewExpertProfile/:id/:bidId/:complete',
      },

      {
        component: 'ViewProfileContainer',
        url: '/viewExpertProfile/:id/:bidId',
      },
      {
        component: 'ViewCompleteContainer',
        url: '/viewExpertProfile/complete/:id/:bidId',
      },
      {
        component: 'CardContainer',
        url: '/addCard',
      },
    ],
  },
  systemAdmin: {
    routes: [
      {
        component: 'BidDetailContainer',
        url: '/systemAdmin/bidManagement/detail/:id',
      },
      {
        component: 'ExpertViewProfileContainer',
        url: '/viewExpertProfile/:id/:bidId/',
      },
      {
        component: 'OrgAdminViewProfileContainer',
        url: '/viewOrgAdminProfile/:id/',
      },
      {
        component: 'LearnerViewProfileContainer',
        url: '/viewLearnerProfile/:id/',
      },
      {
        component: 'OrgAdminViewProfileContainer',
        url: '/viewProfessionalProfile/:id/',
      },
      {
        component: 'SystemAdminDashBoardContainer',
        url: '/systemAdmin/dashboard',
      },
      {
        component: 'SystemAdminPaymentContainer',
        url: '/systemAdmin/payment',
      },
      {
        component: 'UserManagementContainer',
        url: '/systemAdmin/userManagement',
      },
      {
        component: 'AdminGoalManagementContainer',
        url: pageRoutes.GOALMANAGEMENT.PATH
      },
      {
        component: 'ApplicationFeedbackManagementContainer',
        url: '/systemAdmin/applicationFeedback',
      },
      {
        component: 'UserManagementDetailContainer',
        url: '/systemAdmin/userManagement/detail/:userType/:id',
      },
      {
        component: 'UserManagementDetailContainer',
        url: '/systemAdmin/userManagement/detail/:userType/:id/learner/:learnerId',
      },
      {
        component: 'SystemAdminRequestManagementContainer',
        url: routeConstant4,
      },
      {
        component: 'SystemAdminEventManagementContainer',
        url: pageRoutes.EVENTMANAGEMENT.PATH
      },
      {
        component: 'RequestDetailContainer',
        url: routeConstant3,
      },
      {
        component: 'GoalDetailContainer',
        url: routeConstant9,
      },
      {
        component: 'LogsManagementContainer',
        url: '/systemAdmin/logs/:userType/:id',
      },
      {
        component: 'LogsManagementContainer',
        url: '/systemAdmin/logs/:userType/:id/learner/:learnerId',
      },
      {
        component: 'EventManagementDetail',
        url: '/eventManagement/detail/:id',
      },
      {
        component: 'ViewProfileContainer',
        url: '/viewExpertProfile/:id/:bidId/:complete',
      },
      {
        component: 'ViewOrgAdminCompleteProfile',
        url: routeConstant2,
      },
      {
        component: 'ViewLearnerCompleteProfile',
        url: routeConstant,
      },
      {
        component: 'ContentManagementContainer',
        url: '/systemAdmin/contentManagement',
      },
      {
        component: 'ExpertViewProfileContainer',
        url: '/expert/viewProfile/:userId',
      },
      {
        component: 'SystemAdminViewProfile',
        url: '/systemAdmin/viewProfile',
      },
      {
        component: 'AddMessageContainer',
        url: '/messageBoard/addMessage',
      },
      {
        component: 'CustomizedPlanContainer',
        url: '/systemAdmin/plans',
      },
      {
        component: 'TagManagementContainer',
        url: '/systemAdmin/tags',
      },
    ],
  },
  learner: {
    routes: [
      {
        component: 'LearnerDashBoardContainer',
        url: '/learner/dashboard',
      },
      {
        component: 'LearnerViewProfileContainer',
        url: '/learner/viewProfile',
      },
      {
        component: 'LearnerUpdateProfile',
        url: '/learner/updateProfile',
      },
      {
        component: 'GoalManagementContainer',
        url: pageRoutes.GOALMANAGEMENT.PATH
      },
      {
        component: 'CreateGoalContainer',
        url: routeConstant7,
      },
      {
        component: 'GoalDetailContainer',
        url: routeConstant9,
      },
      {
        component: 'CreateGoalContainer',
        url: routeConstant6,
      },
      {
        component: 'EventManagementContainer',
        url: pageRoutes.EVENTMANAGEMENT.PATH
      },
      {
        component: 'EventManagementDetail',
        url: '/eventManagement/detail/:id',
      },
      {
        component: 'ViewProfileContainer',
        url: '/viewExpertProfile/:id/:bidId/:complete',
      },
      {
        component: 'ViewOrgAdminCompleteProfile',
        url: routeConstant2,
      },
      {
        component: 'ViewLearnerCompleteProfile',
        url: routeConstant,
      },
    ],
  },
  expert: {
    routes: [
      {
        component: 'ExpertDashBoardContainer',
        url: '/expert/dashboard',
      },
      {
        component: 'BidManagementContainer',
        url: '/expert/bidManagement',
      },
      {
        component: 'BidDetailContainer',
        url: '/expert/bidManagement/detail/:id',
      },
      {
        component: 'ExpertViewProfileContainer',
        url: '/expert/viewProfile',
      },
      {
        component: 'ExpertUpdateProfile',
        url: '/expert/profile',
      },
      {
        component: 'EventManagementContainer',
        url: pageRoutes.EVENTMANAGEMENT.PATH
      },
      {
        component: 'EventManagementDetail',
        url: '/eventManagement/detail/:id',
      },
      {
        component: 'ViewOrgAdminCompleteProfile',
        url: routeConstant2,
      },
      {
        component: 'ViewLearnerCompleteProfile',
        url: routeConstant,
      },
      {
        component: 'SubscriptionPlan',
        url: routeConstant5,
      },
      {
        component: 'PaymentContainer',
        url: routeConstant8,
      },
      {
        component: 'WithdrawAmount',
        url: '/payment/withdraw',
      },
      {
        component: 'CardContainer',
        url: '/addCard',
      },
      {
        component: 'ExpertPaymentContainer',
        url: '/expert/payments',
      },
      {
        component: 'ConnectWithOpenField',
        url: '/expert/connectWithUs',
      },
    ],
  },

  common: {
    routes: [
      {
        component: 'NotFoundContainer',
      },
      {
        component: 'NotificationListingContainer',
        url: '/notifications',
      },
      {
        component: 'ChatContainer',
        url: '/messageBoard',
      },
      {
        component: 'LogsManagementContainer',
        url: '/logs',
      },
      {
        component: 'CreateGoalContainer',
        url: '/createGoal',
      },
      {
        component: 'Faq',
        url: '/faq',
      },
      {
        component: 'PrivacyPolicy',
        url: '/privacyPolicy',
      },
    ],
  },
};
