import {
  CREATE_LEARNER_MUTATION, CREATE_PASSWORD_MUTATION, FORGOT_PASSWORD_MUTATION,
  GET_CATEGORIES, GET_INDUSTRY,
  GET_PROFILE_QUERY,
  LOGIN_MUTATION,
  REGISTER_MUTATION,
  RESET_PASSWORD_MUTATION,
  UPDATE_USER_MUTATION,
  UPLOAD_PROFILE_IMAGE_MUTATION,
  VERIFY_CREATE_PASSWORD_QUERY,
  VERIFY_FORGOT_PASSWORD_QUERY,
  EXPERT_REGISTER_MUTATION,
  UPDATE_LEARNER_MUTATION,
  UPDATE_USER_PAYMENT_ACCOUNT_ID_MUTATION,
  UPDATE_EXPERT_MUTATION,
  CANCEL_SUBSCRIPTION,
  ASSIGN_LICENCE,
  DELETE_LEARNER,
  UPDATE_SETTING,
  EXPERT_FEEDBACK,
  PROFESSIONAL_REGISTER_MUTATION,
  SOCIAL_LOGIN_MUTATION,
  LINKEDIN_MUTATION,
  CHANGE_PASSWORD_MUTATION,
  DELETE_USER_MUTATION,
  UPDATE_USER_STATUS_MUTATION,
  UPDATE_USER_REQUEST_STATUS_MUTATION,
  UPDATE_CHANGE_RATE_STATUS_MUTATION,
  CHANGE_REQUEST_HOURLY_RATE_MUTATION,
  ADD_USER_MUTATION,
  UPDATE_SYSTEM_ADMIN_PROFILE_MUTATION,
  LICENCE_MOVEMENT_REQUEST_MUTATION,
  UPDATE_LICENCE_MOVEMENT_MUTATION,
  CHECK_EXISTING_EXPERT,
  RE_VERIFY_USER_MUTATION,
  FLAGGED_COMMENT__MUTATION,
  LOGOUT_USER_MUTATION,
  UPDATE_REGION,
} from '../graphql-client/mutations/userMutations';
import {
  GET_SHORT_PROFILE_QUERY,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_HISTORY,
  GET_USERS_MANAGEMENT_LIST_QUERY,
  GET_PAYMENT_LOGS,
  GET_EXPERT_INCOME,
  GET_WITHDRAW_HISTORY,
  GET_PRORATED_AMOUNT,
  GET_SUBSCRIPTION_PRODUCT,
  GET_USER_GOAL_BID_COUNT,
  GET_BANK_INFO,
} from '../graphql-client/queries/userQuery';
import {
  GET_USERS_LIST_QUERY,
  GET_PAYMENT_LOGIN_LINK_QUERY,
} from '../graphql-client/queries/user';
import { client } from '../index';
import {
  ICreatePasswordReq, IOrgAdmin, IResetPasswordReq, IUpdateProfileReq, IUploadImgaeReq,
  IProfile, IUpdateLearnerProfileReq, IProfessional, IChangePasswordReq, ICreateUser, ISystemAdminProfile,
} from '../interfaces';
import { handleResponse, updateKeyLocalUser } from '../utils/common';
import { staticConstants } from './../utils/staticConstants';
import { SEND_FEEDBACK } from '../graphql-client/mutations/feedbackMutations';

export const userServices = {
  login,
  logout,
  register,
  checkExistingExpert,
  forgotPassword,
  verifyCreatePasswordToken,
  verifyForgotPasswordToken,
  createPassword,
  resetPassword,
  getProfile,
  updateProfileImage,
  getIndustries,
  getCategories,
  updateProfile,
  getUsers,
  createUser,
  updateUserStatus,
  updateChangeRateStatus,
  updateUserRequestStatus,
  deleteUser,
  expertRegister,
  updateLearnerProfile,
  updatePaymentAccountID,
  getPaymentLoginLink,
  getShortProfile,
  updateExpertProfile,
  getSubscription,
  getSubscriptionHistory,
  cancelSubscription,
  assignLicence,
  sendFeedback,
  deleteLearner,
  updateSettings,
  sendExpertFeedback,
  professionalRegister,
  socialLogin,
  changePasswordRequest,
  changeRequestRatePerHour,
  systemAdminCreateUser,
  updateSystemAdminProfile,
  raiseLicenceRequest,
  updateLicenceMovementStatus,
  fetchTotalIncome,
  getPaymentLogs,
  getExpertIncome,
  getWithdrawHistory,
  reVerifyUser,
  getProratedInvoiceAmount,
  sendComment,
  getSubscriptionProduct,
  getTotalCount,
  updateRegion,
  getBankInfo,
};

function setDataToLocalStorage(userData, condiion) {
  const { token } = userData;
  if (condiion) {
    localStorage.setItem('user', JSON.stringify(userData));
    localStorage.setItem('token', token);
  }
}

async function login(email: string, password: string, timezone: string) {
  const response = await client.mutate({
    mutation: LOGIN_MUTATION,
    variables: {
      email,
      password,
      timezone
    },
  });
  const user = await handleResponse(response);
  if (user.login) {
    setDataToLocalStorage(user.login.data, true);
  }
  return user.login.data;
}

async function logout(isAll = false) {
  const response = await client.mutate({
    mutation: LOGOUT_USER_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: { isAll },
  });
  return handleResponse(response);
}

async function register(user: IOrgAdmin) {
  const response = await client.mutate({
    mutation: REGISTER_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: user,
  });
  return handleResponse(response);
}

async function professionalRegister(user: IProfessional) {
  const response = await client.mutate({
    mutation: PROFESSIONAL_REGISTER_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: user,
  });
  return handleResponse(response);
}

async function expertRegister(user: IProfile) {
  const response = await client.mutate({
    mutation: EXPERT_REGISTER_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: user,
  });
  return handleResponse(response);
}

async function checkExistingExpert(email: string) {
  const response = await client.mutate({
    mutation: CHECK_EXISTING_EXPERT,
    context: { useApolloNetworkStatus: true },
    variables: {
      email,
    },
  });
  return handleResponse(response);
}

async function forgotPassword(email: string) {
  const response = await client.mutate({
    mutation: FORGOT_PASSWORD_MUTATION,
    variables: {
      email,
    },
  });
  return handleResponse(response);
}

async function verifyCreatePasswordToken(token: string) {
  const response = await client.query({
    query: VERIFY_CREATE_PASSWORD_QUERY,
    context: { useApolloNetworkStatus: true },
    variables: {
      token,
    },
  });
  const resp = await handleResponse(response);
  return resp.verifyToken.data;
}

async function createPassword(data: ICreatePasswordReq) {
  const response = await client.mutate({
    mutation: CREATE_PASSWORD_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: {
      email: data.email,
      token: data.token,
      password: data.password,
    },
  });
  const resp = await handleResponse(response);
  if (resp.createPassword) {
    setDataToLocalStorage(resp.createPassword.data, true);
  }
  return resp.createPassword.data;
}

async function verifyForgotPasswordToken(token: string) {
  const response = await client.query({
    query: VERIFY_FORGOT_PASSWORD_QUERY,
    context: { useApolloNetworkStatus: true },
    variables: {
      token,
    },
  });
  const resp = await handleResponse(response);
  return resp.validateForgotPassword.data;
}

async function resetPassword(data: IResetPasswordReq) {
  const response = await client.mutate({
    mutation: RESET_PASSWORD_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: {
      email: data.email,
      token: data.token,
      password: data.password,
    },
  });
  const resp = await handleResponse(response);
  return resp.updatePassword.message;
}

async function getProfile() {
  const response = await client.mutate({
    mutation: GET_PROFILE_QUERY,
    context: { useApolloNetworkStatus: true },
    variables: {},
  });
  const resp = await handleResponse(response);
  return resp.getProfile.data;
}

async function getPaymentLoginLink() {
  const response = await client.mutate({
    mutation: GET_PAYMENT_LOGIN_LINK_QUERY,
    context: { useApolloNetworkStatus: true },
    variables: {},
  });
  const resp = await handleResponse(response);
  return resp.getPaymentLoginLink.data;
}

async function getUsers(reqObj: any) {
  const isUserManagment = reqObj.isUserManagment;
  const query = isUserManagment ? GET_USERS_MANAGEMENT_LIST_QUERY : GET_USERS_LIST_QUERY;
  delete reqObj.isUserManagment;
  const response = await client.query({
    query,
    variables: reqObj,
  });
  const resp = await handleResponse(response);
  if (isUserManagment) {
    return resp.userManagement;
  }
  return resp.getLearners;

}

async function createUser(user) {
  const response = await client.mutate({
    mutation: CREATE_LEARNER_MUTATION,
    variables: user,
  });
  const ret = await handleResponse(response);
  return ret.addLearner;
}

async function updateUserStatus(user) {
  const response = await client.mutate({
    mutation: UPDATE_USER_STATUS_MUTATION,
    variables: user,
  });
  const ret = await handleResponse(response);
  return ret.updateUserStatus;
}

async function updateUserRequestStatus(user) {
  const response = await client.mutate({
    mutation: UPDATE_USER_REQUEST_STATUS_MUTATION,
    variables: user,
  });
  const ret = await handleResponse(response);
  return ret.updateExpertRequestStatus;
}

async function updateChangeRateStatus(rate) {
  const response = await client.mutate({
    mutation: UPDATE_CHANGE_RATE_STATUS_MUTATION,
    variables: rate,
  });
  const ret = await handleResponse(response);
  return ret.updateChangeRateStatus;
}

async function deleteUser(user) {
  const response = await client.mutate({
    mutation: DELETE_USER_MUTATION,
    variables: user,
  });
  const ret = await handleResponse(response);
  return ret.deleteUser;
}

async function changeRequestRatePerHour(data) {
  data.ratePerHour = +data.ratePerHour;
  const response = await client.mutate({
    mutation: CHANGE_REQUEST_HOURLY_RATE_MUTATION,
    variables: data,
  });
  const ret = await handleResponse(response);
  return ret.changeHourlyRateRequest;
}

async function updateProfileImage(data: IUploadImgaeReq) {
  const response = await client.mutate({
    mutation: UPLOAD_PROFILE_IMAGE_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: {
      upload: data.upload,
      type: data.type,
    },
  });
  const resp = await handleResponse(response);
  if (resp.uploadProfile.data['path']) {
    updateKeyLocalUser('profileImage', resp.uploadProfile.data['path']);
  }
  return resp.uploadProfile.data;
}

async function getIndustries() {
  const response = await client.mutate({
    mutation: GET_INDUSTRY,
    context: { useApolloNetworkStatus: true },
  });
  const resp = await handleResponse(response);
  return resp.industry.data;
}

async function getCategories() {
  const response = await client.mutate({
    mutation: GET_CATEGORIES,
    context: { useApolloNetworkStatus: true },
  });
  const resp = await handleResponse(response);
  return resp.category.data;
}

async function updateExpertProfile(data: IUpdateProfileReq) {
  const response = await client.mutate({
    mutation: UPDATE_EXPERT_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: data,
  });
  const resp = await handleResponse(response);
  updateKeyLocalUser('firstName', data.firstName);
  updateKeyLocalUser('lastName', data.lastName);
  return resp.updateUser.data;
}

async function updateProfile(data: IUpdateProfileReq) {
  const response = await client.mutate({
    mutation: UPDATE_USER_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: data,
  });
  const resp = await handleResponse(response);
  updateKeyLocalUser('firstName', data.firstName);
  updateKeyLocalUser('lastName', data.lastName);
  return resp.updateUser.data;
}

async function updatePaymentAccountID(data: IUpdateProfileReq) {
  const response = await client.mutate({
    mutation: UPDATE_USER_PAYMENT_ACCOUNT_ID_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: data,
  });
  const resp = await handleResponse(response);
  if (resp['updatePaymentAccountId'].data.stripeAccountId) {
    updateKeyLocalUser('isStripeAccount', 'true');
  }
  return resp.updatePaymentAccountId.data;
}

async function updateLearnerProfile(data: IUpdateLearnerProfileReq) {
  const response = await client.mutate({
    mutation: UPDATE_LEARNER_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: data,
  });
  const resp = await handleResponse(response);
  updateKeyLocalUser('firstName', data.firstName);
  updateKeyLocalUser('lastName', data.lastName);
  return resp.updateLearner.data;
}

async function getShortProfile(profileId: string, isScheduleManagement = false) {
  const response = await client.mutate({
    mutation: GET_SHORT_PROFILE_QUERY,
    context: { useApolloNetworkStatus: true },
    variables: { profileId, isScheduleManagement },
  });
  const resp = await handleResponse(response);
  return resp.getProfile.data;
}

async function getSubscription() {
  const response = await client.query({
    query: GET_SUBSCRIPTION,
    context: { useApolloNetworkStatus: true },
  });
  const resp = await handleResponse(response);
  return resp.getSubscription.data;
}

async function getSubscriptionHistory(data: object) {
  const response = await client.query({
    query: GET_SUBSCRIPTION_HISTORY,
    context: { useApolloNetworkStatus: true },
    variables: data,
  });
  const resp = await handleResponse(response);
  return resp.getSubscriptionHistory.data;
}

async function cancelSubscription() {
  const response = await client.mutate({
    mutation: CANCEL_SUBSCRIPTION,
    context: { useApolloNetworkStatus: true },
  });
  const resp = await handleResponse(response);
  return resp.cancelSubscription;
}

async function assignLicence(user) {
  const response = await client.mutate({
    mutation: ASSIGN_LICENCE,
    variables: user,
  });
  const ret = await handleResponse(response);
  return ret.assignLicence;
}
async function sendFeedback(feedback: string) {
  const response = await client.mutate({
    mutation: SEND_FEEDBACK,
    context: { useApolloNetworkStatus: true },
    variables: { feedback },
  });
  const resp = await handleResponse(response);
  return resp.feedback.message;
}

async function deleteLearner(user) {
  const response = await client.mutate({
    mutation: DELETE_LEARNER,
    variables: user,
  });
  const ret = await handleResponse(response);
  return ret.deletelearner;
}

async function updateSettings(setting: any) {
  const response = await client.mutate({
    mutation: UPDATE_SETTING,
    variables: setting,
  });
  const ret = await handleResponse(response);
  return ret.updateSettings;
}

async function sendExpertFeedback(feedback: object) {
  const response = await client.mutate({
    mutation: EXPERT_FEEDBACK,
    variables: feedback,
  });
  const resp = await handleResponse(response);
  return resp.connectFeedback;
}

async function socialLogin(profile: any) {
  let response;
  if (profile.facebook) {
    response = await client.mutate({
      mutation: SOCIAL_LOGIN_MUTATION,
      variables: profile.facebook,
    });
  } else if (profile.linkedin) {
    response = await client.mutate({
      mutation: LINKEDIN_MUTATION,
      variables: profile.linkedin,
    });
  }
  const user = await handleResponse(response);
  if (user.socialLogin) {
    setDataToLocalStorage(user.socialLogin.data, (user.socialLogin.data.role !== staticConstants.ROLE.ORGANIZATION_ADMIN &&
      user.socialLogin.data.role !== staticConstants.ROLE.ORGANIZATION_LEARNER));
  }
  return user.socialLogin.data;
}

async function changePasswordRequest(changePasswordObject: IChangePasswordReq) {
  const response = await client.mutate({
    mutation: CHANGE_PASSWORD_MUTATION,
    variables: changePasswordObject,
  });
  const resp = await handleResponse(response);
  return resp.changePassword;
}

async function systemAdminCreateUser(user: ICreateUser) {
  if (user.hourlyRate) {
    user.hourlyRate = +user.hourlyRate;
  }
  const response = await client.mutate({
    mutation: ADD_USER_MUTATION,
    variables: user,
  });
  const resp = await handleResponse(response);
  return resp.addUser;
}

async function updateSystemAdminProfile(data: ISystemAdminProfile) {
  const response = await client.mutate({
    mutation: UPDATE_SYSTEM_ADMIN_PROFILE_MUTATION,
    context: { useApolloNetworkStatus: true },
    variables: data,
  });
  const resp = await handleResponse(response);
  return resp.updateUser;
}

async function raiseLicenceRequest() {
  const response = await client.mutate({
    mutation: LICENCE_MOVEMENT_REQUEST_MUTATION,
  });
  const resp = await handleResponse(response);
  return resp.licenceMovementRequest.message;
}

async function updateLicenceMovementStatus(user) {
  const response = await client.mutate({
    mutation: UPDATE_LICENCE_MOVEMENT_MUTATION,
    variables: user,
  });
  const ret = await handleResponse(response);
  return ret.updateLicenceMovement;
}

async function fetchTotalIncome(user) {
  const response = await client.mutate({
    mutation: UPDATE_LICENCE_MOVEMENT_MUTATION,
    variables: user,
  });
  const ret = await handleResponse(response);
  return ret.fetchTotalIncome;
}

async function getPaymentLogs(request) {
  const response = await client.query({
    query: GET_PAYMENT_LOGS,
    context: { useApolloNetworkStatus: true },
    variables: request,
  });
  const resp = await handleResponse(response);
  return resp.paymentLogs;
}

async function getExpertIncome() {
  const response = await client.query({
    query: GET_EXPERT_INCOME,
    context: { useApolloNetworkStatus: true },
  });
  const resp = await handleResponse(response);
  return resp.totalIncome;
}

async function getWithdrawHistory(request) {
  const response = await client.query({
    query: GET_WITHDRAW_HISTORY,
    context: { useApolloNetworkStatus: true },
    variables: request,
  });
  const resp = await handleResponse(response);
  return resp.withdrawLogs;
}

async function reVerifyUser(user) {
  const response = await client.mutate({
    mutation: RE_VERIFY_USER_MUTATION,
    variables: user,
  });
  const ret = await handleResponse(response);
  return ret.reVerification;
}

async function sendComment(obj) {
  const response = await client.mutate({
    mutation: FLAGGED_COMMENT__MUTATION,
    variables: obj,
  });
  const ret = await handleResponse(response);
  return ret.flagComment;
}

async function getProratedInvoiceAmount(data: { quantity: number; isAddOn: boolean }) {
  const response = await client.query({
    query: GET_PRORATED_AMOUNT,
    variables: data,
  });
  const ret = await handleResponse(response);
  return ret.getInvoiceAmount;
}

async function getSubscriptionProduct(data: { productId: string }) {
  const response = await client.query({
    query: GET_SUBSCRIPTION_PRODUCT,
    variables: data,
  });
  const ret = await handleResponse(response);
  return ret.getSubscriptionProduct;
}
async function getTotalCount(data: { userId: string;  attribute: string}) {
  const response = await client.query({
    query: GET_USER_GOAL_BID_COUNT,
    variables: data,
  });
  const ret = await handleResponse(response);
  return ret.getTotalCount.total;
}
async function updateRegion(data: any) {
  const response = await client.mutate({
    mutation: UPDATE_REGION,
    variables: data,
  });
  const ret = await handleResponse(response);
  return ret.updateRegion.message;
}

async function getBankInfo() {
  const response = await client.query({
    query: GET_BANK_INFO,
  });
  const ret = await handleResponse(response);
  return ret.bankInfo;
}