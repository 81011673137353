/* eslint-disable react/jsx-curly-newline */
import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { DashBoardContainer as OrgAdminDashBoardContainer } from "../app/orgadmin/dashboard";
import { GoalManagementContainer, CreateGoalContainer, GoalDetailContainer } from "../app/shared/goalManagement";
import { ManagementContainer as LearnerManagementContainer, LearnerDetailContainer } from "../app/shared/learnerManagement";
import { UserManagementContainer, UserManagementDetailContainer } from "../app/systemAdmin/userManagement";
import { ContentManagementContainer } from "../app/systemAdmin/contentManagement";
import { DashBoardContainer as LearnerDashBoardContainer } from "../app/learner/dashboard";
import { DashBoardContainer as ExpertDashBoardContainer } from "../app/expert/dashboard";
import { DashBoardContainer as ProfessionalDashBoardContainer } from "../app/professional/dashboard";
import { ManagementContainer as BidManagementContainer, BidDetailContainer } from "../app/expert/bidManagement";
import { NotFoundContainer } from "../app/404";
import { Faq } from "./../app/home/pages/faqs/index.ts";
import { PrivacyPolicy } from "./../app/home/pages/privacyPolicy/index.ts";
import { ViewProfilePage as LearnerViewProfileContainer } from "../app/learner/updateProfile";
import { UpdateProfile as LearnerUpdateProfile } from "../app/learner/updateProfile/UpdateProfile";
import { ViewProfilePage as OrgAdminViewProfileContainer, UpdateProfile } from "../app/shared/updateProfile";
import { RequestManagementContainer, RequestDetailContainer } from "../app/shared/requestManagement";
import { EventManagementContainer, EventManagementDetail } from "../app/shared/eventManagement";
import { ViewProfilePage as ExpertViewProfileContainer } from "../app/expert/updateProfile";
import { UpdateProfile as ExpertUpdateProfile } from "../app/expert/updateProfile/UpdateProfile";
import ViewProfileContainer from "../app/shared/viewProfile/viewProfileContainer";
import { ListingContainer as NotificationListingContainer } from "../app/shared/notfication/listings/listing";
import { LogsManagementContainer } from "../app/shared/logs";
import { ChatContainer, AddMessageContainer } from "../app/shared/chat";
import { ViewOrgAdminCompleteProfile } from "../app/shared/viewProfile/viewOrgAdminProfile";
import { ViewLearnerCompleteProfile } from "../app/shared/viewProfile/viewLearnerProfile";
import { ExpertPaymentContainer } from "../app/expert/payment/ExpertPaymentContainer";
import { ConnectWithOpenField } from "../app/expert/payment/connectWithOpenField";
import WithdrawAmount from "../app/expert/payment/WithdrawAmount";
import {SystemAdminViewProfile} from "../app/systemAdmin/viewProfile/systemAdminViewProfile";
import { CustomizedPlanContainer } from "../app/systemAdmin/plans/customizedPlanContainer";
import { TagManagementContainer } from "../app/systemAdmin/tags/tagManagementContainer";
import {
  ApplicationFeedbackManagementContainer,
  AdminGoalManagementContainer,
  DashBoardContainer as SystemAdminDashBoardContainer,
  RequestManagementContainer as SystemAdminRequestManagementContainer,
  EventManagementContainer as SystemAdminEventManagementContainer,
  SystemAdminPaymentContainer,
}
  from "../app/systemAdmin";
import canAccessRoute  from "./CanAccessRoute";
import LoadingOverlay from 'react-loading-overlay';

import {
  PaymentContainer,
  CardContainer,
  SubscriptionPlan,
} from "../app/shared";
import rolesConfig from "../config/rolesConfig";
import { getLocalUserData, getMyUserId } from "../utils/common";
import { firestoreQueryConstants } from "../utils/firestoreQueryConstants";
import { pageRoutes, staticConstants } from "../utils";
import { history } from "../helpers";
import { myFirestore } from "../config/firebase";
import { chatAction } from "../app/shared/chat/redux/actions";
const PrivateTheme = React.lazy(() => import('./PrivateTheme'));

const Routes = {
  OrgAdminDashBoardContainer,
  LearnerDashBoardContainer,
  ExpertDashBoardContainer,
  ProfessionalDashBoardContainer,
  SystemAdminDashBoardContainer,
  PaymentContainer,
  SubscriptionPlan,
  GoalManagementContainer,
  CreateGoalContainer,
  GoalDetailContainer,
  LearnerManagementContainer,
  UpdateProfile,
  NotFoundContainer,
  LearnerViewProfileContainer,
  LearnerUpdateProfile,
  OrgAdminViewProfileContainer,
  BidManagementContainer,
  BidDetailContainer,
  RequestManagementContainer,
  EventManagementContainer,
  ExpertViewProfileContainer,
  ExpertUpdateProfile,
  RequestDetailContainer,
  EventManagementDetail,
  ViewProfileContainer,
  NotificationListingContainer,
  CardContainer,
  ChatContainer,
  AddMessageContainer,
  ViewOrgAdminCompleteProfile,
  ViewLearnerCompleteProfile,
  LearnerDetailContainer,
  ExpertPaymentContainer,
  ConnectWithOpenField,
  LogsManagementContainer,
  UserManagementContainer,
  AdminGoalManagementContainer,
  ApplicationFeedbackManagementContainer,
  UserManagementDetailContainer,
  SystemAdminRequestManagementContainer,
  SystemAdminEventManagementContainer,
  ContentManagementContainer,
  SystemAdminViewProfile,
  SystemAdminPaymentContainer,
  WithdrawAmount,
  CustomizedPlanContainer,
  TagManagementContainer,
  PrivacyPolicy,
  Faq
};

class PrivateRoutes extends Component {
  componentDidMount() {
    this.onNewChatMessage();
    this.userId = this.props.userId;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loggedIn !== this.props.loggedIn && this.props.loggedIn) {
      this.onNewChatMessage();
    }
  }

  componentWillUnmount() {
    this.userId && myFirestore
      .collection(firestoreQueryConstants.MESSAGE_BOARD)
      .where("members", "array-contains", this.userId)
      .onSnapshot(() => {
        console.info("******** unsubscribed from message listeners *********");
      });
  }

  onNewChatMessage = () => {
    const { dispatch, loggedIn, role } = this.props;
    const target = role === staticConstants.ROLE.SYSTEM_ADMIN && staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS;
    if (loggedIn && getMyUserId()) {
      myFirestore
        .collection(firestoreQueryConstants.MESSAGE_BOARD)
        .where("members", "array-contains", getMyUserId())
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const isMessageBoard = String(history.location.pathname).includes(pageRoutes.MESSAGE_BOARD.PATH);
            const updatedUser = change.doc.data().lastMessage.to;
            (isMessageBoard) && dispatch(chatAction.getAllConnections(false, target));
            this.handleNewMessageForCurrentUser(updatedUser, isMessageBoard);
          });
        });
    }
  }

  // On new message, get all messages and total unread counts
  handleNewMessageForCurrentUser = (updatedUser, isMessageBoard) => {
    const { dispatch, currentSelectedUser = {} } = this.props;
    if (updatedUser === getMyUserId()) {
      if (isMessageBoard && currentSelectedUser.connectionId) {
        dispatch(chatAction.getAllMessage(currentSelectedUser.connectionId));
      }
      dispatch(chatAction.getTotalUnreadCount());
    }
  }

  render() {
    const userData = getLocalUserData();
    let roles = [];
    if (userData && userData.role) {
      roles = [
        ...[userData.role],
        "common",
      ];
    }
    const allowedRoutes = roles.reduce((acc, role) => [
      ...acc,
      ...rolesConfig[role].routes,
    ], []);
    return (
      <div>
        <React.Suspense fallback={<LoadingOverlay
        active={true}
        spinner
        text=""
        className="white-loader"
      ></LoadingOverlay>}>
          <PrivateTheme />
        </React.Suspense>
        <Switch>
          {
            allowedRoutes.length > 0 ? allowedRoutes.map(({ component, url }, index) => (
              <Route
                exact
                key={index}
                path={`${url}`}
                component={Routes[component]}
              />
            )) : <Redirect to="/login" />
          }
          <Route component={NotFoundContainer} />
        </Switch>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { authentication: { loggedIn, user }, chatMessages: { currentSelectedUser } } = state;
  return {
    loggedIn,
    currentSelectedUser,
    role: user ? user.role : "",
    userId: user ? user._id : null,
  };
}

const connectedPrivateRoutes = canAccessRoute(connect(mapStateToProps)(PrivateRoutes));
export { connectedPrivateRoutes as PrivateRoutes };
