import React from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../sidebar';
import {
  Row,
  Col,
} from 'reactstrap';

import { history } from '../../../helpers';
import userImage from './../../../assets/images/profile.png';
import facebook from './../../../assets/images/icons/fb-link.svg';
import twitter from './../../../assets/images/icons/twitter-link.svg';
import linkedin from './../../../assets/images/icons/linkedin-link.svg';
import { connect } from 'react-redux';
import { IViewProfile, IParams, IWorkExperiance } from './../../../interfaces';
import { viewProfileAction } from './redux/action';
import { getModeOfDeliveryText, renderDaysOfAvailability, languageProficiencies, pageRoutes, MAGIC_NUMBER } from './../../../utils/';
import { map } from 'lodash';
import { Back } from '../back/back';
import StarRatings from 'react-star-ratings';
import Linkify from 'react-linkify';

interface IProps {
  dispatch: OpenFieldTypes.RootAction;
  profile: IViewProfile;
  location: any;
  match: {
    params: {
      [key: string]: IParams;
    };
  };
}

class ViewCompleteProfile extends React.Component<IProps>  {

  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getrfpDetail to fetch goal detail
   */
  public componentDidMount() {
    const { match: { params } } = this.props;
    const { isScheduleManagement } = this.props['location'].state;
    if (params.id) {
      this.props.dispatch(viewProfileAction.getShortProfile(String(params.id), isScheduleManagement));
    }
  }
  // TODO: could be a functional component
  public renderAddedProfeciencyCheck(selectedProfeciencies: any) {
    return map(languageProficiencies, (item: { value: string }, index: number) => {
      if (item.value !== 'all') {
        let checkClass = 'icon-cross';
        if (selectedProfeciencies.includes(item.value)) {
          checkClass = 'icon-accept';
        }
        return (
          <span key={`addedProfeciencyCheck-${index}`}><em className={`icon ${checkClass}`} /></span>
        );
      }
    });
  }
  public renderAddedLanguages() {
    const { profile } = this.props;
    if (profile && profile.languages) {
      return map(profile.languages, (item: any, index: any) => {
        return (
          <>
            <div className="language-data" key={`addedLanguage-${index}`}>
              <span>{item.language}</span>
              {this.renderAddedProfeciencyCheck(item.profeciencies)}
            </div>
          </>
        );
      });
    }
    return '';
  }

  public requestManagementPage() {
    history.push(`${pageRoutes.REQUESTMANAGEMENT.PATH}`);
  }

  public render() {
    const { profile } = this.props;

    let averageProfileRating = 0;
    let expertSessionRating = 0;
    if (profile && profile.averageRating && profile.averageSessionRating) {
      profile.averageRating = profile.averageRating || 0;
      profile.averageSessionRating = profile.averageSessionRating || 0;
      averageProfileRating = Number.isInteger(profile.averageRating)
        ? profile.averageRating
        : Number(profile.averageRating.toFixed(MAGIC_NUMBER.ONE));
      expertSessionRating = Number.isInteger(profile.averageSessionRating)
        ? profile.averageSessionRating
        : Number(profile.averageSessionRating.toFixed(MAGIC_NUMBER.ONE));
    }
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <Back {...this.props} />
          {profile && <div className="dashboard-content shadow">
            <div className="profile-bar">
              <div className="user-profile-details">
                <Row>
                  <Col xs="12" md="4" className="d-flex">
                    <span className="profile-picture-view-only">
                      <img src={profile.profileImage || userImage} alt="Profile" className="img-cover" />
                    </span>
                    <div className="profile-email-view-only text-truncate ">
                      <h3 className="text-truncate">{profile.firstName} {profile.lastName} </h3>
                      <p className="sub-heading text-truncate">{profile.email} </p>
                      <h4>{profile.position} {profile.organizationName && `-`} {profile.organizationName}</h4>
                    </div>
                  </Col>
                  <Col xs="12" md="4" className="rating-count-2">
                    <div className="rating-view-only">
                      <p>Profile Ratings ({averageProfileRating}/5)</p>
                      <StarRatings rating={averageProfileRating} starHoverColor="#4be59e"
                        starEmptyColor="#dcdcdc" starRatedColor="#4be59e"
                        starDimension="20px" starSpacing="2px" numberOfStars={MAGIC_NUMBER.FIVE}
                        name="averageRating"
                      />
                    </div>
                    <div className="rating-view-only">
                      <p>Session Ratings ({expertSessionRating}/5)</p>
                      <StarRatings rating={expertSessionRating} starHoverColor="#4be59e"
                        starEmptyColor="#dcdcdc" starRatedColor="#4be59e"
                        starDimension="20px" starSpacing="2px"
                        numberOfStars={MAGIC_NUMBER.FIVE} name="averageRating"
                      />
                    </div>
                  </Col>
                  <Col xs="12" md="4">
                    <div className="social-links">
                      <ul>
                        {profile.facebook &&
                          <li><a href={profile.facebook} target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" /></a></li>
                        }
                        {profile.twitter &&
                          <li><a href={profile.twitter} target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter" /></a></li>
                        }
                        {profile.linkedIn &&
                          <li><a href={profile.linkedIn} target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="linkedin" /></a></li>
                        }
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="profile-details-wrapper">
              <div className="form-details">
                <h2 className="heading heading-rg roboto-medium">Personal Information</h2>
                <div className="form-data">
                  <Row>
                    {
                      (profile && profile.education) &&
                      <Col xs="12" md="3">
                        <span>Highest Level of Education</span>
                        <p> {profile.education}</p>
                      </Col>
                    }
                    {
                      (profile && profile.occupation) &&
                      <Col xs="12" md="3">
                        <span>Occupation</span>
                        <p>{profile.occupation}</p>
                      </Col>
                    }
                    {
                      (profile && profile.phoneNumber) &&
                      <Col xs="12" md="3">
                        <span>Phone Number</span>
                        <p>{profile.phoneNumber}</p>
                      </Col>
                    }
                    {
                      (profile && profile.address) &&
                      <Col xs="12" md="3">
                        <span>City or Town</span>
                        <p>{profile.address}</p>
                      </Col>
                    }
                    {
                      (profile && profile.modeOfDelivery) &&
                      <Col xs="12" md="3">
                        <span>Mode of Delivery</span>
                        <p>{getModeOfDeliveryText(profile.modeOfDelivery)}</p>
                      </Col>
                    }
                    {
                      (profile && profile.availableDays) &&
                      <Col xs="12" md="3">
                        <span>Days of Availability</span>
                        <p>{renderDaysOfAvailability(profile.availableDays)}</p>
                      </Col>
                    }
                    {
                      (profile && profile.location) &&
                      <Col xs="12" md="3"><span>Country</span><p>{profile.location}</p>
                      </Col>
                    }
                    {
                      (profile && profile.languages && profile.languages.length > MAGIC_NUMBER.ZERO) &&
                      <Col xs="12">
                        <span>Language & Proficiency</span>
                        <Row>
                          <Col xs="12">
                            <div className="selected-language language-view-mode mt-2">
                              <div className="language-head"><span>Language</span>
                                <span>Read</span><span>Write</span><span>Speak</span>
                              </div>
                              {this.renderAddedLanguages()}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    }
                    {
                      (profile && profile.publishLink) &&
                      <Col xs="12">
                        <span>Links to published works</span>
                        <p className="link-text" ><Linkify properties={{target: '_blank'}} >{profile.publishLink}</Linkify></p>
                      </Col>
                    }
                    {
                      (profile && profile.bio) &&
                      <Col xs="12"><span>Bio</span><p>{profile.bio}</p></Col>
                    }
                    {
                      (profile && profile.tags) &&
                      <Col xs="12"><span>Tags</span>
                        <div className="w-100">
                          {profile.tags.map((tag) => {
                            return <em key={tag['value']} className="tags-view-only">{tag['name']}</em>;
                          })}
                        </div>
                      </Col>
                    }
                  </Row>
                </div>
              </div>
            </div>
            {(profile && profile.experiences) &&
              <div className="profile-details-wrapper">
                <div className="form-details">
                  <h2 className="heading heading-rg roboto-medium">Work Experience</h2>
                  <div className="form-data work-experience">
                    {profile.experiences.map((exp: IWorkExperiance, index: number) => {
                      return (
                        <Row key={index}>
                          <Col xs="12" key={Math.random()}>
                            <h3 className="heading heading-md roboto-bold">{exp.position}</h3>
                            <h4>{exp.employer}</h4>
                            <h4>{exp.lengthOfServices}</h4>
                            <h4 className="mb-0">{exp.location}</h4>
                          </Col>
                          <Col xs="12">
                            <span>Work Experience Summary</span>
                            <p>{exp.summary}</p>
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              </div>
            }
          </div>}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { viewProfile } = state;
  return {
    profile: viewProfile['profile'],
  };
}

const ViewCompleteProfileConnected = connect(mapStateToProps)(ViewCompleteProfile);
export { ViewCompleteProfileConnected as ViewCompleteProfile };
