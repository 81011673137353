export * from './messageBoardDefaultClass';

export interface IAllInboxUsers extends Array<IInboxUser> { }

export interface IAllMessages extends Array<IChatMessage> { }

export interface IAllGroups extends Array<IGroup> { }

export interface IInboxUser {
  _id: string;
  connectionId: string;
  email: string;
  firstName: string;
  isSystemAdmin: boolean;
  lastName: string;
  organizationId: string | null;
  profileImage: string;
  role: string;
  roomId: string;
  target: string;
  targetName: string;
  userId: string;
  targetProfile?: string;
  lastMessage?: ILastMessage;
  unreadCount?: number;
  members: Array<[string]>;
}

export interface IChatMessage {
  content: string;
  from: string;
  isBroadcast?: boolean;
  isRead: boolean;
  name: string;
  roomId: string;
  target?: string;
  targetName?: string;
  timeStamp: number;
  to: string;
  receiptType: string;
  date: string;
  time: string;
}

export interface ILastMessage {
  content: string;
  from: string;
  isBroadcast?: boolean;
  isRead: boolean;
  name: string;
  roomId: string;
  target?: string;
  targetName?: string;
  timeStamp: number;
  to: string;
  receiptType: string;
  date: string;
  time: string;
  timeAgo: string;
}

export interface IGroup {
  _id: string;
  groupName: string;
  users: Array<[string]>;
}
