import gql from 'graphql-tag';

const GET_SHORT_PROFILE_QUERY = gql`
query getProfile($profileId: String!,$isScheduleManagement: Boolean) {
    getProfile (profileId: $profileId, isScheduleManagement: $isScheduleManagement) {
        message,
        data {
            inStripeRegion,
            subscriptionId,
            averageRating,
            averageSessionRating,
            isAddOn
            licence,
            isLicenced,
            amount,
            isPaid,
            current_period_end,
            role,
            email,
            organizationName,
            isActive,
            organizationId{
                organizationName
            },
            firstName,
            lastName,
            education,
            dateOfBirth,
            phoneNumber,
            sector,
            sectorId,
            industry,
            industryId,
            occupation,
            position,
            location,
            address,
            tags{name,
                  id},
            profileImage
            title,
            employer
            experiences{
                employer,
                position,
                lengthOfServices,
                location,
                summary,
            },
            references {
              name,
              email,
              phone,
              relation,
            },
            referred {
              isReferred,
              referredBy,
            },
            availableDays
            languages{
                language,
                profeciencies
            }
            systemAdmin {
              isRequest
            }
            bio
            linkedinProfile
            publishLink,
            modeOfDelivery,
            isAddOn,
            isLicenced,
            facebook,
            twitter,
            linkedIn,
            totalLearner,
            flag {
                comment,
                userId {
                    firstName,
                    lastName,
                    email,
                    profileImage,
                }
            },
            licenceMovement {
                count
            },
            hourlyRate {
                current,
                requested,
                comment
            },
            totalLicenceAssign,
        }
    }
}
`;

const GET_SUBSCRIPTION = gql`
query getSubscription {
    getSubscription{
        message,
        data{
            plan,
            amount,
            isAddOn,
            updatedAt,
            date,
            quantity,
            isAddOn,
            updatedAt,
            createdAt,
            subscriptionId,
            current_period_end,
            current_period_start,
            customer,
        }
    }
}`;

const GET_SUBSCRIPTION_HISTORY = gql`
query getSubscriptionHistory($userId: String, $sort: String, $sortBy: Int) {
    getSubscriptionHistory(userId: $userId, sort: $sort, sortBy: $sortBy){
        message,
        data{
            plan,
            amount,
            isAddOn,
            updatedAt,
            date,
            quantity,
            isAddOn,
            updatedAt,
            createdAt
    }
  }
}`;

const GET_USERS_MANAGEMENT_LIST_QUERY = gql`
  query userManagement($limit: Int, $page: Int, $searchKey: String,
     $sort: String, $sortBy: Int, $type: String, $userId: String, $status: String, $flagType: String,
     $columnHeaders:[String], $columnKeys: [String], $isGenerateReport: Boolean, $reportTitle: String,
     $filter: String
    ) {
    userManagement(limit: $limit, page: $page, searchKey: $searchKey,
      sort:$sort, sortBy: $sortBy, type: $type, userId: $userId, status: $status, flagType: $flagType,
      columnHeaders: $columnHeaders, columnKeys: $columnKeys,
      isGenerateReport: $isGenerateReport, reportTitle: $reportTitle, filter: $filter
      ) {
      message,
      count,
      url,
      reportTitle,
      data {
        email,
        customPlan {
          productName,
        },
        firstName,
        lastName,
        isActive,
        _id,
        totalLearner,
        licence,
        address,
        totalFlag,
        role,
        referred {
          isReferred,
          referredBy,
        },
        organizationName,
        organizationId,
        profileImage,
        licenceMovement {
            count,
            requestedCount,
            date,
            isRequested
        },
        hourlyRate {
            current,
            requested,
            comment
        },
        systemAdmin {
            isRequest
        },
        isVerified,
        flagComment,
      }
    }
}`;

const GET_PAYMENT_LOGS = gql`
  query paymentLogs($filter: String!, $page: Int
    $limit: Int, $title: String, $eventType: String, $category: String,
    $sort: String, $sortBy: Int) {
    paymentLogs(filter: $filter, page: $page, limit: $limit, title: $title
      eventType: $eventType, category: $category, sort: $sort, sortBy: $sortBy) {
      count,
      data {
        type,
        sessionFee,
        expertFee,
        goalData {
          _id,
          title,
          modeOfDevivery,
          eventType,
          domain{
            name,
            id
          },
          subDomain{
            name,
            id
          },
          category{
            name,
            id
          },
          subCategory{
            name,
            id
          },
          activity{
            name,
            id
          },
          topic{
            name,
            id
          },
          duration,
          fromDate,
          toDate,
          toTime,
          fromTime,
          description,
          participants{
            firstName
            lastName
            profileImage
            _id
            organizationId
            email,
            role,
          },
          participantsRaw {
            userId,
            isAdmin,
            comment
            isFlagged
          },
          isMe,
          createdAt,
          statusTimeline{
            status,
            createdAt,
          },
          status
          orgId,
          bidCount,
           bid {
            statusTimeline{
              status,
              createdAt,
            },
            adminRatingGoal{
              sessionRating,
              expertRating,
              comment,
              createdAt,
            },
            expertRatingGoal{
              overAllQuality,
              participantsEngagement,
              comment,
              createdAt,
            },
            isEnded,
            adminAttendence {
              isExpertAttended,
              isExpertDeliver,
              comment,
            },
            expertAttendence {
              isParticipantsAttended,
              isAbleToDeliver,
              comment,
            },
            payment {
              type,
              isPaymentSuccessful,
              bidId,
              extendEventId,
            },
            extendMeeting {
              _id,
              status,
              duration,
              requestedId,
              role,
              isPaymentSuccessful
            },
            proposedDate,
            proposedTime,
            bidDetails,
            _id,
            status,
            isShortlisted,
            userId {
              firstName,
              lastName,
              profileImage,
              _id
              bio,
              hourlyRate{
                current
              }
              tags {
                _id,
                type
                name
              },
            },
            zoomEvent{
              meetingId,
              startUrl,
              joinUrl,
              isEnded,
            },
            flag{
              userId,
              _id,
              isFlagged,
              comment,
            },
          },
          userId {
            firstName,
            lastName,
            organizationName,
          },
          acceptedBidder {
            firstName,
            lastName
          },
          goalAdmin {
            firstName
            lastName,
            organizationName,
          },
          rejectedReason,
        }
      }
    }
}`;

const GET_EXPERT_INCOME = gql`
  query totalIncome {
    totalIncome {
      message,
      data{
        totalPending,
        totalReceieved
      }
    }
}`;

const GET_WITHDRAW_HISTORY = gql`
  query withdrawLogs {
    withdrawLogs {
      message,
      count,
      data{
        date,
        amount
      }
    }
}`;

const GET_PRORATED_AMOUNT = gql`
  query getInvoiceAmount($quantity: Int!, $isAddOn: Boolean) {
    getInvoiceAmount(quantity: $quantity, isAddOn: $isAddOn) {
      message,
      data{
        canAvailTrialPeriod,
        invoiceTaxRate,
        invoiceTaxAmount,
        invoiceSubtotal,
        invoiceTotal,
        subscriptionProrationDate,
        amount,
        amountDue,
        currentPeriodInvoiceLogs{
          amount,
          description,
          unitPrice,
          period{
            start,
            end
          },
          quantity
        },
        upcomingPeriodInvoiceLogs{
          amount,
          description,
          unitPrice,
          period{
            start,
            end
          },
          quantity
        },
      }
    }
}`;

const GET_SUBSCRIPTION_PRODUCT = gql`
  query getSubscriptionProduct($productId: String) {
    getSubscriptionProduct(productId: $productId) {
      data{
        addOnAmount,
        licenceAmount,
        learnerAmount,
      }
    }
}`;

const GET_USER_GOAL_BID_COUNT = gql`
  query getTotalCount($userId: ID!, $attribute: AttributeType!) {
    getTotalCount(userId: $userId, attribute: $attribute) {
      total
    }
}`;

const GET_BANK_INFO = gql`
query bankInfo {
  bankInfo {
    bankAccount {
    bankName
    accountNumber
    accountHolderName
    routingNumber
    branchCode
    swiftNumber
    other
    }
    accountHolderAddress {
    street1
    street2
    city{
      label
      value
    }
    state{
      label
      value
    }
    zipcode
    country{
      label
      value
    }
    }
    region
  }
}`;

export {
  GET_SHORT_PROFILE_QUERY,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_HISTORY,
  GET_USERS_MANAGEMENT_LIST_QUERY,
  GET_PAYMENT_LOGS,
  GET_EXPERT_INCOME,
  GET_WITHDRAW_HISTORY,
  GET_PRORATED_AMOUNT,
  GET_SUBSCRIPTION_PRODUCT,
  GET_USER_GOAL_BID_COUNT,
  GET_BANK_INFO,
};
