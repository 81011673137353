import { history } from '../../../../helpers';
import { pageRoutes } from '../../../../utils/routeConstants';
import { goalServices } from './../../../../services';
import { profileAction } from '../../updateProfile/redux/actions';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import { handleError } from '../../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';

export const goalAction = {
  getGoals,
  createGoal,
  getGoalDetails,
  resetGoalStatus,
  updateGoal,
  deleteGoal,
  updateGoalStatus,
  updateGoalParticipants,
};

function getGoals(goal: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(goal));
    goalServices.getGoals(goal)
      .then(
        (goals) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(goals));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request(data: string) { return { type: actionTypes.GOAL_LIST_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.GOAL_LIST_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.GOAL_LIST_FAILURE, error }; }
}

function createGoal(goal: any, queryObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(goal));
    goalServices.createGoal(goal)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          queryObj && dispatch(profileAction.getCountForFeedBack(queryObj));
          dispatch(success(data));
          history.push(`${pageRoutes.GOALMANAGEMENT.PATH}`);
          dispatch(alertActions.successAlert(data.message));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request(goalData: string) { return { type: actionTypes.CREATE_GOAL_REQUEST, goalData }; }
  function success(data: string) { return { type: actionTypes.CREATE_GOAL_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.CREATE_GOAL_FAILURE, error }; }
}

function getGoalDetails(goal: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.getGoalDetails(goal)
      .then(
        (goals) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(goals || {}));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };
  function success(data: any) { return { type: actionTypes.GOAL_DETAILS_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.GOAL_DETAILS_FAILURE, error }; }
}

function resetGoalStatus() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.GOAL_RESET_STATUS });
  };
}

function updateGoal(goal: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.updateGoal(goal)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
          history.push(`${pageRoutes.GOALMANAGEMENT.PATH}`);
          dispatch(alertActions.successAlert(data.message));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function success(data: string) { return { type: actionTypes.UPDATE_GOAL_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_GOAL_FAILURE, error }; }
}

function deleteGoal(goal: any, reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.deleteGoal(goal)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
          if (reqObj.redirect) {
            history.push(`${pageRoutes.GOALMANAGEMENT.PATH}`);
          } else {
            dispatch(getGoals(reqObj));
          }
          dispatch(alertActions.successAlert(data.message));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };
  function success(data: string) { return { type: actionTypes.DELETE_GOAL_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.DELETE_GOAL_FAILURE, error }; }
}

function updateGoalStatus(goal: any, cb?: () => void) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    const isFromDetailPage = goal.isFromDetailPage;
    delete goal.isFromDetailPage;
    goalServices.updateGoalStatus(goal)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          if (isFromDetailPage) {
            dispatch(successDetail(data));
          } else {
            dispatch(success(data));
            cb && cb();
          }

          dispatch(alertActions.successAlert(data.message));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };
  function successDetail(data: string) { return { type: actionTypes.UPDATE_GOAL_STATUS_DETAIL_SUCCESS, data }; }
  function success(data: string) { return { type: actionTypes.UPDATE_GOAL_STATUS_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_GOAL_STATUS_FAILURE, error }; }
}

function updateGoalParticipants(goal: { goalId: string; participants: Array<{ isAdmin: boolean; userId: string }>; isMe: boolean }) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.updateGoalParticipants(goal)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(goal));
          dispatch(alertActions.successAlert(data.message));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };
  function success(data: {
    goalId: string; participants:
      Array<{ isAdmin: boolean; userId: string }>; isMe: boolean
  }) {
    return {
      type: actionTypes.UPDATE_GOAL_PARTICIPANTS_SUCCESS,
      data
    };
  }
  function failure(error: string) {
    return { type: actionTypes.UPDATE_GOAL_PARTICIPANTS_FAILURE, error };
  }
}
