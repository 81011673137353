import React, { Dispatch, Component } from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { groupBy } from 'lodash';
import { Sidebar } from '../../shared/sidebar';
import { connect } from 'react-redux';
import Select from 'react-select';
import { customSelectStyles } from '../../../utils/common';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import profile from './../../../assets/images/profile.png';
import { dashboardActions } from './redux/actions';
import { AreaChart } from '../../shared/charts/areaChart/areaChart';
import { BarChart } from '../../shared/charts/barChart/barChart';
import { pageRoutes, staticConstants, formatCurrency, MAGIC_NUMBER } from '../../../utils';
import moment from 'moment';
import { chatAction } from '../../shared/chat/redux/actions';
import { history } from '../../../helpers';

interface IProps {
  dashboardData: Object;
  upcomingEvents: Array<[]>;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

interface IState {
  categoryOptionsTypes: object[];
  currentDate: Date;
  year: number;
  month: number;
  date: number;
  learningGoalsChartData: object;
  totalInvestmentChartData: object;
  totalData: object;
  latestUnreadMessages: Array<[]>;
  learningEvents: object;
  goalDateSelected: string;
  investmentDateSelected: string;
  eventsDateSelected: string;
}

class DashBoardPage extends Component<IProps, IState> {

  public static getDerivedStateFromProps(props: IProps, state: IState) {
    const newState = {};
    if (props.dashboardData) {
      if (props.dashboardData['learningGoals']) {
        newState['learningGoalsChartData'] = props.dashboardData['learningGoals'];
      }
      if (props.dashboardData['totalData']) {
        newState['totalData'] = props.dashboardData['totalData'];
      }
      if (props.dashboardData['totalInvestment']) {
        newState['totalInvestmentChartData'] = props.dashboardData['totalInvestment'];
      }
      if (props.dashboardData['learningEvents']) {
        newState['learningEvents'] = props.dashboardData['learningEvents'];
      }
    }
    return newState;
  }
  constructor(props: IProps) {
    super(props);
    this.state = {
      categoryOptionsTypes: [
        { value: 'thisMonth', label: 'Current Month' },
        { value: 'lastMonth', label: 'Last Month' },
        { value: 'last6Months', label: 'Last 6 Months' },
        { value: 'lastYear', label: 'Last Year' },
      ],
      currentDate: new Date(),
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      date: new Date().getDate() + 1,
      learningGoalsChartData: {},
      totalInvestmentChartData: {},
      totalData: {},
      learningEvents: {},
      latestUnreadMessages: [],
      goalDateSelected: 'thisMonth',
      investmentDateSelected: 'thisMonth',
      eventsDateSelected: 'thisMonth',
    };
  }

  public componentDidMount = () => {
    this.getLatestUnreadMessages();

    const { dispatch } = this.props;
    const fromDate = new Date(moment(new Date()).subtract(0, 'months').startOf('month').format(staticConstants.DASHBOARD_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(0, 'months').endOf('month').format(staticConstants.DASHBOARD_FORMAT,
      ));
    const obj = [{
      label: 'totalData',
      startDate: fromDate.toLocaleDateString('en-US'),
      endDate: toDate.toLocaleDateString('en-US'),
    },
      {
        label: 'learningGoals',
        startDate: fromDate.toLocaleDateString('en-US'),
        endDate: toDate.toLocaleDateString('en-US'),
      },
      {
        label: 'learningEvents',
        startDate: fromDate.toLocaleDateString('en-US'),
        endDate: toDate.toLocaleDateString('en-US'),
      },
      {
        label: 'totalInvestment',
        startDate: fromDate.toLocaleDateString('en-US'),
        endDate: toDate.toLocaleDateString('en-US'),
      }];
    this.getData(obj, dispatch);

    dispatch(dashboardActions.getUpcomingEvents());
  }

  /**
   * @description Get recent 3 unread chat with message count
   */
  public getLatestUnreadMessages = () => {
    this.props.dispatch(chatAction.getTotalUnreadCount((response: Array<[]>) => {
      const sortedresponse = [...response].sort((a, b) => b['timeStamp'] - a['timeStamp']);
      const data = [];
      Object.values(groupBy(sortedresponse, 'roomId')).forEach(x => data.push(x));
      data.length = MAGIC_NUMBER.THREE;
      this.setState({ latestUnreadMessages: data });
    }));
  }

  public thisMonthDate = (dispatch, key) => {
    const fromDate = new Date(moment(new Date()).subtract(0, 'months').startOf('month').format(staticConstants.DASHBOARD_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(0, 'months').endOf('month').format(staticConstants.DASHBOARD_FORMAT,
      ));
    this.dataObjToChange(key, fromDate, toDate, dispatch);
  }

  public lastYearDate = (dispatch, key) => {
    const fromDate = new Date(moment(new Date()).subtract(1, 'years').startOf('year').format(staticConstants.DASHBOARD_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(1, 'years').endOf('year').format(staticConstants.DASHBOARD_FORMAT,
      ));
    this.dataObjToChange(key, fromDate, toDate, dispatch);
  }

  public lastMonthDate = (dispatch, key) => {
    const fromDate = new Date(moment(new Date()).subtract(1, 'months').startOf('month').format(staticConstants.DASHBOARD_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(1, 'months').endOf('month').format(staticConstants.DASHBOARD_FORMAT,
      ));
    this.dataObjToChange(key, fromDate, toDate, dispatch);
  }

  public lastSixMonthsDate = (dispatch, key) => {
    const fromDate = new Date(moment(new Date()).subtract(MAGIC_NUMBER.SIX, 'months').startOf('month').format(staticConstants.DASHBOARD_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(1, 'months').endOf('month').format(staticConstants.DASHBOARD_FORMAT,
      ));
    this.dataObjToChange(key, fromDate, toDate, dispatch);
  }

  public dataObjToChange = (key, dateStart, currentDate, dispatch) => {
    const dataToChange = [{
      label: key,
      startDate: dateStart.toLocaleDateString('en-US'),
      endDate: currentDate.toLocaleDateString('en-US'),
    }];
    this.getData(dataToChange, dispatch, key);
  }

  public getData = (obj, dispatch, key?: string) => {
    const reqObj = [];
    obj.forEach((el) => {
      reqObj.push({
        type: el.label,
        startDate: el.startDate,
        endDate: el.endDate,
      });
      dispatch(dashboardActions.getDashboardData({
        input: reqObj.filter(label => label.type === el.label),
      },                                         key));
      return reqObj;
    });
  }

  public handleChange = (e: any, key: string) => {
    const { dispatch } = this.props;
    if (e.value === 'last6Months') {
      this.lastSixMonthsDate(dispatch, key);
    } else if (e.value === 'lastMonth') {
      this.lastMonthDate(dispatch, key);
    } else if (e.value === 'lastYear') {
      this.lastYearDate(dispatch, key);
    } else {
      this.thisMonthDate(dispatch, key);
    }
    if (key === 'learningGoals') {
      this.setState({ goalDateSelected: e.value });
    } else if (key === 'learningEvents') {
      this.setState({ eventsDateSelected: e.value });
    } else if (key === 'totalInvestment') {
      this.setState({ investmentDateSelected: e.value});
    } 
  }

  public goToGoalMgmt = () => {
    history.push(pageRoutes.GOALMANAGEMENT.PATH);
  }

  public goToEventMgmt = () => {
    history.push(pageRoutes.EVENTMANAGEMENT.PATH);
  }

  public render() {
    const { latestUnreadMessages,
      categoryOptionsTypes,
      learningGoalsChartData,
      totalInvestmentChartData,
      totalData,
      learningEvents,
      goalDateSelected,
      investmentDateSelected,
      eventsDateSelected } = this.state;
    const { upcomingEvents } = this.props;
    let result = '';
    if (learningEvents['totalDuration'] !== undefined) {
      const duration = learningEvents['totalDuration'];
      const hours = Math.trunc(duration);
      const mins = Math.trunc((duration - Math.floor(duration)) * MAGIC_NUMBER.SIXTY);
      result = `${hours} hrs ${mins} mins`;
    }
    const totalInvestments = totalInvestmentChartData['totalInvestmentCount'] ? formatCurrency(totalInvestmentChartData['totalInvestmentCount']) : 0.00;
    return (
      <>
        <Sidebar  {...this.props} />
        <div className="dashboard-wrapper">
          <div className="dashboard-header">
            <h2 className="heading heading-lg roboto-medium text-uppercase mb-0">DashBoard</h2>
            <Select
              placeholder="Current Month"
              styles={customSelectStyles}
              onChange={(e) => this.handleChange(e, 'totalData')}
              options={categoryOptionsTypes}
              className="react-select-box"
            />
          </div>
          <div className="dashboard-cards mt-4">
            <div className="card card-col-2 pointer" onClick={this.goToGoalMgmt}>
              <div className="card-data">
                <h2>{totalData && totalData['totalLearningGoal']}</h2>
                <p>Goals</p>
              </div>
              <span className="icon icon-goal"/>
            </div>
            <div className="card card-col-2 pointer">
              <div className="card-data" onClick={this.goToEventMgmt}>
                <h2>{totalData && totalData['totalEventGoal']}</h2>
                <p>Total Events</p>
              </div>
              <span className="icon icon-event"/>
            </div>
          </div>
          <div className="graph-wrapper">
            <div className="dashboard-graph-header">
              <h2 className="heading heading-sm roboto-bold text-uppercase">Goals</h2>
              <Link to={`${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.CREATE_GOAL}`}>
                Add Goal
              </Link>
            </div>
            <div>
              <Select
                placeholder="Current Month"
                styles={customSelectStyles}
                onChange={(e) => this.handleChange(e, 'learningGoals')}
                options={categoryOptionsTypes}
                className="react-select-box"
              />
              <AreaChart chartData={learningGoalsChartData} type="learningGoals" dateSelected={goalDateSelected}/>
            </div>
          </div>
          <div className="graph-wrapper">
            <div className="dashboard-graph-header">
              <h2 className="heading heading-sm roboto-bold text-uppercase">Events</h2>
              <Link to={pageRoutes.EVENTMANAGEMENT.PATH}>Events</Link>
            </div>
            <div>
            <div className="graph-filter-row">
              <p>Total Event Hours : <span>{result}</span></p>
            <Select
              placeholder="Current Month"
              styles={customSelectStyles}
              onChange={e => this.handleChange(e, 'learningEvents')}
              options={categoryOptionsTypes}
              className="react-select-box"
            />
            </div>
              <BarChart chartData={learningEvents} dateSelected={eventsDateSelected} role={staticConstants.ROLE.PROFESSIONAL} />
            </div>
          </div>
          <div className="graph-wrapper">
            <div className="dashboard-graph-header">
              <h2 className="heading heading-sm roboto-bold text-uppercase">Total Payments:  ${totalInvestments}</h2>
              <Link to={`${pageRoutes.PROFESSIONAL.PATH}${pageRoutes.PROFESSIONAL.VIEW_PROFILE.PATH}#3`}>
                Transactions
              </Link>
            </div>
            <div>
              <Select
                placeholder="Current Month"
                styles={customSelectStyles}
                onChange={(e) => this.handleChange(e, 'totalInvestment')}
                options={categoryOptionsTypes}
                className="react-select-box"
              />
              <AreaChart chartData={totalInvestmentChartData} type="totalInvestment" dateSelected={investmentDateSelected}/>
            </div>
          </div>
          <div className="graph-box-sm">
            <div className="graph-wrapper graph-col-2">
              <div className="dashboard-graph-header">
                <h2 className="heading heading-sm roboto-bold text-uppercase">Upcoming Events</h2>
                <Link to={pageRoutes.EVENTMANAGEMENT.PATH}>View All</Link>
              </div>
              {upcomingEvents[0] === undefined ? <p className="empty-data">No results found</p> :
              upcomingEvents.map((event: any) => {
                const { title, _id, bid } = event;
                const dateAvailability = moment(bid[0].proposedDate).format(staticConstants.DATE_FORMAT);
                const eventTime = moment(bid[0].proposedTime).format(staticConstants.TIME_FORMAT);
                return (
                  <div key={_id} className="event-table-row">
                    <div className="event-table-col table-col-4">
                      <span>Title</span>
                      <p className="text-truncate">{title}</p>
                    </div>
                    <div className="event-table-col table-col-2">
                      <span>Date</span>
                      <p className="text-truncate">{dateAvailability}</p>
                    </div>
                    <div className="event-table-col table-col-2">
                      <span>Time</span>
                      <p className="text-truncate">{eventTime}</p>
                    </div>
                    <div className="event-table-col table-col-2 text-right pt-2">
                      <Link
                        to={{
                          pathname: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}/${bid[0]._id}`,
                          state: { activeTab: staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS },
                        }}
                      >
                        <Button className="btn btn-primary">View</Button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="graph-wrapper graph-col-2">
              <div className="dashboard-graph-header">
                <h2 className="heading heading-sm roboto-bold text-uppercase">Messages</h2>
                <Link to={pageRoutes.MESSAGE_BOARD.PATH}>View All</Link>
              </div>
              <div className="w-100">
                {latestUnreadMessages[0] === undefined ? <p className="empty-data">No unread messages.</p> :
                latestUnreadMessages.map((msg: any) => {
                  const { profileImage, content, roomId, name = '' } = msg[0];
                  return (
                    <div key={roomId} className="chat-row">
                      <span className="chat-user-icon">
                        <img src={profileImage || profile} alt="user" className="img-cover" />
                      </span>
                      <div className="chat-data">
                        <h3>{name}</h3>
                        <p className="text-truncate">{content}</p>
                      </div>
                      <span className="chat-count">{msg.length}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: { dashboard: { dashboardData: any; upcomingEvents: Array<[]> } }) {
  const { dashboardData, upcomingEvents } = state.dashboard;
  return {
    dashboardData,
    upcomingEvents,
  };
}
const connectedDashboardData = connect(mapStateToProps)(DashBoardPage);
export { connectedDashboardData as DashBoardPage };
