import { GET_ADMIN_APP_FEEDBACK_LIST_QUERY } from '../graphql-client/queries/user';
import { SYSTEM_ADMIN_REPLY_FEEDBACK, SYSTEM_ADMIN_REMOVE_FEEDBACK } from '../graphql-client/mutations/feedbackMutations';
import { client } from '../index';
import { handleResponse } from '../utils/common';

export const appFeedbackServices = {
  getAppFeedbackLists,
  replyFeedback,
  deleteFeedback,
};

async function getAppFeedbackLists(data: object) {
  const response = await client.query({
      query: GET_ADMIN_APP_FEEDBACK_LIST_QUERY,
      variables: data,
    });
  const resp = await handleResponse(response);
  return resp.feedbackList;
}

async function replyFeedback(data: object) {
  const response = await client.mutate({
      mutation: SYSTEM_ADMIN_REPLY_FEEDBACK,
      variables: data,
    });
  const resp = await handleResponse(response);
  return resp.replyFeedback;
}

async function deleteFeedback(data: object) {
  const response = await client.mutate({
      mutation: SYSTEM_ADMIN_REMOVE_FEEDBACK,
      variables: data,
    });
  const resp = await handleResponse(response);
  return resp.removeFeedback;
}
