import _, { isEqual } from 'lodash';
import React, { Dispatch } from 'react';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import { v4 } from 'uuid';
import { eventTypes, tableItemsCount, staticConstants } from '../../../../utils/staticConstants';
import { bidAction } from '../redux/actions';
import { history } from '../../../../helpers';
import { getSelectedValue, getUpdatedOptions, reactSelectFilters, cancelBid, TABLE_SORTING, MAGIC_NUMBER } from './../../../../utils/';
import { customSelectStyles } from './../../../../utils/common';
import { tagAction } from './../../../shared/tags/redux/actions';
import { BidItem } from './bid';
import OpenFieldTypes from 'OpenFieldTypes';
import { MakeBid } from '../makeBid';
import { ErrorPopup } from '../../../shared/modals/errorPopup';
import { IGoalDetailWithId } from '../../../../interfaces';
import { CancelBidPopup } from '../../../shared/modals/cancelBidPopup';

const resetOption = [{ value: '', label: 'Reset Filter' }];

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  categoryOptions: any;
  subCategoryOptions: any;
  domainOptions: any;
  user: any;
  role: any;
  bids: any;
  activeTab: string;
}

interface IState {
  displayErrorPopup: boolean;
  selectedOption: null;
  activePage: number;
  items: any;
  title: string;
  eventType: any;
  category: any;
  subCategory: any;
  domain: any;
  sort: string;
  sortBy: number;
  bids: any;
  fetchBid: boolean;
  eventTypeOptions: any;
  bidId: string;
  reqObj: object;
  selectedCategory: any;
  selectedDomain: any;
  activeTab: string;
  isOpenBidModal: boolean;
  bidGoal: IGoalDetailWithId;
  openFilter: any;
  displayCancelPopup: boolean;
  modalPopupObj: object;
}

class ListingContainer extends React.Component<IProps, IState> {

  /**
   * @description getDerivedStateFromProps is used whenever there is change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: any, state: any) {
    let newState = null;
    if (props.activeTab !== state.activeTab) {
      newState = {
        activeTab: props.activeTab,
        activePage: MAGIC_NUMBER.ONE,
      };
    }
    if (props.bids && props.bids.list) {
      newState = {
        bids: props.bids
      };
    }
    return newState;
  }

  /**
   * @description constructor is used to define the initial state and property
   * @param fields {Object} props
   */
  constructor(props: IProps) {
    super(props);
    this.state = {
      displayErrorPopup: false,
      selectedOption: null,
      activePage: MAGIC_NUMBER.ONE,
      items: tableItemsCount,
      title: '',
      eventType: null,
      category: null,
      subCategory: null,
      domain: null,
      sort: '',
      sortBy: 0,
      bids: {
        count: 0,
        list: [],
        fetchedBidListing: false,
      },
      fetchBid: false,
      eventTypeOptions: resetOption.concat(eventTypes),
      bidId: '',
      reqObj: {},
      selectedCategory: null,
      selectedDomain: null,
      activeTab: this.props.activeTab,
      isOpenBidModal: false,
      bidGoal: null,
      openFilter: false,
      displayCancelPopup: false,
      modalPopupObj: {},
    };
  }

  /**
   * @description componentDidMount is called when component load first time.
   *  called getBids to get the listing of the bids
   */
  public componentDidMount() {
    const { items, activePage } = this.state;
    this.props.dispatch(tagAction.getCategories('', {}));
    this.props.dispatch(tagAction.getDomains());
    this.props.dispatch(bidAction.getBids({
      page: Number(activePage),
      limit: items,
      rfpType: this.props.activeTab,
    }));
  }

  /** filter toggle for responsive ui */
  public filterToggle = () => {
    this.setState({
      openFilter: true,
    });
  }
  public filterRemove = () => {
    this.setState({
      openFilter: false,
    });
  }
  /**
   * @description
   * handleCategory is called when there is change in category state
   * called tagAction.getSubCategories
   */
  public handleCategory = (e, category: any) => {
    this.setState({ selectedCategory: category });
    this.props.dispatch(tagAction.getSubCategories(category, {}));
    this.handleChange(e, 'category'); // Need to discuss...
  }

  /**
   * @description
   * handleDomain is called when there is change in domain state
   */
  public handleDomain = (e, domain: any) => {
    this.setState({ selectedDomain: domain });
    this.handleChange(e, 'domain');
  }

  /**
   * @description componentDidUpdate is called when there is change in fetchBid state
   * called getBids to get the listing of the bids
   */
  public componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.activeTab !== this.props.activeTab) {
      this.props.dispatch(bidAction.resetBidList());
      this.props.dispatch(bidAction.getBids({
        page: MAGIC_NUMBER.ONE,
        limit: this.state.items,
        rfpType: this.props.activeTab,
      }));
      this.setState({ fetchBid: false, title: '' });
    }
    if (!isEqual(prevState.bids, this.state.bids) && !this.state.bids.count && this.state.bids.list.length) {
      this.fetchbidsData(1, true);
    }
  }

  /**
   * @description componentWillUnmount is called to reset the tags
   */
  public componentWillUnmount() {
    this.props.dispatch(tagAction.resetAllTags());
  }

  /**
   * @description fetchbidsData is called to get the listing of the bids again with filter data with sorting
   */
  public fetchbidsData(pageno: number, isPagination = false) {
    const { sort, sortBy } = this.state;
    let reqObj = {
      page: pageno,
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      domain: getSelectedValue(this.state.domain),
      title: this.state.title,
      rfpType: this.props.activeTab,
    };
    if (isPagination) {
      reqObj = Object.assign(reqObj, { isPagination });
    }
    if (sort) {
      reqObj = Object.assign(reqObj, { sort, sortBy });
    }
    this.props.dispatch(bidAction.getBids(reqObj));
  }

  /**
   * @description
   * handleChange is called when someone searched
   * @param fields {Object} e
   * @param fields {key} string
   */
  public handleChange = (e: any, key: any) => {
    const value = key === 'title' ? e.target.value : e;
    this.setState({ ...this.state, [key]: value },
      () => { this.fetchbidsData(1); });
  }

  /**
   * @description handlePageChange is called when someone change the pagination
   * @param fields {pageno} number
   */
  public handlePageChange = (pageno: number) => {
    this.setState({
      activePage: pageno,
    }, () => this.fetchbidsData(pageno));
  }

  /**
   * @description sortData is called to implement sorting
   * @param fields {sort} string
   * @param fields {sortBy} string
   */
  public sortData(sort: string, sortBy: any) {
    const { activePage } = this.state;
    this.setState({ ...this.state, sort, sortBy },
      () => this.fetchbidsData(activePage));
  }

  /**
   * @description renderBids is called to display the listings
   */
  public renderBids() {
    const { list } = this.state.bids;
    return _.map(list, (item) => {
      return (
        <BidItem activeTab={this.props.activeTab} addToFavourite={this.addToFavourite} key={v4()} bid={item}
          makeBid={this.openMakeBidModal} cancelBid={this.showCancelPopup} />
      );
    });
  }

  /**
   * @description
   * open bid modal
   */
  public openMakeBidModal = (goal: IGoalDetailWithId) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!Boolean(user.isStripeAccount)) {
      this.setState({ displayErrorPopup: true });
    } else {
      this.setState({
        ...this.state,
        isOpenBidModal: true,
        bidGoal: goal,
      });
    }
  }

  /**
   * @description
   * addToFavourite is called when someone click on heart button
   * @param fields {bidId} string
   * @param fields {isFavourite} boolean
   */
  public addToFavourite = (isFavourite: boolean, bidId: string) => {
    this.props.dispatch(bidAction.addToFavourite({
      isFavourite,
      bidId,
      activeTab: this.props.activeTab,
    }));
  }

  /**
   * @description
   * showCancelPopup is used show confirmation popup.
   * @param fields {String} id
   */
  public showCancelPopup = (id: string) => {
    const modalPopupObj = cancelBid;
    const reqObj = {
      page: Number(this.state.activePage),
      limit: this.state.items,
      rfpType: this.props.activeTab,
    };
    this.setState({
      displayCancelPopup: true,
      bidId: id,
      reqObj,
      modalPopupObj,
    });
  }

  /**
   * @description
   * cancelRequest is called when someone click on the ok button
   */
  public cancelRequest = () => {
    const { bidId, reqObj } = this.state;
    this.props.dispatch(bidAction.cancelBid({ bidId }, reqObj));
    this.setState({
      displayCancelPopup: false,
      modalPopupObj: {},
      bidId: '',
    });
  }

  /**
   * @description
   * cancelRequest is called when someone click on the ok button
   */
  public hideMakeBidModal = () => {
    this.setState({
      isOpenBidModal: false,
      bidGoal: null,
    });
  }

  public getHeaderText = (activeTab) => {
    let createdHeaderText = '';
    switch (activeTab) {
      case staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST:
      case staticConstants.BID_MANAGEMENT_TAB.OPEN_REQUEST:
        createdHeaderText = 'Created';
        break;
      case staticConstants.BID_MANAGEMENT_TAB.SAVED_REQUEST:
        createdHeaderText = 'Saved';
        break;
      case staticConstants.BID_MANAGEMENT_TAB.SENT_BIDS:
        createdHeaderText = 'Proposed';
        break;
      case staticConstants.BID_MANAGEMENT_TAB.REJECTED_BIDS:
        createdHeaderText = 'Rejected';
        break;
    }
    return createdHeaderText;
  }

  public getMaxMin = (activePage: any, bids: any, items: any) => {
    let minDisplay: number;
    let maxDisplay: number;
    if (bids.count === MAGIC_NUMBER.ZERO) {
      maxDisplay = MAGIC_NUMBER.ZERO;
      minDisplay = MAGIC_NUMBER.ZERO;
    } else if (bids.count <= items) {
      maxDisplay = bids.count;
      minDisplay = 1;
    } else {
      maxDisplay = (Number(activePage) * Number(items)) < bids.count
        ? (Number(activePage) * Number(items)) : bids.count;
      minDisplay = ((Number(activePage) - 1) * Number(items)) + 1;
    }
    return { minDisplay, maxDisplay };
  }

  /**
   * @description render is used to render the component
   */
  public render() {
    const reqObj = {
      page: Number(this.state.activePage),
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      category: getSelectedValue(this.state.category),
      title: this.state.title,
      rfpType: this.props.activeTab,
    };
    const { activeTab, user } = this.props;
    const { activePage, items, eventType, domain,
      bids, eventTypeOptions, sort, sortBy, displayCancelPopup, modalPopupObj, title, isOpenBidModal, bidGoal } = this.state;
    const domainOptionTypes = resetOption.concat(this.props.domainOptions);
    const { maxDisplay, minDisplay } = this.getMaxMin(activePage, bids, items);
    const createdHeaderText = this.getHeaderText(activeTab);
    
    return (
      <React.Fragment>
        <div className="filter-wrapper">
          <div className="search-bar">
            <input type="search" placeholder="Search by Keywords, Title"
              onChange={(e) => this.handleChange(e, 'title')} value={title} />
            <span className="icon icon-search-icon" />
            <div className="filter-icon" onClick={this.filterToggle} />
          </div>
          <div className={`filter-bar ${this.state.openFilter ? 'filter-open' : ''}`}>
            <div className="filter-dropdown">
              <Select
                placeholder="Event Type"
                value={eventType}
                onChange={(e) => this.handleChange(e, 'eventType')}
                options={eventTypeOptions}
                styles={customSelectStyles}
                className="react-select-box"
                filterOption={createFilter(reactSelectFilters)}
              />
            </div>
            <div className="filter-dropdown">
              <Select
                placeholder="Domain"
                value={domain}
                onChange={(e: any) => this.handleDomain(e, domain)}
                options={domainOptionTypes}
                styles={customSelectStyles}
                className="react-select-box"
                autosize={true}
                filterOption={createFilter(reactSelectFilters)}
              />
            </div>
            <span className="icon icon-cross icon-sm-device" onClick={this.filterRemove} />
            {/* <Button color="primary" className="filter-sm-device">Apply</Button> */}
          </div>
        </div>
        {this.props.activeTab === staticConstants.BID_MANAGEMENT_TAB.OPEN_REQUEST &&
          <p className="refer-text">Open requests refers to requests that you have not been matched with.
            To bid on open requests, you will first need to update your tags on your profile.</p>
        }
        <div className="table-wrapper">
          <div className="flex-table">
            <div className="table-head">
              <div className="table-row">
                <div className="table-col table-col-20">
                  <span className="sorting-icon">Title
                      <strong>
                      <em className={(sort === 'title' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('title', MAGIC_NUMBER.ONE); }} />
                      <em className={(sort === 'title' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('title', MAGIC_NUMBER.FOUND_INDEX); }} />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-10">
                  <span className="sorting-icon">Event Type
                      <strong>
                      <em className={(sort === 'eventType' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('eventType', MAGIC_NUMBER.ONE); }} />
                      <em className={(sort === 'eventType' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('eventType', MAGIC_NUMBER.FOUND_INDEX); }} />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-20">
                  <span className="sorting-icon">Domain
                      <strong>
                      <em className={(sort === 'domain' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('domain', MAGIC_NUMBER.ONE); }} />
                      <em className={(sort === 'domain' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('domain', MAGIC_NUMBER.FOUND_INDEX); }} />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-15">
                  <span className="sorting-icon">Sub-Domain
                      <strong>
                      <em className={(sort === 'subDomain' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('subDomain', MAGIC_NUMBER.ONE); }} />
                      <em className={(sort === 'subDomain' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('subDomain', MAGIC_NUMBER.FOUND_INDEX); }} />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-10">
                  <span className="sorting-icon">{createdHeaderText === 'Rejected' ? 'Date' : createdHeaderText}
                    {
                      createdHeaderText === 'Rejected'
                        ? <strong>
                          <em className={(sort === TABLE_SORTING.BID.REJECTEDAT && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                            onClick={() => { this.sortData(TABLE_SORTING.BID.REJECTEDAT, MAGIC_NUMBER.ONE); }} />
                          <em className={(sort === TABLE_SORTING.BID.REJECTEDAT && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                            onClick={() => { this.sortData(TABLE_SORTING.BID.REJECTEDAT, MAGIC_NUMBER.FOUND_INDEX); }} />
                        </strong>
                        : <strong>
                          <em className={(sort === 'createdAt' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                            onClick={() => { this.sortData('createdAt', MAGIC_NUMBER.ONE); }} />
                          <em className={(sort === 'createdAt' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                            onClick={() => { this.sortData('createdAt', MAGIC_NUMBER.FOUND_INDEX); }} />
                        </strong>
                    }
                  </span>
                </div>
                <div className="table-col table-col-15">
                  <span className="sorting-icon justify-content-center">Participants
                      <strong>
                      <em className={(sort === 'totalParticipants' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('totalParticipants', MAGIC_NUMBER.ONE); }} />
                      <em className={(sort === 'totalParticipants' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('totalParticipants', MAGIC_NUMBER.FOUND_INDEX); }} />
                    </strong>
                  </span>
                </div>
                {activeTab === staticConstants.BID_MANAGEMENT_TAB.REJECTED_BIDS &&
                  <div className="table-col table-col-15">
                    <span className="sorting-icon justify-content-center">Status
                      <strong>
                        <em className={(sort === TABLE_SORTING.BID.REQUESTEDBYROLE && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData(TABLE_SORTING.BID.REQUESTEDBYROLE, MAGIC_NUMBER.ONE); }} />
                        <em className={(sort === TABLE_SORTING.BID.REQUESTEDBYROLE && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData(TABLE_SORTING.BID.REQUESTEDBYROLE, MAGIC_NUMBER.FOUND_INDEX); }} />
                      </strong>
                    </span>
                  </div>
                }

                {
                  activeTab !== staticConstants.BID_MANAGEMENT_TAB.REJECTED_BIDS &&
                  <div className="table-col table-col-10 text-center">
                    <span>Action</span>
                  </div>
                }
              </div>
            </div>
            <div className="table-body">
              {this.renderBids()}
              {bids && bids.fetchedBidListing && bids.list && !bids.list.length ?
                <div className="table-row table-no-data">
                  No Results Found.
                    </div>
                : ''}
            </div>
            {(bids && bids.count > MAGIC_NUMBER.TEN) &&
              <div className="table-footer">
                <React.Fragment>
                  <p>Showing {minDisplay} - {maxDisplay} of {bids.count}</p>
                  <div className="pagination-wrapper">
                    <Pagination
                      hideFirstLastPages
                      activePage={activePage}
                      itemsCountPerPage={items}
                      totalItemsCount={bids.count}
                      pageRangeDisplayed={MAGIC_NUMBER.FIVE}
                      onChange={this.handlePageChange}
                    />
                  </div>
                </React.Fragment>
              </div>
            }
          </div>
        </div>
        <MakeBid isOpenModal={isOpenBidModal} goal={bidGoal} reqObj={reqObj} hideModal={this.hideMakeBidModal} />
        {displayCancelPopup &&
          <CancelBidPopup isOpenModal={displayCancelPopup} modalPopupObj={modalPopupObj}
            hideModal={() => this.setState({ displayCancelPopup: false })} onClickYes={this.cancelRequest}
          />
        }
        {this.state.displayErrorPopup &&
          <ErrorPopup
            isOpenModal={true}
            hideModal={() => this.setState({ displayErrorPopup: false })}
            connectStripe={() => {
              this.setState({ displayErrorPopup: false });
              history.push(`/${user.role.toLowerCase()}/viewProfile/#3`);
            }}
          />
        }
      </React.Fragment >
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, tags: { categories, domains, subCategories }, isOpenBidModal } = state;
  const { user } = authentication;
  return {
    user,
    role: user.role,
    bids: state.bids,
    isOpenBidModal: state.bids.isOpenBidModal,
    bidGoal: isOpenBidModal ? state.bidGoal : null,
    categoryOptions: getUpdatedOptions(categories, 'name'),
    subCategoryOptions: getUpdatedOptions(subCategories, 'name'),
    domainOptions: getUpdatedOptions(domains, 'name'),
  };
}

const connectedBidsListingContainer = connect(mapStateToProps)(ListingContainer);
export { connectedBidsListingContainer as ListingContainer };
