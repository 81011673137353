import OpenFieldTypes from 'OpenFieldTypes';
import moment from 'moment';
import { partition, pick, uniq } from 'lodash';
import { firestore } from 'firebase';
import { actionTypes } from './types';
import { myFirestore } from '../../../../config/firebase';
import { IParams } from '../../../../interfaces';
import { alertActions } from './../../../alert/redux/actions';
import { messageBoardServices } from './../../../../services/messageBoardServices';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { firestoreQueryConstants } from '../../../../utils/firestoreQueryConstants';
import { getMyUserId, hashString, handleError, staticConstants, ALERT_MESSAGES } from '../../../../utils';

export const chatAction = {
  getAllConnections,
  setCurrentSelectedUser,
  getInboxUser,
  getAllMessage,
  generateRooms,
  generateRoomId,
  getLastMessage,
  getTotalUnreadCount,
  getGroups,
  createGroup,
  removeGroup,
  sendMessage,
};

const { CHAT, CHAT_HISTORY, TIMESTAMP, MESSAGE_BOARD, BROADCAST } = firestoreQueryConstants;

function getAllConnections(loading = false, userType?: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    if (loading) {
      dispatch(startLoading('MAIN_LOADER', 'Loading'));
    }
    messageBoardServices.getMessageBoardConnections()
      .then(
        async (res) => {
          const rawData = res ? prepareConnections(res.data) : [];
          const withLastMessage = await getLastMessage(rawData);
          const withAllBroadcasts = await getAllBroadcasts(userType);
          const cleanData = [...withAllBroadcasts, ...withLastMessage];
          const part = partition(cleanData, user => user.lastMessage.timeStamp);        // partition based on last message timeStamp
          const sortedUsersWithTime = part[0].sort((a, b) => b.lastMessage.timeStamp - a.lastMessage.timeStamp);
          const sortedData = [...sortedUsersWithTime, ...part[1]];
          dispatch(success(sortedData));
          setTimeout(() => dispatch(endLoading('MAIN_LOADER')), 1e3);
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };
  function request() { return { type: actionTypes.ALL_CONNECTIONS_REQUEST }; }
  function success(data: any) { return { type: actionTypes.ALL_CONNECTIONS_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.ALL_CONNECTIONS_FAILURE, error }; }
}

function prepareConnections(rawConnections: Array<[]>) {
  const allConnections = [];
  rawConnections.forEach((connection: any) => {
    const { user1, user2 } = connection;
    const myUserId = getMyUserId();
    let newConnection = {};
    if (user1.hasOwnProperty('_id') && user1._id === myUserId) {
      newConnection = { userId: user2._id, ...user2, roomId: connection.roomId, connectionId: connection._id, lastMessage: {} };
    }
    if (user2.hasOwnProperty('_id') && user2._id === myUserId) {
      newConnection = { userId: user1._id, ...user1, roomId: connection.roomId, connectionId: connection._id, lastMessage: {} };
    }
    allConnections.push(newConnection);
  });
  return allConnections;
}

async function getLastMessage(users: any) {
  await Promise.all(users.map(async (element: any) => {
    if (element._id !== getMyUserId()) {
      const messages = await myFirestore
                .doc(`${MESSAGE_BOARD}/${element.connectionId}`)
                .collection(CHAT_HISTORY)
                .orderBy(TIMESTAMP, 'desc')
                .get();
      const unreadCount = messages.docs.filter(m => !m.data().isRead).length;
      const msg = messages.docs[0] ? messages.docs[0].data() : {};
      if (msg.content) {
        msg['receiptType'] = msg.from === getMyUserId() ? 'send' : 'received';
        msg['date'] = moment(msg.timeStamp).format(staticConstants.MESSAGE_DATE_FORMAT);
        msg['time'] = moment(msg.timeStamp).format(staticConstants.MESSAGE_TIME_FORMAT);
        msg['timeAgo'] = moment(msg.timeStamp).fromNow();
      }
      element.lastMessage = msg;
      element.unreadCount = unreadCount || 0;
      return element;
    }
    return element;

  }));
  const filteredUsers = users.filter(m => m.lastMessage);
  const part = partition(filteredUsers, u => u.lastMessage.timeStamp);        // partition based on last message timeStamp
  const sortedUsers = part[0].sort((a, b) => b.lastMessage.timeStamp - a.lastMessage.timeStamp);
  return [...sortedUsers, ...part[1]];
}

function setCurrentSelectedUser(user: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    return dispatch({
      type: actionTypes.SET_SELECTED_USER,
      data: user,
    });
  };
}

function getInboxUser(goalId: IParams, loading = false) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    if (loading) {
      dispatch(startLoading('MAIN_LOADER', 'Loading'));
    }
    myFirestore
            .doc(`/${CHAT}/${goalId}`)
            .get()
            .then(
                (result) => {
                  const data = result.data();
                  if (data) {
                    const goalMembers = data.memberList.filter(user => user._id !== getMyUserId());
                    generateRooms(goalMembers, goalId, dispatch);
                  }
                },
                (error) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(failure(error.toString()));
                },
            );

    function failure(error: any) { return { type: actionTypes.ALL_INBOX_USERS_FAILURE, error }; }
  };
}

function getAllMessage(connectionId: string, isSystemAdmin = false) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    dispatch(getTotalUnreadCount());
    const ROOT_DIR = isSystemAdmin ? BROADCAST : MESSAGE_BOARD;
    myFirestore
            .doc(`${ROOT_DIR}/${connectionId}`)
            .collection(CHAT_HISTORY)
            .orderBy(TIMESTAMP, 'desc')
            .get()
            .then(
                (messages) => {
                  const allMessages = [];
                  messages.docs.forEach((msg) => {
                    const data = msg.data();
                    data['receiptType'] = data['from'] === getMyUserId() ? 'send' : 'received';
                    data['date'] = moment(data.timeStamp).format(staticConstants.MESSAGE_DATE_FORMAT);
                    data['time'] = moment(data.timeStamp).format(staticConstants.MESSAGE_TIME_FORMAT);
                    return allMessages.push(data);
                  });
                  allMessages.sort((a, b) => a.timeStamp - b.timeStamp);
                  markAsRead(connectionId, dispatch);
                  dispatch(success(allMessages));
                  dispatch({ type: actionTypes.ALL_MESSAGES_SUCCESS, data: allMessages });
                },
                error => dispatch(failure(error.toString())),
            );
  };
  function request() { return { type: actionTypes.ALL_MESSAGES_REQUEST }; }
  function success(data: any) { return { type: actionTypes.ALL_MESSAGES_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.ALL_MESSAGES_FAILURE, error }; }
}

function generateRoomId(userId: string) {
  let roomId = '';
  if (hashString(getMyUserId()) <= hashString(userId)) {
    roomId = `${getMyUserId()}-${userId}`;
  } else {
    roomId = `${userId}-${getMyUserId()}`;
  }
  return roomId;
}

async function generateRooms(users: any, goalId: any, dispatch: OpenFieldTypes.RootAction) {
  await Promise.all(users.map(async (element) => {
    if (element._id !== getMyUserId()) {
      const roomId = generateRoomId(element._id);
      element.roomId = roomId;
      return element;
    }
    return element;

  }));
  return users;
}

function markAsRead(connectionId: string, dispatch: OpenFieldTypes.RootAction) {
  myFirestore
        .doc(`${MESSAGE_BOARD}/${connectionId}`)
        .collection(CHAT_HISTORY)
        .where('isRead', '==', false)
        .get()
        .then((msgHistory) => {
          msgHistory.docs.forEach((doc) => {
            if (doc.data().to === getMyUserId()) {
              const batch = myFirestore.batch();
              batch.update(doc.ref, { isRead: true });
              batch.commit()
                        .then(() => {
                          dispatch(getAllConnections());
                          dispatch(getTotalUnreadCount());
                        });
            }
          });
        });
}

function getTotalUnreadCount(cb?: (allUnread) => void) {
  return async (dispatch: OpenFieldTypes.RootAction) => {
    let totalUnreadCount = 0;
    let totalUnreadChats = 0;
    if (getMyUserId()) {
      await myFirestore
            .collection(MESSAGE_BOARD)
            .where('members', 'array-contains', getMyUserId())
            .get()
            .then(async (snapshot) => {
              const allUnread = [];
              await Promise.all(snapshot.docs.map(async (document) => {
                await myFirestore.doc(document.ref.path)
                        .collection(CHAT_HISTORY)
                        .orderBy(TIMESTAMP, 'desc')
                        .where('isRead', '==', false)
                        .get()
                        .then((chatHistory) => {
                          const unreadMessage = chatHistory.docs.filter(m => m.data().to === getMyUserId());
                          const unreadChats = chatHistory.docs.some(c => c.data().to === getMyUserId());
                          totalUnreadChats = unreadChats ? totalUnreadChats + 1 : totalUnreadChats;
                          totalUnreadCount += unreadMessage.length;
                          unreadMessage.forEach(x => allUnread.push(x.data()));
                          dispatch({ type: actionTypes.NEW_UNREAD_MESSAGE, data: false, totalUnreadCount, totalUnreadChats });
                          return totalUnreadCount;
                        });
              }));
              cb && cb(allUnread);
            });
    }
    return totalUnreadCount;
  };
}

function getGroups() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    messageBoardServices.getGroups()
            .then(
                res => dispatch(success(res.data)),
                (error) => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
  };
  function request() { return { type: actionTypes.GET_GROUPS_REQUEST }; }
  function success(data: any) { return { type: actionTypes.GET_GROUPS_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.GET_GROUPS_FAILURE, error }; }
}

function createGroup(groupName: string, users: Array<[string]>, cb?: () => void) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    messageBoardServices.createGroup(groupName, users)
            .then(
                (res) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(res.data));
                  cb && cb();
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };
  function request() { return { type: actionTypes.ADD_GROUP_REQUEST }; }
  function success(data: any) { return { type: actionTypes.ADD_GROUP_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.ADD_GROUP_FAILURE, error }; }
}

function removeGroup(groupId: string, cb: () => void) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    messageBoardServices.removeGroup(groupId)
            .then(
                (res) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(res.data));
                  cb && cb();
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };
  function request() { return { type: actionTypes.REMOVE_GROUP_REQUEST }; }
  function success(data: any) { return { type: actionTypes.REMOVE_GROUP_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.REMOVE_GROUP_FAILURE, error }; }
}

function sendMessage(payload: { [key: string]: string | number | boolean }, members: Array<[string]>, cb?: () => void) {
  return (dispatch: OpenFieldTypes.RootAction, getState) => {
    const { roomId, to, content, timeStamp, isBroadcast = false } = payload;
    if (content && to) {
      const ROOT_DIR = isBroadcast ? BROADCAST : MESSAGE_BOARD;
      myFirestore
        .doc(`${ROOT_DIR}/${roomId}/${CHAT_HISTORY}/${timeStamp}`)
        .set({ ...payload }, { merge: true })
        .then(() => {
          cb && cb();
          dispatch(getAllMessage(String(roomId), Boolean(isBroadcast)));
          updateLastMessageInFirestore(payload, roomId, members, isBroadcast, getState);
        })
        .catch(() => dispatch(alertActions.errorAlert(ALERT_MESSAGES.MESSAGE_BOARD.SEND_MESSAGE_FAILED)));
    }
  };
}

function updateLastMessageInFirestore(msgObj, roomId, users, isBroadcast, getState) {
  const members = [getMyUserId(), ...users];
  const ROOT_DIR = isBroadcast ? BROADCAST : MESSAGE_BOARD;
  const { authentication: { user } } = getState();
  const userInfo = pick(user, ['firstName', 'lastName', 'role', 'email', 'profileImage']);
  userInfo['userId'] = user._id;
  userInfo['_id'] = roomId;
  userInfo['roomId'] = roomId;
  userInfo['connectionId'] = roomId;
  userInfo['organizationId'] = null;
  userInfo['isSystemAdmin'] = true;
  userInfo['target'] = msgObj.target;
  userInfo['targetName'] = msgObj.targetName;
  if (msgObj.targetProfile) {
    userInfo['targetProfile'] = msgObj.targetProfile;
  }

  const updateObj = { lastMessage: msgObj, members: uniq(members) };
  if (isBroadcast) updateObj['userInfo'] = userInfo;
  myFirestore
    .doc(`${ROOT_DIR}/${roomId}`)
    .set(updateObj, { merge: true });
}

async function getAllBroadcasts(target: string) {
  const allBroadcasts = [];
  let query: firestore.Query = myFirestore.collection(BROADCAST);
  if (target) query = query.where('lastMessage.target', '==', target);
  try {
    const result = await query.get();
    const filteredDocs = result.docs.filter(res => res.data().members.includes(getMyUserId()));
    filteredDocs.forEach((msg) => {
      const data = msg.data();
      data.lastMessage['receiptType'] = data['from'] === getMyUserId() ? 'send' : 'received';
      data.lastMessage['date'] = moment(data.lastMessage.timeStamp).format(staticConstants.MESSAGE_DATE_FORMAT);
      data.lastMessage['time'] = moment(data.lastMessage.timeStamp).format(staticConstants.MESSAGE_TIME_FORMAT);
      data.lastMessage['timeAgo'] = moment(data.lastMessage.timeStamp).fromNow();
      const userReceived = data.userInfo;
      userReceived.lastMessage = data.lastMessage;
      userReceived.members = data.members;
      return allBroadcasts.push(userReceived);
    });
    return allBroadcasts;
  } catch (error) {
    return allBroadcasts;
  }
}
