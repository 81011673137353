import gql from 'graphql-tag';

const GET_DASHBOARD_QUERY = gql`
  query dashboard($input: [DashboardInput]) {
    dashboard(input: $input) {
      message,
      data {
        totalInvestment {
          totalInvestmentCount,
          totalInvestment {
            month,
            count,
            avgRating,
            week,
            year,
            totalEvent,
            totalIncome,
            amountSpent,
          }
          totalIncome,
          groupedIncome {
            month,
            count,
            avgRating,
            week,
            year,
            totalEvent,
            totalIncome,
            amountSpent,
          }
        },
        learningGoals {
          totalAdminLearningGoal,
          totalLearnerLearningGoal,
          totalCreatedGoals,
          totalRequestSent,
          totalAccepted,
          submitted,
          shortlisted,
          acceptedCount,
          createdGoals {
            month,
            count,
            week,
            year,
          }
          adminGoalCreated {
            month,
            count
            week,
            year,
          },
          acceptedRequestLearner {
            month,
            count,
            week,
            year,
          }
          requestSent {
            month,
            week,
            count,
            year,
          },
          accepted {
            month,
            week,
            count,
            year,
          },
          acceptedRequest {
            month,
            week,
            count,
            year,
          },
          rejectedRequest {
            month,
            week,
            count,
            year,
          },
          newRequest {
            month,
            week,
            count,
            year,
          },
          requestedBids {
            month,
            week,
            count,
            year,
          },
          shortlistedBids {
            month,
            week,
            count,
            year,
          },
          acceptedBids {
            month,
            week,
            count,
            year,
          }
        },
        totalData {
          totalLearningGoal,
          totalEventGoal,
          totalIncome,
          totalBids,
          totalInvestedIncome
        },
        learners {
          totalActiveLearner,
          totalInActiveLearner,
          inActiveLearner {
            month,
            week,
            count,
            year,
          },
          activeLearner {
            month,
            count,
            week,
            year,
          }
        },
        learningEvents {
          totalDuration,
          Advice {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
            totalIncome,
            amountSpent,
          },
          Assessment {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
            totalIncome,
            amountSpent,
          },
          Briefing {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
            totalIncome,
            amountSpent,
          },
          Facilitation {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
            totalIncome,
            amountSpent,
          },
          Interview {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
            totalIncome,
            amountSpent,
          },
          Talk {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
            totalIncome,
            amountSpent,
          },
          Training {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
            totalIncome,
            amountSpent,
          },
          ProblemSolving {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
            totalIncome,
            amountSpent,
          }
        }
      }
    }
}`;

const GET_DASHBOARD_UPCOMING_EVENTS = gql`
  query upcommingEvents{
    upcommingEvents {
      message
      data {
        _id
        title
        status
        bid{
          _id
          proposedTime
          proposedDate
        }
      }
      date
      count
    }
}`;

const GET_DASHBOARD_REVIEWS = gql`
  query rating{
    rating {
      message
      data {
        adminRatingGoal {
          expertRating
          comment
          createdAt
        }
        userData {
          firstName
          lastName,
          profileImage
        }
        _id
      }
    }
}`;

const GET_ADMIN_DASHBOARD_QUERY = gql`
  query dashboard($input: [DashboardInput]) {
    dashboard(input: $input) {
      message,
      data {
        learningGoals {
          totalOrgAdminLearningGoal,
          totalOrgLearnerLearningGoal,
          totalProfessionalLearningGoal,
          createdGoalAdmin {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
          }
          createdGoalOrgLearner {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
          }
          createdGoalProfessional {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
          }
          requestSentOrgAdmin {
            month,
            week,
            count,
            year,
            avgRating,
            totalEvent,
          }
          requestSentProfessional {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
          },
          acceptedOrgAdmin {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
          },
          acceptedLearningGoalLearner {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
          },
          rejectedLearningGoalLearner {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
          },
          acceptedProfessional {
            month,
            count,
            week,
            avgRating,
            year,
            totalEvent,
          }
        },
        totalData {
          totalLearningGoal,
          totalEventGoal,
          totalBids,
          totalRfpRequest,
          totalUpcomingEvents,
          totalUser,
          totalPastEvents,
          totalInvestedIncome,
        },
        learners {
          totalActiveLearner,
          totalInActiveLearner,
          inActiveLearner {
            month,
            week,
            count,
            year,
          },
          activeLearner {
            month,
            week,
            count,
            year,
          }
        },
        learningEvents {
          totalDuration,
          Advice {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
          },
          Assessment {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
          },
          Briefing {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
          },
          Facilitation {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
          },
          Interview {
            month,
            week,
            count,
            year,
            avgRating,
            totalEvent,
          },
          Talk {
            month,
            week,
            count,
            year,
            avgRating,
            totalEvent,
          },
          Training {
            month,
            week,
            count,
            avgRating,
            year,
            totalEvent,
          },
          ProblemSolving {
            month,
            week,
            count,
            year,
            avgRating,
            totalEvent,
          }
        }
        createdUser {
          totalUser
          openfielduser {
            month
            count
            year
          }
        }
        revenue {
          commissionFee
          sessionFee
          expertFee
          openfieldSessionFee {
            month
            count
            year
          }
          openfieldCommission {
            month
            count
            year
          }
          openfieldExpertFee {
            month
            count
          }
        }
      }
    }
}`;

const  GET_LATEST_USERS = gql`
  query newUser{
    newUser {
      message
      data {
        email
        firstName
        lastName
        role
        _id
        organizationId
      }
    }
}`;

export { GET_DASHBOARD_QUERY, GET_DASHBOARD_UPCOMING_EVENTS, GET_DASHBOARD_REVIEWS, GET_ADMIN_DASHBOARD_QUERY, GET_LATEST_USERS };
